
import React, { Component } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import {
    Card,
    CardActions,
    CardContent,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Tabs,
    Tab, 
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from '@material-ui/core';

const businessUrl = "https://riskcompass.us19.list-manage.com/subscribe/post?u=979f7c97769d493242996a52f&amp;id=9c935cd8a0";
const intelligenceUrl = "https://riskcompass.us19.list-manage.com/subscribe/post?u=979f7c97769d493242996a52f&amp;id=241c91c522"

class CustomForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            agb: false,
            business: false,
            intelligence: false
        }
    }

    handleChange = name => event => {
        //console.log('event.target', event.target)
        if (name == "agb") {
            this.setState({ [name]: event.target.checked });
        } else {
            this.setState({ [name]: event.target.value });
        }

    };


    submit = () => {
        console.log('submit', this.state)
        const { onValidated } = this.props
        const { email, fname, lname, firma, agb, nutzung, anrede } = this.state
        email &&
            fname &&
            lname &&
            agb &&
            email.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email,
                FNAME: fname,
                LNAME: lname,
                FIRMA: firma,
                NUTZUNG:nutzung,
                ANREDE:anrede
            });
        if (!agb || !fname || !lname || !email) {
            this.setState({error:"Bitte füllen Sie zuerst alle Pflichtfelder aus!"})
        } else {
            this.setState({error:null})
        }
    }



    render() {
        const { status, onValidated, message } = this.props
        const { agb, name, email,error } = this.state
        return (
            <div
                style={{


                    padding: 20,

                }}
            >
             <div style={{ marginTop: 10 }}>
                <FormControl fullWidth={true} >
                    <InputLabel shrink={true}  htmlFor="anrede">Anrede</InputLabel>
                    <Select
                        value={this.state.anrede}
                        onChange={this.handleChange("anrede")}
                        inputProps={{
                            name: 'anrede',
                            id: 'anrede',
                        }}
                    >
                        <MenuItem value="">
                            <em>-</em>
                        </MenuItem>
                        <MenuItem value={"Herr"}>Herr</MenuItem>
                        <MenuItem value={"Frau"}>Frau</MenuItem>
                        <MenuItem value={"Firma"}>Firma</MenuItem>
                    </Select>
                </FormControl>
                </div>
                <div style={{ marginTop: 10 }}>
                    <TextField fullWidth type="text" name="name" label="Vorname *" onChange={this.handleChange('fname')} InputLabelProps={{ shrink: true }} />
                </div>
                <div style={{ marginTop: 10 }}>
                    <TextField fullWidth type="text" name="name" label="Nachname *" onChange={this.handleChange('lname')} InputLabelProps={{ shrink: true }} />
                </div>
               
                <div style={{ marginTop: 10 }}>
                    <TextField fullWidth type="email" name="email" label="E-Mail *" onChange={this.handleChange('email')} InputLabelProps={{ shrink: true }} />
                </div>
                <div style={{ marginTop: 10 }}>
                <FormControl fullWidth={true}>
                    <InputLabel  shrink={true}  htmlFor="nutzung">Nutzung</InputLabel>
                    <Select
                        value={this.state.nutzung}
                        onChange={this.handleChange("nutzung")}
                        inputProps={{
                            name: 'nutzung',
                            id: 'nutzung',
                        }}
                    >
                        <MenuItem value="">
                            <em>-</em>
                        </MenuItem>
                        <MenuItem value={"Privat"}>Privat</MenuItem>
                        <MenuItem value={"Beruflich"}>Beruflich</MenuItem>
                        
                    </Select>
                </FormControl>
                </div>
                <div style={{ marginTop: 10 }}>
                    <TextField fullWidth type="text" name="name" label="Firma (inkl. Rechtsform)" onChange={this.handleChange('firma')} InputLabelProps={{ shrink: true }} />
                </div>
                <div style={{ marginTop: 10 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.agb}
                                onChange={this.handleChange('agb')}
                                value={this.state.agb}
                                color="primary"
                            />
                        }
                        label="Ich akzeptiere die Datenschutzbestimmung (* = Pflichtfeld)"
                    />
                </div>

                <div style={{ textAlign: 'center' }}>


                    {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
                    {status === "error" && (
                        <div
                            style={{ color: "red" }}
                            dangerouslySetInnerHTML={{ __html: message }}
                        />
                    )}
                    {status === "success" && (
                        <div
                            style={{ color: "green" }}
                            dangerouslySetInnerHTML={{ __html: message }}
                        />
                    )}
                    {error && 
                     <div
                     style={{ color: "red" }}
                     dangerouslySetInnerHTML={{ __html: error }}
                 />
                    }
                    <Button style={{ paddingTop: 20 }} variant="contained" color="primary" onClick={this.submit.bind(this)}>
                        Abonnieren
        </Button>
                </div>
            </div>
        );
    }
};

class Newsletter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: 0
        }
    }
    handleChange = (event, value) => {
        this.setState({ value });
    };
    render() {
        const { value } = this.state;
        return (
            <div>
                <Tabs value={value} onChange={this.handleChange.bind(this)}>
                    <Tab label="Risk Intelligence Monitor" />
                   
                </Tabs>

                {value === 0 &&
                    <MailchimpSubscribe
                        url={businessUrl}
                        render={({ subscribe, status, message }) => (
                            <CustomForm
                                status={status}
                                message={message}
                                onValidated={formData => subscribe(formData)}
                            />
                        )}
                    />
                }
                {false && value === 1 &&
                    <MailchimpSubscribe
                        url={intelligenceUrl}
                        render={({ subscribe, status, message }) => (
                            <CustomForm
                                status={status}
                                message={message}
                                onValidated={formData => subscribe(formData)}
                            />
                        )}
                    />
                }


            </div>
        );
    }
}

export default Newsletter


