import {
    FETCH_COUNTRY,
    FETCH_COUNTRIES,
    FETCHING_COUNTRIES,
    FETCHING_COUNTRY,
    FETCHED_COUNTRIES,
    FETCHED_COUNTRY,
} from "../../actions/types";

import * as _ from "lodash";

const INITIAL_STATE = {
    countries: {
        all:[],
        country:{}
    },
    loading: false,
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case FETCH_COUNTRY:

           // console.log('FETCH_COUNTRY')

            return {
                ...state,
                country: action.payload.data,

            };
        case FETCH_COUNTRIES:

           // console.log('FETCH_COUNTRIES')

            return {
                ...state,
                all: action.payload.data,

            };
        case FETCHING_COUNTRIES:
            return {
                ...state,
                loading: action.payload
            }
        case FETCHING_COUNTRY:
            return {
                ...state,
                loading: action.payload
            }
        case FETCHED_COUNTRIES:
            return {
                ...state,
                all: action.payload.data,
                loading: action.payload.loading
            };
        case FETCHED_COUNTRY:
            return {
                ...state,
                country: action.payload.data,
                loading: action.payload.loading
            };

        default:
            return state;
    }
}