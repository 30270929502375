import React, { Component } from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {Link, Redirect} from 'react-router-dom';
import {renderField} from "../../../components/field_formatters";
import PropTypes from 'prop-types';
import {success} from "react-notification-system-redux";
import {Button, IconButton, Menu, MenuItem} from "@material-ui/core";
import validate from './validation';
import { notificationDuration, newSectionDossierOptions as options} from '../../../config';
import AddIcon from '@material-ui/icons/Add';

import {addNewDossierSection, createDossier} from "../actions";

export class DossierNewForm extends Component {

    state = {
        submittedNewDossier: false,
        open: false,
        anchorEl: null
    }

    dispatchNotification(fn, timeout) {
        setTimeout(() => {
            this.context.store.dispatch(fn(notificationOpts));
        }, timeout);
    }

    async handleFormSubmit(formProps) {
        await this.props.createDossier(formProps);
        this.setState({ submittedNewDossier: true });
        this.props.history.goBack()
       // this.dispatchNotification(success, 100);
    }

    handleClick = event => {
        this.setState({
            open: true,
            anchorEl: event.currentTarget
        });
    };

    handleMenuItemClick = (event, index) => {
        this.setState({ open: false });
        switch (index) {
            case 0:
                this.props.addNewDossierSection();
        }
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    render() {

        const {error, handleSubmit} = this.props;

      
      
        let menu = <Menu anchorEl={this.state.anchorEl} id="lock-menu" open={this.state.open} onRequestClose={this.handleRequestClose}>
            {options.map((option, index) => (
                <MenuItem key={option} onClick={event => this.handleMenuItemClick(event, index)}>
                    {option}
                </MenuItem>
            ))}
        </Menu>;

        return (
            <div>
                <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                    <Field name="title" type="text" component={renderField} label="Title"/>
                    <Field name="creator" type="text" component={renderField} label="Author"/>
                    <Field name="text" type="text" component={renderField} label="Author"/>

                  

                    {menu}

                    <IconButton aria-label="More" aria-owns={this.state.open ? 'lock-menu' : null} aria-haspopup="true" onClick={this.handleClick}>
                        <AddIcon/>
                    </IconButton>

                    {error && <strong> {error} </strong>}

                    <div>
                        <Button type="submit" variant="contained" color="primary">Add New Dossier</Button>{' '}
                        <Button variant="contained" onClick={this.props.history.goBack}>Cancel</Button>
                    </div>
                </form>
            </div>
        )
    }
}

const notificationOpts = {
    title: 'Dossier',
    message: 'Successfully Added.',
    position: 'tr',
    autoDismiss: notificationDuration
}

const form = reduxForm({
    form: 'NewDossierForm',
    validate
});

DossierNewForm.contextTypes = {
    store: PropTypes.object
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({createDossier, addNewDossierSection }, dispatch)
}

function mapStateToProps(state) {
    return {
        dossiersections: state.dossiers.dossiersections
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(form(DossierNewForm));