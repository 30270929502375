import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import {
    renderField, renderFieldTextarea, renderHiddenField, renderCheckbox
} from "../../components/field_formatters";
import PropTypes from 'prop-types';
import { Button, LinearProgress, FormControl, FormControlLabel, InputLabel, Select, MenuItem, Checkbox, ListItemText, RadioGroup, Radio, FormLabel, Divider, CardContent } from "@material-ui/core";
//import validate  from './validation'
import { Link } from 'react-router-dom';
import { sendForm } from "./actions";
import Reaptcha from 'reaptcha';




class OfferForm extends Component {

    state = {
        submittedEditDossier: false,
        open: false,
        anchorEl: null,
        verified: false,
        submitted: false,
        submitting: false

    }

    handleFormSubmit(formProps) {
        // console.log('formProps', formProps)
        console.log('this.state', this.state)
        const { history } = this.props
        if (formProps.Nachname && formProps.Email && formProps.Datenschutz && this.state.request && this.state.type && formProps.Mitarbeiter && formProps.Firma && this.state.verified) {
            this.setState({ submitting: true })
            formProps.type = this.state.type
            formProps.anrede = this.state.anrede
            formProps.request = this.state.request
            this.props.sendForm(formProps).then(res => {
                //history.goBack()

                this.setState({ submitted: true, submitting: false })


            }).catch(err => {

                this.setState({ submitted: false, error: err, submitting: false })

            })
        } else {
            this.setState({error:"Bitte füllen Sie alle Pflichtfelder aus!"})
        }

    }

    handleChange = name => event => {
        console.log('event.target', event.target)

        if (name == "agb") {

            this.setState({ [name]: event.target.checked });
        } else {
            this.setState({ [name]: event.target.value });
        }

    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        // console.log('nextProps', nextProps)
    }

    onVerify = () => {
        this.setState({
            verified: true
        });
    };

    onExpire = () => {
        this.setState({
            verified: false
        });
    };


    render() {

        const { handleSubmit, sent } = this.props;
        const { error, submitted, submitting } = this.state
        // console.log('this.props', this.props)




        return (


            <div style={{}}>
                 <CardContent style={{marginTop: 0, marginBottom: 0, marginLeft:0, paddingLeft:0, textAlign: 'left',paddingBottom:20 }}>
                            <b style={{  marginBottom: 0, fontSize: 18 }}>Anfrage / Bestellung</b>
                        </CardContent>
                        <Divider/>
                        <br/>
                <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                    <Field name="id" type="hidden" component={renderHiddenField} />
                    <div style={{paddingTop:10,paddingBottom:10}}>
                    <FormControl component="fieldset" >
                   
                   
          <RadioGroup
            aria-label="Anfrage / Bestellung"
            name="Anfrage / Bestellung"
            
            value={this.state.request}
            onChange={this.handleChange("request")}

          >

            <FormControlLabel value="Angebot" control={<Radio />} label="Bitte senden Sie mir ein unverbindliches Angebot gemäß der nachfolgend gekennzeichneten Angebotsvariante zu." />
            <FormControlLabel value="Buchen" control={<Radio />} label="Ich möchte das folgende Angebot verbindlich buchen." />
  
          </RadioGroup>
        </FormControl>
                    </div>
                    <div style={{paddingTop:20,paddingBottom:20}}>
                    <FormControl component="fieldset" >
                    <InputLabel shrink={true} htmlFor="Angebotsvariante">Angebotsvariante</InputLabel>
                    <br/>
          <RadioGroup
            aria-label="Angebotsvariante"
            name="Angebotsvariante"
            
            value={this.state.type}
            onChange={this.handleChange("type")}
          >
            <FormControlLabel value="BASIC" control={<Radio />} label="BASIC" />
            <FormControlLabel value="PROFESSIONAL" control={<Radio />} label="PROFESSIONAL" />
           
          </RadioGroup>
              
        </FormControl>
        
                    </div>
                    <Field name={'Mitarbeiter'} type="number" component={renderField} label="Anzahl Mitarbeiter *" />
                    <div style={{paddingTop:20,paddingBottom:20}}>
                    <FormControl fullWidth={true} >
                        <InputLabel shrink={true} htmlFor="anrede">Anrede</InputLabel>
                        <Select
                            value={this.state.anrede}
                            onChange={this.handleChange("anrede")}
                            inputProps={{
                                name: 'anrede',
                                id: 'anrede',
                            }}
                        >
                            <MenuItem value="">
                                <em>-</em>
                            </MenuItem>
                            <MenuItem value={"Herr"}>Herr</MenuItem>
                            <MenuItem value={"Frau"}>Frau</MenuItem>
                            <MenuItem value={"Firma"}>Firma</MenuItem>
                        </Select>
                    </FormControl>
                    </div>
                    <Field name={'Vorname'} type="text" component={renderField} label="Vorname" />
                    <Field name={'Nachname'} type="text" component={renderField} label="Nachname *" />
                    <Field name={'Funktion'} type="text" component={renderField} label="Funktion" />
                    <Field name={'Firma'} type="text" component={renderField} label="Firma (inkl. Rechtsform)* " />
                    <Field name={'Strasse'} type="text" component={renderField} label="Straße* " />
                    <Field name={'PLZ'} type="number" component={renderField} label="PLZ* " />
                    <Field name={'Ort'} type="text" component={renderField} label="Ort* " />
                    
                    <Field name={'Email'} type="email" component={renderField} label="E-Mail *" />
                    <div >
                    
                <p>Mit * gekennzeichnete Felder sind Pflichtfelder.</p>
                </div>
                    <Field name={'Datenschutz'} type="checkbox" checked={"false"} component={renderCheckbox} label="Ich akzeptiere die Datenschutzbestimmungen *" />
                    <div style={{}}>
                        <Reaptcha sitekey="6Lf46HAUAAAAACGT61Y3fOIaEN3wtpZos926lMx0" onVerify={this.onVerify} onExpire={this.onExpire} onError={this.onExpire} />
                    </div>
                    {error && <strong> {error} </strong>}

                    {!submitting && !submitted &&
                        <div style={{ marginTop: 20, textAlign: 'center', }}>
                            <Button type="submit" variant="contained" >Absenden</Button>

                        </div>
                    }
                    {submitting &&
                        <div style={{ marginTop: 20 }}>
                            <LinearProgress />
                        </div>
                    }
                    {submitted &&
                        <div style={{ marginTop: 20, textAlign: 'center', fontSize: '1.5em' }}>
                            <p >Ihre Nachricht wurde verschickt.</p>
                        </div>
                    }
                </form>

            </div>


        )
    }
}

OfferForm.propTypes = {
    classes: PropTypes.object.isRequired,
};


const form = reduxForm({
    form: 'OfferForm',
    enableReinitialize: true,
    //validate:validate
});

OfferForm.contextTypes = {
    store: PropTypes.object
};

function mapStateToProps(state) {
    return {
        sent: state.offer.sent
    }
}

export default connect(mapStateToProps, {
    sendForm: sendForm,
})(form(OfferForm));