import axios from 'axios';
import {
  
    FETCH_TAGS,
} from "../../actions/types";

import {ROOT_URL} from "../../config";




export function fetchTags() {
    return function (dispatch) {

       // dispatch(fetchingMap());
        
        const url = `${ROOT_URL}/Tags`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
           // console.log('response.data', response.data)
            dispatch({
                type: FETCH_TAGS,
                payload: response
            });
        }).catch((err) => {
           // console.log('error fetching the articles', err);
        });
    }
}
