import React, { Component, PropTypes } from 'react';

import { Card, CardContent, Tooltip, FormControl, MenuItem, Select, InputLabel, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core'
import { Markdown } from 'react-showdown';
import { updateState, deleteState } from './actions'
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

class RiskLevelEditor extends Component {

    constructor(props) {
        super(props)
        console.log('constr', this.props)
        this.state = {
            riskLevel: this.props.state.riskLevel,
            state: this.props.state.state
        }
    }

    componentDidMount() {

    }

    UNSAFE_componentWillMount() {
        //this.props.fetchPage("impressum")
    }

    handleChange(event) {
        //console.log('value', value)
        this.setState({
            riskLevel: event.target.value
        })
    }

    save() {
        console.log('save')
        if (this.props.onSave) {

            this.props.updateState({
                riskLevel: this.state.riskLevel,
                state: this.state.state
            }, this.props.dach).then(res => {
                this.props.onSave()
            })

        }
    }

    delete() {
        console.log('delete')
        if (this.props.onSave) {



            this.setState({ del: true })

        }
    }

    deleteNow() {
        this.props.deleteState(this.state.state, this.props.dach).then(res => {

            this.setState({ del: false })
            if (this.props.onSave) {
                this.props.onSave()
            }
        })
    }

    handleClose() {
        this.setState({ del: false })
    }


    render() {
        // const {  } = this.props
        const { riskLevel, state, del } = this.state
        return (
            <div style={{
                padding: 20, display: 'flex', fallbacks: [
                    { display: '-webkit-box' },
                    { display: '-moz-box' },
                    { display: '-ms-flexbox' },
                    { display: '-webkit-flex' }
                ], flexDirection: "row", msFlexDirection: 'row'
            }}>
                <form autoComplete="off">
                    <FormControl>
                        <InputLabel htmlFor="riskLevel">Risklevel</InputLabel>
                        <Select
                            value={riskLevel}
                            onChange={this.handleChange.bind(this)}
                            inputProps={{
                                name: 'Risklevel',
                                id: 'riskLevel',
                            }}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                        </Select>

                    </FormControl>
                </form>
                <div style={{ display: 'flex', marginTop: 10, marginLeft: 10, flexDirection: 'row', width: 200 }}>
                    <div>
                        <IconButton onClick={this.save.bind(this)}>
                            <SaveIcon />
                        </IconButton>
                    </div>
                    <div style={{ flexGrow: 1 }}></div>
                    <div>
                        <IconButton onClick={this.delete.bind(this)}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </div>

                {del &&

                    <Dialog onClose={this.handleClose.bind(this)} open={del}>
                        <DialogTitle>Wirklich löschen?</DialogTitle>
                        <DialogActions>
                            <Button onClick={this.handleClose.bind(this)}>Abbrechen</Button>
                            <Button onClick={this.deleteNow.bind(this)}>Löschen</Button>
                        </DialogActions>
                    </Dialog>
                }

            </div>
        )
    }
}

const styles = {

}

function mapStateToProps(state) {


    return {
        admin: state.auth.admin,
        editor: state.auth.editor
    }
}
export default withStyles(styles, { name: 'Page' })(connect(mapStateToProps, {
    updateState,
    deleteState

})(RiskLevelEditor));

