import React from 'react';
import ReactDOM from 'react-dom';

import { Markdown } from 'react-showdown';
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardContent, List, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { ROOT_URL } from "../../config";
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import { Link } from 'react-router-dom';
import Map from '../map/map_index'
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { fetchMaps } from '../mapEditor/actions'

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = { mapId: null };

    }
    UNSAFE_componentWillMount() {

       // this.props.fetchMaps()


    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate() {

    }
    handleListItemClick(mapId) {


        this.setState({ mapId })

    }



    render() {
        const { mapId } = this.state
        const { maps,admin } = this.props
        // console.log('maps', maps)
        return <div>

            <Grid style={{ maxWidth: '100%', padding: '12px', marginTop: '0px', marginLeft: '0px' }} container >

                <Grid item lg={12} sm={12} xs={12} md={12} >

                    <Map containerStyle={{
                        height: "calc( 100vh - 100px )",
                        width: "100%"

                    }}
                    saveBounds={admin} 
                    mapId={'9988798d-61d7-42f4-8b86-b267dc8b739b'} />

                </Grid>
            </Grid>
        </div>

    }

}

function mapStateToProps(state) {


    return {
        maps: state.maps.maps,
        admin: state.auth.admin,
        editor: state.auth.editor
    }
}
const styles = {

}
export default withStyles(styles, { name: 'Page' })(connect(mapStateToProps, {
    fetchMaps: fetchMaps,
})(Page));


