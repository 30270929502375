import {
    REGISTER_USER
} from "../../actions/types";

import * as _ from "lodash";

const INITIAL_STATE = {
    sent:null
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

       
        case REGISTER_USER:
        return {
            ...state,
            registered: action.payload.data
        };
        default:
            return state;
    }
}