import { push } from 'react-router-redux';
import axios from 'axios';
import { ROOT_URL } from "../../config";


export function goToRoute(route) {
    return function (dispatch) {
        dispatch(push(route));
    }
}

export function fetchUsers(customer, userId) {
    return function (dispatch) {
        var filter = {
            order: 'title ASC',
            //where: { role:"user" } ,
          // include: ['accessTokens'],

        }
        if (customer) {
            filter.where = { customer:customer, /*role:"user"*/  }
        }
        const url = `${ROOT_URL}/users?filter=${encodeURIComponent(JSON.stringify(filter))}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: 'FETCH_USERS',
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the page', err);
        });
    }
}

export function resetUser(id) {
    return function (dispatch) {

        return Promise.resolve().then(() => {
            dispatch({
                type: 'RESET_USER',
                payload: null
            });
        })



    }
}

export function fetchUser(id) {
    return function (dispatch) {

        var filter = {
           // include: "multipolar"
        }
        const url = `${ROOT_URL}/users/${id}`
        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: 'FETCH_USER',
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the page', err);
        });
    }
}

export function fetchUserInfo(id) {
    return function (dispatch) {

      
        const url = `${ROOT_URL}/users/getInfo`
        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: 'FETCH_USER_INFO',
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the page', err);
        });
    }
}



export function updateUser(args) {
    return function (dispatch) {

        const url = `${ROOT_URL}/users`

        return axios.patch(url, args, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: 'UPDATE_USER',
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the page', err);
        });
    }
}

export function resetPassword(args) {
    return function (dispatch) {

        const url = `${ROOT_URL}/users/reset`

        console.log('args', args)
        return axios.post(url, args, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: 'RESET_USER_PASSWORD',
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the page', err);
        });


        
    }
}

export function newPassword(args) {
    return function (dispatch) {

        const url = `${ROOT_URL}/users/reset-password`
        
        console.log('args', args)
        return axios.post(url, args, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: 'RESET_USER_PASSWORD',
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the page', err);
        });


        
    }
}

export function changePassword(args) {
    return function (dispatch) {

        const url = `${ROOT_URL}/users/change-password`

        return axios.post(url, args, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: 'CHANGE_USER_PASSWORD',
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the page', err);
        });
    }
}

export function deleteUser(id) {
    console.log('deleteUser', id)
    return function (dispatch) {
       
        const url = `${ROOT_URL}/users/${id}`

        return axios.delete(url, {
            headers: { authorization: localStorage.getItem('token') }
        })
            .then(response => {
                dispatch({
                    type: 'DELETE_MULTIPOLAR',
                    payload: response
                });
            })
            .catch(() => {
                //dispatch();
            });
    }
}



