import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';
import {
  fetchArticlesByTagTitles, fetchArticle
} from "../articleList/actions";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddCircle';
//import TagSelector from '../tagSelector/tagselector_index'
import { Input,InputLabel, MenuItem } from '@material-ui/core';

import { FormControl, FormHelperText } from '@material-ui/core';
import Select from '@material-ui/core/Select';

const styles = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: 1,
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: 1 * 2,
    },
  });
  

class selectMapIcons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        icon: "Select",
      }
  }

  handleChange = event => {
   // console.log("handleChange event", event.target.value)
    this.setState({ [event.target.name]: event.target.value });
  };

  openTagSelector() {
      this.setState({showTagSelector:true})
  }

  handleClose() {
    this.setState({showTagSelector:false})
  }

  handleSaveClose() {
    this.setState({showTagSelector:false})
  }

  onSelectionChange(selection) {
     // console.log('selecton:' , selection)
  }

  handleDelete(data) {
   // console.log("handleDelete", data)

    if (this.props.onTagDelete) {
      this.props.onTagDelete(data)
    }
  };

  render() {
    const { tagTitles, showTagSelector} = this.state;
    const { classes } = this.props;

    return (
        <form className={classes.root} autoComplete="off">        
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="IconSelectInput">Icon</InputLabel>
          <Select
            value={this.state.icon}
            onChange={this.handleChange}
            input={<Input name="IconSelectInput" id="IconSelectInput" />}
          >
            <MenuItem value={'blank_black.png'}>
                <img src={'/images/mapicons/blank_black.png'} width={"32"} height={"37"} alt={"Bürgerkriegsereignisse, Terrorismus, Terroranschläge, Sabotageaktionen"} title={"Bürgerkriegsereignisse, Terrorismus, Terroranschläge, Sabotageaktionen"} />
                Bürgerkriegsereignisse, Terrorismus, Terroranschläge, Sabotageaktionen
            </MenuItem>
                                
                
            <MenuItem value={"blank_red.png"}>
                <img src={'/images/mapicons/blank_red.png'} width={"32"} height={"37"} />Gewaltkriminalität, Körperverletzung, Vergewaltigung, Totschlag, Mord.
            </MenuItem>
                    
            <MenuItem value={"blank_yellow.png"}>     
                <img src={'/images/mapicons/blank_yellow.png'} /> Eigentumskriminalität, Einbrüche, Diebstähle usw..
            </MenuItem>
                        
            <MenuItem value={"fire.png"}>     
                <img src={'/images/mapicons/fire.png'} /> Sachbeschädigung, Brand und Brandstiftung, Vandalismus.
            </MenuItem>
                     
            <MenuItem value={"blank_pink.png"}>     
                <img src={'/images/mapicons/blank_pink.png'} /> Soziale Unruhen, Aufruhr, Demonstrationen, Streik.
            </MenuItem>
                        
            <MenuItem value={'caution.png'}>     
                <img src={'/images/mapicons/caution.png'} /> Verkehrsrisiken.
            </MenuItem>
                                        
            <MenuItem value={'blank_grey.png'}>     
                <img src={'/images/mapicons/blank_grey.png'} /> Umweltrisiken, Umweltverschmutzung.
            </MenuItem>
                                        
            <MenuItem value={'blank_green.png'}>     
                <img src={'/images/mapicons/blank_green.png'} /> Naturgefahren, Stürme, Erdbeben, Vulkanismus, Überschwemmungen, Dürre, Extremwetter.
            </MenuItem>
                                       
            <MenuItem value={'medicine.png'}>     
                <img src={'/images/mapicons/medicine.png'} /> Gesundheitsrisiken, Seuchenausbrüche.
            </MenuItem>
                          


          </Select>
          <FormHelperText>EIn Icon auswählen, dass auf der Map angezeigt wird</FormHelperText>
        </FormControl>        
      </form>
    );
  }
}

function mapStateToProps(state) {

  //console.log('state.articles', state.articles)
  return {
    articles: state.articles.all,
    article: state.articles.article
  }
}

export default withStyles(styles, { name: 'selectMapIcons' })(connect(mapStateToProps, {
  fetchArticlesByTagTitles: fetchArticlesByTagTitles,
  fetchArticle: fetchArticle
})(selectMapIcons));