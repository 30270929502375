import {
    FETCH_AGENDAS,
    FETCHED_AGENDAS,
    FETCH_AGENDA,
    FETCHED_AGENDA,
    UPDATE_AGENDA,
    INSERT_AGENDA,
    FETCH_TERMS
} from "../../actions/types";


const INITIAL_STATE = {
    agendas: [],

};

export default function (state = INITIAL_STATE, action) {
    //console.log('action.type', action.type)
    switch (action.type) {
        case FETCH_AGENDAS:
            
            return {
                ...state,
                isFetching:true,
                //agendas:[]
            };

        case FETCHED_AGENDAS:
            
            return {
                ...state,
                isFetching:false,
                agendas: action.payload.data
            };
       
        case FETCHED_AGENDA:
            var agenda = action.payload.data
            if (agenda.length > 0) {
                agenda = agenda[0]
            }
            return {
                ...state,
                isFetching:false,
                agenda: agenda
            };


        default:
            return state;
    }
}