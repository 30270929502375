import axios from 'axios';
import {
    INSERT_HOTSPOT,
    UPDATE_HOTSPOT,
    FETCH_HOTSPOT,
    DELETE_HOTSPOT
} from "../../actions/types";

import { ROOT_URL } from "../../config";



export function fetchHotspot(id) {
    // console.log('fetchHotspot', id)
    return function (dispatch) {
        var search = "_id:" + id

        const filter = {
            where: { id: id }
        }
        const url = `${ROOT_URL}/Hotspots?filter=${encodeURIComponent(JSON.stringify(filter))}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: FETCH_HOTSPOT,
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the articles', err);
        });

    }
}



export function deleteHotspot(id) {
    // console.log('deleteHotspot', id)
    return function (dispatch) {

        const url = `${ROOT_URL}/Hotspots/delete?id=${encodeURIComponent(id)}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: DELETE_HOTSPOT,
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the articles', err);
        });

    }
}


export function updateHotspot(article) {
    return function (dispatch) {

        const body = {
            id: article.id,
        }
        delete article.id
        body.hotspot = article

        const url = `${ROOT_URL}/Hotspots/updateById?`
        return axios.post(url, body, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: UPDATE_HOTSPOT,
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the articles', err);
        });
    }
}

export function insertHotspot(article) {
    return function (dispatch) {

        const body = {
            id: article.id,
        }
        delete article.id
        body.article = article

        const url = `${ROOT_URL}/Hotspots/insert?`
        return axios.post(url, body, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: INSERT_HOTSPOT,
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the articles', err);
        });
    }
}

