import React from 'react';
import { connect } from "react-redux";
import { Avatar, Typography, Link, IconButton, Menu, MenuItem } from '@material-ui/core';
import ProfileIcon from '@material-ui/icons/AccountCircle';
import { goToRoute, toggleSideBar } from "../layout/actions";
import { fetchUserInfo } from "../customer/actions"
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next'


class AppUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: false
        };
    }

    componentDidMount() {
        this.props.fetchUserInfo()
    }

    handleClick(e) {
        console.log('handleClick', e)
        this.setState({ showMenu: true, anchorEl: e.currentTarget })
        // this.props.goToRoute.bind(this, '/profile')
    }

    handleClose(msg) {

        if (msg && typeof msg == "string") {
            this.props.goToRoute('/' + msg)
        }
        this.setState({ showMenu: false, anchorEl:null })
    }

    handleLogin() {
        console.log('handleLogin')
        this.props.goToRoute('/login')
    }


    render() {
        const { authenticated, userInfo, t } = this.props
        const { showMenu, anchorEl } = this.state
        return (
            <div>

                {authenticated &&
                    <div>
                        <IconButton style={{ display: 'flex', flexDirection: 'row' }} onClick={this.handleClick.bind(this)}>
                        <div>
                            <ProfileIcon style={{color:'white'}}/>
                            {userInfo && 
                            <div style={{textAlign:'center',fontSize:8,color:'white'}}>{userInfo.name || ""}</div>
                            }
                            </div>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={showMenu}
                            onClose={this.handleClose.bind(this)}
                        >
                            <MenuItem onClick={this.handleClose.bind(this, "profile")}>Passwort ändern</MenuItem>
                            <MenuItem onClick={this.handleClose.bind(this, "logout")}>Logout</MenuItem>
                        </Menu>
                    </div>
                }
                 {!authenticated &&
                    <div>
                        <IconButton style={{ display: 'flex', flexDirection: 'row' }} onClick={this.handleClose.bind(this, "login")}>
                        <div>
                            <ProfileIcon style={{color:'white'}}/>
                            
                            <div  style={{textAlign:'center',fontSize:8,color:'white'}}>{t("sideMenu.Anmelden")}</div>
                            
                            </div>
                        </IconButton>
                      
                    </div>
                }
            </div>

        )
    }

}

function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated,
        admin: state.auth.admin,
        manager: state.auth.manager,
        tenant: state.auth.tenant,
        userInfo:state.users.userInfo,
        editor: state.auth.editor,
        name: state.auth.name,
        sidebarOpen: state.layout.sidebarOpen,
    }
}

export default withNamespaces()(connect(mapStateToProps, { goToRoute,fetchUserInfo })(AppUser))