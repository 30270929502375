import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import MaterialTable from 'material-table'
import ReactMapboxGl, { GeoJSONLayer, Layer, Feature, ZoomControl, RotationControl, Cluster, Marker, Popup } from "react-mapbox-gl";
import DrawControl from 'react-mapbox-gl-draw';
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { Grid, Card, TextareaAutosize, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { fetchLayer } from '../layerTable/actions'
import { mapLimit } from "async-es";
import { centroid, feature, polygon, multiPolygon, featureCollection, bbox, lineString } from '@turf/turf';
import MarkdownEditor from '../markdownEditor';
import Nominatim from '../nominatim/nominatim_index';
import axios from 'axios';
import { ROOT_URL } from "../../config";


const Mapbox = ReactMapboxGl({
  //accessToken: MAPBOX_TOKEN
});

const symbolLayout = {

  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  'text-offset': [0, 0],
  'text-size': 15,
  'text-anchor': 'center',
  'icon-anchor': 'center',
  "icon-image": "{icon}",
  'icon-size': [
    'match',
    ['get', 'icon'],
    'warning_terror.png', 0.4,
    "warning_general.png", 0.4,
    "Hotspot_Pin-Kopie.png", 0.5,
    "THW.png", 0.5,
    "Klinik.png", 0.5,
    'Politischer_Extremismus_Ausländer.png', 0.5,
    'Politischer_Extremismus_rechts.png', 0.5,
    'triangle_rape.png', 0.4,
    'triangle_assault_police.png', 0.4,

    0.3
  ],
  'icon-ignore-placement': false,
  'text-allow-overlap': true,
  'icon-allow-overlap': true

};
const symbolPaint = {
  'text-color': 'black',

};

GeoJSONLayer.prototype.initialize = function () {
  var map = this.context.map;
  map.addSource(this.id, this.source);
  this.createLayer('circle');
  this.createLayer('symbol');
  this.createLayer('line');
  this.createLayer('fill');
  this.createLayer('fill-extrusion');

};

const circleLayout = {
  visibility: 'visible',


};

const imageLayout = {
  visibility: 'visible',
  "id": "markers",
  "type": "symbol",
  "layout": {
    "icon-image": "https://riskcompass.eu/images/mapicons/{icon}",
  }
};

const circlePaint = {
  'circle-color': 'black',
  'circle-color': [
    'match',
    ['get', 'icon'],
    "blank_green.png", '#9CFF00',
    'warning_terror.png', 'black',
    "blank_black.png", 'black',
    "blank_pink.png", '#f442f1',
    "warning_general.png", 'yellow',
    "blank_red.png", '#ff0000',
    'medicine.png', '#000099',
    'caution.png', 'grey',
    'fire.png', '#ff9900',

   /* other */ 'purple'
  ],
  'circle-radius': 7,
  'circle-opacity': 1,
  'circle-stroke-width': 1,
  'circle-stroke-opacity': 0.75,
  'circle-stroke-color': 'black',



};

const polygonPaint = {

  'fill-color': [
    'match',
    ['get', 'riskLevel'],
    0, '#7d9cce',
    1, '#00ff00',
    2, '#ffff00',
    3, '#ffa500',
    4, '#ff6600',
    5, '#ff0000',
                          /* other */ '#7d9cce'
  ],
  'fill-opacity': 0.6,
  'fill-outline-color': '#464646'
};

const polygonPaint2 = {
  'fill-color': '#ffff00',
  'fill-opacity': 0.0,
  'fill-outline-color': '#ffff00',
};


class LayerEditor extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selected: [],
      _layer: null
    }
  }

  componentDidMount() {
    if (this.props.match.params.id && this.props.match.params.id != "new") {
      console.log('fetchLayer', this.props.match.params.id)
      this.props.fetchLayer([this.props.match.params.id])
    } else {

    }
    if (this.props.match.params.map) {

    }


  }

  updateLayer() {
    const { selected } = this.state
    var _layer = {}
    console.log('updateLayer')
    if (this.drawControl && this.drawControl.draw) {
      console.log('updateLayer with drawControl')
      _layer = this.drawControl.draw.getAll()
      console.log('updateLayer features', _layer.features)
      if (selected && _layer.features) {

        _layer.features = _layer.features.map(f => {
          /*if (selected.indexOf(f.properties.id ) != -1) {
   
          }*/
          //f.id = f.properties.id
          if (!f.properties.riskLevel) {
            f.properties.riskLevel = 3
          }
          f.tableData = {
            // id: f.properties.id,
            checked: selected.indexOf(f.id) != -1
          }
          return f
        })
      }
      this.setState({
        _layer
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log('nextProps', nextProps)
    if (nextProps.layer != this.props.layer) {
      if (this.drawControl) {
        console.log('add icons')
        this.drawControl.draw.add({ type: "FeatureCollection", features: nextProps.layers.features })
        console.log('before updateLayer')
        this.updateLayer(nextProps.layer)
        console.log('after updateLayer')
        this.setState({ layerTitle: nextProps.layer.title })
      }
    }
  }

  onSelectionChange(features) {
    console.log('features', features)
    const draw = this.drawControl.draw
    if (features.length > 0) {
      //var feature = draw.get(features[0])
      // this.setState({ selectedFeatureTitle: feature.properties.name })
      // console.log('feature', feature)
    } else {
      // this.setState({ selectedFeatureTitle: null })
    }
    var featureIds = features.map(f => {
      return f.id
    })
    console.log('featureIds', featureIds)
    draw.changeMode('simple_select', {
      featureIds: featureIds
    })
  }

  geoJSONLayerClick(e) {
    //// console.log('geoJSONLayerClick', e)
    var features = this.map.queryRenderedFeatures(e.point);
    var feature = features[0]
    console.log('feature', feature)

    var { selected } = this.state
    var index = selected.indexOf(feature.id)
    if (index == -1) {
      selected.push(feature.id)
    } else {
      selected.splice(index, 1)
    }
    this.setState({
      selected
    })


    /* if (this.props.onSelected && feature) {
       this.props.onSelected(feature.properties)
     }*/

    /*this.setState({
        popup: feature
    });*/

  }

  onMapLoaded(map) {
    console.log('mapLoaded')
    if (!map) {
      return
    }
    this.map = map
    var that = this
    var images = ["blank_green.png",
      'warning_terror.png',
      "blank_black.png",
      "blank_pink.png",
      "warning_general.png",
      "circle_brown_traffic_incident.png",
      "triangle_grey_warning_pollution.png",
      "triangle_blue_warning_health_outbreaks.png",
      "blank_red.png",
      'medicine.png',
      'caution.png',
      'fire.png',
      'Hotspot_Pin-Kopie.png',
      "THW.png",
      "Klinik.png",
      'Politischer_Extremismus_Ausländer.png',
      'Politischer_Extremismus_links.png',
      'Politischer_Extremismus_rechts.png',

      'triangle_rape.png',
      'triangle_assault_police.png'

    ]
    //  console.log('images', images)

    mapLimit(images, 20, (_image, cb) => {
      //cb(null, {})
      const url = window.location.origin + "/images/mapicons/" + _image
      console.log('image url', url)
      if (this.map) {
        this.map.loadImage(url, (error, image) => {
          // console.log('url', url, "loaded", error)
          /*if (error) {
              console.log('error', error)
              cb(null, error)
              return
          }*/

          this.map.addImage(_image, image)
          cb({})
        })
      } else {
        cb({})
      }


    }, (err, result) => {
      ///  console.log('images loaded')
      this.setState({ imagesLoaded: true })
    })

    if (this.drawControl && this.props.layer && this.props.layer.features) {
      console.log('add icons')
      this.setState({ layerTitle: this.props.layer.title })
      this.drawControl.draw.add({ type: "FeatureCollection", features: this.props.layer.features })
      this.updateLayer()
    } else if (this.drawControl && !this.props.layer) {
      console.log('add icons')
      this.setState({ layerTitle: "" })
      this.drawControl.draw.add({ type: "FeatureCollection", features: [] })
      this.updateLayer()
    }

    /* map.on('click', (e) => {
         var features = map.queryRenderedFeatures(e.point)
        // console.log('features', features)
     })*/

    //console.log('featureCollection', this.props.featureCollection)
    //console.log('mapId', this.props.mapId)
    if (this.props.layer) {
      // console.log('should fit bounds #1')
      if (this.map) {
        this.fitBounds(this.props.layer)
      }
    }
    // console.log('should fit bounds #3', "mapId", this.props.mapId, "bounds", this.state.bounds)
    map.on('draw.create', function (e) {
      console.log("draw.create", e.features);
      if (e.features.length > 0) {
        // console.log('should be red')
        const draw = that.drawControl.draw
        // var prop = draw.setFeatureProperty(e.features[0].id, "riskLevel", 5)
        var added = draw.get(e.features[0].id)

        //console.log('that.geoshapeTable', that.geoshapeTable)
        //draw.changeMode('simple_select')
        that.setState({ selected: [] })
        that.updateLayer()
        // that.geoshapeTable.addRows([added])
      }
    });
    map.on('draw.update', function (e) {
      // console.log('draw.update', e.features);

    });
    map.on('draw.delete', function (e) {
      // console.log('draw.delete', e.features);
      var ids = e.features.map(f => { return f.id })

      //  var test = that.geoshapeTable.deleteRows(ids)
      // console.log('after call', test)
    });

    map.on('draw.selectionchange', function (e) {
      console.log('draw.selectionchange', e);
      if (e.features.length > 0) {
        var feature = e.features[0]
        console.log('feature', feature)

        var { selected } = that.state
        var index = selected.indexOf(feature.id)
        if (index == -1) {
          selected.push(feature.id)
        } else {
          selected.splice(index, 1)
        }
        that.setState({
          selected
        })
        that.updateLayer()

        // that.geoshapeTable.setSelection(draw.getSelectedIds())

        var features = map.queryRenderedFeatures(e.point, {
          layers: ['gl-draw-polygon-fill-inactive.cold', 'gl-draw-line-inactive.cold']
        });
      }

      // console.log(features);


      //console.log('setPaintProperty', map.setPaintProperty)
      //map.setPaintProperty(e.features[0].id, 'fill-color', '#000000')
      // map.setPaintProperty('gl-draw-active-polygon.hot', 'fill-color', '#000000');
    });

    map.resize()
    //map.invalidateSize();
  }


  fitBounds(featureCollection) {
    console.log('should fit bounds', featureCollection)
    var line = featureCollection.features.map(f => {

      return f.geometry.coordinates;
    })
    console.log('line', line)
    if (line && line.length > 1) {
      try {
        var box = bbox(lineString(line))
        // console.log('box', box)
        const bounds = [[box[0], box[1]], [box[2], box[3]]]
        // console.log('fitBounds', JSON.stringify(bounds))
        this.map.fitBounds(bounds, {
          padding: 20
        })
      } catch (e) {

      }

    }
    if (featureCollection.features.length == 1) {
      const f = featureCollection.features[0]
      this.map.flyTo({
        center: [f.geometry.coordinates[0], f.geometry.coordinates[1]],
        zoom: 7,
        speed: 2,
        curve: 0.75,
      })

    }
  }

  markdownChange(e, row) {
    console.log('markdownChange', e, row)
  }

  markdownSave(row, markup) {
    console.log('markdownSave', row, markup)
    const draw = this.drawControl.draw
    draw.setFeatureProperty(row.id, "markdown", markup)
    this.updateLayer()
  }

  layerTitleChanged(e) {
    console.log('layerTitleChanged', e.target.value)
    this.setState({ layerTitle: e.target.value })
  }

  save() {
    console.log('saveTitle', this.state.layerTitle)
    const draw = this.drawControl.draw
    var fc = draw.getAll()
    console.log('fc', fc)
    const url = `${ROOT_URL}/Layers/saveFeatureCollection`
    const payload = {
      title: this.state.layerTitle,
      featureCollection: fc,
      type: "static",
      mapId: this.props.match.params.map
    }
    if (this.props.match.params.id && this.props.match.params.id != "new") {
      payload.id = this.props.match.params.id
    }
    axios.post(url, payload, {
      headers: { authorization: localStorage.getItem('token') }
    }).then(res => {
      console.log('res', res)
      this.props.history.goBack()
    }).catch(err => {
      console.log('err', err)
    })
  }

  deleteFeatures(features) {
    console.log('deleteFeatures', features)
    const draw = this.drawControl.draw
    var ids = features.map(f => {
      return f.id
    })
    draw.delete(ids)
    this.updateLayer()
  }

  onSelectGeoshape(shape) {
    // console.log(shape)

    var shapes = []
    var geom = shape.geojson;

    if (geom.type === 'MultiPolygon') {
      for (var i = 0; i < geom.coordinates.length; i++) {
        var polygon = {
          'type': 'Polygon',
          'coordinates': geom.coordinates[i],

        };
        shapes.push(polygon)
        // console.log(JSON.stringify(polygon));
      }
    } else {
      shapes.push(shape.geojson)
    }
    shapes.forEach((sh, index) => {
      // console.log('sh', sh)

      var feature = {
        id: shape.place_id + '_' + index,
        riskLevel: 0,
        type: 'Feature',
        properties: {
          title: shape.namedetails.name || shape.display_name,
          name: shape.namedetails.name,
          riskLevel: 0
        },
        geometry: sh
      };

      // console.log('feature', feature)

      var added = this.drawControl.draw.add(feature)
      // console.log('added', added)
      this.drawControl.draw.changeMode('simple_select', {
        featureIds: added
      })
      // console.log('feature.geometry.coordinates', feature.geometry.coordinates)
      /*  this.map.flyTo({
            center: feature.geometry.coordinates,
            zoom: 12,
            speed: 7,
           
        })*/

      this.updateLayer()
      // console.log('get', this.drawControl.draw.get(added[0]));
      //    this.geoshapeTable.addRows([this.drawControl.draw.get(added[0])])
      //this.drawControl.draw.set()
      //console.log('prop', prop)
    })

  }

  componentWillUnmount() {
    const draw = this.drawControl.draw
    this.map.removeControl(draw)
  }

  render() {

    const { layer } = this.props
    const { imagesLoaded, selected, _layer, layerTitle } = this.state
    console.log('layer', layer)

    return (

      <div style={{ maxWidth: '100%', padding: 20 }}>
        <Grid container >
          <Grid item lg={6}>
            <Card>
              <Nominatim style={{ float: 'left', width: '80%' }} onSelectGeoshape={this.onSelectGeoshape.bind(this)} disablePolygon={this.props.disablePolygon} />

              <Mapbox
                onStyleLoad={this.onMapLoaded.bind(this)}
                // onMapLoaded={this.onMapLoaded.bind(this)}
                containerStyle={{
                  marginRight: 10,
                  height: "calc( 100vh - 100px )",
                  width: "100%"
                }}
                style={"https://api.maptiler.com/maps/fef74ea1-76e5-440f-b0d9-86450b327741/style.json?key=MnSoswF3TIIirAiE6mqv"}
                renderWorldCopies={false}

              >
                < ZoomControl
                  position="top-left" />
                <RotationControl
                  position="top-left" />

                <DrawControl
                  userProperties={true}
                  position="top-right"
                  ref={(drawControl) => {
                    this.drawControl = drawControl;


                  }}
                />

                {false && imagesLoaded && layer && layer.features.length > 0 &&



                  <GeoJSONLayer
                    data={layer}
                    layerOptions={{ minZoom: 0 }}
                    symbolLayout={symbolLayout}
                    symbolPaint={symbolPaint}
                    fillPaint={polygonPaint2}
                    symbolOnClick={this.geoJSONLayerClick.bind(this)}
                  />

                }

              </Mapbox>
            </Card>
          </Grid>
          <Grid item lg={6}>
            {_layer && _layer.features &&
              <MaterialTable
                style={{ marginLeft: 10 }}
                columns={
                  [
                    {
                      title: "Title", field: 'properties.title',
                      editComponent: props => (
                        <TextareaAutosize
                          type="text"
                          rows={4}
                          style={{ width: '100%' }}
                          value={props.value}
                          onChange={e => props.onChange(e.target.value)}
                        />
                      )
                    }, {
                      title: "Gefahrenstufe", field: 'properties.riskLevel',
                      editComponent: props => (
                        <FormControl InputLabelProps={{ shrink: true }}>

                          <Select

                            value={props.value}
                            onChange={e => props.onChange(e.target.value)}
                          >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                          </Select>
                        </FormControl>
                      )
                    },
                    {
                      title: "Icon", field: 'properties.icon',
                      render: rowData => {
                        console.log('render rowData', rowData)
                        if (rowData.properties.icon) {
                          return <img style={{ width: 20, height: 20 }} src={"https://riskcompass.eu/images/mapicons/" + rowData.properties.icon} />

                        } else {
                          return <div></div>
                        }
                      },
                      editComponent: props => (
                        <FormControl InputLabelProps={{ shrink: true }}>

                          <Select

                            value={props.value}
                            onChange={e => props.onChange(e.target.value)}
                          >

                            {['Hotspot_Pin-Kopie.png',
                              "THW.png",
                              "Klinik.png",
                              'Politischer_Extremismus_links.png',
                              'Politischer_Extremismus_rechts.png'].map(icon => {
                                return <MenuItem value={icon}><img style={{ width: 20, height: 20 }} src={"https://riskcompass.eu/images/mapicons/" + icon} /></MenuItem>
                              })

                            }


                          </Select>
                        </FormControl>
                      )
                    }
                    /*{ title: "Markdown", field: 'properties.markdown', render: rowData => {
                      console.log('rowData', rowData)
                      return (<Markdown markup={rowData.properties.markdown} style={{width: '100%'}}/>)
                    } ,
                    
                      editComponent: props => (
                        <TextareaAutosize
                          type="text"
                          rows={4}
                          style={{width:'100%'}}
                          value={props.value}
                          onChange={e => props.onChange(e.target.value)}
                        />
                      )
                    }*/
                  ]
                }
                detailPanel={rowData => {
                  return (
                    <MarkdownEditor markup={rowData.properties.markdown} style={{ width: '100%' }} onChange={this.markdownChange.bind(this, rowData)} onSave={this.markdownSave.bind(this, rowData)} />
                  )
                }}
                options={{
                  selection: true,

                }}
                actions={[
                  {
                    tooltip: 'Remove All Selected Features',
                    icon: 'delete',
                    onClick: (evt, data) => {
                      console.log('data', data)
                      this.deleteFeatures(data)
                    }
                  }
                ]}
                onSelectionChange={this.onSelectionChange.bind(this)}
                data={_layer.features}
                title={
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField value={layerTitle} onChange={this.layerTitleChanged.bind(this)}></TextField>
                    <Button onClick={this.save.bind(this)}>Speichern</Button>
                  </div>
                }
                editable={{
                  isEditable: rowData => true, // only name(a) rows would be editable
                  isDeletable: rowData => false, // only name(a) rows would be deletable
                  onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        {
                          /* const data = this.state.data;
                          data.push(newData);
                          this.setState({ data }, () => resolve()); */
                        }
                        resolve();
                      }, 1000);
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      console.log('newData', newData)
                      console.log('oldData', oldData)
                      const draw = this.drawControl.draw
                      draw.setFeatureProperty(newData.id, "title", newData.properties.title)
                      draw.setFeatureProperty(newData.id, "riskLevel", newData.properties.riskLevel)
                      if (newData.properties.icon) {
                        draw.setFeatureProperty(newData.id, "icon", newData.properties.icon)

                      }
                      this.updateLayer()
                      setTimeout(() => {
                        {
                          /* const data = this.state.data;
                          const index = data.indexOf(oldData);
                          data[index] = newData;                
                          this.setState({ data }, () => resolve()); */
                        }
                        resolve();
                      }, 1000);
                    }),
                  /* onRowDelete: oldData =>
                     new Promise((resolve, reject) => {
                       setTimeout(() => {
                         {
                           
                         }
                         resolve();
                       }, 1000);
                     })*/
                }}
              />
            }
          </Grid>
        </Grid>

      </div>
    )
  }
}

function mapStateToProps(state) {

  return {
    layerList: state.layer.layerList,
    layers: state.layer.layers,
    layer: state.layer.layer,
    map: state.maps.map,
    lang: state.lang.lang,
    admin: state.auth.admin,
    editor: state.auth.editor
  }
}

export default withNamespaces()(connect(mapStateToProps, {
  fetchLayer
})(LayerEditor));
