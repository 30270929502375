import { push } from 'react-router-redux';
import axios from 'axios';
import {
    UPDATE_STATE,
    DELETE_STATE
} from "../../actions/types";

import {ROOT_URL} from "../../config";

export function updateState(state, type) {
    return function (dispatch) {
        console.log('updateState', state)
        const body = {
            state:{
                riskLevel: state.riskLevel
            },
            id:state.state
        }
        
        //body.page = page
        var model = "States"
        if (type) {
            model = "DachStates"
        }
        const url = `${ROOT_URL}/${model}/updateById?`
        console.log('url', url)
        return axios.post(url, body, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
           // console.log('response.data', response.data)
            dispatch({
                type: UPDATE_STATE,
                payload: response
            });
        }).catch((err) => {
           // console.log('error updating the page', err);
        });
    }
}

export function deleteState(state, type) {
    return function (dispatch) {
        console.log('deleteState', state)
       
        
        //body.page = page
        var model = "States"
        if (type) {
            model = "DachStates"
        }
        const url = `${ROOT_URL}/${model}/${state}`
        console.log('url', url)
        return axios.delete(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
           // console.log('response.data', response.data)
            dispatch({
                type:DELETE_STATE,
                payload: response
            });
        }).catch((err) => {
           // console.log('error updating the page', err);
        });
    }
}