export const FETCH_POST = 'FETCH_POST';
export const FETCH_MAP = 'FETCH_MAP';
export const FETCH_MAPS = 'FETCH_MAPS';
export const FETCH_LAYER = 'FETCH_LAYER';
export const FETCH_LAYERS = 'FETCH_LAYERS';
export const FETCH_LAYERLIST = 'FETCH_LAYERLIST';
export const FETCH_COUNTRY = 'FETCH_COUNTRY';
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCHING_COUNTRIES = 'FETCHING_COUNTRIES';
export const FETCHING_COUNTRY = 'FETCHING_COUNTRY';
export const FETCHED_COUNTRIES = 'FETCHED_COUNTRIES';
export const FETCHED_COUNTRY = 'FETCHED_COUNTRY';
export const FETCH_DACH_COUNTRIES = 'FETCH_DACH_COUNTRIES'
export const FETCH_DACH_COUNTRY = 'FETCH_DACH_COUNTRY'
export const FETCHING_DACH_COUNTRY = 'FETCHING_DACH_COUNTRY'
export const FETCHING_DACH_COUNTRIES = 'FETCHING_DACH_COUNTRIES'
export const FETCHED_DACH_COUNTRY = 'FETCHED_DACH_COUNTRY'
export const FETCHED_DACH_COUNTRIES = 'FETCHED_DACH_COUNTRIES'
export const UPDATE_DOSSIER_CHAPTER = 'UPDATE_DOSSIER_CHAPTER';
export const FETCH_ARTICLE = 'FETCH_ARTICLE'
export const DELETE_ARTICLE = 'DELETE_ARTICLE'
export const FETCH_CHECKLISTS = 'FETCH_CHECKLISTS'
export const FETCH_ARTICLE_SEARCH = 'FETCH_ARTICLE_SEARCH'
export const INSERT_PAGE = 'INSERT_PAGE'
export const UPDATE_PAGE = 'UPDATE_PAGE'
export const DELETE_PAGE = 'DELETE_PAGE'
export const FETCH_PAGE = 'FETCH_PAGE'

export const INSERT_RL = 'INSERT_RL'
export const UPDATE_RL = 'UPDATE_RL'
export const DELETE_RL = 'DELETE_RL'
export const FETCH_RL = 'FETCH_RL'
export const FETCH_RLS = 'FETCH_RLS'


export const INSERT_WHITEPAPER = 'INSERT_WHITEPAPER'
export const UPDATE_WHITEPAPER = 'UPDATE_WHITEPAPER'
export const DELETE_WHITEPAPER = 'DELETE_WHITEPAPER'
export const FETCH_WHITEPAPER = 'FETCH_WHITEPAPER'
export const SEND_CONTACT = 'SEND_CONTACT'
export const SEND_WHITEPAPER_CONTACT = 'SEND_WHITEPAPER_CONTACT'
export const SEND_OFFER = 'SEND_OFFER'
export const SEND_REPORT = 'SEND_REPORT'
export const FETCH_TERMS = 'FETCH_TERMS'
export const SELECT_LANG = 'SELECT_LANG'
export const UPDATE_COUNTRY = 'UPDATE_COUNTRY'
export const FETCH_AGENDAS = 'FETCH_AGENDAS'
export const FETCH_AGENDA = 'FETCH_AGENDA'
export const FETCHED_AGENDAS = 'FETCHED_AGENDAS'
export const FETCHED_AGENDA = 'FETCHED_AGENDA'
export const INSERT_AGENDA = 'INSERT_AGENDA'
export const UPDATE_AGENDA = 'UPDATE_AGENDA'
export const DELETE_AGENDA = 'DELETE_AGENDA'
export const UPDATE_STATE = 'UPDATE_STATE'
export const DELETE_STATE = 'DELETE_STATE'
export const TRANSLATE_DOSSIER = 'TRANSLATE_DOSSIER'
export const PUBLISH_NEWSLETTER = 'PUBLISH_NEWSLETTER'
export const PUBLISH_TWEET = 'PUBLISH_TWEET'
export const REGISTER_USER = 'REGISTER_USER'
export const FETCH_HOTSPOT = 'FETCH_HOTSPOT'
export const INSERT_HOTSPOT =  'INSERT_HOTSPOT'
export const UPDATE_HOTSPOT =  'UPDATE_HOTSPOT'
export const DELETE_HOTSPOT =  'DELETE_HOTSPOT'

export const FETCH_ARTICLES = 'FETCH_ARTICLES'
export const  INSERT_ARTICLE =  'INSERT_ARTICLE'
export const  UPDATE_ARTICLE =  'UPDATE_ARTICLE'
export const  FETCH_DOCUMENT =  'FETCH_DOCUMENT'
export const  ADMIN_USER =  'ADMIN_USER'
export const  STARTER_USER =  'STARTER_USER'
export const  EDITOR_USER =  'EDITOR_USER'
export const  SUBSCRIBER_USER =  'SUBSCRIBER_USER'
export const  COUNTRY_SUBSCRIBER_USER =  'COUNTRY_SUBSCRIBER_USER'
export const  DACH_SUBSCRIBER_USER =  'DACH_SUBSCRIBER_USER'

export const FETCH_DOSSIER_CHAPTER = 'FETCH_DOSSIER_CHAPTER';
export const FETCH_DOSSIER_CHAPTERS = 'FETCH_DOSSIER_CHAPTERS';
export const FETCHED_POSTS_CHUNKS = 'FETCHED_POSTS_CHUNKS';
export const FETCHING_POSTS_CHUNKS = 'FETCHING_POSTS_CHUNKS';
export const FETCHING_LAYER = 'FETCHING_LAYER';
export const FETCHING_LAYERS = 'FETCHING_LAYERS';
export const FETCHING_LAYERLIST = 'FETCHING_LAYERLIST';
export const FETCHING_MAP = 'FETCHING_MAP';
export const FETCHING_MAPS = 'FETCHING_MAPS';
export const CREATE_POST = 'CREATE_POST';
export const UPDATE_POST = 'UPDATE_POST';
export const UPDATE_MAP = 'UPDATE_MAP';
export const UPDATED_MAP = 'UPDATED_MAP';
export const DELETE_POST = 'DELETE_POST';
export const SET_POSTS_GRID_PAGE_SIZE = 'SET_POSTS_GRID_PAGE_SIZE';
export const SET_POSTS_GRID_PAGE_INDEX = 'SET_POSTS_GRID_PAGE_INDEX';
export const SET_POSTS_GRID_SORT_CRITERIA = 'SET_POSTS_GRID_SORT_CRITERIA';
export const SET_POSTS_GRID_FILTERS = 'SET_POSTS_GRID_FILTER';
export const ADD_NEW_POST_SECTION = 'ADD_NEW_POST_SECTION';
export const RESET_POST_SECTION_TO_0 = 'RESET_POST_SECTION_TO_0';
export const REMOVE_POST_SECTION = 'REMOVE_POST_SECTION';
export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_DOSSIER = 'FETCH_DOSSIER';
export const FETCHED_DOSSIERS_CHUNKS = 'FETCHED_DOSSIERS_CHUNKS';
export const FETCHED_LAYER = 'FETCHED_LAYER';
export const FETCHED_LAYERS = 'FETCHED_LAYERS';
export const FETCHED_LAYERLIST = 'FETCHED_LAYERLIST';
export const FETCHED_MAP = 'FETCHED_MAP';
export const FETCHED_MAPS = 'FETCHED_MAPS';
export const FETCHING_DOSSIERS_CHUNKS = 'FETCHING_DOSSIERS_CHUNKS';
export const CREATE_DOSSIER = 'CREATE_DOSSIER';
export const UPDATE_DOSSIER = 'UPDATE_DOSSIER';
export const DELETE_DOSSIER = 'DELETE_DOSSIER';
export const SET_DOSSIERS_GRID_PAGE_SIZE = 'SET_DOSSIERS_GRID_PAGE_SIZE';
export const SET_DOSSIERS_GRID_PAGE_INDEX = 'SET_DOSSIERS_GRID_PAGE_INDEX';
export const SET_DOSSIERS_GRID_SORT_CRITERIA = 'SET_DOSSIERS_GRID_SORT_CRITERIA';
export const SET_DOSSIERS_GRID_FILTERS = 'SET_DOSSIERS_GRID_FILTER';
export const ADD_NEW_DOSSIER_SECTION = 'ADD_NEW_DOSSIER_SECTION';
export const RESET_DOSSIER_SECTION_TO_0 = 'RESET_DOSSIER_SECTION_TO_0';
export const REMOVE_DOSSIER_SECTION = 'REMOVE_DOSSIER_SECTION';



export const AUTHENTICATED_ADMIN = 'AUTHENTICATED_ADMIN';
export const AUTHENTICATED_STARTER = 'AUTHENTICATED_STARTER';
export const UNAUTHENTICATED_ADMIN = 'UNAUTHENTICATED_ADMIN';
export const AUTHENTICATED_USER = 'AUTHENTICATED_USER';
export const MANAGER_USER = 'MANAGER_USER';
export const UNAUTHENTICATED_USER = 'UNAUTHENTICATED_USER';
export const AUTH_ERROR = 'AUTH_ERROR';

export const LAYOUT_SIDEBAR = 'LAYOUT_SIDEBAR';