import React from 'react';
import MobileStoreButton from 'react-mobile-store-button';
import Grid from '@material-ui/core/Grid'

const iOSUrl = 'https://apps.apple.com/ua/app/riskcompass/id1463753022';
const androidUrl = 'https://play.google.com/store/apps/details?id=eu.riskcompass.app';
				

class AppTeaser extends React.Component {

    render() {
      
         return (

            <div style={{marginTop:50}}>
                <h1>Sicherheit zum mitnehmen: Die RiskCompass-App</h1>

            <Grid style={{ maxWidth: '100%', padding: '12px', paddingTop: 0, marginTop: '0px', marginLeft: '0px' }} container >
                   <Grid item lg={2} sm={2} xs={2} md={2} ></Grid>
                  <Grid item lg={4} sm={4} xs={4} md={4} >
                <div style={{marginTop:19}}>
				<MobileStoreButton
				  store="ios"
                  url={iOSUrl}
                 
				  linkProps={{ title: 'iOS Store Button' }}
				/>
                </div>
                </Grid>
                <Grid item lg={4} sm={4} xs={4} md={4} >
                <div>
                <MobileStoreButton
                  store="android"
                  linkStyles={{width:'156%', height:'156%', padding:0}}
				  url={androidUrl}
				  linkProps={{ title: 'Android Store Button' }}
				/>
                 </div>
                 </Grid>

                 <Grid item lg={2} sm={2} xs={2} md={2} ></Grid>
           
            </Grid>
            </div>
		)
        
    }
}

export default AppTeaser