import {
    FETCH_DOSSIER,
    DELETE_DOSSIER,
    SET_DOSSIERS_GRID_PAGE_SIZE,
    SET_DOSSIERS_GRID_PAGE_INDEX,
    SET_DOSSIERS_GRID_SORT_CRITERIA,
    SET_DOSSIERS_GRID_FILTERS,
    FETCHED_DOSSIERS_CHUNKS,
    FETCHING_DOSSIERS_CHUNKS,
    ADD_NEW_DOSSIER_SECTION,
    RESET_DOSSIER_SECTION_TO_0,
    REMOVE_DOSSIER_SECTION,
    FETCH_DOSSIER_CHAPTER,
    FETCH_DOSSIER_CHAPTERS,
    UPDATE_DOSSIER_CHAPTER,
    FETCH_COUNTRY,
    FETCH_COUNTRIES,
    FETCHING_COUNTRIES,
    FETCHING_COUNTRY,
    FETCHED_COUNTRIES,
    FETCHED_COUNTRY,
} from "../../actions/types";

import * as _ from "lodash";

const INITIAL_STATE = {
    all: [],
    dossierChapter: null,
    loading: false,
    numberPages: 0,
    dossier: null,
    dossiersections: [],
    countries: [],
    dossiersGridData: {
        pageIndex: 0,
        pageSize: 10,
        gridFilters: {},
        gridSortCriteria: {},
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case FETCH_DOSSIER:
            return {
                ...state,
                dossier: action.payload.data
            };

        case FETCH_DOSSIER_CHAPTER:
            var chapter = action.payload.data
            if (!chapter.map) {
                chapter.map = ""
            }
            return {
                ...state,
                dossierChapter: chapter
            };
        case FETCH_DOSSIER_CHAPTERS:
            var chapter = action.payload && action.payload.data ? action.payload.data : null
         
            return {
                ...state,
                chapter: chapter && chapter.length > 0 ? chapter[0] : null
            };

        case RESET_DOSSIER_SECTION_TO_0:
            return { ...state, dossiersections: [] };

        case ADD_NEW_DOSSIER_SECTION:

            let newDossierSections = [];
            let elm = 0;

            if (state.dossiersections.length) {
                newDossierSections = state.dossiersections.slice();
                elm = newDossierSections[newDossierSections.length - 1];
            }

            newDossierSections.push(parseInt(elm) + 1);

            return {
                ...state,
                dossiersections: newDossierSections
            };

        case REMOVE_DOSSIER_SECTION:

            let new_sections1 = state.dossiersections.filter(function (el) {
                return el != action.payload;
            });

            return {
                ...state,
                dossiersections: new_sections1
            };

            return state;

        case FETCHING_DOSSIERS_CHUNKS:
            return {
                ...state,
                loading: action.payload
            }

        case FETCHED_DOSSIERS_CHUNKS:
            return {
                ...state,
                all: action.payload.data,
                numberPages: action.payload.numberPages,
                loading: action.payload.loading
            };

        case DELETE_DOSSIER:
            var allDossiers = state.all.filter(function (obj) {
                return obj.id !== action.dossier.id;
            });
            return { ...state, all: allDossiers };

        case SET_DOSSIERS_GRID_PAGE_SIZE:
            var pGridData = {
                ...state.dossiersGridData,
                pageSize: action.payload
            };
            return { ...state, dossiersGridData: pGridData };

        case SET_DOSSIERS_GRID_PAGE_INDEX:
            var pGridData = {
                ...state.dossiersGridData,
                pageIndex: action.payload
            };
            return { ...state, dossiersGridData: pGridData };

        case SET_DOSSIERS_GRID_SORT_CRITERIA:
            var pGridData = {
                ...state.dossiersGridData,
                gridSortCriteria: action.payload
            };
            return { ...state, dossiersGridData: pGridData };

        case SET_DOSSIERS_GRID_FILTERS:
            var pGridData = {
                ...state.dossiersGridData,
                gridFilters: action.payload
            };
            return { ...state, dossiersGridData: pGridData };
        case FETCH_COUNTRY:
            return {
                ...state,
                country: action.payload.data,

            };
        case FETCH_COUNTRIES:
            return {
                ...state,
                all: action.payload.data,

            };
        case FETCHING_COUNTRIES:
            return {
                ...state,
                loading: action.payload
            }
        case FETCHING_COUNTRY:
            return {
                ...state,
                loading: action.payload
            }
        case FETCHED_COUNTRIES:
            return {
                ...state,
                countries: action.payload.data,
                loading: action.payload.loading
            };
        case FETCHED_COUNTRY:
            return {
                ...state,
                country: action.payload.data,
                loading: action.payload.loading
            };
        default:
            return state;
    }
}