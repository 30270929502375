import { ControlCameraOutlined } from '@material-ui/icons';
import axios from 'axios';
import {
    ADD_NEW_POST_SECTION,
    CREATE_POST,
    DELETE_POST,
    FETCH_POST,
    FETCHED_LAYER,
    FETCHING_LAYER,
    FETCHING_LAYERLIST,
    FETCHED_LAYERS,
    FETCHING_LAYERS,
    FETCHED_MAP,
    FETCHED_LAYERLIST,
    FETCHING_MAP,
    REMOVE_POST_SECTION,
    RESET_POST_SECTION_TO_0,
    SET_POSTS_GRID_FILTERS,
    SET_POSTS_GRID_PAGE_INDEX,
    SET_POSTS_GRID_PAGE_SIZE,
    SET_POSTS_GRID_SORT_CRITERIA,
    UPDATE_POST,
    UPDATE_MAP,
    UPDATED_MAP
} from "../../actions/types";

import { ROOT_URL } from "../../config";


export function fetchMap(id) {
    console.log('fetchMapfetchMapfetchMap')

    const url = `${ROOT_URL}/Maps/${encodeURIComponent(id)}`

    return function (dispatch) {
        axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then(response => {
            console.log('fetchMapfetchMapfetchMap response', response)
            dispatch(
                {
                    type: FETCHED_MAP,
                    payload: {
                        data: response.data,
                        loading: false
                    }
                }
            )
        }).catch((e) => {
            console.log('error fetching the map', e);
        })
    }
}

export function updateMapMetadata(payload) {
    console.log('updateMapMetadata', payload)

    const filter = {
        "id":payload.id
    }

    const url = `${ROOT_URL}/Maps/update?where=${encodeURIComponent(JSON.stringify(filter))}`
    return function (dispatch) {
        return axios.post(url, payload, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            dispatch({
                type: UPDATED_MAP,
                payload: response
            })
        }).catch((e) => {
            console.log('updateMapMetadata error', e)
        })
    }
}