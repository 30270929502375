import React, { Component } from 'react';
import './style/auth.css';
import SignInForm from './forms/sign_in_form';
import { Card,  CardActions, CardContent, CardHeader, Grid } from '@material-ui/core';

export default class SignIn extends Component {

    render() {
        return (
            <Grid  container justify={'center'}>
            <Grid lg={6} item style={{paddingTop:60}}>
            <Card style={{padding:20}}>
            <CardContent>
                <div className="col col-12">
                    <h1 className="lead"><strong>Registrierung bestätigt! </strong></h1>
                    
                    <p>Sie können sich nun einloggen</p>
                    <SignInForm />
                </div>
                </CardContent>
            </Card>
            </Grid>
            </Grid>
        )
    }
}

