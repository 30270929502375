import React from 'react';
import ReactDOM from 'react-dom';

import { Markdown } from 'react-showdown';
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardContent, IconButton } from '@material-ui/core';
import { ROOT_URL } from "../../config";
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import { Link } from 'react-router-dom';
import Map from '../map/map_index'
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import LinkIcon from '@material-ui/icons/Link'
import PrintIcon from '@material-ui/icons/Print'
import ErrorIcon from '@material-ui/icons/Error'
import EditIcon from '@material-ui/icons/Edit'
import RegisterUser from '../registerUser'

import { CircularProgress, Tooltip } from '@material-ui/core'
import {
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailShareButton,

    FacebookIcon,
    TwitterIcon,
    TelegramIcon,
    WhatsappIcon,
    EmailIcon,
} from 'react-share';
import {
    fetchArticlesByTagTitles, fetchChecklists, fetchArticlesSearch
} from "../articles/actions";
import NewsLetter from '../newsletter'
//import ReactGA from 'react-ga';

class Article extends React.Component {
    constructor(props) {
        super(props);
        this.state = { article: null, featureCollection: null };

    }
    UNSAFE_componentWillMount() {

        //ReactGA.initialize('UA-130702159-1');
        //ReactGA.pageview(window.location.pathname + window.location.search)

        if (this.props.match.params.postName) {
            this.fetchArticleByPostname(this.props.match.params.postName).then(res => {
                // console.log('fetchArticleByPostname', res.data)
                this.setState({
                    article: res.data.article,
                    featureCollection: res.data.featureCollection
                })
            }).catch(err => {
                // console.log('err', err)
            })
        }
        this.props.fetchChecklists()


    }

    fetchGeoJson(url) {
        return axios.get(url)
    }

    makeLinksExternal() {
        //console.log('makeLinksExternal')
        var links = document.links;
        for (var i = 0, linksLength = links.length; i < linksLength; i++) {
            if (links[i].hostname != window.location.hostname) {
                links[i].target = "_blank";
                links[i].setAttribute("rel", "noopener noreferrer");
                links[i].className += " externalLink";
            } else {
                links[i].className += " localLink";
            }
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        /* if (nextProps.article && nextProps.article.geojsonUrl && !this.state.geojson) {
             this.fetchGeoJson(nextProps.article.geojsonUrl).then( geojson=>{
                 ///this.setState({geojson})
                 if (this.state.featureCollection && this.state.featureCollection.features) {
                     var point = this.state.featureCollection.features[0]
                     var 
                 }
 
             }).catch(err=>{
                // console.log('err', err)
             })
         }*/
    }

    componentDidUpdate() {

    }

    fetchArticleByPostname(postName) {

        const url = `${ROOT_URL}/Articles/byPostname?postName=${encodeURIComponent(postName)}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        })
    }


    onClick(article) {
        if (this.props.onClick) {
            this.props.onClick(article)
        }
    }

    onTagClick(tag) {
        // console.log('tag click', tag)
        if (this.props.onTagClick) {
            this.props.onTagClick(tag)
        }
    }

    /*

     <CardActions>
                                <Button onClick={this.onClick.bind(this, article)} size="small">Mehr</Button>
                            </CardActions>

    */

    componentDidUpdate() {
        //ReactDOM.findDOMNode(this).scrollTop = 0
        this.makeLinksExternal()
    }

    render() {
        const { article, featureCollection } = this.state
        const { admin, editor, checklists, authenticated } = this.props
        var showChecklists = null
        var isDailyBrief = false
        var leftSize = 6
        var rightSize = 6
        if (article) {
            showChecklists = article.tags.map(tag => {
                var res = checklists.filter(checklist => {
                    return checklist.tag == tag
                })
                if (res.length > 0) {
                    return res[0]
                }
            }).filter(i => {
                return i != undefined
            })
            isDailyBrief = article.tags.indexOf("daily-brief") != -1

            if (isDailyBrief) {
                leftSize = 6
                rightSize = 6
            }
        }

        //// console.log('fc', featureCollection)
        return (<div>

            {article && !isDailyBrief &&

                <Grid style={{ maxWidth: '100%', padding: '12px', marginTop: '12px', marginLeft: '0px' }} container >

                    <Hidden smDown>
                        <Grid style={{ paddingLeft: '0px', paddingRight: '10px', marginRight: '0px' }} item lg={leftSize} xs={12} sm={12} md={leftSize} >
                            <Paper>
                                {!isDailyBrief &&
                                    <Map
                                        drawControl={false} containerStyle={{
                                            height: '85vh',
                                            width: "100%"
                                        }} featureCollection={featureCollection}>
                                    </Map>
                                }
                                {isDailyBrief &&
                                    <div>
                                        <div style={{ padding: 20 }}>
                                            <h2>Online-Publikationen (Intelligence Monitor, Travel Risk Intelligence)</h2>
                                            <p>Mit den Online-Publikationen von RiskCompass bleiben Sie als Entscheidungsträger und/oder interessierte Privatperson auf dem Laufenden. Sie erhalten kurz und prägnant einen kritisch-analytischen Blick auf die Welt, auf die innere und äußere Sicherheitslage oder auf das Geschehen im Travel-Risk-Bereich.</p>

                                            <p>Die vertraulichen Informationen, Hintergrundrecherchen, Interviews und Prognosen ergänzen die täglichen RiskCompass-Nachrichten; sie liefern das »ganze Bild«, das notwendig ist, um Zusammenhänge zu erkennen und zu verstehen, um nachhaltige Entscheidungen treffen zu können.</p>

                                            <p>Deshalb gleich ‒ entweder den Newsletter »Intelligence Monitor¹« oder den Newsletter »Travel Risk Intelligence²« ‒ (oder beide zusammen) gratis und unverbindlich bestellen!</p>

                                            <small>¹Intelligence Monitor: Der Informationsdienst zur aktuellen inneren und äußeren Sicherheitslage.<br />

                                                ²Travel Risk Intelligence: Der Nachrichten- und Informationsdienst für Unternehmer, Geschäfts- und Privatreisende, Sicherheitspersonal, Fuhrparkmanager und alle Menschen, die mobil und/oder weltweit unterwegs sind!</small>
                                            <br /><br /><br />
                                        </div>
                                        <NewsLetter />
                                    </div>
                                }
                            </Paper>

                        </Grid>
                    </Hidden>

                    <Grid item lg={rightSize} sm={12} xs={12} md={rightSize} >


                        <div style={{
                            marginTop: '0px',
                            paddingLeft: '2px',
                            paddingRight: '2px',
                            height: 'calc( 100vh - 150px )',
                            overflowY: 'scroll',
                            WebkitOverflowScrolling: 'touch'
                        }}>

                            <Card key={article.id} style={{ marginBottom: '15px', maxWidth: '100%' }}>
                                <CardContent>
                                    <h2><strong><Link to={"/article/" + article.postName}>{article.country} ({article.city ? article.city + ', ' : ''}{article.date}): {article.title}</Link></strong></h2>
                                    <Markdown markup={article.markdown ||  article.teaser} tables={true} />
                                    {showChecklists.length > 0 &&
                                        <p>
                                            Im Ereignisfall sollten Reisende die Sicherheits- und/oder Verhaltenshinweise gemäß der RiskCompass-Checkliste
                                             {showChecklists.map((checklist, index) => {
                                                return <Link style={{ paddingLeft: 5, marginBottom: 30, textDecoration: 'none' }} to={"/page/" + checklist.postName}>{checklist.title}{index == showChecklists.length - 1 ? "" : ","}</Link>
                                            })} beachten
                                    </p>

                                    }


                                    {article.copyright &&
                                        <p><h6>{article.copyright}</h6></p>
                                    }

                                    <div style={{ marginTop: 20, paddingBottom: 20, marginLeft: 5, display: 'flex', flexDirection: 'row', msFlexDirection: 'row' }}>

                                        {article.link &&
                                            <Tooltip title="Zur Original-Quelle">
                                                <a style={{ marginLeft: 0 }} href={article.link}><LinkIcon /></a>
                                            </Tooltip>
                                        }
                                        <Tooltip title="Auf Facebook teilen">
                                            <div>
                                                <FacebookShareButton style={{ marginLeft: 10, marginRight: 10 }} url={"https://riskcompass.eu/article/" + article.postName}>

                                                    <FacebookIcon size={24} round />

                                                </FacebookShareButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Auf Twitter teilen">
                                            <div>
                                                <TwitterShareButton style={{ marginLeft: 10, marginRight: 10 }} title={`${article.country} (${article.city ? article.city + ', ' : ''}${article.date}): ${article.title}`} url={"https://riskcompass.eu/article/" + article.postName}>
                                                    <TwitterIcon size={24} round />
                                                </TwitterShareButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Per Telegram teilen">
                                            <div>
                                                <TelegramShareButton style={{ marginLeft: 10, marginRight: 10 }} url={"https://riskcompass.eu/article/" + article.postName}>
                                                    <TelegramIcon size={24} round />
                                                </TelegramShareButton>
                                            </div>
                                        </Tooltip>

                                        <Tooltip title="Per Whatsapp teilen">
                                            <div>
                                                <WhatsappShareButton style={{ marginLeft: 10, marginRight: 10 }} url={"https://riskcompass.eu/article/" + article.postName}>
                                                    <WhatsappIcon size={24} round />
                                                </WhatsappShareButton>
                                            </div>
                                        </Tooltip>

                                        <Tooltip title="Per Email teilen">
                                            <div>
                                                <EmailShareButton style={{ marginLeft: 10, marginRight: 10 }} url={"https://riskcompass.eu/article/" + article.postName}>
                                                    <EmailIcon size={24} round />
                                                </EmailShareButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Drucken">

                                            <PrintIcon style={{ marginLeft: 10, }} onClick={window.print} />

                                        </Tooltip>
                                        {(admin || editor) &&
                                            <Tooltip title="Artikel bearbeiten">
                                                <a style={{ marginLeft: 10, }} href={article.link}><Link to={"/article/edit/" + article.id}><EditIcon /></Link></a>
                                            </Tooltip>
                                        }
                                    </div>



                                </CardContent>

                            </Card>
                            {!authenticated && 
                            <Card>
                                <RegisterUser />
                                </Card>
                            }

                            {false && !authenticated &&
                                <Card>
                                    <CardContent>
                                        <div style={{display:'flex', flexDirection:'row'}}>
                                        <ErrorIcon color="red" style={{marginTop:50,color:'red'}} fontSize={'large'}/>
                                    <h2 style={{marginLeft:30}}>Sehr geehrter Nutzer, Sie haben sich noch nicht angemeldet, um die Nachrichten vollständig lesen zu können.
                                     Wenn Sie das komplette <Link to="/offer">Angebot</Link> der RiskCompass Länder- und/oder Städtedatenbanken
                                      einschließlich des Nachrichten-Feeds nutzen möchten, können Sie <Link to="/offer">hier</Link> ihre Lizenz bestellen.</h2>
                                    </div>
                                    </CardContent>

                                </Card>
                            }


                        </div>
                    </Grid>


                </Grid>

            }

            {article && isDailyBrief &&
                <div>
                    <Grid container  style={{ marginTop: 10 }} >
                        <Grid item lg={2}>

                        </Grid>
                        <Grid item lg={8} >
                            <Card key={article.id} style={{ marginBottom: '15px', maxWidth: '100%' }}>
                                <CardContent>
                                    <h2><strong><Link to={"/article/" + article.postName}>{article.country} ({article.city ? article.city + ', ' : ''}{article.date}): {article.title}</Link></strong></h2>
                                    <Markdown markup={article.markdown || article.teaser} tables={true} />



                                    {article.copyright &&
                                        <p><h6>{article.copyright}</h6></p>
                                    }

                                    <div style={{ marginTop: 20, paddingBottom: 20, marginLeft: 5, display: 'flex', flexDirection: 'row', msFlexDirection: 'row' }}>

                                        {article.link &&
                                            <Tooltip title="Zur Original-Quelle">
                                                <a style={{ marginLeft: 0 }} href={article.link}><LinkIcon /></a>
                                            </Tooltip>
                                        }
                                        <Tooltip title="Auf Facebook teilen">
                                            <div>
                                                <FacebookShareButton style={{ marginLeft: 10, marginRight: 10 }} url={"https://riskcompass.eu/article/" + article.postName}>

                                                    <FacebookIcon size={24} round />

                                                </FacebookShareButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Auf Twitter teilen">
                                            <div>
                                                <TwitterShareButton style={{ marginLeft: 10, marginRight: 10 }} title={`${article.country} (${article.city ? article.city + ', ' : ''}${article.date}): ${article.title}`} url={"https://riskcompass.eu/article/" + article.postName}>
                                                    <TwitterIcon size={24} round />
                                                </TwitterShareButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Per Telegram teilen">
                                            <div>
                                                <TelegramShareButton style={{ marginLeft: 10, marginRight: 10 }} url={"https://riskcompass.eu/article/" + article.postName}>
                                                    <TelegramIcon size={24} round />
                                                </TelegramShareButton>
                                            </div>
                                        </Tooltip>

                                        <Tooltip title="Per Whatsapp teilen">
                                            <div>
                                                <WhatsappShareButton style={{ marginLeft: 10, marginRight: 10 }} url={"https://riskcompass.eu/article/" + article.postName}>
                                                    <WhatsappIcon size={24} round />
                                                </WhatsappShareButton>
                                            </div>
                                        </Tooltip>

                                        <Tooltip title="Per Email teilen">
                                            <div>
                                                <EmailShareButton style={{ marginLeft: 10, marginRight: 10 }} url={"https://riskcompass.eu/article/" + article.postName}>
                                                    <EmailIcon size={24} round />
                                                </EmailShareButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Drucken">

                                            <PrintIcon style={{ marginLeft: 10, }} onClick={window.print} />

                                        </Tooltip>

                                        {(admin || editor) &&
                                            <Tooltip title="Artikel bearbeiten">
                                                <a style={{ marginLeft: 10, }} href={article.link}><Link to={"/article/edit/" + article.id}><EditIcon /></Link></a>
                                            </Tooltip>
                                        }


                                    </div>



                                </CardContent>

                            </Card>
                        </Grid>
                        <Grid item lg={2}>

                        </Grid>

                    </Grid>

                    <Grid container  className="no-print">
                        <Grid item lg={2}>

                        </Grid>

                        <Grid item lg={8} style={{ marginBottom: '35px' }}>
                            <Card>
                                <CardContent>

                                    <div >
                                        <h2>Online-Publikationen (Intelligence Monitor, Travel Risk Intelligence)</h2>
                                        <p>Mit den Online-Publikationen von RiskCompass bleiben Sie als Entscheidungsträger und/oder interessierte Privatperson auf dem Laufenden. Sie erhalten kurz und prägnant einen kritisch-analytischen Blick auf die Welt, auf die innere und äußere Sicherheitslage oder auf das Geschehen im Travel-Risk-Bereich.</p>

                                        <p>Die vertraulichen Informationen, Hintergrundrecherchen, Interviews und Prognosen ergänzen die täglichen RiskCompass-Nachrichten; sie liefern das »ganze Bild«, das notwendig ist, um Zusammenhänge zu erkennen und zu verstehen, um nachhaltige Entscheidungen treffen zu können.</p>

                                        <p>Deshalb gleich ‒ entweder den Newsletter »Intelligence Monitor¹« oder den Newsletter »Travel Risk Intelligence²« ‒ (oder beide zusammen) gratis und unverbindlich bestellen!</p>

                                        <small>¹Intelligence Monitor: Der Informationsdienst zur aktuellen inneren und äußeren Sicherheitslage.<br />

                                            ²Travel Risk Intelligence: Der Nachrichten- und Informationsdienst für Unternehmer, Geschäfts- und Privatreisende, Sicherheitspersonal, Fuhrparkmanager und alle Menschen, die mobil und/oder weltweit unterwegs sind!</small>
                                        <br /><br /><br />
                                    </div>
                                    <NewsLetter />
                                </CardContent>
                            </Card>

                        </Grid>
                        <Grid item lg={2}>

                        </Grid>



                    </Grid>
                </div>
            }



        </div>)

    }

}

/*#
#

 {article && 
                        <Card key={article.id} style={{ marginBottom: '15px', maxWidth: 'calc( 100% - 14px )' }}>
                            <CardContent>
                                <h2><strong><Link  to={"/article/" + article.postName}>{article.title}</Link></strong></h2>
                                <Markdown markup={article.markdown} tables={true} />

                                 {article.link && 
                                <p><a href={article.link}>Zur Originalmeldung</a></p>
                                }
                            
                                {article.copyright && 
                                    <p><h6>{article.copyright}</h6></p>
                                }
                                <div>
                                    {article.tags && article.tags.map(tag => {
                                        return (

                                            <Link key={tag.id} to={"/articles/" + tag.title}>
                                                <Chip
                                                    style={{ margin: '3px' }}
                                                    key={tag.id}
                                                    label={tag.title}
                                                    onClick={this.onTagClick.bind(this, tag)}
                                                />
                                            </Link>

                                        )


                                    })}
                                </div>

                            </CardContent>

                        </Card>
                    
                }


*/



function mapStateToProps(state) {


    return {
        authenticated: state.auth.authenticated,
        checklists: state.articles.checklists,
        admin: state.auth.admin,
        editor: state.auth.editor
    }
}
const styles = {

}
export default withStyles(styles, { name: 'Article' })(connect(mapStateToProps, {
    fetchChecklists: fetchChecklists
})(Article));


