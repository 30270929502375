import React, { Component } from 'react'
import { connect } from 'react-redux'
import { render, findDOMNode } from 'react-dom';
import ReactMapboxGl, { GeoJSONLayer, Layer, Feature, ZoomControl, Cluster, Marker, Popup, RotationControl } from "react-mapbox-gl";
import DrawControl from 'react-mapbox-gl-draw';
import { FullscreenControl } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { fetchLayer, fetchLayers, fetchLayerList, fetchMap, updateMap } from './actions';
import { Divider, CircularProgress } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import LayerIcon from '@material-ui/icons/Layers';
//import BoxIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import BoxIcon from '@material-ui/icons/SelectAll'
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import ExpandIcon from '@material-ui/icons/Fullscreen';
import CollapseIcon from '@material-ui/icons/FullscreenExit';
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment"
import { FormControl, Select, MenuItem, Paper, Popper, List, IconButton, ListItem, ListItemSecondaryAction, ListItemText, Card, CardContent } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { ROOT_URL } from "../../config";
//import feature from 'react-mapbox-gl/lib/feature';
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { centroid, feature, polygon, multiPolygon, featureCollection, bbox, lineString } from '@turf/turf';
import { Markdown } from 'react-showdown';
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next'
import RiskLevelEditor from '../riskLevelEditor'
import { mapLimit } from "async-es";
import { fetchDossierChapters } from '../dossier/actions'


const MAPBOX_TOKEN = ""//process.env.MapboxAccessToken || 'pk.eyJ1IjoibW9yaXR6a25lY2h0IiwiYSI6ImNqYXZyNzdrMTExazcyd24waDI2a2hydWUifQ.5yuV5AFnTNMAZBXwI3MocA'; // eslint-disable-line

const Mapbox = ReactMapboxGl({
    //accessToken: MAPBOX_TOKEN
    dragRotate: false,
    pitchWithRotate: false,
    antialias: false,
});

const styles = {
    clusterMarker: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: 'orange',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        border: '2px solid #56C498',
        cursor: 'pointer'
    },
    marker: {
        width: 15,
        height: 15,
        borderRadius: '50%',
        backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid #C9C9C9',
        cursor: 'pointer'
    }
};



const symbolLayout = {

    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
    'text-offset': [0, 0],
    'text-size': 15,
    'text-anchor': 'center',
    'icon-anchor': 'center',
    "icon-image": "{icon}",
    'icon-size': [
        'match',
        ['get', 'icon'],
        'warning_terror.png', 0.4,
        "warning_general.png", 0.4,
        "Hotspot_Pin-Kopie.png", 0.5,
        "THW.png", 0.5,
        "Klinik.png", 0.5,
        "strike.png", 0.5,
        "apartment.png", 0.5,
        "Politischer_Extremismus_links.png", 0.5,
        'Politischer_Extremismus_Ausländer.png', 0.5,
        'Politischer_Extremismus_rechts.png', 0.5,
        'triangle_rape.png', 0.4,
        'triangle_assault_police.png', 0.4,
        'Moschee_Icon.png', 0.4,
        'graue_woelfe.jpg', 0.4,
        'usarmy.jpg', 0.4,
        'clan.png', 0.4,
        'bundeswehr.jpg', 0.4,

        0.3
    ],
    'icon-ignore-placement': false,
    'text-allow-overlap': true,
    'icon-allow-overlap': true

};
const symbolPaint = {
    'text-color': 'black',

};

GeoJSONLayer.prototype.initialize = function () {
    var map = this.context.map;
    map.addSource(this.id, this.source);
    this.createLayer('circle');
    this.createLayer('symbol');
    this.createLayer('line');
    this.createLayer('fill');
    this.createLayer('fill-extrusion');

};

const circleLayout = {
    visibility: 'visible',


};

const imageLayout = {
    visibility: 'visible',
    "id": "markers",
    "type": "symbol",
    "layout": {
        "icon-image": "https://riskcompass.eu/images/mapicons/{icon}",
    }
};

const circlePaint = {
    'circle-color': 'black',
    'circle-color': [
        'match',
        ['get', 'icon'],
        "blank_green.png", '#9CFF00',
        'warning_terror.png', 'black',
        "blank_black.png", 'black',
        "blank_pink.png", '#f442f1',
        "warning_general.png", 'yellow',
        "blank_red.png", '#ff0000',
        'medicine.png', '#000099',
        'caution.png', 'grey',
        'fire.png', '#ff9900',

     /* other */ 'purple'
    ],
    'circle-radius': 7,
    'circle-opacity': 1,
    'circle-stroke-width': 1,
    'circle-stroke-opacity': 0.75,
    'circle-stroke-color': 'black',



};

const polygonPaint = {

    'fill-color': [
        'match',
        ['get', 'riskLevel'],
        0, '#7d9cce',
        1, '#00ff00',
        2, '#ffff00',
        3, '#ffa500',
        4, '#ff6600',
        5, '#ff0000',
                            /* other */ '#7d9cce'
    ],
    'fill-opacity': 0.5,
    'fill-outline-color': '#464646'
};
function polygonPaintCovid(riskPotential) {
    // console.log('poly paint', riskPotential)

    return {

        'fill-color': [
            'match',
            ['get', riskPotential],
            0, '#7d9cce',
            1, '#00ff00',
            2, '#ffff00',
            3, '#ffa500',
            4, '#ff6600',
            5, '#ff0000',
            6, '#d11515',
            7, '#8c0b0b',
                                /* other */ '#7d9cce'
        ],
        'fill-opacity': 0.5,
        'fill-outline-color': '#464646'
    }
}


const polygonPaint2 = {
    'fill-color': '#ffff00',
    'fill-opacity': 0.0,
    'fill-outline-color': '#ffff00',
};

const multiPolygonPaint = {
    'fill-color': '#3bb2d0',
    'fill-opacity': 0.5
};

const layerPaint = {
    'heatmap-weight': {
        property: 'scalerank',
        type: 'exponential',
        stops: [
            [0, 0],
            [5, 2]
        ]
    },
    // Increase the heatmap color weight weight by zoom level
    // heatmap-ntensity is a multiplier on top of heatmap-weight
    'heatmap-intensity': {
        stops: [
            [0, 0],
            [5, 1.2]
        ]
    },
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparancy color
    // to create a blur-like effect.
    'heatmap-color': [
        'interpolate',
        ['linear'],
        ['heatmap-density'],
        0, 'rgba(33,102,172,0)',
        0.25, 'rgb(103,169,207)',
        0.5, 'rgb(209,229,240)',
        0.8, 'rgb(253,219,199)',
        1, 'rgb(239,138,98)',
        2, 'rgb(178,24,43)'
    ],
    // Adjust the heatmap radius by zoom level
    'heatmap-radius': {
        stops: [
            [0, 1],
            [5, 50]
        ]
    }
}

var PointPaint = {
    "id": "earthquakes-point",
    "type": "circle",
    "source": "earthquakes",
    "minzoom": 7,
    "paint": {
        // Size circle radius by earthquake magnitude and zoom level
        "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            7, [
                "interpolate",
                ["linear"],
                ["get", "mag"],
                1, 1,
                6, 4
            ],
            16, [
                "interpolate",
                ["linear"],
                ["get", "mag"],
                1, 5,
                6, 50
            ]
        ],
        // Color circle by earthquake magnitude
        "circle-color": [
            "interpolate",
            ["linear"],
            ["get", "mag"],
            1, "rgba(33,102,172,0)",
            2, "rgb(103,169,207)",
            3, "rgb(209,229,240)",
            4, "rgb(253,219,199)",
            5, "rgb(239,138,98)",
            6, "rgb(178,24,43)"
        ],
        "circle-stroke-color": "white",
        "circle-stroke-width": 1,
        // Transition from heatmap to circle layer by zoom level
        "circle-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            7, 0,
            8, 1
        ]
    }
}

// https://free.tilehosting.com/styles/basic/style.json?key=ALSU4XFuQjnG2jVilgsk
//var simple = 'https://free.tilehosting.com/styles/positron/style.json?key=ALSU4XFuQjnG2jVilgsk'  //http://tiles.vespertine.it/styles/positron/style.json'
var simple = 'https://free.tilehosting.com/data/v3.json?key=ALSU4XFuQjnG2jVilgsk'

/*{
    "version": 8,
    "sources": {
        "osm": {
            "type": "vector",
            "tiles": ["http://tiles.vespertine.it/data/v3/{z}/{x}/{y}.pbf"]
        }
    },
    "layers": [
        {
            "id": "background",
            "type": "background",
            "paint": {
                "background-color": "#41afa5"
            }
        }, {
            "id": "water",
            "type": "fill",
            "source": "osm",
            "source-layer": "water",
            "filter": ["==", "$type", "Polygon"],
            "paint": {
                "fill-color": "#3887be"
            }
        }
    ]
};*/

class Map extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            //bounds:[[-178.3539,-58.1107],[168.9131,69.6998]],
            layerMenuOpen: false,
            checked: [0],
            imagesLoaded: false,
            layers: [],
            style: this.getStyle("topo"),
            popup: null,
            labels: ["building"],
            zoomState: [0],
            pitchState: [0],
            bearingState: [0],
            loading: false
        }
        this.button = null
        this.map = null

    }

    handleToggle = value => () => {
        //  // console.log('handleToggle', value.id)
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value.id);
        // // console.log('currentIndex', currentIndex)
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({
            checked: newChecked,
        }, this.getLayers)
    };

    fetchLayer(ids) {
        const url = `${ROOT_URL}/Layers/featureCollection?ids=${encodeURIComponent(JSON.stringify(ids))}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        })
    }

    fetchGeojsonUrl(url) {
        url = url.replace("\/\/da", "\/da")
        return axios.get(url, {
            headers: {}
        })
    }

    fetchMap(id) {

        const url = `${ROOT_URL}/Maps/fullMap?id=${encodeURIComponent(id)}&options=${encodeURIComponent(JSON.stringify({ small: true }))}`

        console.log('fetchMap', id, url)

        if (this.cancelToken) {
            console.log('should cancel')
            this.cancelToken.cancel()
            // this.setState({loading:false})
        }
        this.cancelToken = axios.CancelToken.source();

        return axios.get(url, {
            cancelToken: this.cancelToken.token,
            headers: { authorization: localStorage.getItem('token') }
        }).catch(function (thrown) {
            console.log('thrown', thrown)
            if (axios.isCancel(thrown)) {
                console.log('Request canceled', thrown.message);

            } else {
                // handle error
            }
        });
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined)
        }
    }


    componentDidMount() {
        // document.addEventListener('webkitfullscreenchange', () => setTimeout(() => this.map.resize(), 0))

        // this.setState({anchorEl})
        if (this.props.onRef) {
            this.props.onRef(this)
        }
        if (this.props.riskPotentials && this.props.riskPotentials.length > 0) {
            this.setState({ riskPotential: this.props.riskPotentials[0] })
        }
        //this.setState({ containerStyle: this.props.containerStyle })

        this.setState({ drawControl: this.props.drawControl || false })
        if (this.props.style) {
            this.setState({ style: this.getStyle(this.props.style) })
        }
        if (this.props.mapId) {
            this.setState({ mapId: this.props.mapId })

            this.setState({ loading: true })
            this.fetchMap(this.props.mapId).then((res) => {
                console.log('fetchMap in componentDidMount')

                if (res && res.data) {
                    var layers = res.data.layers

                    const checked = layers.map(layer => {
                        if (layer.type == "featureCollection" && !this.props.riskPotentials) {
                            this.setState({ riskPotential: 'all' })
                        }
                        return layer.id
                    })
                    if (layers.length == 0) {
                        this.setState({ loading: false })
                    }

                    var newState = { currentMap: res.data, layers: layers, checked: checked, bounds: res.data.bounds, subtype: res.data.subtype }

                    this.setState(newState, () => {
                        // console.log('statezoom', this.state)
                    })
                }


            }).catch(err => {
                //this.setState({loading:false})
            })
        }



        //// console.log('this.props.layerIds', this.props.layerIds)
        if (this.props.layerIds && this.props.layerIds.length) {

            //this.props.fetchMap("10")

            this.setState({ layerIds: this.props.layerIds })


            this.fetchLayer(this.props.layerIds).then((res) => {

                // // console.log('res', res.data)
                const layers = res.data
                /* layers = layers.map( layer => {
                     layer.styles = 
                 })*/

                this.setState({ layers: res.data })

            })



        }
        // this.props.fetchLayerList()

        //        this.props.fetchLayer("16")




    }

    clusterMarker = (coordinates, pointCount, getLeaves) => {
        return (
            <Marker key={coordinates + pointCount} coordinates={coordinates} style={styles.clusterMarker} onClick={this.clusterClick.bind(this, coordinates, pointCount, getLeaves)}>
                {pointCount}
            </Marker>
        )
    }

    fitBounds(featureCollection) {
        console.log('should fit bounds', featureCollection)
        var line = featureCollection.features.map(f => {

            return f.geometry.coordinates;
        })
        if (line && line.length > 1) {
            var box = bbox(lineString(line))
            // console.log('box', box)
            const bounds = [[box[0], box[1]], [box[2], box[3]]]
            // console.log('fitBounds', JSON.stringify(bounds))
            this.map.fitBounds(bounds, {
                padding: 20
            })
        }
        if (featureCollection.features.length == 1) {
            const f = featureCollection.features[0]
            this.map.flyTo({
                center: [f.geometry.coordinates[0], f.geometry.coordinates[1]],
                zoom: 7,
                speed: 2,
                curve: 0.75,
            })
            if (f.properties.geojsonUrl) {
                // console.log('geoUrl ', f.properties.geojsonUrl)
                this.fetchGeojsonUrl(f.properties.geojsonUrl).then(res => {
                    const geojson = res.data
                    this.setState({ geojson, geojsonUrl: f.properties.geojsonUrl })

                    if (f.properties.geojsonUrl.includes("riskcompass.eu")) {
                        this.map.addSource('earthquakes', {
                            "type": "geojson",
                            "data": f.properties.geojsonUrl
                        });

                        this.map.addLayer({
                            "id": "earthquakes-heat",
                            "type": "heatmap",
                            "source": "earthquakes",
                            "maxzoom": 9,
                            "paint": {
                                // Increase the heatmap weight based on frequency and property magnitude
                                "heatmap-weight": [
                                    "interpolate",
                                    ["linear"],
                                    ["get", "mag"],
                                    0, 0,
                                    6, 1
                                ],
                                // Increase the heatmap color weight weight by zoom level
                                // heatmap-intensity is a multiplier on top of heatmap-weight
                                "heatmap-intensity": [
                                    "interpolate",
                                    ["linear"],
                                    ["zoom"],
                                    0, 1,
                                    9, 3
                                ],
                                // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                                // Begin color ramp at 0-stop with a 0-transparancy color
                                // to create a blur-like effect.
                                "heatmap-color": [
                                    "interpolate",
                                    ["linear"],
                                    ["heatmap-density"],
                                    0, "rgba(33,102,172,0)",
                                    0.2, "rgb(103,169,207)",
                                    0.4, "rgb(209,229,240)",
                                    0.6, "rgb(253,219,199)",
                                    0.8, "rgb(239,138,98)",
                                    1, "rgb(178,24,43)"
                                ],
                                // Adjust the heatmap radius by zoom level
                                "heatmap-radius": [
                                    "interpolate",
                                    ["linear"],
                                    ["zoom"],
                                    0, 2,
                                    9, 20
                                ],
                                // Transition from heatmap to circle layer by zoom level
                                "heatmap-opacity": [
                                    "interpolate",
                                    ["linear"],
                                    ["zoom"],
                                    7, 1,
                                    9, 0
                                ],
                            }
                        });
                    }


                }).catch(err => {
                    // console.log('err', err)
                })
            }
        }
    }




    /*

    


              

                {layer && layer.features &&
                    <Layer
                        type="heatmap"
                        paint={layerPaint}
                    >
                        {
                            layer.features.map((el, index) => {
                               // console.log('el', el)
                                return <Feature
                                    key={index}
                                    coordinates={el.geometry.coordinates}
                                    properties={el.properties}
                                />
                            })
                        }
                    </Layer>
                }


                


    */

    /*
                  {

                layer &&
                <GeoJSONLayer
                    data={layer}
                    circleLayout={circleLayout}
                    circlePaint={circlePaint}
                   // circleOnClick={this.onClickCircle}
                    symbolLayout={symbolLayout}
                    symbolPaint={symbolPaint}
                />
                
                }
                <DrawControl />


                <Layer
                    type="heatmap"
                    paint={layerPaint}
                >
                    {
                        falls.features.map((el, index) => {
                           // console.log('el', el)
                            return <Feature
                                key={index}
                                coordinates={el.geometry.coordinates}
                                properties={el.properties}
                            />
                        })
                    }
                </Layer>

                 <Feature coordinates={[falls.features[0].polygonpoints]} />

    */

    onSourceDataEventHandler(event) {
        // This is a very sloppy way of waiting until at least some of the loading is done.
        if (event.isSourceLoaded && this.state.loading) {
            this.map.off('sourcedata', this.onSourceDataEventHandler)
            console.log('off!!!')
            this.setState({ loading: false })
        }
    }

    onMapLoaded(map) {
        if (!map) {
            return
        }

        //this.setState({ loading: true })
        this.map = map
        const { admin } = this.props
        if (admin) {
            console.log('admin!!!')
            this.map.addControl(new FullscreenControl(), 'top-left');

        }

        this.map.on('sourcedata', this.onSourceDataEventHandler.bind(this))

        var images = [
            "blank_green.png",
            'warning_terror.png',
            "blank_black.png",
            "blank_pink.png",
            "warning_general.png",
            "circle_brown_traffic_incident.png",
            "triangle_grey_warning_pollution.png",
            "triangle_blue_warning_health_outbreaks.png",
            "blank_red.png",
            'medicine.png',
            'caution.png',
            'fire.png',
            "strike.png",
            "Moschee_Icon.png",
            'graue_woelfe.jpg',
            'bundeswehr.jpg',
            'clan.png',
            'usarmy.jpg',
            'Hotspot_Pin-Kopie.png',
            "apartment.png",
            "THW.png",
            "Klinik.png",
            'Politischer_Extremismus_Ausländer.png',
            'Politischer_Extremismus_links.png',
            'Politischer_Extremismus_rechts.png',
            'triangle_rape.png',
            'triangle_assault_police.png',

        ]
        //  console.log('images', images)

        mapLimit(images, 21, (_image, cb) => {
            //cb(null, {})
            const url = window.location.origin + "/images/mapicons/" + _image
            this.map.loadImage(url, (error, image) => {
                console.log('url', url, "loaded", error)
                /*if (error) {
                    console.log('error', error)
                    cb(null, error)
                    return
                }*/
                if (this.map && !error && image) {
                    this.map.addImage(_image, image)
                }
                cb({})
            })
        }, (err, result) => {
            //console.log('images loaded')
            this.setState({ imagesLoaded: true })
        })

        /* map.on('click', (e) => {
             var features = map.queryRenderedFeatures(e.point)
            // console.log('features', features)
         })*/

        //console.log('featureCollection', this.props.featureCollection)
        //console.log('mapId', this.props.mapId)
        if (this.props.featureCollection && !this.props.mapId) {
            // console.log('should fit bounds #1')
            if (this.map && !this.props.heatmap) {
                this.fitBounds(this.props.featureCollection)
            }
            else if (this.map && this.props.heatmap) {
                // console.log('add heatmap')
                this.map.addSource('earthquakes', {
                    "type": "geojson",
                    "data": this.props.featureCollection
                })
                this.map.addLayer({
                    "id": "earthquakes-heat",
                    "type": "heatmap",
                    "source": "earthquakes",
                    "maxzoom": 9,
                    "paint": {
                        // Increase the heatmap weight based on frequency and property magnitude
                        "heatmap-weight": [
                            "interpolate",
                            ["linear"],
                            ["get", "mag"],
                            0, 0,
                            6, 1
                        ],
                        // Increase the heatmap color weight weight by zoom level
                        // heatmap-intensity is a multiplier on top of heatmap-weight
                        "heatmap-intensity": [
                            "interpolate",
                            ["linear"],
                            ["zoom"],
                            0, 1,
                            9, 3
                        ],
                        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                        // Begin color ramp at 0-stop with a 0-transparancy color
                        // to create a blur-like effect.
                        "heatmap-color": [
                            "interpolate",
                            ["linear"],
                            ["heatmap-density"],
                            0, "rgba(33,102,172,0)",
                            0.2, "rgb(103,169,207)",
                            0.4, "rgb(209,229,240)",
                            0.6, "rgb(253,219,199)",
                            0.8, "rgb(239,138,98)",
                            1, "rgb(178,24,43)"
                        ],
                        // Adjust the heatmap radius by zoom level
                        "heatmap-radius": [
                            "interpolate",
                            ["linear"],
                            ["zoom"],
                            0, 2,
                            9, 20
                        ],
                        // Transition from heatmap to circle layer by zoom level
                        "heatmap-opacity": [
                            "interpolate",
                            ["linear"],
                            ["zoom"],
                            7, 1,
                            9, 0
                        ],
                    }
                });



            }



        }
        // console.log('should fit bounds #3', "mapId", this.props.mapId, "bounds", this.state.bounds)

        if (this.props.mapId && this.state.bounds) {
            // console.log('should fit bounds #2')
            this.map.fitBounds(this.state.bounds, {
                padding: 20
            })
        }
        map.resize()
        setTimeout(() => {
            // map.invalidateSize();
            map.resize()

            // this.setState({ loading: false })

        }, 100)


        if (false && this.state.labels) {

            //var labels = ["aerodrome_label", "building"]
            this.state.labels.forEach(label => {

                switch (label) {
                    case 'aerodrome_label':
                        map.addLayer({
                            "id": label,
                            "type": "symbol",
                            "source": {
                                type: 'vector',
                                bounds: [-120, -55.051129, 120, 55.051129],
                                url: 'https://maps.tilehosting.com/data/v3.json?key=ALSU4XFuQjnG2jVilgsk'
                            },
                            "source-layer": label,
                            "interactive": true,

                            "layout": {

                                "icon-allow-overlap": true,
                                "icon-image": "{marker-symbol}-11",
                                "text-field": "{name}",
                                "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                                "text-size": 11,
                                "text-transform": "uppercase",
                                "text-letter-spacing": 0.05,
                                "text-offset": [0, 1.5]
                            },
                            "paint": {
                                "text-color": "#202",

                            },
                        });
                        break;
                    case "boundary":
                        map.addLayer({
                            "id": label,
                            "type": "line",
                            "source": {
                                type: 'vector',
                                url: 'https://maps.tilehosting.com/data/v3.json?key=ALSU4XFuQjnG2jVilgsk'
                            },

                            "source-layer": label,
                            "interactive": true,

                            "layout": {
                                "line-join": "round",
                                "line-cap": "round"
                            },
                            "paint": {
                                "line-color": "#234fff",
                                "line-width": 2
                            }
                        });

                        break;
                    default:
                        map.addLayer({
                            "id": label,
                            "type": "symbol",
                            "source": {
                                type: 'vector',
                                url: 'https://maps.tilehosting.com/data/v3.json?key=ALSU4XFuQjnG2jVilgsk'
                            },
                            "source-layer": label,
                            "interactive": true,

                            "layout": {

                                "icon-allow-overlap": true,
                                "icon-image": "{marker-symbol}-11",
                                "text-field": "{name}",
                                "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                                "text-size": 11,
                                "text-transform": "uppercase",
                                "text-letter-spacing": 0.05,
                                "text-offset": [0, 1.5]
                            },
                            "paint": {
                                "text-color": "#202",

                            },
                        });
                        break;
                }


            })
        }
        //map.invalidateSize();
    }

    getCenter() {
        return this.map.getCenter()
    }

    handleClickButton = () => {
        let anchorEl = findDOMNode(this.button)

        this.setState({
            layerMenuOpen: true,
            anchorEl
        });
    };

    handleLegendButton = () => {
        let anchorEl = findDOMNode(this.legendButton)

        this.setState({
            legendMenuOpen: true,
            anchorEl
        });
    };

    getStyle(style) {
        var name = "topo"
        switch (style) {
            case 'basic':
                name = "basic"
                break;
            case 'light':
                name = "positron"
                break;
            case 'topo':
                name = "topo"
                break;
            case 'dark':
                name = "darkmatter"
                break;
            default:
                name = "topo"
                break;

        }
        // console.log('name', name)
        //topo/style.json?key=MnSoswF3TIIirAiE6mqv
        //   return "https://api.maptiler.com/maps/be8655a2-3c8f-4243-a818-14d7ebb83cd3/style.json?key=MnSoswF3TIIirAiE6mqv"
        return "https://api.maptiler.com/maps/fef74ea1-76e5-440f-b0d9-86450b327741/style.json?key=MnSoswF3TIIirAiE6mqv"
        //return "https://maps.tilehosting.com/c/131362e6-c138-4cc4-9e12-36d857d304bf/styles/" + name + "/style.json?key=MnSoswF3TIIirAiE6mqv";

    }

    reloadMap() {
        this.setState({ popup: null })
        this.fetchMap(this.props.mapId).then((res) => {
            console.log('fetchMap in reloadMap')
            if (res && res.data) {
                var layers = res.data.layers

                const checked = layers.map(l => {
                    return l.id
                })
                var labels = null
                if (layers && layers.length > 0) {
                    layers.forEach(layer => {
                        if (layer.type == "heatmap") {
                            this.map.addSource('earthquakes', {
                                "type": "geojson",
                                "data": layer.featureCollection
                            });
                            this.map.addLayer({
                                "id": "earthquakes-heat",
                                "type": "heatmap",
                                "source": "earthquakes",
                                "maxzoom": 9,
                                "paint": {
                                    // Increase the heatmap weight based on frequency and property magnitude
                                    "heatmap-weight": [
                                        "interpolate",
                                        ["linear"],
                                        ["get", "mag"],
                                        0, 0,
                                        6, 1
                                    ],
                                    // Increase the heatmap color weight weight by zoom level
                                    // heatmap-intensity is a multiplier on top of heatmap-weight
                                    "heatmap-intensity": [
                                        "interpolate",
                                        ["linear"],
                                        ["zoom"],
                                        0, 1,
                                        9, 3
                                    ],
                                    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                                    // Begin color ramp at 0-stop with a 0-transparancy color
                                    // to create a blur-like effect.
                                    "heatmap-color": [
                                        "interpolate",
                                        ["linear"],
                                        ["heatmap-density"],
                                        0, "rgba(33,102,172,0)",
                                        0.2, "rgb(103,169,207)",
                                        0.4, "rgb(209,229,240)",
                                        0.6, "rgb(253,219,199)",
                                        0.8, "rgb(239,138,98)",
                                        1, "rgb(178,24,43)"
                                    ],
                                    // Adjust the heatmap radius by zoom level
                                    "heatmap-radius": [
                                        "interpolate",
                                        ["linear"],
                                        ["zoom"],
                                        0, 2,
                                        9, 20
                                    ],
                                    // Transition from heatmap to circle layer by zoom level
                                    "heatmap-opacity": [
                                        "interpolate",
                                        ["linear"],
                                        ["zoom"],
                                        7, 1,
                                        9, 0
                                    ],
                                }
                            });
                        }
                    })
                }


                //  f.geometry.coordinates = f.geometry.coordinates[1]
                ///layers[0].featureCollection.features = [f]

                var newState = { currentMap: res.data, mapId: this.props.mapId, layers: layers, checked: checked, /* zoomState: [res.data.zoom || 0], pitchState: [res.data.pitch || 0], bearingState: [res.data.bearing || 0], labels: res.data.labels,*/ bounds: res.data.bounds, subtype: res.data.subtype, style: res.data.style ? this.getStyle(res.data.style) : this.getStyle(this.props.style) }
                // console.log('newState#3', newState)
                this.setState(newState, () => {
                    // console.log('statezoom#3', this.state)

                })

            }

        }).catch(err => {

        })

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.riskPotentials && JSON.stringify(this.state.riskPotentials) != JSON.stringify(nextProps.riskPotentials) && nextProps.riskPotentials.length > 0) {
            this.setState({ riskPotential: nextProps.riskPotentials[0], riskPotentials: nextProps.riskPotentials })
        }

        if (nextProps.maps && JSON.stringify(nextProps.maps) != JSON.stringify(this.state.maps) && nextProps.maps.length > 0) {
            console.log('should set riskPotentials with maps')
            this.setState({ riskPotentials: nextProps.maps[0].riskPotentials, maps: nextProps.maps, currentMap: nextProps.maps[0], riskPotential: nextProps.maps[0].riskPotentials[0] })
        }

        if (this.props.layerList && this.props.layerList.length && nextProps.layerList.length > 0) // Check if it's a new user, you can also use some unique, like the ID
        {
            if (this.props.layerIds && this.props.layerIds.length) {
                this.props.layerIds.forEach((value) => {
                    //this.handleToggle({id:value})
                })
            } else {

            }
        }
        if (nextProps.style) {
            // console.log('nextProps.style', nextProps.style)
            this.setState({ style: this.getStyle(nextProps.style) })
        }
        if (nextProps.layerIds && nextProps.layerIds.length && nextProps.layerIds != this.props.layerIds) {
            this.setState({ layerIds: nextProps.layerIds })
            this.fetchLayer(nextProps.layerIds).then((res) => {
                const layers = res.data
                this.setState({ layers: res.data })
            })
        }
        if (nextProps.featureCollection && !this.state.featureCollection) {
            // this.setState({featureCollection:nextProps.featureCollection})
        }
        if (nextProps.featureCollection && !nextProps.mapId) {
            console.log('fitbounds', nextProps)
            //console.log('nextProps.featureCollection', nextProps.featureCollection.length)
            if (this.map && !nextProps.heatmap) {
                this.fitBounds(nextProps.featureCollection)
            }
            else if (this.map && nextProps.heatmap) {
                console.log('add heatmap')
                /* this.map.addSource('earthquakes', {
                     "type": "geojson",
                     "data": nextProps.featureCollection
                 });*/
                this.map.addLayer({
                    "id": "earthquakes-heat",
                    "type": "heatmap",
                    "source": "earthquakes",
                    "maxzoom": 9,
                    "paint": {
                        // Increase the heatmap weight based on frequency and property magnitude
                        "heatmap-weight": [
                            "interpolate",
                            ["linear"],
                            ["get", "mag"],
                            0, 0,
                            6, 1
                        ],
                        // Increase the heatmp color weight weight by zoom level
                        // heatmap-intensity is a multiplier on top of heatmap-weight
                        "heatmap-intensity": [
                            "interpolate",
                            ["linear"],
                            ["zoom"],
                            0, 1,
                            9, 3
                        ],
                        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                        // Begin color ramp at 0-stop with a 0-transparancy color
                        // to create a blur-like effect.
                        "heatmap-color": [
                            "interpolate",
                            ["linear"],
                            ["heatmap-density"],
                            0, "rgba(33,102,172,0)",
                            0.2, "rgb(103,169,207)",
                            0.4, "rgb(209,229,240)",
                            0.6, "rgb(253,219,199)",
                            0.8, "rgb(239,138,98)",
                            1, "rgb(178,24,43)"
                        ],
                        // Adjust the heatmap radius by zoom level
                        "heatmap-radius": [
                            "interpolate",
                            ["linear"],
                            ["zoom"],
                            0, 2,
                            9, 20
                        ],
                        // Transition from heatmap to circle layer by zoom level
                        "heatmap-opacity": [
                            "interpolate",
                            ["linear"],
                            ["zoom"],
                            7, 1,
                            9, 0
                        ],
                    }
                })
            }
        }


        if (nextProps.mapId && nextProps.mapId != this.props.mapId) {
            // console.log('should fetchMap in receive')
            //this.props.fetchMap("10")
            if (this.map && this.map.getLayer && this.map.getLayer("earthquakes-heat")) {
                this.map.removeLayer("earthquakes-heat");
            }

            if (this.map && this.map.getSource('earthquakes')) {
                this.map.removeSource('earthquakes');
            }

            this.setState({ mapId: nextProps.mapId })
            this.setState({ loading: true })
            this.fetchMap(nextProps.mapId).then((res) => {
                console.log('fetchMap in componentWillReceiveProps')
                if (res && res.data) {
                    var layers = res.data.layers

                    const checked = layers.map(l => {
                        return l.id
                    })
                    var labels = null
                    if (layers && layers.length > 0) {
                        layers.forEach(layer => {
                            if (layer.type == "heatmap") {
                                this.map.addSource('earthquakes', {
                                    "type": "geojson",
                                    "data": layer.featureCollection
                                });
                                this.map.addLayer({
                                    "id": "earthquakes-heat",
                                    "type": "heatmap",
                                    "source": "earthquakes",
                                    "maxzoom": 9,
                                    "paint": {
                                        // Increase the heatmap weight based on frequency and property magnitude
                                        "heatmap-weight": [
                                            "interpolate",
                                            ["linear"],
                                            ["get", "mag"],
                                            0, 0,
                                            6, 1
                                        ],
                                        // Increase the heatmap color weight weight by zoom level
                                        // heatmap-intensity is a multiplier on top of heatmap-weight
                                        "heatmap-intensity": [
                                            "interpolate",
                                            ["linear"],
                                            ["zoom"],
                                            0, 1,
                                            9, 3
                                        ],
                                        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                                        // Begin color ramp at 0-stop with a 0-transparancy color
                                        // to create a blur-like effect.
                                        "heatmap-color": [
                                            "interpolate",
                                            ["linear"],
                                            ["heatmap-density"],
                                            0, "rgba(33,102,172,0)",
                                            0.2, "rgb(103,169,207)",
                                            0.4, "rgb(209,229,240)",
                                            0.6, "rgb(253,219,199)",
                                            0.8, "rgb(239,138,98)",
                                            1, "rgb(178,24,43)"
                                        ],
                                        // Adjust the heatmap radius by zoom level
                                        "heatmap-radius": [
                                            "interpolate",
                                            ["linear"],
                                            ["zoom"],
                                            0, 2,
                                            9, 20
                                        ],
                                        // Transition from heatmap to circle layer by zoom level
                                        "heatmap-opacity": [
                                            "interpolate",
                                            ["linear"],
                                            ["zoom"],
                                            7, 1,
                                            9, 0
                                        ],
                                    }
                                });
                            }
                            if (layer.subtype == "pks") {
                                if (!this.props.riskPotentials) {
                                    this.setState({ riskPotential: "all" })
                                }

                            }
                        })
                    }


                    //  f.geometry.coordinates = f.geometry.coordinates[1]
                    ///layers[0].featureCollection.features = [f]

                    var newState = { currentMap: res.data, subtype: res.data.subtype, mapId: nextProps.mapId, layers: layers, checked: checked,/* zoomState: [res.data.zoom || 0], pitchState: [res.data.pitch || 0], bearingState: [res.data.bearing || 0], labels: res.data.labels,*/ bounds: res.data.bounds, style: res.data.style ? this.getStyle(res.data.style) : this.getStyle(this.props.style) }
                    // console.log('newState#3', newState)
                    this.setState(newState, () => {
                        // console.log('statezoom#3', this.state)
                        // this.setState({ loading: false })
                    })

                }

            })



        }
    }



    handleClose = () => {
        this.setState({
            layerMenuOpen: false,
            legendMenuOpen: false
        });
    };

    renderLayers() {

        const { mapStyle, layerMenuOpen, anchorEl, popup } = this.state
        const { layer, loading, layerList } = this.props

        return (
            <div>

            </div>
        )
    }

    loadChapter(dossierId) {
        console.log('dossierId', dossierId)
        this.props.fetchDossierChapters(dossierId).then(chapter => {
            // console.log('chapter', chapter)
            this.setState({ chapter: this.props.chapter })
        }).catch(err => {
            console.log('err', err)
        })
    }
    changeMap(event) {
        if (event.target.value != this.state.mapId) {
            if (this.props.onMapIdChanged) {
                this.props.onMapIdChanged(event.target.value)
            }
            this.map.on('sourcedata', this.onSourceDataEventHandler.bind(this))

            this.setState({ mapId: event.target.value, loading: true }, () => {
                this.fetchMap(event.target.value).then((res) => {
                    console.log('fetchMap in changeMap')
                    if (res && res.data) {
                        var layers = res.data.layers

                        const checked = layers.map(layer => {
                            console.log('layer.subtype', layer)
                            /*if (layer.type == "featureCollection" && !this.state.riskPotentials) {
                                this.setState({ riskPotential: 'all' })
                            }*/
                            return layer.id
                        })

                        //var f = layers[0].featureCollection.features
                        ///// console.log('layers')
                        let riskPotentials = null
                        if (this.props.maps) {
                            riskPotentials = this.props.maps.find(item => item.mapId == event.target.value).riskPotentials
                        }
                        console.log('riskPot', riskPotentials)

                        var newState = { currentMap: res.data, layers: layers, checked: checked, bounds: res.data.bounds, subtype: res.data.subtype, riskPotentials, riskPotential: riskPotentials[0] }

                        // console.log('newState', newState)
                        this.setState(newState, () => {
                            console.log('statezoom', this.state)
                        })
                    } else {
                        console.log('else')
                    }


                }).catch(err => {
                    //this.setState({loading:false})
                })
            })

        }

    }

    renderMapSelect() {
        const { lang, t, maps } = this.props
        console.log('this.props.maps', this.props.maps)
        return (
            <Paper
                style={{
                    width: '100%',
                    backgroundColor: 'rgb(249, 249, 249)',
                }}
            >
                <FormControl style={{ width: '100%', textAlign: 'center' }}>
                    <Select
                        autoWidth
                        style={{ backgroundColor: 'rgb(249, 249, 249)', marginLeft: 10, marginRight: 10, textAlign: 'center' }}
                        value={this.state.mapId || this.props.maps[0].mapId}
                        onChange={this.changeMap.bind(this)}
                    >
                        {this.props.maps.map(p => {
                            return (
                                <MenuItem key={p.mapId} value={p.mapId}>{p.title}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Paper>

        )
    }

    renderPopup() {
        const { popup } = this.state
        const { lang, t, admin } = this.props
        var coordinates = []
        var json = null
        //  console.log('renderPopup admin', admin)

        if (popup && popup.geometry) {
            //console.log('centerOfMass(popup)', centerOfMass(popup))
            //coordinates = (popup.geometry.type == 'Polygon' || popup.geometry.type == 'MultiPolygon') ? centroid(feature(popup)) : popup.geometry.coordinates
            coordinates = []
            if (popup.geometry.type == 'Polygon') {
                coordinates = centroid(polygon(popup.geometry.coordinates)).geometry.coordinates
            } else if (popup.geometry.type == 'MultiPolygon') {
                coordinates = centroid(multiPolygon(popup.geometry.coordinates)).geometry.coordinates
            } else {
                coordinates = popup.geometry.coordinates
            }
            // console.log('coordinates', coordinates)
            console.log('item', popup.properties)
            console.log('popup.properties', popup.properties)
            console.log(' typeof popup.properties.title', popup.properties.title && (typeof popup.properties.title == "string") || popup.properties.name)
            console.log('typeof md', typeof popup.properties.markdown)
            if (popup.properties.markdown && typeof popup.properties.markdown == "string" && popup.properties.markdown.includes("\"de\"")) {
                popup.properties.markdown = JSON.parse(popup.properties.markdown)
            }
        }


        // console.log('this.state.riskPotential', this.state.riskPotential)
        //console.log('coordinates', coordinates)
        return (

            popup && (
                <Popup style={{ padding: 'none' }} offset={[10, 10]} coordinates={coordinates} onClick={() => {
                    if (!admin) {
                        this.setState({ popup: null })
                    }

                }}>
                    <div style={{
                        background: 'white',
                        color: 'black',

                        //padding: '5px',
                        minWidth: '300px',
                        maxWidth: '400px',
                        minHeight: '400px'
                        //borderRadius: '2px'
                    }} >
                        <div style={{ padding: 5 }}>
                            <CloseIcon style={{ float: 'right', marginTop: 0, marginRight: 0 }} onClick={() => {

                                this.setState({ popup: null })


                            }} />
                            {(popup.properties.title && typeof popup.properties.title == "string") &&
                                <Typography variant="heading" gutterBottom>
                                    <h2 style={{ marginBlockStart: 0, marginBlockEnd: 0 }}><b>{popup.properties.title}</b></h2>
                                </Typography>
                            }
                            {(popup.properties.country && typeof popup.properties.country == "string") &&
                                <Typography variant="subheading" gutterBottom>
                                    <h2 style={{ fontSize: 12, marginBlockStart: 0, marginBlockEnd: 0, marginBottom: 7 }}>{popup.properties.country}</h2>
                                </Typography>
                            }
                            {(!popup.properties.title && typeof popup.properties.name == "string") &&
                                <Typography variant="subheading" gutterBottom>
                                    <h2 style={{ marginBlockStart: 0, marginBlockEnd: 0 }}><b>{popup.properties.name}</b></h2>
                                </Typography>
                            }

                            {popup.properties.title && typeof popup.properties.title == "object" &&
                                <Typography variant="subheading" gutterBottom>
                                    <h2 style={{ marginBlockStart: 0, marginBlockEnd: 0 }}><b>{popup.properties.title[lang]}</b></h2>
                                </Typography>
                            }
                            {(popup.properties.description && typeof popup.properties.description == "string") &&
                                <Typography variant="heading" gutterBottom>
                                    <h3 style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>{popup.properties.description}</h3>
                                </Typography>
                            }

                            {popup.properties.dossier &&
                                <div style={{ marginTop: 0 }}>
                                    <Link style={{ textAlign: 'center' }} to={"/dossiers/" + popup.properties.dossier}>{t("map.ZumDossier")}</Link>
                                </div>
                            }
                            {popup.properties.verband &&
                                <Typography variant="caption" gutterBottom>

                                    <div>
                                        <div>{popup.properties.name2}</div>

                                        <br />
                                        <div>{popup.properties.plz} {popup.properties.city}</div>
                                        <div>{popup.properties.strasse}</div>
                                        <div>{popup.properties.tel}</div>
                                        <div>{popup.properties.verband}</div>




                                    </div>





                                </Typography>
                            }
                            {popup.properties.markdown && !popup.properties.dossier && !popup.properties.markdown[lang] &&
                                <div style={{
                                    height: 300, maxHeight: 300, overflowY: 'scroll',
                                    WebkitOverflowScrolling: 'touch'
                                }}>
                                    {popup.properties.hotspotId && popup.properties.image &&
                                        <img src={`/images/hotspots/${encodeURIComponent(popup.properties.image)}`} height="300" />
                                    }
                                    <Markdown style={{ maxWidth: '400px' }} markup={popup.properties.markdown} tables={true} />
                                </div>
                            }
                            {popup.properties.markdown && !popup.properties.dossier && popup.properties.markdown[lang] &&
                                <div style={{
                                    height: 100, maxHeight: 100, overflowY: 'scroll',
                                    WebkitOverflowScrolling: 'touch'
                                }}>

                                    <Markdown style={{ maxWidth: '400px' }} markup={popup.properties.markdown[lang]} tables={true} />
                                </div>
                            }
                            {popup.properties.riskLevel && (this.state.riskPotential != "covidTL" && this.state.riskPotential != "covidRL") && this.state.riskPotential != "covidRisk" && this.state.riskPotential != "stringency_index" &&
                                <div style={{ marginTop: 5 }}>
                                    {t("dossier.Gefährdungstufe")} {popup.properties.riskLevel}
                                </div>
                            }
                            {this.state.riskPotential && this.state.riskPotential != "all" &&
                                <div>
                                    {false &&
                                        <div style={{ marginTop: 15 }}>
                                            <b>{t("dossier.Covid")}</b> {popup.properties.covid}
                                        </div>
                                    }
                                    {popup.properties.type && popup.properties.text &&
                                        <div style={{ marginTop: 20, lineHeight: "100%" }}>
                                            Lt. <a href="https://www.rki.de/DE/Content/InfAZ/N/Neuartiges_Coronavirus/Risikogebiete_neu.html/">RKI</a>: {popup.properties.type == "risk" ? t("map." + popup.properties.type) : ""} {popup.properties.text}<br /><br />
                                            Das Auswärtige Amt spricht für {popup.properties.title} eine COVID-19-bedingte {popup.properties.situationWarning ? "Reisewarnung" : (popup.properties.situationPartWarning ? "Teilreisewarnung" : "keine Reisewarnung")} aus.
                                            <br />
                                        </div>
                                    }
                                    {true && popup.properties.incidence &&
                                        <div>
                                            {popup.properties.incidence &&
                                                <div style={{ marginTop: 0, padding: 0, lineHeight: '13px' }}>
                                                    {t("map.Inzidenzwert")} {popup.properties.incidence}
                                                </div>
                                            }




                                        </div>
                                    }
                                    {popup.properties.link &&
                                        <div style={{ marginTop: 20, lineHeight: "100%" }}>
                                            Liebe Leserinnen und Leser,<br /><br />

                                            aktuelle Hinweise und Informationen zum lokalen bzw. regionalen Infektionsgeschehen sowie zu den jeweiligen Maßnahmen und Regeln finden Sie auf folgender
                                            <a href={popup.properties.link}> Webseite{popup.properties.touristInfoAddress ? `: ${popup.properties.touristInfoAddress}` : ""}</a>
                                            {popup.properties.contact &&
                                                <div>
                                                    <br />
                                                    <div>Kontakt: {popup.properties.contact.Kontakt}</div>
                                                    <div>Adresse: {popup.properties.contact.Adresse}</div>
                                                    <div>Hotline: <a href={"tel: " + popup.properties.contact.Infoline}>{popup.properties.contact.Hotline}</a></div>
                                                    <div>Infoline: <a href={"tel: " + popup.properties.contact.Infoline}>{popup.properties.contact.Infoline}</a></div>


                                                </div>

                                            }


                                        </div>}


                                    {this.state.riskPotential == "covidTL" || this.state.riskPotential == "covidRL" &&
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{
                                                height: 150, maxHeight: 150, overflowY: 'scroll',
                                                WebkitOverflowScrolling: 'touch'
                                            }}>
                                                {this.state.riskPotential == "covidTL" &&
                                                    <div style={{ marginTop: 10, padding: 0, lineHeight: '13px' }}>
                                                        <Markdown markup={popup.properties.covidTLInfo}>
                                                        </Markdown>

                                                        {popup.properties.covidTLUpdate && popup.properties.covidTLUpdate != "" &&
                                                            <div>

                                                                Update: {moment(popup.properties.covidTLUpdate).format("DD.MM.YYYY HH:mm")}
                                                            </div>}
                                                    </div>
                                                }
                                                {this.state.riskPotential == "covidRL" &&
                                                    <div style={{ marginTop: 20, padding: 0, lineHeight: '13px' }}>
                                                        <Markdown markup={popup.properties.covidRLInfo}>
                                                        </Markdown>
                                                        {popup.properties.covidRLUpdate && popup.properties.covidRLUpdate != "" &&
                                                            <div>

                                                                Update: {moment(popup.properties.covidRLUpdate).format("DD.MM.YYYY HH:mm")}
                                                            </div>}
                                                    </div>
                                                }
                                                {false && this.state.chapter &&
                                                    <div style={{ marginTop: 20, fontSize: 14 }}><b>{t("dossier.situationReport")}</b></div>
                                                }
                                                {false && this.state.chapter && this.state.chapter.type == "situationReport" && this.state.chapternews && this.state.chapter.news[lang] &&
                                                    <Markdown markup={t("dossier.AA2")}></Markdown>
                                                }
                                                {false && this.state.chapter && this.state.chapter.news && this.state.chapter.news[lang] &&
                                                    <Markdown markup={this.state.chapter.news[lang]} tables={true} />
                                                }
                                                {false &&
                                                    <Markdown markup={this.state.chapter.markdown[lang]}>
                                                    </Markdown>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {admin && popup.properties.riskLevel && popup.properties.state &&
                                <div>
                                    <RiskLevelEditor state={popup.properties} dach={this.state.subtype == "dach_country" || this.state.subtype == "dach_city"} onSave={this.reloadMap.bind(this)} />

                                </div>
                            }

                            {(admin) && popup.properties.hotspotId &&
                                <Link to={"/hotspot/edit/" + popup.properties.hotspotId}>
                                    <IconButton edge="end" aria-label="edit">
                                        <EditIcon />
                                    </IconButton>
                                </Link>
                            }

                            {popup.properties.incidentScene &&
                                <div>

                                    <b>{t("map.Ereignisort")}</b> {popup.properties.incidentScene}

                                </div>
                            }
                            {popup.properties.postName &&
                                <Link style={{ float: 'right' }} to={"/article/" + popup.properties.postName}>{t("map.ZumArtikel")}</Link>
                            }

                            <div style={{ marginTop: 10, lineHeight: "100%" }}>
                                <h6 style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>RiskCompass GmbH. Alle Rechte vorbehalten. Für die Vollständigkeit und Richtigkeit der Eintragungen wird keine Haftung übernommen.</h6>
                            </div>
                        </div>
                    </div>
                </Popup>
            )

        )
    }

    renderLegend() {
        const { mapStyle, legendMenuOpen, anchorEl, checked, layers } = this.state
        const { admin, editor, mapId, layer, loading, layerList } = this.props
        let legendDescription = this.state.currentMap && this.state.currentMap.description && this.state.currentMap.description[this.props.lang] ? this.state.currentMap.description[this.props.lang] : ''
        let legendTitle = this.state.currentMap && this.state.currentMap.title ? this.state.currentMap.title : ''

        let copyrights = layers.filter((tmpLayer) => {
            if (tmpLayer && tmpLayer.copyright && tmpLayer.copyright.length > 0) {
                return true
            }
        }).map((tmpLayer) => {
            return tmpLayer.copyright
        })

        return (
            <>
                <Button variant="contained" aria-label="Legend"
                    style={{
                        position: 'absolute',
                        backgroundColor: 'rgb(249, 249, 249)',
                        //boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
                        top: 134,
                        left: 10,
                        minWidth: 28,
                        minHeight: 28,
                        padding: 5,
                        zIndex: 10
                    }}
                    onClick={this.handleLegendButton.bind(this)}
                    ref={node => {
                        this.legendButton = node
                    }}>
                    <InfoIcon style={{ fontSize: 24 }} />
                </Button>

                <Popover
                    open={legendMenuOpen}
                    onClose={this.handleClose}
                    container={anchorEl ? anchorEl.parentNode.parentNode : null}
                    anchorEl={anchorEl}
                    anchorReference='anchorEl'
                    anchorPosition={{ top: 60, left: 150 }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transitionDuration={0}
                    PaperProps={{
                        style: {
                            padding: '0 10px 10px 10px',
                            fontSize: 13,
                        }
                    }}
                >

                    {this.state.currentMap && this.state.currentMap.title &&
                        <>
                            <h5 style={{ fontSize: '1.25rem', margin: '0.5rem 0' }}>{this.state.currentMap.title}</h5>
                            <hr />
                        </>
                    }

                    {copyrights && copyrights.length > 0 &&
                        <>
                            {copyrights.map(copyright => (
                                <div>{copyright}</div>
                            ))}
                            <hr />
                        </>
                    }

                    {legendDescription.length > 0 &&
                        <div class="legendDescription">
                            {<Markdown markup={legendDescription}></Markdown>}
                        </div>
                    }

                    {(admin || editor) && this.state.currentMap &&
                        <Link to={"/mapMetadata/edit/" + this.state.currentMap.id}>
                            <IconButton edge="end" aria-label="edit">
                                <EditIcon />
                            </IconButton>
                        </Link>
                    }



                    {(this.props.featureCollection && this.props.featureCollection.features) &&
                        <>
                            <table className="featureCollection">
                                <tbody>
                                    <tr>
                                        <td>
                                            <img src={'/images/mapicons/warning_general.png'} />
                                        </td>
                                        <td>Reisewarnung, Wetterwarnung</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={'/images/mapicons/warning_terror.png'} />
                                        </td>
                                        <td>Terrorwarnung</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={'/images/mapicons/triangle_grey_warning_pollution.png'} />
                                        </td>
                                        <td>Warnung vor Umweltverschmutzung</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={'/images/mapicons/triangle_blue_warning_health_outbreaks.png'} />
                                        </td>
                                        <td>Gesundheitswarnung</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={'/images/mapicons/caution.png'} />
                                        </td>
                                        <td >Verkehrsrisiken</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={'/images/mapicons/triangle_rape.png'} />
                                        </td>
                                        <td >Sexualdelikt, Vergewaltigung</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={'/images/mapicons/triangle_assault_police.png'} />
                                        </td>
                                        <td >Übergriffe auf Polizei und Rettungskräfte</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={'/images/mapicons/blank_black.png'} />
                                        </td>
                                        <td>Bürgerkriegsereignisse, Terrorismus, Terroranschläge, Sabotageaktionen</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={'/images/mapicons/blank_red.png'} />
                                        </td>
                                        <td>Gewaltkriminalität, Körperverletzung, Totschlag, Raub, Mord</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={'/images/mapicons/fire.png'} />
                                        </td>
                                        <td>Sachbeschädigung, Brand und Brandstiftung, Vandalismus</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={'/images/mapicons/blank_pink.png'} />
                                        </td>
                                        <td>Soziale Unruhen, Aufruhr, Demonstrationen, Streik</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={'/images/mapicons/blank_green.png'} />
                                        </td>
                                        <td>Naturgefahren, Stürme, Erdbeben, Vulkanismus, Überschwemmungen, Dürre, Extremwetter</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={'/images/mapicons/medicine.png'} />
                                        </td>
                                        <td>Gesundheitsrisiken, Seuchenausbrüche</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={'/images/mapicons/Hotspot_Pin-Kopie.png'} />
                                        </td>
                                        <td>»Meidegebiet«, sozialer Brennpunkt, »kriminalitätsbelasteter Ort« oder Naturgefahren</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={'/images/mapicons/arpartment.png'} />
                                        </td>
                                        <td>Vulnerables Sozialraumgebiet mit hohem Gefährdungspotenzial.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                    }
                </Popover>
            </>
        )
    }

    editLayer(item) {
        console.log('editLayer', item)
        this.props.goToRoute()
    }

    renderSaveBounds() {
        return (
            <Button variant="contained" aria-label="Layers" style={{
                backgroundColor: 'rgb(249, 249, 249)', opacity: 0.95, zIndex: 1000, top: 222, left: 10, minWidth: 34, minHeight: 34,
                padding: 0
            }} onClick={this.saveBounds.bind(this)}
                ref={node => {
                    // this.button = node;
                }}>
                <BoxIcon style={{ fontSize: 24 }} />
            </Button>
        )
    }


    renderLayerMenu() {

        const { mapStyle, layerMenuOpen, anchorEl, checked, layers } = this.state
        const { layer, loading, layerList, admin, editor, map, mapId } = this.props
        // console.log('map', map, mapId)
        return (
            <>
                <Button variant="contained" aria-label="Layers"
                    style={{
                        position: 'absolute',
                        backgroundColor: 'rgb(249, 249, 249)',
                        top: admin ? 178 : 10,
                        left: 10,
                        minWidth: 28,
                        minHeight: 28,
                        padding: 5,
                        zIndex: 10
                    }}
                    onClick={this.handleClickButton}
                    ref={node => {
                        this.button = node;
                    }}>
                    <LayerIcon style={{ fontSize: 24 }} />
                </Button>

                <Popover
                    open={layerMenuOpen}
                    onClose={this.handleClose}
                    container={anchorEl ? anchorEl.parentNode.parentNode : null}
                    anchorEl={anchorEl}
                    anchorReference='anchorEl'
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transitionDuration={0}
                >
                    <List style={{ width: 300 }}>
                        {layers.length > 0 && layers.map(value => (
                            <ListItem
                                key={value.id}
                                dense
                                button
                                onClick={this.handleToggle(value)}
                            >
                                <Checkbox
                                    checked={this.state.checked.indexOf(value.id) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                />
                                <ListItemText primary={value.title} />
                                {(admin || editor) &&
                                    <ListItemSecondaryAction>
                                        <Link to={"/layerEditor/" + value.id + "/map/" + mapId}>
                                            <IconButton edge="end" aria-label="edit">
                                                <EditIcon />
                                            </IconButton>
                                        </Link>
                                    </ListItemSecondaryAction>
                                }

                            </ListItem>
                        ))}

                        {(admin || editor) &&
                            <ListItem key="add" dense
                                button>
                                <ListItemText primary={"Ebene hinzufügen"} />
                                <ListItemSecondaryAction>
                                    <Link to={"/layerEditor/new/map/" + mapId}>
                                        <IconButton edge="end" aria-label="edit">
                                            <EditIcon />
                                        </IconButton>
                                    </Link>
                                </ListItemSecondaryAction>
                            </ListItem>
                        }
                    </List>
                </Popover>
            </>
        )
    }

    onZoomEnd(value) {
        if (this.props.onZoomEnd) {
            this.props.onZoomEnd(value)
        }
    }

    onPitchEnd(value) {
        if (this.props.onPitchEnd) {
            this.props.onPitchEnd(value)
        }
    }

    onRotateEnd(value) {
        if (this.props.onRotateEnd) {
            this.props.onRotateEnd(value)
        }
    }

    onMoveEnd(value) {
        if (this.props.onMoveEnd && this.map) {
            var bounds = this.map.getBounds()
            //this.props.onMoveEnd(bounds)
        }
    }

    saveBounds() {
        if (this.map && this.props.saveBounds) {
            var bounds = this.map.getBounds()
            var bbox = [[bounds._sw.lng, bounds._sw.lat], [bounds._ne.lng, bounds._ne.lat]]
            console.log('bbox', bbox)
            console.log('mapId', this.state.mapId)
            this.props.updateMap({ id: this.state.mapId, bounds: bbox }).then(res => {
                // console.log('res', res)
                this.fetchMap(this.props.mapId).then((res) => {
                    console.log('map fetched')
                    var layers = res.data.layers

                    const checked = layers.map(l => {
                        return l.id
                    })

                    var newState = { subtype: res.data.subtype, layers: layers, checked: checked, bounds: res.data.bounds }
                    this.setState(newState, () => {
                        // console.log('statezoom', this.state)
                    })

                })
            }).catch(err => {
                console.log('err', err)
            })
            // this.props.
        }
    }

    /*
 
     <Layer type="fill" paint={polygonPaint}>
                        <Feature coordinates={[falls.features[0].polygonpoints]} />
                    </Layer>
 
 
                    <Layer type="fill" paint={polygonPaint2}>
                        <Feature coordinates={[falls.features[1].polygonpoints]} />
 
                    </Layer>
 
    */

    json(data) {
        return <div><pre>{JSON.stringify(data, null, 2)}</pre></div>;
    }



    clusterClick(coordinates, pointCount, getLeaves) {
        //console.log('feature of cluster: ' , feature)
        // this.setState({
        //     popup: feature
        // });
        // feature.getLeaves();
        //console.log('coordinates', coordinates)
        //console.log('cluster coordinates: ', getLeaves());

        var features = getLeaves().map(leave => { return leave.props["data-feature"] })
        //console.log('features')
        /* this.setState({
             popup: {geometry: coordinates, features:features}
         });*/
        /*
                this.map.flyTo({
                    center: coordinates,
                    zoom:5
                });*/
    };

    markerClick(feature) {
        console.log('feature', feature)
        if (this.props.onSelected && feature) {
            this.props.onSelected(feature.properties)
        }
        if (feature && feature.properties.dossier) {
            this.loadChapter(feature.properties.dossier)

        }


        this.setState({
            popup: feature
        });
    };

    selectMarker(id) {
        // console.log('selectMarker', feature)
        if (this.props.featureCollection && this.props.featureCollection.features) {
            const feature = this.props.featureCollection.features.filter(f => {
                return f.properties.id == id
            })
            // console.log('selectMarker', feature)
            if (feature.length > 0) {
                const f = feature[0]
                this.setState({
                    popup: f
                });
                this.map.flyTo({
                    center: [f.geometry.coordinates[0], f.geometry.coordinates[1]],
                    zoom: 5,
                    speed: 1,
                    curve: 0.5,
                })
            }
        }
    }

    geoJSONLayerClick(e) {
        console.log('geoJSONLayerClick', e)
        var features = this.map.queryRenderedFeatures(e.point);
        var feature = features[0]
        if (this.props.onSelected && feature) {
            this.props.onSelected(feature.properties)
        }

        this.setState({
            popup: feature
        });

    }

    renderMarker(feature, index) {

        const { properties } = feature
        //console.log('renderMarker', feature)
        var icon = false
        if (properties.icon) {
            // icon = getIcon(properties.icon)
            icon = '/images/mapicons/' + properties.icon;
        }
        //console.log('index', index)
        //// console.log('icon', icon)



        return <Marker

            key={feature.properties.title}
            style={styles.marker}
            onClick={this.markerClick.bind(this, feature)}
            coordinates={feature.geometry.coordinates}
            data-feature={feature}
        >
            {icon &&
                <img src={icon} />
            }


        </Marker>
    }



    /*
<GeoJSONLayer
                            circleLayout={circleLayout}
                            circlePaint={circlePaint}
                            circleOnClick={this.geoJSONLayerClick.bind(this)}
 
                            data={featureCollection} />
 
    */

    /*
  <GeoJSONLayer
                            circleLayout={circleLayout}
                            circlePaint={circlePaint}
                            symbolLayout={"none"}
                            //circleOnClick={this.geoJSONLayerClick.bind(this)}
                            circleOnClick={this.geoJSONLayerClick.bind(this)}
                            data={geojson} />
 
 
 
 
 
 
    */

    handleExpandButton() {
        if (this.state.tmpStyle) {
            this.setState({ tmpStyle: null })
        } else {
            this.setState({
                tmpStyle: {
                    height: '100%',
                    width: "100%"
                }
            })
        }
        if (this.props.onExpand) {
            this.props.onExpand()
        }
    }

    resize() {
        if (this.map) {
            // console.log('triggered resize')
            this.map.resize()
        }
    }

    onTitleClick(event) {
        var { titleEl } = this.state
        titleEl = titleEl ? null : event.currentTarget
        this.setState({ titleEl })
    }

    changeRiskpotential(event) {
        console.log('riskPotential', event.target.value)
        this.setState({ riskPotential: event.target.value })
    }

    showRiskPotentialInfo() {
        console.log('showRiskPotentialInfo')

    }

    renderRiskPotentialSelect() {
        // console.log('renderRiskPotentialSelect')
        const { lang, t } = this.props
        const { titleEl, mapId } = this.state
        //let anchorEl = findDOMNode(this.button)
        let riskPotentialsTranslated = this.state.riskPotentials.map(p => {
            return { key: p, translation: t("map." + p) }
        }).sort(
            (a, b) => a.translation.localeCompare(b.translation)
        )

        return (
            <Paper
                style={{
                    marginTop: 10,
                    width: '100%',
                    backgroundColor: 'rgb(249, 249, 249)',
                }}
            >
                <FormControl style={{ width: '100%', textAlign: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Select

                            style={{ backgroundColor: 'rgb(249, 249, 249)', marginLeft: 10, marginRight: 10, textAlign: 'center', width: '100%' }}
                            value={this.state.riskPotential || this.state.riskPotentials[0]}
                            onChange={this.changeRiskpotential.bind(this)}
                        //container={anchorEl ? anchorEl.parentNode.parentNode : null}
                        >
                            {riskPotentialsTranslated.map(item => {
                                return (
                                    <MenuItem key={item.key} value={item.key}>{item.translation}</MenuItem>
                                )
                            })}
                        </Select>
                        <IconButton style={{ margin: 0, padding: 0, width: 32, height: 32 }} onClick={this.onTitleClick.bind(this)}>
                            <InfoIcon style={{ fontSize: 15, margin: 0 }} />

                        </IconButton>
                        <Popper placement={'bottom-end'} style={{ marginTop: 10, marginRight: 0, width: 310, height: 300 }} id={"titlePopper"} open={titleEl} anchorEl={titleEl}>
                            <Paper style={{ width: '100%', height: '100%', backgroundColor: 'rgb(249, 249, 249)', }}>
                                <div style={{ flexDirection: 'column', display: 'flex' }}>
                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        <div style={{ margin: 10, fontSize: 15 }}>
                                            <b>{riskPotentialsTranslated.find(item => item.key == this.state.riskPotential).translation}</b>
                                        </div>
                                        <div style={{ flex: 1, display: 'flex' }}></div>
                                        <CloseIcon style={{ float: 'right', marginTop: 5, marginRight: 5 }} onClick={this.onTitleClick.bind(this, null)} />
                                    </div>
                                    <div style={{
                                        marginTop: 0,
                                        height: '100%',
                                        width: '100%',
                                        maxHeight: 250,
                                        overflowY: 'scroll',
                                        WebkitOverflowScrolling: 'touch'
                                    }}>
                                        {this.state.riskPotential &&
                                            <div style={{ margin: 10 }}>
                                                <Markdown markup={t("riskPotentialInfo." + this.state.riskPotential)} />

                                            </div>
                                        }
                                    </div>
                                </div>
                            </Paper>
                        </Popper>
                    </div>
                </FormControl>
            </Paper>
        )
    }

    render() {

        const { imagesLoaded, loading, geojson, geojsonUrl, titleEl, mapStyle, layerMenuOpen, anchorEl, layers, drawControl, checked, style, zoomState, bounds, tmpStyle } = this.state
        const { layer, layerList, zoom, bearing, pitch, containerStyle, featureCollection, onExpand, expanded, heatmap, admin, title } = this.props

        return (
            <div style={{ position: 'relative', width: containerStyle ? containerStyle.width : '100%', }}>

                <Mapbox
                    style={tmpStyle ? { width: '100%', height: '100%' } : style}
                    onZoomEnd={this.onZoomEnd.bind(this)}
                    onMoveEnd={this.onMoveEnd.bind(this)}
                    onStyleLoad={this.onMapLoaded.bind(this)}
                    //onSourceData={this.onSourceData.bind(this)}
                    containerStyle={containerStyle}
                    onMapLoaded={this.onMapLoaded.bind(this)}
                    fitBounds={this.state.bounds}
                    zoom={zoomState || [0]}
                >

                    {this.renderLegend()}

                    {layers.length > 0 &&
                        this.renderLayerMenu()
                    }
                    {admin && layers.length == 0 &&
                        this.renderLayerMenu()
                    }
                    {admin && this.props.saveBounds &&
                        this.renderSaveBounds()
                    }

                    {imagesLoaded && featureCollection && !heatmap && featureCollection.features && featureCollection.features.length > 0 &&

                        //   <Cluster maxZoom={4} ClusterMarkerFactory={this.clusterMarker} zoomOnClick={true} zoomOnClickPadding={50}>
                        //
                        //                           {featureCollection && featureCollection.features && featureCollection.features.map((feature, index) => (
                        ///                             
                        //                          this.renderMarker(feature, index)
                        //                    ))}
                        //              </Cluster>

                        <GeoJSONLayer
                            data={featureCollection}
                            layerOptions={{ minZoom: 0 }}
                            symbolLayout={symbolLayout}
                            symbolPaint={symbolPaint}
                            //   before={"earthquakes-heat"}
                            //  id={"news"}
                            //layerOptions={{ id: "news" }}
                            fillPaint={polygonPaint2}
                            symbolOnClick={this.geoJSONLayerClick.bind(this)}
                        />

                    }
                    {imagesLoaded && featureCollection && heatmap && featureCollection.features && featureCollection.features.length > 0 &&

                        //   <Cluster maxZoom={4} ClusterMarkerFactory={this.clusterMarker} zoomOnClick={true} zoomOnClickPadding={50}>
                        //
                        //                           {featureCollection && featureCollection.features && featureCollection.features.map((feature, index) => (
                        ///                             
                        //                          this.renderMarker(feature, index)
                        //                    ))}
                        //              </Cluster>

                        <GeoJSONLayer
                            key={"heatmap"}
                            data={featureCollection}
                            symbolLayout={"none"}
                            before={"earthquakes-heat"}
                            circleLayout={circleLayout}
                            circlePaint={circlePaint}
                            circlePaint={circlePaint}
                            fillPaint={polygonPaint2}
                            circleOnClick={this.geoJSONLayerClick.bind(this)} />

                    }

                    {geojson && geojsonUrl &&

                        <GeoJSONLayer
                            data={geojson}
                            symbolLayout={"none"}
                            circleLayout={geojsonUrl.includes("riskcompass.eu") || geojsonUrl.includes("https") ? circleLayout : "none"}
                            circlePaint={circlePaint}
                            circlePaint={circlePaint}
                            fillPaint={polygonPaint2}
                            circleOnClick={this.geoJSONLayerClick.bind(this)}
                        // circleOnClick={this.onClickCircle}

                        />


                    }


                    {layers.length > 0 &&
                        layers.filter(l => {
                            return checked.indexOf(l.id) != -1
                        }).map((layer, index) => {
                            //// console.log('layer.type', layer.type)
                            if (layer.type == "riskmap" || layer.type == "country" || layer.type == "states" || (layer.type == "featureCollection" && layer.subtype != "points")) {
                                //// console.log('should render layer')
                                console.log('this.state.riskPotential', this.state.riskPotential)
                                return (
                                    <Layer id={layer.id} key={index} type="fill" paint={this.state.riskPotential ? polygonPaintCovid(this.state.riskPotential) : polygonPaint}>



                                        {layer.featureCollection && layer.featureCollection.features && layer.featureCollection.features.map((feature, index) => {
                                            //if (feature.properties.name == "Brandenburg") {
                                            //     console.log('feature', feature)
                                            // console.log('feature',feature.geometry.coordinates)
                                            return <Feature key={index} properties={feature.properties} coordinates={feature.geometry.coordinates} onClick={this.markerClick.bind(this, feature)} />

                                            // }

                                            //  return   <Feature key={index} properties={feature.properties} coordinates={feature.geometry.coordinates} onClick={this.markerClick.bind(this, feature)} />

                                        }

                                        ).filter(item => {
                                            return item != null
                                        })}

                                    </Layer>




                                )
                            } else if ((layer.type == null || layer.type == "static") && layer.featureCollection && layer.featureCollection.features) {
                                console.log('dhkfjhaldksfjlka')
                                return (
                                    /*  <Cluster key={index} ClusterMarkerFactory={this.clusterMarker} zoomOnClick={true} zoomOnClickPadding={50}>
  
                                          {layer.featureCollection && layer.featureCollection.features && layer.featureCollection.features.map((feature, index) => (
                                              this.renderMarker(feature, index)
                                          ))}
                                      </Cluster>*/

                                    <GeoJSONLayer
                                        data={layer.featureCollection}
                                        layerOptions={{ minZoom: 0, maxZoom: 20 }}
                                        symbolLayout={symbolLayout}
                                        symbolPaint={symbolPaint}


                                        fillPaint={polygonPaint2}
                                        symbolOnClick={this.geoJSONLayerClick.bind(this)}
                                    />
                                )
                            } else if (imagesLoaded && (layer.type == "featureCollection" && layer.subtype == "points")) {
                                console.log('yeahyeahyeah')
                                return (
                                    <GeoJSONLayer
                                        data={layer.featureCollection}
                                        layerOptions={{ minZoom: 0 }}
                                        symbolLayout={symbolLayout}
                                        symbolPaint={symbolPaint}
                                        //   before={"earthquakes-heat"}
                                        //  id={"news"}
                                        //layerOptions={{ id: "news" }}
                                        fillPaint={polygonPaint2}
                                        symbolOnClick={this.geoJSONLayerClick.bind(this)}
                                    />

                                )
                            }





                        }



                        )
                    }
                    {this.renderPopup()}



                    < ZoomControl
                        position="top-left"
                        style={{
                            position: 'absolute',
                            top: 55,
                            border: 'none'
                        }}
                    />
                    {<RotationControl position="bottom-right" style={{
                        position: 'absolute',
                        bottom: 40,
                        border: 'none'
                    }} />}
                    {drawControl &&
                        <DrawControl
                            ref={(drawControl) => { this.drawControl = drawControl; }}
                        />
                    }
                    {/* {title &&
                        <div style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            padding: 0,
                        }}>
                            <Button
                                style={{
                                    backgroundColor: 'rgb(249, 249, 249)',
                                }}
                                variant="contained" aria-label="Info"
                                onClick={this.onTitleClick.bind(this)}
                            >
                                {title}
                            </Button>
                            <Popper placement={'bottom-end'} style={{ marginTop: 10, marginRight: 0 }} id={"titlePopper"} open={titleEl} anchorEl={titleEl}>
                                <CloseIcon style={{ float: 'right', marginTop: 5, marginRight: 5 }} onClick={this.onTitleClick.bind(this, null)} />
                                {this.props.titleEl}
                            </Popper>
                            {this.props.maps &&
                                this.renderMapSelect()
                            }
                            {this.state.riskPotentials &&
                                this.renderRiskPotentialSelect()
                            }
                        </div>
                    } */}

                    {false && title &&
                        <div style={{
                            position: 'absolute',
                            top: 10,
                            left: '50%',
                            padding: 7,
                            width: 'auto',
                            textAlign: 'center',
                            fontSize: 20,
                            color: '#fff',
                            transform: 'translate(-50%, 0)',
                            cursor: 'none',
                            pointerEvents: 'none',
                            background: 'rgba(0, 46, 128, 0.54)',
                            borderRadius: 3,
                            fontSize: '1rem'
                        }}>
                            {this.state.currentMap && this.state.currentMap.title ? this.state.currentMap.title : ''}
                        </div>
                    }

                    <div style={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        padding: 0,
                    }}>
                        {this.props.maps &&
                            this.renderMapSelect()
                        }
                        {this.state.riskPotentials &&
                            this.renderRiskPotentialSelect()
                        }
                    </div>
                </Mapbox>
                {loading &&
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        width: containerStyle && containerStyle.width ? containerStyle.width : '100%',
                        height: containerStyle && containerStyle.height ? containerStyle.height : '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.65)',
                        textAlign: 'center',
                        cursor: 'progress',
                        zIndex: 100,
                    }}>
                        <CircularProgress style={{
                            margin: 0,
                            position: 'absolute',
                            top: 'calc(50% - 20px)',
                            left: 'calc(50% - 20px)',
                        }} />
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    /* let filtered = _.isEmpty(state.posts.postsGridData.gridFilters) ? [] : state.posts.postsGridData.gridFilters;
     let sorted = _.isEmpty(state.posts.postsGridData.gridSortCriteria) ? [] : state.posts.postsGridData.gridSortCriteria;
 */

    return {
        layerList: state.layer.layerList,
        layers: state.layer.layers,
        map: state.maps.map,
        chapter: state.dossiers.chapter,
        lang: state.lang.lang,
        admin: state.auth.admin,
        editor: state.auth.editor,

    }
}

export default withNamespaces()(connect(mapStateToProps, {
    fetchLayer,
    // fetchLayers,
    // fetchLayerList,
    fetchMap,
    updateMap,
    fetchDossierChapters
})(Map));