import React from 'react';
import ReactDOM from 'react-dom';
import { Card, CardActions, CardContent, List, ListItem, ListItemText, Tooltip } from '@material-ui/core';


//import ReactGA from 'react-ga';
const gaProperty = 'UA-130702159-1'
export default class OptOut extends React.Component {
    constructor(props) {
        super(props);
    }
    UNSAFE_componentWillMount() {

        //var disableStr = 'ga-disable-' + gaProperty;
       // document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
       // window[disableStr] = true;
        //alert('');
    }



    render() {
        // console.log('this.props', this.props)
        return <Card><CardContent><h1 style={{textAlign:'center'}}>Das Google Analytics Tracking ist jetzt deaktiviert</h1></CardContent></Card>
    }
}