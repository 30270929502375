import React, { Component } from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
    renderField, renderLocalizedField, renderFieldEditor, renderHiddenField, renderMapSelector, renderLocalizedEditor
} from "../../../components/field_formatters";
import PropTypes from 'prop-types';
import { success } from "react-notification-system-redux";
import { CircularProgress, FormControlLabel, FormGroup, Button, IconButton, Menu, MenuItem, Grid, Paper, Card, CardContent, Checkbox, Ac } from "@material-ui/core";
import validate from './validation';
import { notificationDuration, newSectionDossierOptions as options } from '../../../config';
import AddIcon from '@material-ui/icons/Add';
import { addNewDossierSection, updateCountry, updateCity, translateDossier } from "../actions";
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next'
import { withRouter } from 'react-router-dom';



const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 1 * 3,
        backgroundColor: theme.palette.background.paper,
    },
});



const renderGeneralInfo = ({ fields, fieldIdent, meta: { error } }) => {
    // console.log('general Info fields', fields.getAll())
    var fieldData = fields.getAll()
    return (
        <div>
            {fields.map((fieldName, index) => {
                // console.log('fieldIden', fieldName)
                //const ident = `${fieldName}.${Object.keys(fieldName)[0]}`
                const label = Object.keys(fieldData[index])[0] == "order" ? Object.keys(fieldData[index])[1] : Object.keys(fieldData[index])[0]
                //console.log('ident', ident)
                return (<Field
                    name={`${fieldName}.${label}`}
                    type="number"
                    component={renderField}
                    label={label}
                />)
            }
            )}

        </div>
    )
}



class DossierEditForm extends Component {

    state = {
        submittedEditDossier: false,
        open: false,
        anchorEl: null,
        lang: this.props.lang

    }


    dispatchNotification(fn, timeout) {
        setTimeout(() => {
            this.context.store.dispatch(fn(notificationOpts));
        }, timeout);
    }

    handleFormSubmit(formProps) {
        const { dossier } = this.props
       // console.log('formProps', JSON.stringify(formProps.summary.de))
        const body = {
            title: formProps.title,
            polity: formProps.polity,
            generalInfo: formProps.generalInfo,
            infoLink: formProps.infoLink,
            riskPotentials: formProps.riskPotentials,
            riskLevel: formProps.riskLevel,
            id: formProps.country,
            summaryNotes: formProps.summaryNotes,
            summary: formProps.summary

        }
        if (dossier.type == "country") {
            this.props.updateCountry(body).then(function () {
                this.setState({ submittedEditDossier: true })
                this.props.history.goBack()
                //this.dispatchNotification(success, 200);
            }.bind(this));
        } else if (dossier.city) {
            this.props.updateCity(body).then(function () {
                this.setState({ submittedEditDossier: true })
                this.props.history.goBack()
                // this.dispatchNotification(success, 200);
            }.bind(this));
        }


    }

    handleClick = event => {
        this.setState({
            open: true,
            anchorEl: event.currentTarget
        });
    };

    handleMenuItemClick = (event, index) => {
        this.setState({ open: false });
        switch (index) {
            case 0:
                this.props.addNewDossierSection();
        }
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };


    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    translate() {
        this.setState({ translating: true })
        var options = {
            langs: []
        }
        if (this.state.en) {
            options.langs.push("en")
        }
        if (this.state.fr) {
            options.langs.push("fr")
        }
        this.props.translateDossier(this.props.dossier.id, options).then(() => {
            this.setState({ translating: false })
        })
    }


    render() {

        const { error, handleSubmit, dossier, t } = this.props;
        const { classes } = this.props;
        const { value, lang, translating } = this.state;
        // console.log('this.props', this.props)


        console.log('dossier', dossier)

        return (

            <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                <Field name="id" type="hidden" component={renderHiddenField} />

                <div>

                    <Grid style={{ marginTop: '0px', padding: 15, paddingTop: 0 }} container >

                        <Grid item lg={6} md={6} sm={6} >
                            <Card><CardContent>
                                {dossier &&
                                    <div>
                                        <Field name={'title'} type="text" component={renderLocalizedField} label="Titel" />

                                        <Field name={'polity'} type="text" component={renderLocalizedField} label="Staatsform" />
                                        <Field name={'riskLevel'} type="number" component={renderField} label="Risklevel" />
                                    </div>
                                }

                            </CardContent></Card>

                            {dossier && dossier.generalInfo &&
                                <Card style={{ marginTop: 10 }}><CardContent>
                                    <Field name={'generalInfo.capital'} type="text" component={renderLocalizedField} label={t("dossier.capital")} />
                                    <Field name={'generalInfo.area'} type="text" component={renderLocalizedField} label={t("dossier.area")} />
                                    <Field name={'generalInfo.population'} type="text" component={renderLocalizedField} label={t("dossier.population")} />
                                    <Field name={'generalInfo.currency'} type="text" component={renderLocalizedField} label={t("dossier.currency")} />
                                    <Field name={'generalInfo.languages'} type="text" component={renderLocalizedField} label={t("dossier.languages")} />
                                    <Field name={'generalInfo.entryRequirements'} type="text" component={renderLocalizedField} label={t("dossier.entryRequirements")} />
                                    <Field name={"infoLink"} type="text" component={renderLocalizedField} label={"Link Einreisebestimmungen"} />

                                </CardContent></Card>
                            }

                            {dossier && dossier.summary &&
                                <Card >
                                    <CardContent>
                                        <Field name={'summary'} type="text" component={renderLocalizedEditor} label="Zusammenfassung" />
                                    </CardContent>
                                </Card>
                            }

                            <Card style={{ marginTop: 10 }}>
                                <CardContent>
                                    Alle Kapitel neu übersetzen
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.fr}
                                                    onChange={this.handleChange('fr')}
                                                    value="fr"
                                                />
                                            }
                                            label="Französisch"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.en}
                                                    onChange={this.handleChange('en')}
                                                    value="en"
                                                />
                                            }
                                            label="Englisch"
                                        />
                                        {translating &&
                                            <CircularProgress />
                                        }
                                        <Button variant="contained" color="primary" onClick={this.translate.bind(this)}>Übersetzen</Button>{' '}

                                    </FormGroup>



                                </CardContent>
                            </Card>

                        </Grid>
                        <Grid style={{ paddingLeft: 0 }} item lg={6} md={6} sm={6} >



                            {dossier && dossier.riskPotentials &&
                                <Card ><CardContent>

                                    {["travelSafety", "geopolitics", "terrorism", "kidnapping", "anthropogenicThreats", "medicalRisks", "G35", "covid", "naturalHazards", "trafficRisks", "economicSecurity", "businessClimate", "corruption"]
                                        .map((fieldName, index) => {
                                            return (
                                                <Field
                                                    key={fieldName}
                                                    name={'riskPotentials.' + fieldName}
                                                    type="text"
                                                    component={renderField}
                                                    label={t('riskPotentials.' + fieldName)}
                                                />)
                                        }
                                        )}


                                </CardContent></Card>
                            }



                            {dossier && dossier.summaryNotes &&
                                <Card >
                                    <CardContent>
                                        <Field name={'summaryNotes'} type="text" component={renderLocalizedEditor} label="Zusammenfassung" />
                                    </CardContent>
                                </Card>
                            }


                            <div>
                                <Button type="submit" variant="contained" color="primary">Speichern</Button>{' '}
                                <Button variant="contained" onClick={this.props.history.goBack}>Abbrechen</Button>
                            </div>
                        </Grid>



                    </Grid>






                </div>



                {error && <strong> {error} </strong>}


            </form>
        )
    }
}

DossierEditForm.propTypes = {
    //classes: PropTypes.object.isRequired,
};

const notificationOpts = {
    title: 'Dossier',
    message: 'Successfully Updated.',
    position: 'tr',
    autoDismiss: notificationDuration
}

const form = reduxForm({
    form: 'DossierEditForm',
    enableReinitialize: true,
    validate
});

DossierEditForm.contextTypes = {
    store: PropTypes.object
};

function mapStateToProps(state) {
    return {
        initialValues: state.dossiers.dossier,
        dossier: state.dossiers.dossier,
        lang: state.lang.lang,
        //dossierChapter: state.dossiers.dossierChapter,
        //dossiersections: state.dossiers.dossiersections
    }
}

export default withNamespaces()(connect(mapStateToProps, { updateCountry, updateCity, translateDossier })(form(withRouter(DossierEditForm))))