import React from 'react';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import phoneImg from '../startpage/images/RC-Android-App.jpg';
import playstoreImg from '../startpage/images/google-play-badge.png';
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

class RiskPotentials extends React.Component {
    renderTitle(p) {
        const { t } = this.props
        var title = ""
        return t("riskPotentials."+p)
    }

    renderValue(v) {

        var color = ""
        var fontColor = ""
        switch (parseInt(v)) {
            case 0:
                color = '#00ffff'
                fontColor = 'black'
                break;
            case 1:
                color = '#00ff00'
                fontColor = 'black'
                break;
            case 2:
                color = '#ffff00'
                fontColor = 'black'
                break;
            case 3:
                color = '#ffa500'
                fontColor = 'white'
                break;
            case 4:
                color = '#ff6600'
                fontColor = 'white'
                break;
            case 5:
                color = '#ff0000'
                fontColor = 'white'
                break;
        }
        var style = {
            margin: 10,
            fontSize: '1rem',
            background: color,
            fontColor: fontColor,
            width: 20,
            height: 20,
            marginLeft:40
            //backgroundColor: color,
        }
        return (
            <Avatar style={style}>
                <p style={{ color: fontColor,  }}>{v}</p>
            </Avatar>
        )

    }

    render() {
      
        const {riskPotentials , t } = this.props
        return (
            <div>
                {riskPotentials &&


                    <table >
                        <thead >
                            <tr >
                                <th align="left"><b>{t("riskPotentials.Gefährdungspotenziale (Kategorien)")}</b></th>
                                <th align="left"><b>{t("riskPotentials.Gefährdungsstufe")}</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ["travelSafety","geopolitics","terrorism","kidnapping", "anthropogenicThreats","medicalRisks","G35","naturalHazards","trafficRisks","economicSecurity","businessClimate","corruption"] 
                                .map((pot, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><div style={{marginLeft:10}}>{this.renderTitle(pot)}</div></td>
                                            <td>{this.renderValue(riskPotentials[pot])}</td>
                                        </tr>
                                    )
                                })
                                
                                    }
                                  
                        </tbody>
                    </table>

                    

                            }
                             <div>
                     <h6>{t("riskPotentials.Anthropogene Bedrohungen")}<br/>
                     {t("riskPotentials.economicSecurityInfo")}<br/>
                     {t("riskPotentials.businessClimateInfo")}</h6>
                    <Link to={"/page/ereignislegende/"} ><b>{t("riskPotentials.Erläuterung der Gefährdungsstufen")}</b></Link>
                    </div>
            </div>
                        )
    }
}

export default withNamespaces()(RiskPotentials)


