import React from 'react';
import ReactDOM from 'react-dom';

import { Markdown } from 'react-showdown';
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Card, CardActions, CardContent, List, ListItem, ListItemText, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { ROOT_URL } from "../../config";
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import { Link } from 'react-router-dom';
import Map from '../map/map_index'
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { fetchArticlesByTagTitles } from '../articles/actions'
//import ReactGA from 'react-ga';
import ArticleList from '../articleList'
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next'
import { fetchDossierChapters } from '../dossier/actions'

const gaProperty = 'UA-130702159-1'
String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
};

class Covid extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: 0, currentTag: "", tab: 1, showRules: true };

    }
    UNSAFE_componentWillMount() {
        //ReactGA.initialize(gaProperty);
        //ReactGA.pageview(window.location.pathname + window.location.search)


    }
    componentDidMount() {
        const tags = [
            //nextProps.dossier.title.trim().toLowerCase(),
            "nachricht",
            "infektionskrankheiten"
        ]
        //// console.log('fetchArticles ')
        this.props.fetchArticlesByTagTitles(tags, { operator: 'and', featureCollection: true, limit: 150 })

    }


    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate() {

    }

    deUmlaut(value) {
        value = value.toLowerCase();
        value = value.replaceAll(/ä/g, 'ae');
        value = value.replaceAll(/ö/g, 'oe');
        value = value.replaceAll(/ü/g, 'ue');
        value = value.replaceAll(/ß/g, 'ss');
        value = value.replaceAll(/ /g, '-');
        value = value.replaceAll(/\./g, '');
        value = value.replaceAll(/,/g, '');
        value = value.replaceAll(/\(/g, '');
        value = value.replaceAll(/\)/g, '');
        return value;
    }


   
    onSelected(item) {
        console.log('onSelected item', item.tag)
        var tag = item.tag
        if (!tag) {
           if (item && item.contact && item.contact.Name) {
            tag = this.deUmlaut(item.contact.Name.trim()).replaceAll(" ", "-").toLowerCase()
           }
            
        }
        const tags = [
           tag,
            //nextProps.dossier.title.trim().toLowerCase(),
            "nachricht",
            "infektionskrankheiten"
        ]
        this.setState({ currentTag: item.tag, covidInfo: item.covidInfo })
        if (item.covidInfo) {
            item.covidInfo.title = item.title
        }
        // this.setState({ covidInfo: item.covidInfo })
        //// console.log('fetchArticles ')
        this.props.fetchArticlesByTagTitles(tags, { operator: 'and', featureCollection: true, limit: 150 })
        //this.props.fetchCountryByIso("")
    }
    capitalize(s) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    makeLinksExternal() {
        //console.log('makeLinksExternal')
        var links = document.links;
        for (var i = 0, linksLength = links.length; i < linksLength; i++) {
            if (links[i].hostname != window.location.hostname) {
                links[i].target = "_blank";
                links[i].setAttribute("rel", "noopener noreferrer");
                links[i].className += " externalLink";
            } else {
                links[i].className += " localLink";
            }
        }
    }
    handleTabChange(event, value2) {
        const { value } = this.state
        console.log('tabchange', value2)
        this.setState({ tab: value2 })
        /*
        if (value == 0 && value2 == "") {
            this.setState({ currentTag: "" })
            const tags = [
                //nextProps.dossier.title.trim().toLowerCase(),
                "nachricht",
                "infektionskrankheiten"
            ]
            //// console.log('fetchArticles ')
            this.props.fetchArticlesByTagTitles(tags, { operator: 'and', featureCollection: true, limit: 150 })
            
            
        }
        else if (value == 1 && value2 == "") {
            this.setState({ currentTag: "" })
            const tags = [
                //nextProps.dossier.title.trim().toLowerCase(),
                "deutschland",
                "oesterreich",
                "schweiz",
                
            ]
            //// console.log('fetchArticles ')
            this.props.fetchArticlesByTagTitles(tags, {
                operator: 'or',
                operator2: "and",
                tags2: ["infektionskrankheiten", "nachricht"],
                featureCollection: true,
                limit: 150
            })
            
        }
        */
    }

    handleModeChange(event, value) {
        console.log('tabchange', value)
        this.setState({ value })
        if (value == 1) {
            this.setState({ currentTag: "" })
            const tags = [
                //nextProps.dossier.title.trim().toLowerCase(),
                "deutschland",
                "oesterreich",
                "schweiz",

            ]
            //// console.log('fetchArticles ')
            this.props.fetchArticlesByTagTitles(tags, {
                operator: 'or',
                operator2: "and",
                tags2: ["infektionskrankheiten", "nachricht"],
                featureCollection: true,
                limit: 150
            })
        }
    }
    snippet(str, num) {
        if (str.length > num) {
            return str.slice(0, num) + "...";
        } else {
            return str;
        }
    }

    renderECML() {
        const { covidInfo } = this.state
        const { t, lang } = this.props
        console.log('ecml', covidInfo.ecml)
        return (
            <TableContainer >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>

                            <TableCell align="right">1</TableCell>
                            <TableCell align="right">2</TableCell>
                            <TableCell align="right">3</TableCell>
                            <TableCell align="right">Start</TableCell>
                            <TableCell align="right">End</TableCell>
                            <TableCell align="right">Notes</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {covidInfo.ecml.map((row) => (
                            <TableRow key={row.name}>

                                <TableCell align="right">{row.Response_meas_LEV_1}</TableCell>
                                <TableCell align="right">{row.Response_meas_LEV_2}</TableCell>
                                <TableCell align="right">{row.Response_meas_LEV_3}</TableCell>
                                <TableCell align="right">{row.Date_start}</TableCell>
                                <TableCell align="right">{row.Date_end}</TableCell>

                                <TableCell align="right">{this.snippet(row.Notes, 30)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    renderCovidInfo() {
        const { covidInfo } = this.state
        const { t, lang } = this.props
        console.log('covidInfo', covidInfo)

        return (
            <div>
                {covidInfo &&

                    <div>
                        <div style={{ marginLeft: 15 }}> <h2><b>{covidInfo && covidInfo.title && typeof covidInfo.title == 'string' ? covidInfo.title : ""}</b></h2></div>
                        <Table size="small" className="uniqueName">

                            <TableHead style={{ background: '#F48942', }}>
                                <TableRow>
                                    <TableCell><b>{t("covid.CoronaRegel")}</b></TableCell>
                                    <TableCell align="right"><b>{t("covid.Detail")}</b></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody style={{
                                width: '100%', overflowY: 'scroll', WebkitOverflowScrolling: 'touch', height: 'calc(100vh - 172px)'
                            }}>
                                {[
                                    "C8_International travel controls",
                                    "C7_Restrictions on internal movement",
                                    "C5_Close public transport",
                                    "H2_Testing policy",
                                    "H6_Facial Coverings",
                                    "H7_Vaccination policy",
                                    "H3_Contact tracing",
                                    "C2_Workplace closing",
                                    "C4_Restrictions on gatherings",
                                    "C3_Cancel public events",
                                    "C6_Stay at home requirements"].map((row, index) => (
                                        <TableRow key={row} style={{ background: index % 2 == 1 ? '#eaeaea' : 'white' }}>
                                            <TableCell component="th" scope="row">
                                                {t("covid." + row)}
                                            </TableCell>
                                            <TableCell align="right"> {covidInfo.oxford && covidInfo.oxford[row] ? t(`${row}.${parseInt(covidInfo.oxford[row])}`): ""}</TableCell>

                                        </TableRow>
                                    ))}
                            </TableBody>

                        </Table>
                    </div>
                }
                <div style={{ margin: 10 }}>
                    <h6>Quelle: Thomas Hale , Noam Angrist , Rafael Goldszmidt, Beatriz Kira, Anna Petherick , Toby Phillips, Samuel Webster, Emily Cameron-Blake , Laura Hallas, Saptarshi Majumdar, and Helen Tatlow. (2021). <a href="https://doi.org/10.1038/s41562-021-01079-8">“A global panel database of pandemic policies (Oxford COVID-19 Government Response Tracker).” Nature Human Behaviour. </a></h6>
                </div>
                <div style={{ minHeight: 5 }}></div>
                {covidInfo.ecml &&

                    <div>
                        {this.renderECML()}

                    </div>
                }
            </div>
        )
    }
    /*
    <TableBody>
    {rows.map((row) => (
        <TableRow key={row.name}>
        <TableCell component="th" scope="row">
        {row.name}
        </TableCell>
        <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell>
        </TableRow>
        ))}
        </TableBody>
        */

    onMapIdChanged(mapId) {
        if (mapId == "76b2231b-578b-499f-bfd8-658b79c5cf36") {
            this.setState({ showRules: false, tab:1 })
        } else {
            this.setState({ showRules: true })
        }
    }

    render() {
        const { pageName, currentTag, value, covidInfo, tab, showRules } = this.state
        const { checklists, admin, editor, articles, t, lang } = this.props
        // console.log('checklists', checklists)
        // console.log('pageName', pageName)
        //const contentSize = this.props.match.params.postName ? 12 : 9
        console.log('currentTab', tab)
        return <div>

            <Grid style={{ maxWidth: '100%', padding: '12px', marginTop: '0px', marginLeft: '0px' }} container >

                <Grid item lg={8} sm={12} xs={12} md={8} >

                    <Map containerStyle={{
                        height: '88vh',
                        width: '100%'
                    }}

                        onMapIdChanged={this.onMapIdChanged.bind(this)}
                        title={value == 0 ? "COVID-19 »Infectious disease RiskMap«" : "COVID-19 Risk-Monitor DACH-Region"}
                        mapId={value == 0 ? "f18927f8-1f05-47f5-8857-b36fb7caa715" : "76b2231b-578b-499f-bfd8-658b79c5cf36"}
                        maps={[{
                            mapId: "f18927f8-1f05-47f5-8857-b36fb7caa715",
                            title: "COVID-19 »Infectious disease RiskMap«",
                            riskPotentials: ["stringency_index", "covidRL"],
                            riskPotentialInfo: ["stringency_index", "covidRL"]


                        },
                        {
                            mapId: "76b2231b-578b-499f-bfd8-658b79c5cf36",
                            title: "COVID-19 Risk-Monitor DACH-Region",
                            riskPotentials: ["covidRisk"],
                            riskPotentialInfo: ["covidRisk"]

                        }]}
                        onSelected={this.onSelected.bind(this)} />
                </Grid>

                {articles &&
                    <Grid className={"no-print"} item lg={4} sm={12} md={4} xs={12} style={{ paddingLeft: 15 }} >
                        <Paper >
                            <Tabs
                                variant="fullWidth"
                                centered
                                value={tab} onChange={this.handleTabChange.bind(this)} aria-label="simple tabs example">
                                {showRules &&
                                    <Tab label={"Corona-Regeln"} value={0} />
                                }
                                <Tab label={"Nachrichten"} value={1} />
                                {showRules &&
                                    <Tab label={"Aktuelles"} value={2} />
                                }


                            </Tabs>
                            {tab == 0 && covidInfo &&
                                this.renderCovidInfo()
                            }
                            {tab == 1 &&
                            <div  style={{ maxHeight: '84vh',
                            overflowY: 'scroll',
                            WebkitOverflowScrolling: 'touch'}}>
                                <ArticleList
                               
                                // checklistsVisible={false}
                                //onRef={ref => {//// console.log('ref'); this.articleList = ref }}
                                //onClick={this.onClick.bind(this)}
                                //onTagClick={this.onTagClick.bind(this)}

                                /></div>
                            }
                            {tab == 2 &&
                                <div style={{
                                    margin: 20, 
                                    maxHeight: '82vh',
                                    overflowY: 'scroll',
                                    WebkitOverflowScrolling: 'touch'
                                }}>
                                    <div style={{ marginLeft: 0 }}> <h2><b>{covidInfo && covidInfo.title && typeof covidInfo.title == 'string' ? covidInfo.title : ""}</b></h2></div>

                                    <div style={{ marginTop: 20, fontSize: 14 }}><h2>{t("dossier.situationReport")}</h2></div>

                                    {this.props.chapter && this.props.chapter.type == "situationReport" && this.props.chapternews && this.props.chapter.news[lang] &&
                                        <Markdown markup={t("dossier.AA2")}></Markdown>
                                    }
                                    {this.props.chapter && this.props.chapter.news && this.props.chapter.news[lang] &&
                                        <Markdown markup={this.props.chapter.news[lang]} tables={true} />
                                    }
                                    {this.props.chapter && this.props.chapter.markdown &&
                                        <Markdown markup={this.props.chapter.markdown[lang]}>

                                        </Markdown>
                                    }
                                </div>
                            }

                        </Paper>
                    </Grid>
                }


            </Grid>
        </div>

    }

}

function mapStateToProps(state) {


    return {
        admin: state.auth.admin,
        editor: state.auth.editor,
        articles: state.articles.all,
        chapter: state.dossiers.chapter,
        lang: state.lang.lang
    }
}
const styles = {

}
export default withNamespaces()(withStyles(styles, { name: 'Page' })(connect(mapStateToProps, {
    fetchArticlesByTagTitles,
    fetchDossierChapters
})(Covid)))


