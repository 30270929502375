import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import TagFacesIcon from '@material-ui/icons/TagFaces';
import {
  fetchArticlesByTagTitles, fetchArticle
} from "../articleList/actions";

class TagList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: this.props.tags
    };

  }
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined)
    }
  }


  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.tagTitles && nextProps.tagTitles != this.props.tagTitles) {
      this.setState({ tagTitles: nextProps.tagTitles })
      //this.props.fetchArticlesByTagTitles(nextProps.tagTitles)
    }
  }

  componentDidUpdate() {

  }




  handleDelete(data){
   // console.log("handleDelete", data)

    if (this.props.onTagDelete) {
      this.props.onTagDelete(data)
    }
  };

  render() {
    const { tagTitles } = this.state;

    return (
      <Paper style={{ minHeight: '80px' }}>
        <div style={{ paddingTop: '25px', marginLeft: '20px' }}>
          {tagTitles && tagTitles.map(data => {
            let avatar = null;

            return (
              <Chip
                key={data}
                primary
                className="chip"
                style={{ margin: '3px' }}
                avatar={avatar}
                label={data}
                onDelete={this.handleDelete.bind(this, data)}

              />
            );
          })}
        </div>
      </Paper>
    );
  }
}

function mapStateToProps(state) {

  //console.log('state.articles', state.articles)
  return {
    articles: state.articles.all,
    article: state.articles.article
  }
}
const styles = {

}
export default withStyles(styles, { name: 'TagList' })(connect(mapStateToProps, {
  fetchArticlesByTagTitles: fetchArticlesByTagTitles,
  fetchArticle: fetchArticle
})(TagList));