import React from 'react';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography';
import phoneImg from '../startpage/images/RC-Android-App.jpg';
import playstoreImg from '../startpage/images/google-play-badge.png';


export default class AppInfo extends React.Component {
   
    render() {
        return (
            <Paper style={{padding:20}} className={'appAd'}>
                <a href="https://www.riskcompass.info/mobile-app/">
                <Typography variant="title" gutterBottom>
                    RiskCompass Reisesicherheits-App
                </Typography>
                <Typography variant="subheading" gutterBottom>
                    »weltweit-sicher.reisen«
                </Typography>


                <img src={phoneImg} alt="RiskCompass App" />
                <br />
                <br />
            </a>
            <a href="https://play.google.com/store/apps/details?id=info.riskcompass.android">
                <img className="googlePlay" src={playstoreImg} alt="Google Play Store" />
            </a>
            </Paper>
        )
    }
}



