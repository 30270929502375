
export default function (state = {}, action) {
    switch (action.type) {

        case 'FETCH_USER':
            return { ...state, user: action.payload.data }
        case 'FETCH_USER_INFO':
            return { ...state, userInfo: action.payload.data }
        case 'RESET_USER':
            return { ...state, user: null }
        case 'UPDATE_USER':
            return { ...state, user: action.payload.data }
        case 'FETCH_USERS':
            return { ...state, users: action.payload.data }
        case 'CHANGE_USER_PASSWORD':
            return { ...state, pwChanged: action.payload.data }
        case 'RESET_USER_PASSWORD':
            return { ...state, pwChanged: action.payload.data }

    }

    return state;
}