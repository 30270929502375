import { push } from 'react-router-redux';
import axios from 'axios';
import { LOGIN_URL } from "../../config";
import { AUTH_ERROR, AUTHENTICATED_USER, AUTHENTICATED_STARTER,STARTER_USER,UNAUTHENTICATED_USER,EDITOR_USER, ADMIN_USER, SUBSCRIBER_USER, COUNTRY_SUBSCRIBER_USER, DACH_SUBSCRIBER_USER,MANAGER_USER } from "../../actions/types";
import * as _ from "lodash";

export function signInUser({ username, password }) {
    return function (dispatch) {
        return axios.post(`${LOGIN_URL}`, { email: username, password: password })
            .then(response => {
                console.log('response', response.data)
                switch (response.data.role.name) {
                    case 'admin':
                        dispatch({ type: ADMIN_USER });
                        break;
                    case 'editor':
                        dispatch({ type: EDITOR_USER });
                        break;
                    case 'manager':
                        dispatch({ type: MANAGER_USER,customer:response.data.customer });
                        localStorage.setItem('customer', response.data.customer);
                        localStorage.setItem('role', response.data.role.name);
                        
                        break;
                    case 'subscriber':
                        dispatch({ type: SUBSCRIBER_USER });
                        break;
                    case 'dachSubcriber':
                        dispatch({ type: DACH_SUBSCRIBER_USER });
                        break;
                    case 'starter':
                        dispatch({ type: STARTER_USER });
                        break;
                    case 'countrySubscriber':
                        dispatch({ type: COUNTRY_SUBSCRIBER_USER });
                        break;
                }
                //dispatch({ type: AUTHENTICATED_USER });
                localStorage.setItem('token', response.data.id);
                //localStorage.setItem('token', response.data.id);
                dispatch(push('/'));
            })
            .catch(() => {
                dispatch(authError('Bad Login Info'));
            });
    }
}

export function signOut() {
    return function (dispatch) {

        _.forIn(localStorage, (value, objKey) => {
            if (true === _.startsWith(objKey, 'reduxPersist')) {
                localStorage.removeItem(objKey);
            }
        });

        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('customer');

        dispatch({
            type: UNAUTHENTICATED_USER,
        });

        dispatch(push('/login'));
    }
}

export function authError(error) {
    return {
        type: AUTH_ERROR,
        payload: error
    }
}