import axios from 'axios';
import { push } from 'react-router-redux'
import {
    SEND_WHITEPAPER_CONTACT,
} from "../../actions/types";

import { ROOT_URL } from "../../config";


export function goToRoute(route) {
    return function (dispatch) {
        dispatch(push(route));
    }
}

export function sendForm(props) {

    
    var payload = props

    const url = `${ROOT_URL}/Whitepapers/requestLink`
    return function (dispatch) {
        return axios.post(url, payload, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            console.log('response.data', response.data)
            dispatch({
                type: SEND_WHITEPAPER_CONTACT,
                payload: response
            });
        }).catch((err) => {
            console.log('err', err)
        });
    }
}
