import React from 'react';
import {
  SortingState, EditingState, PagingState,FilteringState,IntegratedFiltering,
  IntegratedPaging, IntegratedSorting, SelectionState, IntegratedSelection
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table, TableHeaderRow, TableEditRow, TableEditColumn,TableSelection,
  PagingPanel, DragDropProvider, TableColumnReordering,TableFilterRow
} from '@devexpress/dx-react-grid-material-ui';

import { TableCell, MenuItem,Select,Input,IconButton,Button,DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,Dialog,Paper } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { fetchLayer, fetchLayers, fetchLayerList, fetchMap } from './actions';

import {
  ProgressBarCell,
} from './cells/progress-bar-cell';
import {
  HighlightedCell,
} from './cells/highlighted-cell';

import {
    generateRows,
    globalSalesValues,
  } from './demo-data/generator';

const styles = theme => ({
  lookupEditCell: {
    paddingTop: 1 * 0.875,
    paddingRight: 1,
    paddingLeft: 1,
  },
  dialog: {
    width: 'calc(100% - 16px)',
  },
  inputRoot: {
    width: '100%',
  },
});

const AddButton = ({ onExecute }) => (
  <div style={{ textAlign: 'center' }}>
    <Button
      color="primary"
      onClick={onExecute}
      title="Create new row"
    >
      New
    </Button>
  </div>
);

const EditButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Edit row">
    <EditIcon />
  </IconButton>
);

const DeleteButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Delete row">
    <DeleteIcon />
  </IconButton>
);

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Save changes">
    <SaveIcon />
  </IconButton>
);

const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancel changes">
    <CancelIcon />
  </IconButton>
);

const commandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton,
};

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return (
    <CommandButton
      onExecute={onExecute}
    />
  );
};

const availableValues = {
  product: globalSalesValues.product,
  region: globalSalesValues.region,
  customer: globalSalesValues.customer,
};

const LookupEditCellBase = ({
  availableColumnValues, value, onValueChange, classes,
}) => (
  <TableCell
    className={classes.lookupEditCell}
  >
    <Select
      value={value}
      onChange={event => onValueChange(event.target.value)}
      input={
        <Input
          classes={{ root: classes.inputRoot }}
        />
      }
    >
      {availableColumnValues.map(item => (
        <MenuItem key={item} value={item}>{item}</MenuItem>
      ))}
    </Select>
  </TableCell>
);
export const LookupEditCell = withStyles(styles, { name: 'LayerTable' })(LookupEditCellBase);

const Cell = (props) => {
  if (props.column.name === 'discount') {
    return <ProgressBarCell {...props} />;
  }
  if (props.column.name === 'amount') {
    return <HighlightedCell {...props} />;
  }
  return <Table.Cell {...props} />;
};

const EditCell = (props) => {
  const availableColumnValues = availableValues[props.column.name];
  if (availableColumnValues) {
    return <LookupEditCell {...props} availableColumnValues={availableColumnValues} />;
  }
  return <TableEditRow.Cell {...props} />;
};

const getRowId = row => row.id;

class LayerTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'title', title: 'Title' },
        
      ],
      tableColumnExtensions: [
        { columnName: 'amount', align: 'right' },
      ],
      editable:true,
      rows: [],
      sorting: [],
      editingRowIds: [],
      addedRows: [],
      selection:[],
      rowChanges: {},
      currentPage: 0,
      deletingRows: [],
      pageSize: 0,
      pageSizes: [5, 10, 0],
      columnOrder: ['title'],
    };
    //console.log("rows", this.state.rows)

    this.changeSorting = sorting => this.setState({ sorting });
    this.changeEditingRowIds = editingRowIds => this.setState({ editingRowIds });
    this.changeAddedRows = addedRows => this.setState({
      addedRows: addedRows.map(row => (Object.keys(row).length ? row : {
        amount: 0,
        discount: 0,
        saleDate: new Date().toISOString().split('T')[0],
        product: availableValues.product[0],
        region: availableValues.region[0],
        customer: availableValues.customer[0],
      })),
    });
    this.changeRowChanges = rowChanges => this.setState({ rowChanges });
    this.changeCurrentPage = currentPage => this.setState({ currentPage });
    this.changePageSize = pageSize => this.setState({ pageSize });
    this.commitChanges = ({ added, changed, deleted }) => {
      let { layerList } = this.props;
      
      if (added) {

       // console.log('added', added)
      
      /*  const startingAddedId = (rows.length - 1) > 0 ? rows[rows.length - 1].id + 1 : 0;
        rows = [
          ...layerList,
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...row,
          })),
        ];*/
      }
      
      if (changed) {
       // console.log('changed', changed)
        //layerList = layerList.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      }
      this.setState({ deletingRows: deleted || this.state.deletingRows });
    };
    this.cancelDelete = () => this.setState({ deletingRows: [] });
    this.deleteRows = () => {
      const rows = this.state.rows.slice();
      this.state.deletingRows.forEach((rowId) => {
        const index = rows.findIndex(row => row.id === rowId);
        if (index > -1) {
          rows.splice(index, 1);
        }
      });
      this.setState({ rows, deletingRows: [] });
    };
    this.changeColumnOrder = (order) => {
      this.setState({ columnOrder: order });
    };
  }

  componentDidMount() {
   // console.log('componentDidMount', this.props)
    if (this.props.selection) {
      this.setState({selection:this.props.selection})
    }
    this.props.onRef(this)
    this.props.fetchLayerList()
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  getSelection() {
    return this.state.selection
  }

  setSelection(selection) {
    this.setState({selection})
    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(selection)
    }
  }



  render() {
    const {
      classes,
    } = this.props;
    const {
      rows,
      columns,
      tableColumnExtensions,
      sorting,
      editingRowIds,
      addedRows,
      rowChanges,
      currentPage,
      deletingRows,
      pageSize,
      pageSizes,
      columnOrder,
      
      selection
    } = this.state;

    const { layerList, editable } = this.props

    return (
      <Paper>
        <Grid
          rows={layerList}
          columns={columns}
          getRowId={getRowId}
        >
          <SortingState
            sorting={sorting}
            onSortingChange={this.changeSorting}
          />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={this.changeCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={this.changePageSize}
          />
          
          <SelectionState
            selection={selection}
            onSelectionChange={this.setSelection.bind(this)} />
            <FilteringState defaultFilters={[]} />
            <IntegratedSelection/>
          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
        {editable && 
          <EditingState
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={this.changeEditingRowIds}
            rowChanges={rowChanges}
            onRowChangesChange={this.changeRowChanges}
            addedRows={addedRows}
            onAddedRowsChange={this.changeAddedRows}
            onCommitChanges={this.commitChanges}
          />
        }
          <DragDropProvider />

          <Table
            columnExtensions={tableColumnExtensions}
            cellComponent={Cell}
          />

          <TableColumnReordering
            order={columnOrder}
            onOrderChange={this.changeColumnOrder}
          />

          <TableHeaderRow showSortingControls />
          <TableSelection showSelectAll />
          <TableFilterRow />
        
          {editable && 
          <TableEditRow
            cellComponent={EditCell}
          />
          }
          {editable && 
          <TableEditColumn
            width={120}
            showAddCommand={!addedRows.length}
            showEditCommand
            showDeleteCommand
            commandComponent={Command}
          />
        }
          <PagingPanel
            pageSizes={pageSizes}
          />
        </Grid>

        <Dialog
          open={!!deletingRows.length}
          onClose={this.cancelDelete}
          classes={{ paper: classes.dialog }}
        >
          <DialogTitle>Delete Row</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure to delete the following row?
            </DialogContentText>
            <Paper>
              <Grid
                rows={layerList.filter(row => deletingRows.indexOf(row.id) > -1)}
                columns={columns}
              >
                <Table
                  columnExtensions={tableColumnExtensions}
                  cellComponent={Cell}
                />
                <TableHeaderRow />
              </Grid>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.cancelDelete} color="primary">Cancel</Button>
            <Button onClick={this.deleteRows} color="secondary">Delete</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}



function mapStateToProps(state) {
    /* let filtered = _.isEmpty(state.posts.postsGridData.gridFilters) ? [] : state.posts.postsGridData.gridFilters;
     let sorted = _.isEmpty(state.posts.postsGridData.gridSortCriteria) ? [] : state.posts.postsGridData.gridSortCriteria;
 */

   // console.log('state', state)



    return {
        layerList: state.layer.layerList,
        layers: state.layer.layers,
        //map: state.map.map
    }
}

export default withStyles(styles, { name: 'LayerTable' })(connect(mapStateToProps, {
    fetchLayer: fetchLayer,
    fetchLayers: fetchLayers,
    fetchLayerList: fetchLayerList,
    fetchMap: fetchMap,
})(LayerTable));

//export default withStyles(styles, { name: 'DataTable' })(DataTable);