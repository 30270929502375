import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Drawer from '@material-ui/core//Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Redirect } from "react-router-dom";
import InboxIcon from '@material-ui/icons/Inbox';
import EditIcon from "@material-ui/icons/Edit";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import RSSIcon from '@material-ui/icons/RssFeed';
import WorldIcon from '@material-ui/icons/Language';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from "react-redux";
import { goToRoute, toggleSideBar } from "./actions";
import styles from './style/side_menu';
import logo from './images/logo.png'; // Tell Webpack this JS file uses this image
import twitter from './images/twitter.png';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import Menu, { MenuItem } from '@material-ui/core/Menu'
import FlagIcon from '@material-ui/icons/Flag';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Snackbar from '@material-ui/core//Snackbar';
import FlagSelect from '../flagSelect'
import Appuser from '../appuser';
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next'

class SideMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: true,
            anchorEl: null,
            snackBarOpen: localStorage.getItem('cookie-law', false),
        };
    }



    handleDrawerOpen = () => {
        this.props.toggleSideBar(true);
        if (this.props.onOpen) {
            this.props.onOpen(true)
        }
    };

    handleDrawerClose = () => {
        this.props.toggleSideBar(false);
        if (this.props.onOpen) {
            this.props.onOpen(false)
        }
    };

    goToRoute = (route) => {

        //this.setState({ anchorEl: null });
        this.props.goToRoute(route)
        this.props.toggleSideBar(false);
        if (this.props.onOpen) {
            this.props.onOpen(false)
        }

    }

    handleChange = (event, checked) => {
        this.setState({ auth: checked });
    };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleLogoutClose(route) {
        this.setState({ anchorEl: null });
        this.props.goToRoute(route)
        if (this.props.onOpen) {
            this.props.onOpen(false)
        }

    }




    /*

    <div>
                                <IconButton
                                    aria-owns={open ? 'menu-appbar' : null}
                                    aria-haspopup="true"
                                    onClick={this.handleMenu}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={this.handleClose}
                                >
                                    <MenuItem onClick={this.handleClose}>Profile</MenuItem>
                                    <MenuItem onClick={this.handleLogoutClose.bind(this, '/logout')}>Logout</MenuItem>

                                </Menu>
                            </div>


    */


    render() {
        const classes = this.props.classes;
        const { auth, anchorEl } = this.state;
        const { t } = this.props

        const open = Boolean(anchorEl);
        //// console.log('class', classes)

        var { manager, customer } = this.props
        if (!manager) {
            manager = localStorage.getItem('role') == "manager";
        }
        if (!customer) {
            customer = localStorage.getItem('customer')
        }

        if (false) { //!this.props.authenticated) {
            return null;
        } else {
            return (
                <div >

                    <AppBar position="fixed" className={classNames('no-print')}>
                        <Toolbar className={"no-print"} >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <IconButton aria-label="open drawer" onClick={this.handleDrawerOpen.bind(this)}
                                    className={classNames(classes.menuButton)}>
                                    <MenuIcon style={{ color: 'white' }} />
                                </IconButton>
                                <div style={{
                                    fontSize: '11px', color: 'white', textAlign: 'center', marginTop: -12, marginLeft: 0,
                                    marginRight: 20,
                                }}>Menue</div>
                            </div>
                            <div className={classes.logoContainer} onClick={this.goToRoute.bind(this, '/')}>
                                <img className={classes.logoImg} src={logo} alt="RiskCompass" />
                            </div>
                            <IconButton style={{ marginTop: -5, marginRight: 10 }} aria-label="twitter">
                                <a href={"https://twitter.com/RiskCompassInfo"}>
                                    <img style={{ width: 20, height: 20 }} src={twitter} />
                                </a>
                            </IconButton>
                            <IconButton aria-label="rss">
                                <a href={"https://api.riskcompass.eu/api/Articles/rss"}>
                                    <RSSIcon style={{ color: 'white' }} />
                                </a>
                            </IconButton>
                            <div>
                                <FlagSelect />
                            </div>
                            <div>
                                <Appuser />
                            </div>
                        </Toolbar>

                    </AppBar>



                    <Drawer className={"no-print"}

                        open={this.props.sidebarOpen}>
                        <div className={classes.drawerInner}>
                            <div className={classes.drawerHeader}>
                                <IconButton onClick={this.handleDrawerClose.bind(this)}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </div>
                            <Divider />
                            <List>
                                <ListItem button onClick={this.goToRoute.bind(this, '/')}>
                                    <ListItemText primary="Home" />
                                </ListItem>

                                <ListItem button className={classes.menuEntry} onClick={this.goToRoute.bind(this, '/register')}>

                                    <ListItemText primary={t("sideMenu.Registrieren")} />
                                </ListItem>

                                <ListItem button className={classes.menuEntry} onClick={this.goToRoute.bind(this, '/dossiers')}>

                                    <ListItemText primary={t("sideMenu.LänderStädte")} />
                                </ListItem>

                                <ListItem button className={classes.menuEntry} onClick={this.goToRoute.bind(this, '/news')}>

                                    <ListItemText primary={t("sideMenu.Nachrichten")} />
                                </ListItem>
                                {(this.props.admin || this.props.editor) &&
                                    <ListItem className={classes.subMenuEntry} button onClick={this.goToRoute.bind(this, '/news/entwurf')}>

                                        <ListItemText secondary="Entwurf Generell" />
                                    </ListItem>
                                }
                                {(this.props.admin || this.props.editor) &&
                                    <ListItem className={classes.subMenuEntry} button onClick={this.goToRoute.bind(this, '/news/entwurf,dpa')}>

                                        <ListItemText secondary="Entwurf dpa" />
                                    </ListItem>
                                }
                                {(this.props.admin || this.props.editor) &&
                                    <ListItem className={classes.subMenuEntry} button onClick={this.goToRoute.bind(this, '/news/entwurf,rsoe')}>

                                        <ListItemText secondary="Entwurf rsoe" />
                                    </ListItem>
                                }
                                {(this.props.admin || this.props.editor) &&
                                    <ListItem className={classes.subMenuEntry} button onClick={this.goToRoute.bind(this, '/news/entwurf,scatterblogs')}>

                                        <ListItemText secondary="Entwurf Scatterblogs" />
                                    </ListItem>
                                }
                                {(this.props.admin || this.props.editor) &&
                                    <ListItem className={classes.subMenuEntry} button onClick={this.goToRoute.bind(this, '/news/entwurf,terrorismus')}>

                                        <ListItemText secondary="Entwurf Terrorismus" />
                                    </ListItem>
                                }
                                {(this.props.admin || this.props.editor) &&
                                    <ListItem className={classes.subMenuEntry} button onClick={this.goToRoute.bind(this, '/news/entwurf,reisewarnung')}>

                                        <ListItemText secondary="Entwurf Reisewarnung" />
                                    </ListItem>
                                }



                                {false &&
                                    <ListItem button className={classes.menuEntry} onClick={this.goToRoute.bind(this, '/offer')}>

                                        <ListItemText primary={t("sideMenu.Angebot")} />
                                    </ListItem>
                                }
                                {false &&
                                    <ListItem className={classes.subMenuEntry} button onClick={this.goToRoute.bind(this, '/portfolio')}>

                                        <ListItemText secondary="Portfolio" />
                                    </ListItem>
                                }
                                {false &&

                                    <ListItem className={classes.subMenuEntry} button onClick={this.goToRoute.bind(this, '/app')}>

                                        <ListItemText secondary="App" />
                                    </ListItem>

                                }
                                {manager && customer &&
                                    <div>
                                        <ListItem button className={classes.menuEntry} onClick={this.goToRoute.bind(this, '/customer/' + customer)}>

                                            <ListItemText primary="Benutzer Verwaltung" />
                                        </ListItem>
                                    </div>
                                }



                                {this.props.admin &&
                                    <div>
                                        <ListItem button className={classes.menuEntry} onClick={this.goToRoute.bind(this, '/customers')}>

                                            <ListItemText primary="Kunden-Verwaltung" />
                                        </ListItem>
                                        <ListItem button className={classes.menuEntry} onClick={this.goToRoute.bind(this, '/tracking')}>

                                            <ListItemText primary="Tracking" />
                                        </ListItem>
                                        <ListItem button className={classes.menuEntry} onClick={this.goToRoute.bind(this, '/map')}>

                                            <ListItemText primary="Karteneditor" />
                                        </ListItem>
                                        <ListItem button className={classes.menuEntry} onClick={this.goToRoute.bind(this, '/documents')}>

                                            <ListItemText primary="Dokumente" />
                                        </ListItem>
                                        <ListItem button className={classes.menuEntry} onClick={this.goToRoute.bind(this, '/checklists')}>

                                            <ListItemText primary="Checklisten" />
                                        </ListItem>
                                        <ListItem button className={classes.menuEntry} onClick={this.goToRoute.bind(this, '/thememaps')}>

                                            <ListItemText primary="Themenkarten" />
                                        </ListItem>
                                        <ListItem button className={classes.menuEntry} onClick={this.goToRoute.bind(this, '/agenda')}>

                                            <ListItemText primary="Agenda" />
                                        </ListItem>

                                    </div>
                                }
                                {false &&
                                    <ListItem className={classes.menuEntry} button onClick={this.goToRoute.bind(this, '/news/daily-brief')}>
                                        <ListItemText primary="Newsletter" />
                                    </ListItem>
                                }
                                {(this.props.admin || this.props.editor) &&
                                    <ListItem className={classes.menuEntry} button onClick={this.goToRoute.bind(this, '/police')}>

                                        <ListItemText primary="Polizeinachrichten" />
                                    </ListItem>
                                }

                                {this.props.admin &&

                                    <ListItem button className={classes.menuEntry} onClick={this.goToRoute.bind(this, '/riskLevels')}>

                                        <ListItemText primary="Risikostufen" />
                                    </ListItem>
                                }
                                <ListItem button className={classes.menuEntry} onClick={this.goToRoute.bind(this, '/covid')}>

                                    <ListItemText primary="Pandemie-Monitor" />
                                </ListItem>

                                <ListItem button className={classes.menuEntry} onClick={this.goToRoute.bind(this, 'whitepaper')}>

                                    <ListItemText primary="Whitepaper" />
                                </ListItem>






                                <ListItem button className={classes.menuEntry}onClick={this.goToRoute.bind(this, '/contact')}>

<ListItemText primary="RiskCompass" />
</ListItem>


                                <ListItem button className={classes.subMenuEntry} onClick={this.goToRoute.bind(this, '/contact')}>

                                    <ListItemText primary={t("sideMenu.Kontakt")} />
                                </ListItem>

                                <ListItem button className={classes.subMenuEntry} onClick={this.goToRoute.bind(this, '/team')}>

                                    <ListItemText primary="Team" />
                                </ListItem>

                                <ListItem button className={classes.subMenuEntry} onClick={this.goToRoute.bind(this, '/agb')}>

                                    <ListItemText primary={t("sideMenu.AGB")} />
                                </ListItem>

                                <ListItem button className={classes.subMenuEntry} onClick={this.goToRoute.bind(this, 'faq')}>

                                    <ListItemText primary="FAQ" />
                                </ListItem>

                            

                                <ListItem button className={classes.subMenuEntry} onClick={this.goToRoute.bind(this, '/impressum')}>

                                    <ListItemText primary={t("sideMenu.Impressum")} />
                                </ListItem>

                                <ListItem button className={classes.subMenuEntry} onClick={this.goToRoute.bind(this, '/dataProtection')}>

                                    <ListItemText primary={t("sideMenu.Datenschutz")} />
                                </ListItem>

                                <ListItem button className={classes.subMenuEntry} onClick={this.goToRoute.bind(this, '/terms')}>

                                    <ListItemText primary={t("sideMenu.Nutzungsbedingungen")} />
                                </ListItem>




                                <ListItem button className={classes.menuEntry} onClick={this.goToRoute.bind(this, '/logout')}>
                                    {this.props.authenticated &&
                                        <ListItemText primary={t("sideMenu.Abmelden")} />
                                    }
                                    {!this.props.authenticated &&
                                        <ListItemText primary={t("sideMenu.Anmelden")} />
                                    }
                                </ListItem>
                            </List>
                        </div>
                    </Drawer>



                </div>
            );
        }
    }

    goToRoute(route) {
        return (
            <Redirect to={route} />
        )
    }
}

SideMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated,
        admin: state.auth.admin,
        editor: state.auth.editor,
        manager: state.auth.manager,
        customer: state.auth.customer,
        sidebarOpen: state.layout.sidebarOpen,
        lang: state.lang.lang,
    }
}

export default withNamespaces()(connect(mapStateToProps, { goToRoute, toggleSideBar })(
    withStyles(styles)(SideMenu)
));
