import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import  {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './style/start';


class Maplegend extends Component {

    render() {
        const classes = this.props.classes;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Ereignislegende</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <img src={'/images/mapicons/blank_black.png'} width={"32"} height={"37"} />
                                </td>
                                <td>= Bürgerkriegsereignisse, Terrorismus, Terroranschläge, Sabotageaktionen.</td>
                            </tr>
                            <tr>
                                <td>
                                    <img src={'/images/mapicons/blank_red.png'} width={"32"} height={"37"} />
                                </td>
                                <td>
                                    = Gewaltkriminalität, Körperverletzung, Vergewaltigung, Totschlag, Mord.
                                        </td>
                            </tr>
                            <tr>
                                <td>
                                    <img src={'/images/mapicons/blank_yellow.png'} />
                                </td>
                                <td>
                                    = Eigentumskriminalität, Einbrüche, Diebstähle usw..
                                        </td>
                            </tr>
                            <tr>
                                <td>
                                    <img src={'/images/mapicons/fire.png'} />
                                </td>
                                <td>
                                    = Sachbeschädigung, Brand und Brandstiftung, Vandalismus.
                                        </td>
                            </tr>
                            <tr>
                                <td>
                                    <img src={'/images/mapicons/blank_pink.png'} />
                                </td>
                                <td>
                                    = Soziale Unruhen, Aufruhr, Demonstrationen, Streik.
                                        </td>
                            </tr>
                            <tr>
                                <td>
                                    <img src={'/images/mapicons/caution.png'} />
                                </td>
                                <td >
                                    = Verkehrsrisiken.
                                        </td>
                            </tr>
                            <tr>
                                <td>
                                    <img src={'/images/mapicons/blank_grey.png'} />
                                </td>
                                <td>
                                    = Umweltrisiken, Umweltverschmutzung.
                                        </td>
                            </tr>
                            <tr>
                                <td>
                                    <img src={'/images/mapicons/blank_green.png'} />
                                </td>
                                <td>
                                    = Naturgefahren, Stürme, Erdbeben, Vulkanismus, Überschwemmungen, Dürre, Extremwetter.
                                        </td>
                            </tr>
                            <tr>
                                <td>
                                    <img src={'/images/mapicons/medicine.png'} />
                                </td>
                                <td>
                                    = Gesundheitsrisiken, Seuchenausbrüche.
                                        </td>
                            </tr>
                        </tbody>
                        </table>

                   
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }
}
export default withStyles(styles)(Maplegend);
//export default (Maplegend)