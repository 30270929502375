import { push } from 'react-router-redux';
import axios from 'axios';
import {
    FETCH_ARTICLE_SEARCH,
    FETCH_ARTICLE,
    FETCH_ARTICLES,
    FETCH_CHECKLISTS
    
} from "../../actions/types";

import {ROOT_URL} from "../../config";

export function fetchArticle(postName) {
    return function (dispatch) {

        return axios.get(`${ROOT_URL}/articles/byPostname?postName=${encodeURIComponent(postName)}`, {
            headers: {authorization: localStorage.getItem('token')}
        }).then((response) => {
            dispatch({
                type: FETCH_ARTICLE,
                payload: response
            });
        }).catch(() => {
           // console.log('error fetching the dossier');
        });
    }
}

export function fetchArticlesByTagTitles(tagTitles, options) {
   // console.log('fetch tagTitles', tagTitles)
    if (!options) {
        options = {featureCollection:true, limit:50}
    } 
    if (tagTitles.length == 0) {
       // tagTitles = ["nachricht"]
    }
    if (tagTitles.indexOf("polizeinachricht") != -1) {
        options.limit = 50
    }
    options.teaser = true
   
   
   // options.featureCollection = false
   // console.log('options', options)
    return function (dispatch) {
       
        return axios.get(`${ROOT_URL}/articles/byMultipleTags?tags=${encodeURIComponent(JSON.stringify(tagTitles))}&options=${encodeURIComponent(JSON.stringify(options))}`, {
            headers: {authorization: localStorage.getItem('token')}
        }).then((response) => {
            //console.log('response.data', response.data)
            dispatch({
                type: FETCH_ARTICLES,
                payload: response
            });
        }).catch((err) => {
           // console.log('error fetching the articles', err);
        });
    }
}

export function fetchChecklists() {
   // console.log('fetchChecklists')
   
    return function (dispatch) {
        const payload = {
            where:{
                type:"checklist"
            }
        }
       
        return axios.get(`${ROOT_URL}/Pages?filter=${encodeURIComponent(JSON.stringify(payload))}`, {
            headers: {authorization: localStorage.getItem('token')}
        }).then((response) => {
            //console.log('response.data', response.data)
            dispatch({
                type: FETCH_CHECKLISTS,
                payload: response
            });
        }).catch((err) => {
           // console.log('error fetching the articles', err);
        });
    }
}


export function fetchArticlesSearch(search) {
    return function (dispatch) {

        const url = `${ROOT_URL}/Articles/search?q=${encodeURIComponent(search.search)}&sort=${encodeURIComponent(search.sort)}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
           // console.log('before dispatch')
            dispatch({
                type: FETCH_ARTICLE_SEARCH,
                payload: response
            });
        }).catch(() => {
           // console.log('error fetching the articles');
        });

    }
}

export function goToRoute(route) {
    return function (dispatch) {
        dispatch(push(route));
    }
}

