import React from 'react';
import ReactDOM from 'react-dom';

import { Markdown } from 'react-showdown';
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardContent, List, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { ROOT_URL } from "../../config";
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import { Link } from 'react-router-dom';
import Map from '../map/map_index'
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import AddIcon from '@material-ui/icons/Add';
import { fetchChecklists, goToRoute } from './actions'
//import ReactGA from 'react-ga';
const gaProperty = 'UA-130702159-1'


class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pageName: null };

    }
    UNSAFE_componentWillMount() {
        //ReactGA.initialize(gaProperty);
        //ReactGA.pageview(window.location.pathname + window.location.search)
        this.props.fetchChecklists()
        if (this.props.match.params.postName) {
            this.setState({ pageName: this.props.match.params.postName })

            /*this.fetchPage(this.props.match.params.postName).then(res => {
                if (res.data.length > 0) {
                    this.setState({
                        page: res.data[0]
                    })
                }

            }).catch(err => {
               // console.log('err', err)
            })*/


        }


    }



    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate() {

    }
    handleListItemClick(pageName) {
        // console.log('handleListItemClick', pageName)
        this.props.goToRoute("/page/" + pageName)
        this.setState({ pageName })

    }

    print() {

        window.print()
    }


    render() {
        const { pageName } = this.state
        const { checklists, admin, editor } = this.props
        // console.log('checklists', checklists)
        // console.log('pageName', pageName)
        const contentSize = this.props.match.params.postName ? 12 : 9
        return <div>
            {checklists && checklists.length > 0 &&
                <Grid style={{ maxWidth: '100%', padding: '12px', marginTop: '0px', marginLeft: '0px' }} container >
                    {!this.props.match.params.postName &&
                        <Grid item lg={3} sm={3} xs={3} md={3} >
                            <Card className={"no-print"}>
                                <List component="nav">
                                    {checklists.map(checklist => {
                                        return (<ListItem
                                            key={checklist.id}
                                            button
                                            selected={checklist.postName === pageName}
                                            onClick={this.handleListItemClick.bind(this, checklist.postName)}
                                        >
                                            <ListItemText primary={checklist.title} />
                                        </ListItem>)
                                    })}

                                </List>
                            </Card>
                        </Grid>
                    }
                    <Grid item lg={contentSize} sm={contentSize} xs={contentSize} md={contentSize} >


                        <div style={{
                            marginTop: '0px',
                            paddingLeft: '2px',
                            paddingRight: '2px',
                            height: '100%',
                            overflowY: 'scroll',
                            WebkitOverflowScrolling: 'touch'
                        }}>
                            {pageName && checklists && checklists.filter(checklist => {

                                return checklist.postName === pageName


                            }).map(page => {
                                return (
                                    <Card key={page.id} style={{ marginBottom: '15px', maxWidth: '100%' }}>
                                        <div >
                                            {(admin || editor) &&
                                                <Tooltip title="neuen Checkliste schreiben">
                                                    <Link className={"no-print"}  style={{ position: 'absolute', top: 115, right: 150 }} to="/page/edit/new">
                                                        <Button style={{ color: 'white' }} variant="fab" color="secondary" aria-label="Neue Checkliste" >

                                                            <AddIcon />

                                                        </Button>
                                                    </Link>
                                                </Tooltip>
                                            }
                                            <Tooltip title="Drucken">

                                                <Button className={"no-print"} onClick={this.print.bind(this)} style={{ position: 'absolute',color: 'white',top: 115, right: 50  }} variant="fab" color="secondary" aria-label="Drucken" >

                                                    <PrintIcon />

                                                </Button>

                                            </Tooltip>
                                            <CardContent style={{ paddingBottom: 0 }}><h1>{page.title}</h1></CardContent>
                                            <CardContent style={{ paddingTop: 0 }}>
                                                <Markdown markup={page.markdown} tables={true} />

                                                {(admin || editor) &&
                                                    <Tooltip title="Checkliste bearbeiten">
                                                        <a style={{ marginLeft: 0 }} ><Link className={"no-print"} to={"/page/edit/" + page.id}><EditIcon /></Link></a>
                                                    </Tooltip>
                                                }

                                            </CardContent>
                                        </div>
                                    </Card>)
                            })}



                        </div>
                    </Grid>


                </Grid>

            }



        </div>

    }

}

function mapStateToProps(state) {


    return {
        checklists: state.page.checklists,
        admin: state.auth.admin,
        editor: state.auth.editor
    }
}
const styles = {

}
export default withStyles(styles, { name: 'Page' })(connect(mapStateToProps, {
    fetchChecklists: fetchChecklists,
    goToRoute: goToRoute,

})(Page));


