import React from 'react';
import ReactDOM from 'react-dom';
import {
    Card, CardActions, CardContent, IconButton, Paper, Grid, Checkbox, FormControlLabel,
    Dialog, DialogActions, DialogTitle, Button, CircularProgress, MenuItem
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { ROOT_URL } from "../../config";

import Hidden from '@material-ui/core/Hidden';
import {
    insertArticle, updateArticle, fetchArticle, deleteArticle, publishNewsletter, publishTweet
} from "./actions";

import TextField from '@material-ui/core/TextField/TextField';
import Chip from '@material-ui/core/Chip/Chip'
import Editor from '@toast-ui/editor'
import '@toast-ui/editor/dist/toastui-editor.css'
////import 'tui-editor/dist/tui-editor-contents.css
import 'codemirror/lib/codemirror.css'
import 'highlight.js/styles/github.css'
import EditTagList from '../editTagList/editTagList_index'
import SelectMapIcons from '../selectMapIcons/selectMapIcons_index'
import ShapeEditor from '../shapeEditor/index'
import moment from 'moment'

String.prototype.trunc = String.prototype.trunc ||
    function (n) {
        return (this.length > n) ? this.substr(0, n - 1) + '&hellip;' : this;
    };

function snippet(text, length) {
    if (!length) {
        length = 180
    }
    console.log('cut ', length)
    if (text && text.length > length) {
        return text.trunc(length) + ".."
    }
    return text
}

const riskLevels = [1, 2, 3, 4, 5]

class ArticleEditor extends React.Component {

    constructor(props) {
        super(props);
        var date = moment().format("DD-MM-YYYY")
        this.state = {
            editor: null,
            eventLocation: null,
            submitted: false,
            del: false,
            updating: false,
            article: {
                date: date,
                riskLevel: 3,
                tags: ["nachricht", "terrorismus", "naturgefahren", "infrastruktur", "krieg", "kriminalitaet", "reisemedizin", "soziale-unruhen", "wirtschaftssicherheit"],
                copyright: "RiskCompass GmbH. Alle Rechte vorbehalten, all rights reserved."

            },
            newArticle: false


        }

    }
    /*
        componentWillReceiveProps(nextProps) {
            if (nextProps.input.value != this.props.input.value) {
                this.state.editor.setMarkdown(nextProps.input.value)
            }
    
        }*/

    UNSAFE_componentWillMount() {
        // console.log('before', this.props.match.params)
        if (this.props.match.params.id) {
            // console.log('before')
            if ((this.props.match.params.id != "new" || this.props.match.params.id != "new-daily-brief")) {
                this.props.fetchArticle(this.props.match.params.id)

            }
            if (this.props.match.params.id == "new-daily-brief") {
                var article = this.state.article
                article.tags = ["daily-brief"]
                article.country = "Global"
                article.title = "Der Lagebericht"
                this.setState({ article: article, newArticle: true })
            } else if (this.props.match.params.id == "new") {
                this.setState({ newArticle: true })
            }

        }
    }

    handleChange = name => event => {
        //console.log('handleChange', name)
        var article = this.state.article

        article[name] = event.target.value
        if (name == "date") {
            article[name] = moment(article[name], "YYYY-MM-DD").format("DD.MM.YYYY")
        }
        if (name == "pushDisabled") {
            article[name] = event.target.checked
        }
        //console.log('handleChange', article)
        this.setState({ article });
    };

    saveArticle() {
        var article = this.state.article

        const fields = ["date", "title", "link", "country", "city", "incidentScene"]

        fields.forEach(key => {
            if (article[key]) {
                article[key] = article[key].trim()
            }

        })

        article.markdown = this.clean(this.state.editor.getMarkdown())

        var isDailyBrief = article.tags.indexOf("daily-brief") != -1
        article.teaser = snippet(article.markdown, isDailyBrief ? 500 : 180)
        if (article.date) {
            // console.log('article.date', article.date)
            if (article.date.includes("-")) {
                article.date = article.date.split("-").join(".")
            }
        }
        if (this.state.newArticle) {
            this.props.insertArticle(article).then(res => {
                this.props.history.goBack()

            }).catch(err => {
                // console.log('err', err)
            })
        } else {
            this.setState({ updating: true })
            this.props.updateArticle(article).then(res => {
                this.props.history.goBack()


            }).catch(err => {
                // console.log('err', err)
            })
        }

    }
    handleDeleteArticle(id) {
        this.setState({ del: id })
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        // console.log('nextProps', nextProps)
        if ((this.props.match.params.id != "new" || this.props.match.params.id != "new-daily-brief") && (nextProps.article && !this.state.article) || (nextProps.article && nextProps.article.id != this.state.article.id)) {
            if (this.state.editor) {
                this.state.editor.setMarkdown(nextProps.article.markdown)

            }
            const article = nextProps.article
            if (!article.riskLevel) {
                article.riskLevel = 3
            }
            this.setState({ article })
            if (article.location) {
                this.setState({

                    featureCollection: {
                        type: "FeatureCollection", features: [
                            {
                                geometry: {
                                    coordinates: article.location.coordinates,
                                    type: "Point"
                                },
                                type: "Feature",
                                properties: {
                                    title: "",
                                    riskLevel: 0,
                                    user_riskLevel: 0
                                }
                            }
                        ]
                    }
                })
            }
            // console.log('featureCollection', this.state.featureCollection)
        }
    }

    clean(text) {
        return text.replaceAll(/\\/g, '')
    }

    componentDidMount() {
        let editor = new Editor({
            el: document.querySelector('#editSection'),
            initialEditType: 'wysiwyg',
            previewStyle: 'tab',
            height: '86vh',
            events: {
                change: this.onChange
            },
            hooks: {
                changeMode: this.handleModeChange.bind(this)
            },
            initialValue: this.state.article ? this.state.article.markdown : ""
        });

        this.setState({ editor });
    }

    handleModeChange() {

        //var { value, name } = this.props.input

        if (this.state.editor) {
            var { article } = this.state
            article.markdown = this.clean(this.state.editor.getMarkdown())
            this.state.editor.setMarkdown(this.clean(article.markdown), false)
            this.setState({ article })
        }
    }

    onChange = (event) => {
        //this.props.change(this.props.meta.form, "markdown", this.state.editor.getValue())
    }

    cancelDelete() {
        this.setState({ del: null })
    }

    deleteArticle(id) {
        this.props.deleteArticle(id).then(res => {
            this.props.history.goBack()
        }).catch(err => {

        })
    }

    handleNewsletterChange = name => event => {
        const payload = { [name]: event.target.checked }
        console.log('payload', payload)
        this.setState(payload);


    };

    onMarkerChanged(feature) {
        // console.log('feature', feature)
        var article = this.state.article
        if (article.location) {
            article.location.coordinates = feature.geometry.coordinates

        } else {
            article.location = { coordinates: feature.geometry.coordinates, type: "Point" }
        }
        article.incidentScene = feature.properties.name
        this.setState({
            article
        })

    }

    //  <SelectMapIcons />
    onTagsChange(tags) {
        // console.log('onTagsChange', tags)
        var article = this.state.article
        article.tags = tags
        this.setState({
            article
        })
    }

    onTagSelect(tag) {
        //// console.log('onTagSelect', tag)
        var article = this.state.article
        article.mainTag = tag
        this.setState({ article: article })
    }

    publishNewsletter() {
        var campaigns = []
        console.log('this.state', this.state)
        if (this.state.sendBusiness) {
            campaigns.push("Travel Risk Intelligence")
        }
        if (this.state.sendIntelligence) {
            campaigns.push("Intelligence-Monitor")
        }
        console.log('campaigns', campaigns)
        this.props.publishNewsletter(this.state.article.id, campaigns).then(article => {
            console.log('publishNewsletter', article)
            this.setState({ article })
        }).catch(err => {
            console.log('err', err)
        })

    }

    publishTweet() {
        this.props.publishTweet(this.state.article.id).then(article => {
            console.log('article', article)
            this.setState({ article })
        }).catch(err => {
            console.log('err', err)
        })
    }


    render() {

        //const {  } = this.props
        const { featureCollection, article, submitted, newArticle, del, updating, sendBusiness, sendIntelligence } = this.state
        const { classes } = this.props
        console.log('article', article)

        return (
            <div>

                <Grid style={{ maxWidth: '100%', padding: '12px', marginTop: '0px', marginLeft: '0px' }} container >
                    <Grid item lg={6} >

                        <Card>
                            {article &&
                                <div style={{
                                    marginTop: 10,
                                    display: 'flex',
                                    fallbacks: [
                                        { display: '-webkit-box' },
                                        { display: '-moz-box' },
                                        { display: '-ms-flexbox' },
                                        { display: '-webkit-flex' }
                                    ],
                                    flexWrap: 'wrap'
                                }}>

                                    <TextField
                                        id={"country"}
                                        label={"Land"}
                                        className={classes.textField}
                                        value={article.country}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleChange("country")}
                                        margin='dense'>
                                    </TextField>
                                    <TextField id={"city"}
                                        label={"Ort"}
                                        className={classes.textField}
                                        // defaultValue=""
                                        className={classes.textField}
                                        value={article.city}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleChange("city")}
                                        margin='dense'>
                                    </TextField>
                                    <TextField
                                        id="date"
                                        label={"Datum"}
                                        type="date"

                                        className={classes.textField}
                                        value={moment(article.date, "DD.MM.YYYY").format("YYYY-MM-DD")}
                                        InputLabelProps={{ shrink: true }}
                                        //defaultValue={moment().format("YYYY-MM-DD")}
                                        onChange={this.handleChange("date")}
                                        margin='dense' />
                                    <div style={{ paddingLeft: 10, paddingRight: 10, marginTop: 10, float: 'right' }}>
                                        {!updating &&
                                            <Button
                                                variant="fab"
                                                color="secondary"

                                                onClick={this.saveArticle.bind(this)}
                                            >
                                                <SaveIcon />
                                            </Button>
                                        }
                                        {updating && <CircularProgress size={44} style={{


                                        }} />}

                                    </div>
                                    <TextField
                                        id="riskLevel"
                                        select
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        label="Gefährdungsstufe"
                                        value={article.riskLevel || 3}
                                        onChange={this.handleChange('riskLevel')}
                                        className={classes.textField}

                                        margin='dense'
                                    >
                                        {riskLevels.map(option => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <TextField
                                        id={"title"}
                                        label={"Titel"}
                                        fullWidth
                                        className={classes.textField}
                                        value={article.title}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleChange("title")}
                                        margin='dense'>
                                    </TextField>

                                    <TextField
                                        id={"link"}
                                        label={"Orignal-Quelle"}
                                        fullWidth
                                        className={classes.textField}
                                        value={article.link || ""}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleChange("link")}
                                        margin='dense'>
                                    </TextField>
                                    <TextField
                                        id={"copyright"}
                                        label={"Copyright"}
                                        fullWidth
                                        className={classes.textField}
                                        value={article.copyright || ""}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleChange("copyright")}
                                        margin='dense'>
                                    </TextField>

                                    <TextField
                                        id={"geojsonUrl"}
                                        label={"Geojson"}
                                        fullWidth
                                        className={classes.textField}
                                        value={article.geojsonUrl || ""}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleChange("geojsonUrl")}
                                        margin='dense'>
                                    </TextField>

                                    <TextField
                                        id={"incidentScene"}
                                        label={"Ereignisort"}
                                        style={{ maxWidth: '70%', minWidth: '70%' }}
                                        className={classes.textField}
                                        value={article.incidentScene || ""}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleChange("incidentScene")}
                                        margin='dense'>
                                    </TextField>

                                    {article.location &&
                                        <div style={{ marginTop: 20, marginBottom: 20 }}>GPS: Lon: {article.location.coordinates[0]}  Lat: {article.location.coordinates[1]}  </div>
                                    }
                                    <EditTagList mainTag={article.mainTag} onTagSelect={this.onTagSelect.bind(this)} style={{ width: '100%', marginTop: '10px', marginRight: '10px' }} tags={article.tags} onChange={this.onTagsChange.bind(this)} />

                                </div>

                            }
                        </Card>
                        <div style={{ paddingTop: 20 }}>
                            <ShapeEditor shapes={featureCollection} markerChanged={this.onMarkerChanged.bind(this)} hideList={true} disablePolygon={true} />
                        </div>

                        {newArticle &&

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={article.pushDisabled}
                                        onChange={this.handleChange('pushDisabled')}

                                        color="primary"
                                    />
                                }
                                label="Push deaktivieren"
                            />

                        }

                    </Grid>
                    <Grid item lg={6} >
                        <div style={{ marginTop: 0 }} id='editSection' />
                        {!newArticle && article &&
                            <IconButton onClick={this.handleDeleteArticle.bind(this, article.id)}>
                                <DeleteIcon />
                            </IconButton>
                        }
                        {
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={sendIntelligence}
                                            onChange={this.handleNewsletterChange('sendIntelligence')}

                                            color="primary"
                                        />
                                    }
                                    label="Intelligence-Monitor"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={sendBusiness}
                                            onChange={this.handleNewsletterChange('sendBusiness')}

                                            color="primary"
                                        />
                                    }
                                    label="Travel Risk Intelligence"
                                />
                                <Button variant="contained" onClick={this.publishNewsletter.bind(this)}>
                                    Newsletter publizieren
                                </Button>

                                {article && article.campaigns && article.campaigns.length > 0 && article.campaigns.reverse().map(campaign => {
                                    return (
                                        <div style={{ marginTop: 10 }}>
                                            <a href={"https://us19.admin.mailchimp.com/reports/summary?id=" + campaign} target='_blank'>{"https://us19.admin.mailchimp.com/reports/summary?id=" + campaign}</a>
                                        </div>
                                    )
                                })

                                }

                            </div>
                        }
                        {article && !article.tweet &&
                            <Button variant="contained" onClick={this.publishTweet.bind(this)}>
                                Tweet publizieren
                            </Button>
                        }
                        {article && article.tweet &&
                            <div style={{ marginTop: 10 }}>
                                <a href={"https://twitter.com/RiskCompassInfo/status/" + article.tweet} target='_blank'>{"https://twitter.com/RiskCompassInfo/status/" + article.tweet}</a>
                            </div>
                        }
                    </Grid>
                    {article &&
                        <Dialog
                            open={del}
                            onClose={this.cancelDelete.bind(this)}

                        >
                            <DialogTitle>Wirklich löschen?</DialogTitle>

                            <DialogActions>
                                <Button onClick={this.deleteArticle.bind(this, article.id)} color="secondary">Ja</Button>
                                <Button onClick={this.cancelDelete.bind(this)} color="primary">Nein</Button>

                            </DialogActions>
                        </Dialog>
                    }
                </Grid>

            </div>
        )

    }
}

function mapStateToProps(state) {

    // console.log("state", state)
    return {
        // document: state.documentEdit.document,
        article: state.articleEdit.article
    }
}
const styles = {
    textField: {
        marginLeft: 15,
        marginRight: 15,

    },
    textFieldWide: {

        marginLeft: 15,
        marginRight: 15,


    }
}
export default withStyles(styles, { name: 'ArticleEditor' })(connect(mapStateToProps, {
    insertArticle, updateArticle, fetchArticle, deleteArticle, publishNewsletter, publishTweet
})(ArticleEditor));