import {
    FETCH_CHECKLISTS,
    FETCH_WHITEPAPER,
    UPDATE_WHITEPAPER,
    INSERT_WHITEPAPER,
    FETCH_TERMS
} from "../../actions/types";

import * as _ from "lodash";

const INITIAL_STATE = {
    checklists: [],
    terms:[]
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case FETCH_CHECKLISTS:
            
            return {
                ...state,
                checklists: action.payload.data
            };
        case FETCH_TERMS:
        return {
            ...state,
            terms: action.payload.data
        };
        case FETCH_WHITEPAPER:
            var whitepaper = action.payload.data
            if (whitepaper.length > 0) {
                whitepaper = whitepaper[0]
            }
            return {
                ...state,
                whitepaper: whitepaper
            };


        default:
            return state;
    }
}