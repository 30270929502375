import axios from 'axios';
import {
    FETCH_ARTICLE_SEARCH,
    FETCH_ARTICLE,
    FETCH_ARTICLES,

} from "../../actions/types";

import { ROOT_URL } from "../../config";

export function fetchArticle(postName) {
    return function (dispatch) {

        return axios.get(`${ROOT_URL}/articles/byPostname?postName=${encodeURIComponent(postName)}`, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            dispatch({
                type: FETCH_ARTICLE,
                payload: response
            });
        }).catch(() => {
            
        });
    }
}



export function fetchArticlesSearch(search) {
    return function (dispatch) {

        const url = `${ROOT_URL}/Articles/search?q=${encodeURIComponent(search.search)}&sort=${encodeURIComponent(search.sort)}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            
            dispatch({
                type: FETCH_ARTICLE_SEARCH,
                payload: response
            });
        }).catch(() => {
            
        });

    }
}

export function fetchArticlesByTagTitles(tagTitles, options) {
   // console.log('fetch tagTitles', tagTitles)
    if (!options) {
        options = {limit:150}
    }
    options.teaser = "true"
    options.featureCollection = false
    if (tagTitles.indexOf("polizeinachricht") != -1) {
        options.limit = 1500
    }
    return function (dispatch) {
       
        return axios.get(`${ROOT_URL}/articles/byMultipleTags?tags=${encodeURIComponent(JSON.stringify(tagTitles))}&options=${encodeURIComponent(JSON.stringify(options))}`, {
            headers: {authorization: localStorage.getItem('token')}
        }).then((response) => {
            //console.log('response.data', response.data)
            dispatch({
                type: FETCH_ARTICLES,
                payload: response
            });
        }).catch((err) => {
           // console.log('error fetching the articles', err);
        });
    }
}
/*
export function fetchArticlesByTagTitles(tagTitles) {
    
    return function (dispatch) {

        return axios.get(`${ROOT_URL}/articles/byTags?tags=${encodeURIComponent(JSON.stringify(tagTitles))}&limit=150`, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            
            dispatch({
                type: FETCH_ARTICLES,
                payload: response
            });
        }).catch((err) => {
            
        });
    }
}*/
