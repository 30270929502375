import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
} from '@material-ui/core';
import FlagIcon from '@material-ui/icons/Flag';
import CityIcon from '@material-ui/icons/LocationCity';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './style/start';

class Risklegend extends Component {
    render() {
        const classes = this.props.classes;
        return (
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Definition der »RiskCompass«-Gefährdungsstufen (1-5)</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Typography className={classes.riskLevel}>

                    <span><FlagIcon style={{ color: '#c0ffb0' }} /> <CityIcon style={{ color: '#c0ffb0' }} /> <strong className={classes.strong} >Gefährdungsstufe 1:</strong>niedrig (Reisen landesweit problemlos möglich, Reisende sollten sich über ihr Reiseziel informieren sowie auf die üblichen Alltags- und evtl. Naturgefahren gefasst sein. Informationen über politische Ereignisse, Verkehrsstörungen, Feiertage usw.). Die Sicherheitslage ist unauffällig, die Kriminalitätsrate niedrig, die öffentliche Ordnung allseits gewährleistet.</span>
                    <span><FlagIcon style={{ color: '#fffe75' }} /> <CityIcon style={{ color: '#fffe75' }} /> <strong className={classes.strong}>Gefährdungsstufe 2:</strong> moderat (Reisen landesweit möglich, mit gewissen regionalen Unterschieden hinsichtlich der Sicherheitslage muss gerechnet werden; Reisende sollten unterwegs die üblichen Vorsichts- und Sicherheitsmaßnahmen [z.B. gegen Klein- und Eigentumskriminalität] beachten und sich von Bereichen mit punktuell erhöhter Kriminalität fernhalten. Informationen über Verkehrsbehinderungen, Demonstrationen, Streiks usw.). Obgleich die öffentliche Ordnung weitgehend gesichert ist, gibt es Regionen, Orte und Stadtteile mit erhöhter Kriminalitätsbelastung (einschl. Gewaltkriminalität), die zu meiden sind.</span>
                    <span><FlagIcon style={{ color: '#fdb100' }} /> <CityIcon style={{ color: '#fdb100' }} /> <strong className={classes.strong}>Gefährdungsstufe 3:</strong> erhöht (Reisen sind landesweit ‒ mit Einschränkungen ‒ möglich; Reisende sollten sich vor Reiseantritt allerdings ausführlich über das Reiseziel informieren, die Reiserouten sorgfältig planen und vor Ort entsprechende Vorsichtsmaßnahmen ergreifen. Informationen über Gefahren auf lokaler Ebene bzw. in einem räumlich überschaubaren Gebiet). Die öffentliche Ordnung kann nicht überall bzw. vollumfänglich gewährleistet werden, Kriminalität und soziale Unruhen (Demonstrationen, Streiks, usw.) gehen parallel mit einer politisch labilen Lage, in Unruheregionen verfügen die Sicherheitskräfte nur über begrenzte Kapazitäten. Reisende sollten sich vor Kriminalität schützen und Menschenansammlungen grundsätzlich meiden.</span >
                    <span><FlagIcon style={{ color: '#ff6600' }} /> <CityIcon style={{ color: '#ff6600' }} /> <strong className={classes.strong}>Gefährdungsstufe 4:</strong> hoch(Reisende sollten die Notwendigkeit einer persönlichen Reise kritisch überdenken und ggfls.geeignete Maßnahmen ergreifen, um die Sicherheit vor Ort zu gewährleisten.Informationen über Ereignisse, die eine erhebliche Gefahr für den Einzelnen oder eine erhebliche Störung der öffentlichen Ordnung darstellen).Es muss landesweit mit einer instabilen Sicherheitslage gerechnet werden, politische und innerstaatliche Konflikte können teils mit Waffengewalt ausgetragen werden, sowohl Kriminalität als auch Sabotage oder Terror stellen große Probleme dar und überfordern die Sicherheitsbehörden, sofern diese nicht selbst Teil des Problems sind.Reisende sollten eindeutige Sicherheitsvorkehrungen treffen und vor Ort nur in Begleitung vertrauenswürdiger Einheimischer unterwegs sein.</span >
                    <span><FlagIcon style={{ color: '#fd0000' }} /> <CityIcon style={{ color: '#fd0000' }} /> <strong className={classes.strong}>Gefährdungsstufe 5:</strong> extrem(Reisende sollten das Gefahrengebiet[umgehend] verlassen bzw.nicht bereisen, sofern keine Maßnahmen[z.B.gepanzerte Fahrzeuge, bewaffneter Begleitschutz usw.]ergriffen wurden, die die Sicherheit des Reisenden gewährleisten können.Informationen über Ereignisse, die eine erhebliche Bedrohung für Leib und Leben des Reisenden darstellen).Landesweit herrschen bewaffnete Konflikte(Krieg oder Bürgerkrieg), die u.a.mit Kriegswaffen ausgetragen werden.Die öffentliche Ordnung ist teils oder ganz zusammengebrochen, ebenso die Autorität einer Zentralregierung, Terroranschläge und lokale Warlords bestimmen die Gefahrenlage.</span >

                </Typography >
            </ExpansionPanelDetails >
        </ExpansionPanel >
        )
    }
}

export default withStyles(styles)(Risklegend);
//export default (Risklegend)