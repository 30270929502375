import React from 'react';
import ReactDOM from 'react-dom';
import {
    Card, CardActions, CardContent, IconButton, Paper, Grid, Checkbox, FormControlLabel,
    Dialog, DialogActions, DialogTitle, Button, CircularProgress, MenuItem
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { ROOT_URL } from "../../config";

import Hidden from '@material-ui/core/Hidden';
import {
    insertHotspot, updateHotspot, fetchHotspot, deleteHotspot
} from "./actions";

import TextField from '@material-ui/core/TextField/TextField';
import Chip from '@material-ui/core/Chip/Chip'
import Editor from '@toast-ui/editor'
import '@toast-ui/editor/dist/toastui-editor.css'
////import 'tui-editor/dist/tui-editor-contents.css
import 'codemirror/lib/codemirror.css'
import 'highlight.js/styles/github.css'
import EditTagList from '../editTagList/editTagList_index'
import SelectMapIcons from '../selectMapIcons/selectMapIcons_index'
import ShapeEditor from '../shapeEditor/index'
import moment from 'moment'

String.prototype.trunc = String.prototype.trunc ||
    function (n) {
        return (this.length > n) ? this.substr(0, n - 1) + '&hellip;' : this;
    };

function snippet(text, length) {
    if (!length) {
        length = 180
    }
    console.log('cut ', length)
    if (text && text.length > length) {
        return text.trunc(length) + ".."
    }
    return text
}

const riskLevels = [1, 2, 3, 4, 5]

class HotspotEditor extends React.Component {

    constructor(props) {
        super(props);
        var date = moment().format("DD-MM-YYYY")
        this.state = {
            editor: null,
            eventLocation: null,
            submitted: false,
            del: false,
            updating: false,
            hotspot: {
                date: date,
                riskLevel: 3,

            },
            newHotspot: false


        }

    }
    /*
        componentWillReceiveProps(nextProps) {
            if (nextProps.input.value != this.props.input.value) {
                this.state.editor.setMarkdown(nextProps.input.value)
            }
    
        }*/

    UNSAFE_componentWillMount() {
        // console.log('before', this.props.match.params)
        if (this.props.match.params.id) {
            // console.log('before')
            if (this.props.match.params.id) {
                this.props.fetchHotspot(this.props.match.params.id)

            }

        }
    }

    handleChange = name => event => {
        //console.log('handleChange', name)
        var hotspot = this.state.hotspot

        hotspot[name] = event.target.value
        if (name == "date") {
            hotspot[name] = moment(hotspot[name], "YYYY-MM-DD").format("DD.MM.YYYY")
        }
        if (name == "pushDisabled") {
            hotspot[name] = event.target.checked
        }
        //console.log('handleChange', hotspot)
        this.setState({ hotspot });
    };

    saveHotspot() {
        var hotspot = this.state.hotspot

        const fields = ["date", "title", "description"]

        fields.forEach(key => {
            if (hotspot[key]) {
                hotspot[key] = hotspot[key].trim()
            }

        })

        hotspot.markdown = this.clean(this.state.editor.getMarkdown())


        if (this.state.newHotspot) {
            this.props.insertHotspot(hotspot).then(res => {
                this.props.history.goBack()

            }).catch(err => {
                // console.log('err', err)
            })
        } else {
            this.setState({ updating: true })
            this.props.updateHotspot(hotspot).then(res => {
                this.props.history.goBack()


            }).catch(err => {
                // console.log('err', err)
            })
        }

    }
    handleDeleteHotspot(id) {
        this.setState({ del: id })
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        // console.log('nextProps', nextProps)
        if ((this.props.match.params.id != "new" || this.props.match.params.id != "new-daily-brief") && (nextProps.hotspot && !this.state.hotspot) || (nextProps.hotspot && nextProps.hotspot.id != this.state.hotspot.id)) {
            if (this.state.editor) {
                this.state.editor.setMarkdown(nextProps.hotspot.markdown)

            }
            const hotspot = nextProps.hotspot
            if (!hotspot.riskLevel) {
                hotspot.riskLevel = 3
            }
            this.setState({ hotspot })
            if (hotspot.location) {
                this.setState({

                    featureCollection: {
                        type: "FeatureCollection", features: [
                            {
                                geometry: {
                                    coordinates: hotspot.location.coordinates,
                                    type: "Point"
                                },
                                type: "Feature",
                                properties: {
                                    title: "",
                                    riskLevel: 0,
                                    user_riskLevel: 0
                                }
                            }
                        ]
                    }
                })
            }
            // console.log('featureCollection', this.state.featureCollection)
        }
    }

    clean(text) {
        return text.replaceAll(/\\/g, '')
    }

    componentDidMount() {
        let editor = new Editor({
            el: document.querySelector('#editSection'),
            initialEditType: 'wysiwyg',
            previewStyle: 'tab',
            height: '86vh',
            events: {
                change: this.onChange
            },
            hooks: {
                changeMode: this.handleModeChange.bind(this)
            },
            initialValue: this.state.hotspot ? this.state.hotspot.markdown : ""
        });

        this.setState({ editor });
    }

    handleModeChange() {

        //var { value, name } = this.props.input

        if (this.state.editor) {
            var { hotspot } = this.state
            hotspot.markdown = this.clean(this.state.editor.getMarkdown())
            this.state.editor.setMarkdown(this.clean(hotspot.markdown), false)
            this.setState({ hotspot })
        }
    }

    onChange = (event) => {
        //this.props.change(this.props.meta.form, "markdown", this.state.editor.getValue())
    }

    cancelDelete() {
        this.setState({ del: null })
    }

    deleteHotspot(id) {
        this.props.deleteHotspot(id).then(res => {
            this.props.history.goBack()
        }).catch(err => {

        })
    }

    handleNewsletterChange = name => event => {
        const payload = { [name]: event.target.checked }
        console.log('payload', payload)
        this.setState(payload);


    };

    onMarkerChanged(feature) {
        // console.log('feature', feature)
        var hotspot = this.state.hotspot
        if (hotspot.location) {
            hotspot.location.coordinates = feature.geometry.coordinates

        } else {
            hotspot.location = { coordinates: feature.geometry.coordinates, type: "Point" }
        }
        hotspot.incidentScene = feature.properties.name
        this.setState({
            hotspot
        })

    }

    //  <SelectMapIcons />
    onTagsChange(tags) {
        // console.log('onTagsChange', tags)
        var hotspot = this.state.hotspot
        hotspot.tags = tags
        this.setState({
            hotspot
        })
    }

    onTagSelect(tag) {
        //// console.log('onTagSelect', tag)
        var hotspot = this.state.hotspot
        hotspot.mainTag = tag
        this.setState({ hotspot: hotspot })
    }



    render() {

        //const {  } = this.props
        const { featureCollection, hotspot, submitted, newHotspot, del, updating, sendBusiness, sendIntelligence } = this.state
        const { classes } = this.props
        console.log('hotspot', hotspot)

        return (
            <div>

                <Grid style={{ maxWidth: '100%', padding: '12px', marginTop: '0px', marginLeft: '0px' }} container >
                    <Grid item lg={6} >

                        <Card>
                            {hotspot &&
                                <div style={{
                                    marginTop: 10,
                                    display: 'flex',
                                    fallbacks: [
                                        { display: '-webkit-box' },
                                        { display: '-moz-box' },
                                        { display: '-ms-flexbox' },
                                        { display: '-webkit-flex' }
                                    ],
                                    flexWrap: 'wrap'
                                }}>



                                    <div style={{ paddingLeft: 10, paddingRight: 10, marginTop: 10, float: 'right' }}>
                                        {!updating &&
                                            <Button
                                                variant="fab"
                                                color="secondary"

                                                onClick={this.saveHotspot.bind(this)}
                                            >
                                                <SaveIcon />
                                            </Button>
                                        }
                                        {updating && <CircularProgress size={44} style={{


                                        }} />}

                                    </div>
                                    <TextField
                                        id="riskLevel"
                                        select
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        label="Gefährdungsstufe"
                                        value={hotspot.riskLevel || 3}
                                        onChange={this.handleChange('riskLevel')}
                                        className={classes.textField}

                                        margin='dense'
                                    >
                                        {riskLevels.map(option => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <TextField
                                        id={"title"}
                                        label={"Titel"}
                                        fullWidth
                                        className={classes.textField}
                                        value={hotspot.title}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleChange("title")}
                                        margin='dense'>
                                    </TextField>

                                    <TextField
                                        id={"radius"}
                                        label={"Radius"}
                                        fullWidth
                                        className={classes.textField}
                                        value={hotspot.radius || ""}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleChange("radius")}
                                        margin='dense'>
                                    </TextField>




                                    <TextField
                                        id={"description"}
                                        label={"Ereignisort"}
                                        style={{ maxWidth: '70%', minWidth: '70%' }}
                                        className={classes.textField}
                                        value={hotspot.description || ""}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleChange("description")}
                                        margin='dense'>
                                    </TextField>

                                    {hotspot.location &&
                                        <div style={{ marginTop: 20, marginBottom: 20 }}>GPS: Lon: {hotspot.location.coordinates[0]}  Lat: {hotspot.location.coordinates[1]}  </div>
                                    }

                                </div>

                            }
                        </Card>
                        <div style={{ paddingTop: 20 }}>
                            <ShapeEditor shapes={featureCollection} markerChanged={this.onMarkerChanged.bind(this)} hideList={true} disablePolygon={true} />
                        </div>

                        {newHotspot &&

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hotspot.pushDisabled}
                                        onChange={this.handleChange('pushDisabled')}

                                        color="primary"
                                    />
                                }
                                label="Push deaktivieren"
                            />

                        }

                    </Grid>
                    <Grid item lg={6} >
                        <div style={{ marginTop: 0 }} id='editSection' />
                        {!newHotspot && hotspot &&
                            <IconButton onClick={this.handleDeleteHotspot.bind(this, hotspot.id)}>
                                <DeleteIcon />
                            </IconButton>
                        }
                        {
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={sendIntelligence}
                                            onChange={this.handleNewsletterChange('sendIntelligence')}

                                            color="primary"
                                        />
                                    }
                                    label="Intelligence-Monitor"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={sendBusiness}
                                            onChange={this.handleNewsletterChange('sendBusiness')}

                                            color="primary"
                                        />
                                    }
                                    label="Travel Risk Intelligence"
                                />



                            </div>
                        }

                    </Grid>
                    {hotspot &&
                        <Dialog
                            open={del}
                            onClose={this.cancelDelete.bind(this)}

                        >
                            <DialogTitle>Wirklich löschen?</DialogTitle>

                            <DialogActions>
                                <Button onClick={this.deleteHotspot.bind(this, hotspot.id)} color="secondary">Ja</Button>
                                <Button onClick={this.cancelDelete.bind(this)} color="primary">Nein</Button>

                            </DialogActions>
                        </Dialog>
                    }
                </Grid>

            </div>
        )

    }
}

function mapStateToProps(state) {

    // console.log("state", state)
    return {
        // document: state.documentEdit.document,
        hotspot: state.hotspotEdit.hotspot
    }
}
const styles = {
    textField: {
        marginLeft: 15,
        marginRight: 15,

    },
    textFieldWide: {

        marginLeft: 15,
        marginRight: 15,


    }
}
export default withStyles(styles, { name: 'HotspotEditor' })(connect(mapStateToProps, {
    insertHotspot, updateHotspot, fetchHotspot, deleteHotspot,
})(HotspotEditor));