import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderField, renderHiddenField, renderSelect } from "../../components/field_formatters";
import PropTypes from 'prop-types';
import { Typography, Toolbar, Card, CardContent, Grid, Button, Dialog, DialogTitle, DialogActions, LinearProgress, CardHeader } from "@material-ui/core";
import { fetchCustomers } from '../customers/actions'
import { changePassword, resetPassword, newPassword } from "../customer/actions";
import SignIn from '../auth/sign_in'
import MobileStoreButton from 'react-mobile-store-button';
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next'

class UserForm extends Component {

    constructor(args) {
        super(args)
        this.state = {
            open: true,
            updating: false,
            resetted: false
        }

    }

    componentDidMount() {
        console.log('did mount')
        if (this.props.match.params.token) {
            localStorage.setItem('token', this.props.match.params.token);
        }
    }

    handleFormSubmit(formProps) {
        console.log('formProps', formProps)
        if (formProps.newPassword != formProps.newPassword2) {
            this.setState({ error: "Passwords do not match!" })
            return
        }
        this.setState({ updating: true, error: null })
        if (!this.props.match.params.token) {
            this.props.changePassword(formProps).then(res => {
                this.setState({ updating: false })
                this.handleClose()
            }).catch(error => {
                this.setState({ updating: false, error })
            })
        } else {
            this.props.newPassword(formProps).then(res => {
                this.setState({ updating: false, resetted: true })
                this.handleClose()
            }).catch(error => {
                this.setState({ updating: false, error })
            })
        }


    }

    renderErrorMessage() {
        if (this.props.errorMessage) {
            return (
                <div>
                    <strong></strong> {this.props.errorMessage}
                </div>
            )
        }
    }

    handleClose = value => {
        this.setState({ open: false });
        if (this.props.onDismiss) {
            this.props.onDismiss()
        }
    };

    render() {
        const { handleSubmit, initialValues, tenants, t } = this.props;
        const { open, updating, resetted, error } = this.state

        const token = this.props.match.params.token
        //console.log('resetted', resetted)
        return (
            <div style={{ padding: 20 }}>
                <Grid container
                    justify="center"
                    alignItems="center"
                    >
                    <Grid item lg={6} md={6} sm={12} xs={12}>

                        <Toolbar>

                            <Typography variant="h5" color="inherit" style={{ flexGrow: 1,textAlign:'center' }}>
                                {t("profile.Profil")}
                            </Typography>

                        </Toolbar>
                        <Card style={{marginTop:20}}>
                            <CardContent>
                                {false && <CardHeader title={'Passwort ändern'}></CardHeader>}

                                {!resetted &&
                                    <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>

                                        <div style={{ padding: 20, }}>
                                            {!token &&
                                                <Field name="oldPassword" type="password" component={renderField} label={t("profile.AltesPasswort")} />
                                            }
                                            <Field name="newPassword" type="password" component={renderField} label={t("profile.NeuesPasswort")} />
                                            <Field name="newPassword2" type="password" component={renderField} label={t("profile.NeuesPasswortWiederholen")} />

                                        </div>
                                        {error &&
                                            <div>{error}</div>
                                        }

                                        {!updating &&
                                            <div>
                                                <Button type="submit" style={{ marginTop: 35 }} color="secondary">{t("profile.Speichern")}</Button>{' '}
                                            </div>
                                        }
                                        {updating &&
                                            <LinearProgress />
                                        }

                                    </form>
                                }
                                {resetted &&

                                    <div style={{textAlign:'center'}}>
                                        <div>Passwort erfolgreich geändert!</div>
                                        <div>Sie können sich nun in der App und auf der Webseite einloggen </div>
                                        <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}></div>
                                        <MobileStoreButton
                                            store="ios"
                                            url={"https://apps.apple.com/us/app/riskcompass/id1463753022"}
                                            linkProps={{ title: 'iOS Store Button' }}
                                        />
                                         <MobileStoreButton
                                            store="android"
                                            url={"https://play.google.com/store/apps/details?id=eu.riskcompass.app"}
                                            linkProps={{ title: 'android Store Button' }}
                                        />
                                        <SignIn />
                                    </div>
                                }
                            </CardContent></Card></Grid></Grid></div>

        )
    }
}

const form = reduxForm({
    form: 'UserForm',

});

UserForm.contextTypes = {
    store: PropTypes.object
};

function mapStateToProps(state) {
    return {

        pwChanged: state.users.pwChanged,

    }
}

export default withNamespaces()(connect(mapStateToProps, { changePassword, resetPassword, newPassword })(form(UserForm)));