import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
    renderField, renderLocalizedEditor, renderHiddenField, renderMapSelector
} from "../../../components/field_formatters";
import PropTypes from 'prop-types';
import { success } from "react-notification-system-redux";
import { Button, IconButton, Menu, MenuItem, Grid, Paper } from "@material-ui/core";
import { notificationDuration, newSectionDossierOptions as options } from '../../../config';
import AddIcon from '@material-ui/icons/Add';
import { updateMapMetadata } from "../mapMetadata_actions";
import { withRouter } from 'react-router-dom';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 1 * 3,
        backgroundColor: theme.palette.background.paper,
    },
});

class MapMetadataEditForm extends Component {

    state = {
        submittedEditDossier: false,
        open: false,
        anchorEl: null,
    }

    dispatchNotification(fn, timeout) {
        setTimeout(() => {
            this.context.store.dispatch(fn(notificationOpts));
        }, timeout);
    }

    clean(text) {
        return text && text.length > 0 ? text.replaceAll(/\\/g, '') : ''
    }

    handleFormSubmit(formProps) {
        console.log('formProps', formProps)

        let payload = {
            id: formProps.id,
            description: formProps.description
        }

        if (payload.description && Object.keys(payload.description).length > 0) {
            var langs = Object.keys(payload.description)
            langs.forEach(lang => {
                payload.description[lang] = this.clean(payload.description[lang])
            })
        }
        
        this.props.updateMapMetadata(payload).then(function () {
            this.setState({ submittedMapMetadata: true })
            this.props.history.goBack()
            //this.dispatchNotification(success, 200);
        }.bind(this));
    }

    handleClick = event => {
        this.setState({
            open: true,
            anchorEl: event.currentTarget
        });
    };

    handleMenuItemClick = (event, index) => {
        this.setState({ open: false });
        switch (index) {
            case 0:
                this.props.addNewDossierSection();
        }
    }

    handleRequestClose = () => {
        this.setState({ open: false });
    }

    render() {
        const { error, handleSubmit, map } = this.props;
        const { value } = this.state;


        return (
            <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                <Field name="id" type="hidden" component={renderHiddenField} />

                <Grid style={{ marginTop: '0px' }} container >
                    <Grid item lg={6} sm={12} >
                        <Paper style={{ marginLeft: '10px' }}>
                            <Field name={'description'} type="text" component={renderLocalizedEditor} label="Content" />
                        </Paper>
                    </Grid>
                </Grid>

                {error && <strong> {error} </strong>}

                <div>
                    <Button type="submit" variant="contained" color="primary">Speichern</Button>{' '}
                    <Button variant="contained" onClick={this.props.history.goBack}>Abbrechen</Button>
                </div>
            </form>
        )
    }
}

function validate(formProps) {
    const errors = {};

    if (!formProps.title) {
        errors.title = 'Please enter a title';
    }

    if (!formProps.author) {
        errors.author = 'Please enter an author';
    }

    return errors;
}


const form = reduxForm({
    form: 'MapMetadataEditForm',
    enableReinitialize: true,
    validate
})

MapMetadataEditForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

MapMetadataEditForm.contextTypes = {
    store: PropTypes.object
}

const notificationOpts = {
    title: 'Map Metadata',
    message: 'Successfully updated',
    position: 'tr',
    autoDismiss: notificationDuration
}


function mapStateToProps(state) {
    // console.log('mmm mapStateToProps', state)
    return {
        initialValues: state.maps.map,
        map: state.maps.map,
    }
}

export default connect(mapStateToProps, { updateMapMetadata })(form(withRouter(MapMetadataEditForm)))