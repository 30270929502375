import React, { Component, PropTypes } from 'react';
import {
    Card, Badge, CardContent, Tooltip, Grid, IconButton,
     Toolbar, Typography, List, ListItem, ListItemAvatar,
    ListItemText, ListItemSecondaryAction,
    Button, Dialog, DialogTitle, DialogActions, DialogContent,
    LinearProgress, TextField, InputAdornment

} from '@material-ui/core'
import { fetchCustomer, resetCustomer, fetchCustomers, goToRoute, deleteCustomer } from './actions'
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next'
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RouterIcon from '@material-ui/icons/Router';
import StorageIcon from '@material-ui/icons/Storage';
import SearchIcon from '@material-ui/icons/Search';
import GroupIcon from '@material-ui/icons/Group';
import BallotIcon from '@material-ui/icons/Ballot';
import CurveIcon from '@material-ui/icons/Assessment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CustomerForm from './form'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dropzone from 'react-dropzone'

class Customers extends Component {

    constructor(args) {
        super(args)
        this.state = {
            addModal: false,
            deleting:false,
            selectedId: null,
            updating:false,
            search:null
        }

    }

    componentDidMount() {
        this.props.fetchCustomers()
    }

    UNSAFE_componentWillMount() {
        //this.props.fetchCustomers()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ( (!this.state.customers && nextProps.customers) || (JSON.stringify(this.state.customers) != JSON.stringify(nextProps.customers))) {
            this.setState({customers:nextProps.customers})
        }
    }

    addClick(id) {


        if (id && typeof id == 'string') {
            this.props.fetchCustomer(id).then(res => {
                this.setState({
                    addModal: true,
                    currentId: id
                })
            }).catch(error => {

            })
        } else {
            this.props.resetCustomer().then(res => {
                this.setState({
                    addModal: true,
                    currentId: null
                })
            })


        }

    }

    onDismiss() {
        this.setState({ addModal: false })
        this.props.fetchCustomers()
    }

    deleteItem(id) {
        console.log('deleteItem', id)
        this.setState({ deleting: true, selectedId: id })
    }

    handleDialogClose() {
        this.setState({ deleting: false, selectedId: null })
    }

    onDelete(id) {
        this.setState({ updating: true })
        this.props.deleteCustomer(this.state.selectedId).then(res => {
            this.setState({ updating: false, deleting: false, selectedId: null })
            this.props.fetchCustomers()
        })
    }

    onSearch(e) {
        console.log('search', e.target.value)
        var customers = this.props.customers

        customers = customers.filter(function (item) {
            return item.title.toLowerCase().search(
                e.target.value.toLowerCase()) !== -1;
        });
        this.setState({ customers })
    }

    render() {
        const {  admin, editor,t } = this.props
        const { addModal, deleting, updating,search,customers, } = this.state
        //console.log('customers', customers)
        return (
            <div style={{ padding: 20 }}>

                <Grid container
                    justify="center"
                    alignItems="center"
                    >
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div style={{ flexGrow: 1 }}>
                            <Toolbar>

                                <Typography variant="h5" color="inherit" style={{ flexGrow: 1,textAlign:'center' }}>
                                    {t('customers.Kunden')}
                                </Typography>
                                <Tooltip title={t('customers.NeuenKundeanlegen')}>
                                    <IconButton onClick={this.addClick.bind(this)} color="inherit" aria-label={t('customers.NeuenKundeanlegen')}>
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            </Toolbar>
                        </div>
                    </Grid>
                    <Grid  item lg={10} md={10} sm={12} xs={12}>

                    <div style={{ paddingLeft:0,paddingRight:0 }}>
                    <TextField

label="Suche"
onChange={this.onSearch.bind(this)}
value={search}
fullWidth
InputProps={{
    startAdornment: (
        <InputAdornment position="start">
            <SearchIcon />
        </InputAdornment>
    ),
}}
/>
</div>
<div style={{ marginTop: 40 }}>
                        {customers && customers.map(customer => {
                            //console.log('Customer', Customer)
                            return (
                                <Card key={customer.id} style={{ marginTop: 20 }}>
                                    <CardContent>
                                        <List>
                                            <ListItem>
                                                

                                                    <div>

                                                       
                                                       
                                                        <Link to={"/users/" + customer.id}>
                                                            <Tooltip title={t("customers.ZurBenutzer-Verwaltung")}>
                                                                <IconButton aria-label={t("customers.ZurBenutzer-Verwaltung")}>
                                                                
                                                                    <Badge
                                                                        color="secondary"
                                                                        badgeContent={(customer.users && customer.users.length ? customer.users.length : 0)}
                                                                    >
                                                                    <GroupIcon />
                                                                    </Badge>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Link>
                                                       
                                                       

                                                    </div>
                                              
                                                    <Link to={"/customer/" + customer.id}>
                                                    <Tooltip title={t("customers.ZurBenutzer-Verwaltung")}>
                                                        <ListItemText
                                                            style={{ marginLeft: 20, width: '50vw' }}
                                                            primary={customer.title}
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography component="span" color="textSecondary">
                                         

                                                                    </Typography>
                                                                   
                                                                    <Typography component="span" color="textSecondary">
                                                                        {t("customers.Benutzer")+": " + (customer.users && customer.users.length ? customer.users.length : 0)}

                                                                    </Typography>

                                                                </React.Fragment>
                                                            }



                                                        />
                                                    </Tooltip>
                                                </Link>
                                                <ListItemSecondaryAction>
                                                <Tooltip title={t("customers.Kundebearbeiten")}>
                                                            <IconButton onClick={this.addClick.bind(this, customer.id)}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                <Tooltip title={t("customers.Mieterlöschen")}>
                                                        <IconButton onClick={this.deleteItem.bind(this, customer.id)} aria-label="Delete">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>

                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </Card>
                            )
                        })}
</div>
                    </Grid>
                </Grid>

                {addModal &&

                    <CustomerForm onDismiss={this.onDismiss.bind(this)} />


                }
                 {deleting &&
                    <Dialog open={deleting} onClose={this.handleDialogClose.bind(this)} aria-labelledby= {t("customers.Mieterlöschen")} style={{ minWidth: '50%' }}>
                        <DialogTitle>
                         {t("customers.Mieterlöschen")}
                        </DialogTitle>
                        <DialogContent>
                        {t("customers.WollenSie")}
                        </DialogContent>
                        <DialogActions>
                            {!updating &&
                                <div>
                                    <Button onClick={this.handleDialogClose.bind(this)} style={{ marginTop: 35 }} color="secondary">{t("customers.Nein")}</Button>{' '}
                                    <Button onClick={this.onDelete.bind(this)} style={{ marginTop: 35 }} color="secondary">{t("customers.Ja")}</Button>{' '}
                                </div>
                            }
                            {updating &&
                                <LinearProgress />
                            }
                        </DialogActions>
                    </Dialog>
                }
            </div>
        )
    }
}

const styles = {

}

function mapStateToProps(state) {


    return {
        customers: state.customers.customers,
        admin: state.auth.admin,
        editor: state.auth.editor
    }
}
export default withNamespaces()(withStyles(styles, { name: 'Gateways' })(connect(mapStateToProps, {
    fetchCustomer: fetchCustomer,
    resetCustomer: resetCustomer,
    fetchCustomers: fetchCustomers,
    deleteCustomer:deleteCustomer,
    goToRoute: goToRoute,

})(Customers)));

