import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
    renderField, renderLocalizedEditor, renderHiddenField, renderMapSelector
} from "../../components/field_formatters";
import PropTypes from 'prop-types';
import { success } from "react-notification-system-redux";
import { Button, IconButton, Menu, MenuItem, Grid, Paper } from "@material-ui/core";
//import validate from './validation';
import { notificationDuration, newSectionDossierOptions as options } from '../../config';
import AddIcon from '@material-ui/icons/Add';
import { updateRiskLevel,fetchRiskLevel } from "./actions";
import {withRouter} from 'react-router-dom';



const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 1 * 3,
        backgroundColor: theme.palette.background.paper,
    },
});

class RiskLevelEditForm extends Component {

    state = {
        submittedEditDossier: false,
        open: false,
        anchorEl: null,

    }


    dispatchNotification(fn, timeout) {
        setTimeout(() => {
            this.context.store.dispatch(fn(notificationOpts));
        }, timeout);
    }
    clean(text) {
        return text.replaceAll(/\\/g, '')
    }

    handleFormSubmit(formProps) {
       // console.log('formProps', formProps)
        if (formProps.markdown && Object.keys(formProps.markdown).length > 0) {
            var langs =  Object.keys(formProps.markdown)
            langs.forEach( lang => {
                formProps.markdown[lang] = this.clean( formProps.markdown[lang])
            })
        }
        this.props.updateRiskLevel(formProps).then(function () {
            this.setState({ submittedEditDossier: true })
            this.props.history.goBack()
            //this.dispatchNotification(success, 200);
        }.bind(this));
    }

    handleClick = event => {
        this.setState({
            open: true,
            anchorEl: event.currentTarget
        });
    };

    handleMenuItemClick = (event, index) => {
        this.setState({ open: false });
        switch (index) {
            case 0:
                this.props.addNewDossierSection();
        }
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };

   

    /*

 


    */

    componentDidMount() {
        this.props.fetchRiskLevel(this.props.match.params.id)
    }

    render() {

        const { error, handleSubmit, riskLevel} = this.props;
        const { classes } = this.props;
        const { value } = this.state;
       // console.log('this.props', this.props)

      
        console.log('riskLevel', riskLevel)

        return (
            <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                <Field name="id" type="hidden" component={renderHiddenField} />

                <div>

                    <Grid style={{ marginTop: '0px' }} container >

                        <Grid item lg={12} sm={12} >
                            <Paper style={{ marginLeft: '10px'}}>
                                <Field name={'markdown'} type="text" component={renderLocalizedEditor} label="Content" />
                            </Paper>
                        </Grid>
                       


                    </Grid>






                </div>


                <IconButton aria-label="More" aria-owns={this.state.open ? 'lock-menu' : null} aria-haspopup="true" onClick={this.handleClick}>
                    <AddIcon />
                </IconButton>

                {error && <strong> {error} </strong>}

                <div>
                    <Button type="submit" variant="contained" color="primary">Speichern</Button>{' '}
                    <Button variant="contained" onClick={this.props.history.goBack}>Abbrechen</Button>
                </div>
            </form>
        )
    }
}

RiskLevelEditForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

const notificationOpts = {
    title: 'Dossier',
    message: 'Successfully Updated.',
    position: 'tr',
    autoDismiss: notificationDuration
}

const form = reduxForm({
    form: 'RiskLevelEditForm',
    enableReinitialize: true,
    //validate
});

RiskLevelEditForm.contextTypes = {
    store: PropTypes.object
};

function mapStateToProps(state) {
    return {
        initialValues: state.riskLevels.riskLevel,
        riskLevel:  state.riskLevels.riskLevel,
    }
}

export default connect(mapStateToProps, { updateRiskLevel,fetchRiskLevel })(form(withRouter(RiskLevelEditForm)))