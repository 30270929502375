import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

export function requireAuthentication(Component) {
    class AuthenticatedComponent extends React.Component {

        UNSAFE_componentWillMount () {
            this.checkAuth(this.props.authenticated);
        }

        UNSAFE_componentWillReceiveProps (nextProps) {
            this.checkAuth(nextProps.authenticated);
        }

        checkAuth (isAuthenticated) {
            if (!isAuthenticated) {
                let redirectAfterLogin = this.props.location.pathname;
                this.props
                    .dispatch(push(`/login?next=${redirectAfterLogin}`));
            }
        }

        render () {
            return (
                <div>
                    {
                        this.props.authenticated === true
                        ? <Component {...this.props}/>
                        : null
                    }
                </div>
            )

        }
    }

    const mapStateToProps = (state) => ({
        // token: state.auth.token,
        // userName: state.auth.userName,
        authenticated: state.auth.authenticated
        
    });


    return connect(mapStateToProps)(AuthenticatedComponent);
}