import { push } from 'react-router-redux';
import axios from 'axios';
import {
    FETCH_CHECKLISTS,
    FETCH_WHITEPAPER,
    INSERT_WHITEPAPER,
    UPDATE_WHITEPAPER,
    DELETE_WHITEPAPER,
    FETCH_TERMS
} from "../../actions/types";

import { ROOT_URL } from "../../config";

export function goToRoute(route) {
    return function (dispatch) {
        dispatch(push(route));
    }
}

export function deleteWhitepaper(id) {
    // console.log('deleteArticle', id)
    return function (dispatch) {

        const url = `${ROOT_URL}/Whitepapers/delete?id=${encodeURIComponent(id)}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: DELETE_WHITEPAPER,
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the articles', err);
        });

    }
}

export function fetchWhitepaper(id) {
    return function (dispatch) {
        var query = { "where": { "id": id } }
        const url = `${ROOT_URL}/Whitepapers/${id}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
             console.log('response.data', response.data)
            dispatch({
                type: FETCH_WHITEPAPER,
                payload: response
            });
        }).catch((err) => {
             console.log('error fetching the page', err);
        });
    }
}


export function updateWhitepaper(page) {
    return function (dispatch) {

        const body = {
            id: page.id,
        }
        delete page.id
        body.page = page

        const url = `${ROOT_URL}/Whitepapers/updateById?`
        return axios.post(url, body, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: UPDATE_WHITEPAPER,
                payload: response
            });
        }).catch((err) => {
            // console.log('error updating the page', err);
        });
    }
}

export function insertWhitepaper(article) {
    return function (dispatch) {

        const body = {
            id: article.id,
        }
        delete article.id
        article.type = "checklist"
        body.page = article
        console.log('body', body)
        const url = `${ROOT_URL}/Whitepapers/insert?`
        return axios.post(url, body, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: INSERT_WHITEPAPER,
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the articles', err);
        });
    }
}


export function fetchTerms() {
    // console.log('fetchChecklists')

    return function (dispatch) {
        const payload = {
            where: {
                type: "terms"
            }
        }

        return axios.get(`${ROOT_URL}/Whitepapers?filter=${encodeURIComponent(JSON.stringify(payload))}`, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: FETCH_TERMS,
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the articles', err);
        });
    }
}


export function fetchChecklists() {
    // console.log('fetchChecklists')

    return function (dispatch) {
        const payload = {
            order: "title ASC",
            where: {
                or: [
                    { type: { like: "checklist" } },
                    { type: { like: "ereignislegende" } }
                ]
            }
        }

        return axios.get(`${ROOT_URL}/Whitepapers?filter=${encodeURIComponent(JSON.stringify(payload))}`, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.dattita', response.data)
            dispatch({
                type: FETCH_CHECKLISTS,
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the articles', err);
        });
    }
}


