import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Grid, Card } from "@material-ui/core";

import ContactForm from '../contactForm'

class Contact extends Component {

    render() {

        return (

            <Grid style={{ marginTop: '0px' }} container  justify="center">
                <Grid item lg={6} sm={12} md={8}>
                <Card>
                <div style={{padding:20}}>
                    <ContactForm />
                    </div>
                    </Card>
                </Grid>
            </Grid>

        )
    }
}


export default Contact