import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Card, Typography, TextField, Button } from "@material-ui/core";

class DeleteAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailContent: 'Ich möchte mein Konto und alle zugehörigen Daten unwideruflich löschen.',
        };
    }

    handleChange = (event) => {
        this.setState({ emailContent: event.target.value });
    };

    render() {
        const { emailContent } = this.state;
        return (
            <Grid style={{ marginTop: '0px' }} container justify="center">
                <Grid item lg={6} sm={12} md={8}>
                    <Card>
                        <div id="deleteMe" style={{ padding: 20 }}>
                            <Typography variant="h5" gutterBottom>
                                Konto löschen
                            </Typography>
                            <TextField
                                label="Email Content"
                                multiline
                                rows={4}
                                variant="outlined"
                                value={emailContent}
                                onChange={this.handleChange}
                                fullWidth
                                margin="normal"
                            />
                            <Button
                                variant="contained"
                                color="secondary"
                                href={`mailto:moritz.knecht@riskcompass.info?subject=Delete%20Account%20Request&body=${encodeURIComponent(
                                    emailContent
                                )}`}
                            >
                                Konto löschen
                            </Button>
                        </div>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

export default DeleteAccount;
