import React from 'react';
import ReactDOM from 'react-dom';

import { Markdown } from 'react-showdown';
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardContent, List, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { ROOT_URL } from "../../config";
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import { Link } from 'react-router-dom';
import Map from '../map/map_index'
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { fetchTerms, goToRoute } from '../page/actions'

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pageName: null };

    }
    UNSAFE_componentWillMount() {
        this.props.fetchTerms()
        if (this.props.match.params.id) {
            this.setState({ pageName: this.props.match.params.id })
        } else {
            this.setState({ pageName: "terms" })
        }

       /* if (!this.props.admin) {
            this.props.fetchPage(id)
        }*/
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate() {

    }
    handleListItemClick(pageName) {
       // console.log('handleListItemClick', pageName)
        this.props.goToRoute("/terms/" + pageName)
        this.setState({ pageName })

    }



    render() {
        const { pageName } = this.state
        const { terms, admin, editor, page } = this.props
       // console.log('terms', terms)
       // console.log('pageName', pageName)
        return <div>
            
            {admin && terms && terms.length > 0 &&
                <Grid style={{ maxWidth: '100%', padding: '12px', marginTop: '0px', marginLeft: '0px' }} container >
                    <Grid item lg={3} sm={3} xs={3} md={3} >
                        <Card>
                            <List component="nav">
                                {terms.map(term => {
                                    return (<ListItem
                                        key={term.id}
                                        button
                                        selected={term.postName === pageName}
                                        onClick={this.handleListItemClick.bind(this, term.id)}
                                    >
                                        <ListItemText primary={term.title} />
                                    </ListItem>)
                                })}

                            </List>
                        </Card>
                    </Grid>
                    <Grid item lg={9} sm={9} xs={9} md={9} >


                        <div style={{
                            marginTop: '0px',
                            paddingLeft: '2px',
                            paddingRight: '2px',
                            height: '100%',
                            overflowY: 'scroll',
                            WebkitOverflowScrolling: 'touch'
                        }}>
                            {pageName && terms && terms.filter(term => {

                                return term.id === pageName


                            }).map(_page => {
                                return (
                                    <Card key={_page.id} style={{ marginBottom: '15px', maxWidth: '100%' }}>
                                        {(admin || editor) &&
                                            <Tooltip title="neuen terme schreiben">
                                                <Link style={{ position: 'absolute', top: 115, right: 50 }} to="/page/edit/new">
                                                    <Button style={{ color: 'white' }} variant="fab" color="secondary" aria-label="Neue terme" >

                                                        <AddIcon />

                                                    </Button>
                                                </Link>
                                            </Tooltip>
                                        }
                                        <CardContent style={{ paddingBottom: 0 }}><h1>{_page.title}</h1></CardContent>
                                        <CardContent style={{ paddingTop: 0 }}>
                                            <Markdown markup={_page.markdown} tables={true} />

                                            {(admin || editor) &&
                                                <Tooltip title="terme bearbeiten">
                                                    <a style={{ marginLeft: 0 }} ><Link to={"/page/edit/" + _page.id}><EditIcon /></Link></a>
                                                </Tooltip>
                                            }

                                        </CardContent>

                                    </Card>)
                            })}



                        </div>
                    </Grid>


                </Grid>

            }
            {!admin &&  pageName && terms && terms.filter(term => {

                return term.id === pageName


            }).map(_page => {

                return (
                    <Grid style={{ maxWidth: '100%', padding: '12px', marginTop: '0px', marginLeft: '0px' }} container >
                    <Grid item lg={12} sm={12} xs={12} md={12} >
                    <Card key={_page.id}>
                    <CardContent style={{ paddingBottom: 0 }}><h1>{_page.title}</h1></CardContent>
                    <CardContent style={{ paddingTop: 0 }}>
                        <Markdown markup={_page.markdown} tables={true} />
        
        
                    </CardContent>
                </Card>
                </Grid>


</Grid>
                
                )
            })
        }
           
        
            



        </div>

    }

}

function mapStateToProps(state) {


    return {
        terms: state.page.terms,
        admin: state.auth.admin,
        editor: state.auth.editor
    }
}
const styles = {

}
export default withStyles(styles, { name: 'Page' })(connect(mapStateToProps, {
    fetchTerms: fetchTerms,
    goToRoute: goToRoute, 
    
})(Page));


