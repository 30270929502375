import React, { Component } from 'react'
import { connect } from 'react-redux'
import { render, findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { MenuItem } from '@material-ui/core';
import Downshift from 'downshift';
import axios from 'axios';
import { ROOT_URL } from "../../config";

const suggestions = [
    { label: 'Afghanistan' },
    { label: 'Aland Islands' },
    { label: 'Albania' },
    { label: 'Algeria' },
    { label: 'American Samoa' },
    { label: 'Andorra' },
    { label: 'Angola' },
    { label: 'Anguilla' },
    { label: 'Antarctica' },
    { label: 'Antigua and Barbuda' },
    { label: 'Argentina' },
    { label: 'Armenia' },
    { label: 'Aruba' },
    { label: 'Australia' },
    { label: 'Austria' },
    { label: 'Azerbaijan' },
    { label: 'Bahamas' },
    { label: 'Bahrain' },
    { label: 'Bangladesh' },
    { label: 'Barbados' },
    { label: 'Belarus' },
    { label: 'Belgium' },
    { label: 'Belize' },
    { label: 'Benin' },
    { label: 'Bermuda' },
    { label: 'Bhutan' },
    { label: 'Bolivia, Plurinational State of' },
    { label: 'Bonaire, Sint Eustatius and Saba' },
    { label: 'Bosnia and Herzegovina' },
    { label: 'Botswana' },
    { label: 'Bouvet Island' },
    { label: 'Brazil' },
    { label: 'British Indian Ocean Territory' },
    { label: 'Brunei Darussalam' },
];



const styles = {
    container: {
        flexGrow: 1,
        height: 200,
        width: 200,
    },
};



class Nominatim extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: "",
            results: []
        }
    }


    fetchNominatim(place, options) {

        var NOMINATIM_URL = "https://nominatim.openstreetmap.org/"
        var url = `${NOMINATIM_URL}search?q=${place}&format=json&polygon=1&polygon_geojson=1&namedetails=1`

        if (options.disablePolygon) {
            url = `${NOMINATIM_URL}search?q=${place}&format=json&namedetails=1`
        }
       // console.log('url', url)
        return axios.get(url)
    }

    handleTextFieldKeyDown = event => {
        switch (event.key) {
            case 'Enter':
                // call corresponding handler
                //console.log('Enter', this.state.search)
                var options = {}
                if (this.props.disablePolygon) {
                    options.disablePolygon = this.props.disablePolygon
                }
                this.fetchNominatim(this.state.search, options).then(results => {
                    //console.log('results', results.data)
                    this.setState({ results: results.data })
                }).catch(err => {
                   // console.log('err', err)
                })

                break
            case 'Escape':
                // etc...
                break
            default: break
        }
    }


    handleChange = name => event => {
       // console.log('event.target.value', event.target.value)
        this.setState({
            [name]: event.target.value,
        });
    };

    renderInput(inputProps) {
        const { search } = this.state
        const { InputProps, classes, ref, ...other } = inputProps;

        return (
            <TextField
                {...other}
                id="search"
                inputRef={ref}
                value={search}
                onChange={this.handleChange('search')}
                onKeyDown={this.handleTextFieldKeyDown}
                InputProps={{

                    ...InputProps,
                }}
            />
        );
    }

    renderSuggestion(params) {
        const { results } = this.state
        const { suggestion, index, itemProps, highlightedIndex, selectedItem } = params;
        const isHighlighted = highlightedIndex === index;
        const isSelected = selectedItem === suggestion.label;
       // console.log('params', params)

        return (
            <MenuItem
                {...itemProps}
                key={index}
                selected={isHighlighted}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400,
                }}
            >
                {suggestion.display_name}

                {suggestion.class}
            </MenuItem>
        );
    }


    getSuggestions(inputValue) {
        let count = 0;

        return this.state.results.filter(suggestion => {
            const keep =
                (!inputValue || suggestion.display_name.toLowerCase().includes(inputValue.toLowerCase())) &&
                count < 5;

            if (keep) {
                count += 1;
            }

            return keep;
        });
    }

    onSelect(selectedItem, selectedObject) {
        var item = this.state.results.filter(i => {
            return i.display_name == selectedItem
        })
        if (item.length > 0) {
            if (this.props.disablePolygon) {
               // console.log('item', item[0])
                var r = item[0]
                r.geojson = {
                    coordinates: [parseFloat(r.lon), parseFloat(r.lat)],
                    type: "Point"
                }
                this.props.onSelectGeoshape(r)
            } else {
               // console.log('item', item[0])
                this.props.onSelectGeoshape(item[0])
            }

        }
    }

    render() {
        const { results, isOpen, highlightedIndex, selectedItem } = this.state
        var that = this;
        return (

            <Paper style={{ maxWidth: '60%', width: '60%', top: 10 }}>
                <Downshift onSelect={this.onSelect.bind(this)}>
                    {({ getInputProps, getItemProps, isOpen, inputValue, selectedItem, highlightedIndex }) => (
                        <div className={styles.container}>
                            {this.renderInput({
                                fullWidth: true,

                                InputProps: {
                                    ...getInputProps({
                                        onChange(e) {
                                            that.setState({ search: e.target.value })
                                            /* if (e.key === 'Enter' && highlightedIndex === null) {
                                              // console.log('enter', )
                                               this.setState({search:inputValue}, this.fetchNominatim(inputValue).then(res => {
                                                   this.setState({results:results.data})
                                               }))
                                               
                                             }*/
                                        }
                                    })
                                },
                            })}
                            {isOpen ? (
                                <Paper square>
                                    {results.map((suggestion, index) =>
                                        this.renderSuggestion({
                                            suggestion,
                                            index,
                                            itemProps: getItemProps({ item: suggestion.display_name }),
                                            highlightedIndex,
                                            selectedItem,
                                        }),
                                    )}
                                </Paper>
                            ) : null}
                        </div>
                    )}
                </Downshift>
            </Paper>
        );
    }



}




function mapStateToProps(state) {
    return {

    }
}

export default connect(mapStateToProps, {

})(Nominatim);