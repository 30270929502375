import { push } from 'react-router-redux';
import axios from 'axios';
import {
    FETCH_CHECKLISTS,
    FETCH_PAGE,
    INSERT_PAGE,
    UPDATE_PAGE,
    DELETE_PAGE,
    FETCH_TERMS
} from "../../actions/types";

import {ROOT_URL} from "../../config";

export function goToRoute(route) {
    return function (dispatch) {
        dispatch(push(route));
    }
}



