import React, { Component } from 'react';
import DossierEditForm from './forms/dossier_edit_form';
import {fetchDossier} from "./actions";
import {connect} from "react-redux";
import './style/dossier.css';

class DossierEdit extends Component {

    UNSAFE_componentWillMount() {
        this.props.fetchDossier(this.props.match.params.id);
    }

    render() {
        return (
            
                    <DossierEditForm />
            
          
        )
    }
}

export default connect(null, {fetchDossier})(DossierEdit);
