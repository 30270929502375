import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardContent, List, ListItem, ListItemText, Tooltip, Divider, IconButton } from '@material-ui/core';
import { ROOT_URL } from "../../config";
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import { Link } from 'react-router-dom';
import { Markdown } from 'react-showdown';
import Map from '../map/map_index'
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { goToRoute } from './actions'
import OfferForm from '../offerForm'
//import ReactGA from 'react-ga';
import PrintIcon from '@material-ui/icons/Print'
import pdf from '../../files/RiskCompass_Auslandssicherheit_Preisliste.pdf'
import { fetchPage } from '../page/actions'
const gaProperty = 'UA-130702159-1'


class Offer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pageName: null };

    }
    UNSAFE_componentWillMount() {
        //ReactGA.initialize(gaProperty);
        //ReactGA.pageview(window.location.pathname + window.location.search)

    }

    componentDidMount() {
        this.props.fetchPage("offer")
    }



    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate() {

    }
    handleListItemClick(pageName) {
        // console.log('handleListItemClick', pageName)
        this.props.goToRoute("/page/" + pageName)
        this.setState({ pageName })

    }



    render() {
        const { pageName } = this.state
        const { checklists, admin, editor, page, lang } = this.props

        // const contentSize = this.props.match.params.postName ? 12 : 9
        return <div>
            {page && page.type == "offer" &&
                <div>

                   
                   
                   
                    <Grid style={{ maxWidth: '100%', padding: '12px', paddingTop: 0, marginTop: '0px', marginLeft: '0px' }} container >
                        <Grid item lg={1} sm={0} xs={0} md={0} >
                        </Grid>

                       
                       
                        <Grid item lg={10} sm={12} xs={12} md={12} >

                      
                       
                            <Grid style={{ maxWidth: '100%', padding: '12px', paddingTop: 0, paddingBottom: 0, marginTop: '0px', marginLeft: '0px', }} container >
                        <Grid item lg={1} sm={0} xs={0} md={0} >
                        </Grid>
                        <Grid item lg={10} sm={12} xs={12} md={12} >
                            <CardContent style={{ marginTop: 0, marginBottom: 0, textAlign: 'center', paddingBottom: 10 }}>
                                <h1><b style={{ marginBottom: 0, fontSize: 32 }}>{page.title[lang]}</b>
                                    <IconButton className={"no-print"} style={{ paddingLeft: 20 }} onClick={window.print}>
                                        <PrintIcon />
                                    </IconButton>
                                </h1>

                            </CardContent>

                        </Grid>
                        <Grid item lg={1} sm={0} xs={0} md={0} >
                        </Grid>





                        <Grid style={{ maxWidth: '100%', padding: '12px', paddingTop: 0, marginTop: '0px', marginLeft: '0px' }} container >
                                {page.fields.filter((field)=>field.type == 'card').map((field) => {
                                    return (
                                        <Grid item lg={4} sm={12} xs={12} md={4} >
                                            <Card style={{}}>
                                                <CardContent style={{ marginTop: 0, marginBottom: 0, textAlign: 'center', paddingBottom: 10 }}>
                                                    <b style={{ fontSize: 18 }}>{field.title[lang]}</b>

                                                </CardContent>
                                                <Divider />
                                                <CardContent style={{ marginTop: 0, paddingTop: 0, }}>
                                                    <Markdown markup={field.markdown[lang]}></Markdown>
                                                    {admin &&
                                                        <Tooltip title="Feld bearbeiten">
                                                            <a style={{ marginLeft: 0 }} ><Link to={"/offer/edit"}><EditIcon /></Link></a>
                                                        </Tooltip>
                                                    }
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                    )
                                })}

                            </Grid>



                    </Grid>


                           
                          
                            
                        </Grid>

                        <Grid item lg={1} sm={0} xs={0} md={0} >
                        </Grid>

                        <Grid style={{ maxWidth: '100%', padding: '12px', paddingTop: 0, marginTop: '0px', marginLeft: '0px', }} container >
                        <Grid item lg={1} sm={0} xs={0} md={0} >
                        </Grid>
                        <Grid item lg={10} sm={12} xs={12} md={12} >
                            <Card style={{ marginLeft: 24, marginRight: 24, }}>
                                <CardContent style={{ marginTop: 0, marginBottom: 0, textAlign: 'center', paddingBottom: 10 }}>
                                    <b style={{ marginBottom: 0, fontSize: 18 }}>STARTER</b>
                                </CardContent>
                                <Divider />
                                <CardContent style={{ paddingTop: 0, marginTop: 0, paddingBottom: 0, marginBottom: 0 }} >
                                    <Grid style={{ maxWidth: '100%', paddingTop: 0, marginTop: '0px', marginLeft: '0px' }} container >
                                        <Grid item lg={6} sm={6} xs={6} md={6} style={{ hyphens: 'auto' }}>
                                            <p>Testen Sie die RiskCompass-Angebote gratis und unverbindlich mit dem RiskCompass-Starter-Paket. </p><p>Sie erhalten einen kostenfreien Zugang zu den Kurzzusammenfassungen aller 200 Länder-Sicherheits-Dossiers inkl. Risikolandkarten der zweisprachigen RiskCompass-Länderdatenbank (deutsch/englisch) sowie zur Oberfläche des Monitoringtools mit Ereignislandkarte und 24/7-Sicherheitsnachrichten.</p>


                                        </Grid>
                                        <Grid item lg={6} sm={6} xs={6} md={6} >
                                            <p>Aufbau, Struktur und Inhalte eines kompletten Länder- und Städte-Dossiers können Sie hier näher kennenlernen (Beispiel <Link to='/dossiers/7e2e9731-645a-4a8a-9237-a69b9c984969'>Albanien</Link>, Beispiel <Link to='/dossiers/0743cf81-ef86-4eaa-93e8-695570943982'>Tirana</Link>).</p>
                                            <p>Darüber hinaus erhalten Sie den <Link to={'/news/daily-brief'}>RiskCompass-Newsletter</Link> »Travel Risk Intelligence« mit wichtigen Nachrichten und Hintergrundinformationen: Bleiben Sie up to date - Woche für Woche!  </p>
                                        </Grid>

                                    </Grid>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'center', marginTop: 0, paddingTop: 0, marginBottom: 10 }}>

                                    <Link style={{ color: 'black' }} to="/register"> <Button variant="contained">Einfach hier anmelden!</Button></Link>
                                </CardActions>
                            </Card>
                            <Card style={{ marginLeft: 24, marginRight: 24, marginTop: 20 }}>
                                <CardContent>
                                    <OfferForm />
                                </CardContent>
                            </Card>

                        </Grid>
                        <Grid item lg={1} sm={0} xs={0} md={0} >
                        </Grid>

                    </Grid>

                   



                    </Grid>
                </div>
            }
        </div>

    }

}

function mapStateToProps(state) {


    return {
        lang: state.lang.lang,
        page: state.page.page,
        admin: state.auth.admin,
        editor: state.auth.editor
    }
}
const styles = {

}
export default withStyles(styles, { name: 'Offer' })(connect(mapStateToProps, {

    fetchPage: fetchPage,
    goToRoute: goToRoute,

})(Offer));


