import { SELECT_LANG } from "../../actions/types";

export function setLang(lang) {
    return function (dispatch) {

        localStorage.setItem('lang', lang);
        dispatch({ type: SELECT_LANG, data: lang });

    }
}

export function getLang() {
    return function (dispatch) {

        var lang = localStorage.getItem('lang', "de");

        if (!lang) {
            lang = "de"
        }
        dispatch({ type: SELECT_LANG, data: lang });

    }
}

