import {
    FETCH_RLS,
    FETCH_RL,
    UPDATE_RL,
    INSERT_RL,
    FETCH_TERMS
} from "../../actions/types";

import * as _ from "lodash";

const INITIAL_STATE = {
    checklists: [],
    terms:[]
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case FETCH_RLS:
            
            return {
                ...state,
                riskLevels: action.payload.data
            };
        case FETCH_TERMS:
        return {
            ...state,
            terms: action.payload.data
        };
        case FETCH_RL:
            var page = action.payload.data
            if (page.length > 0) {
                page = page[0]
            }
            return {
                ...state,
                riskLevel: page
            };


        default:
            return state;
    }
}