import React from 'react';
import ReactDOM from 'react-dom';
import { Card, CardActions, CardContent } from '@material-ui/core';


import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { ROOT_URL } from "../../config";

import Hidden from '@material-ui/core/Hidden';
import {
    insertArticle, fetchDocument
} from "./actions";
import TextField from '@material-ui/core/TextField/TextField';
import Chip from '@material-ui/core/Chip/Chip'
/*import Editor from '@toast-ui/editor'
import '@toast-ui/editor/dist/toastui-editor.css'
////import 'tui-editor/dist/tui-editor-contents.css
import 'codemirror/lib/codemirror.css'
import 'highlight.js/styles/github.css'*/
import EditTagList from '../editTagList/editTagList_index'
import SelectMapIcons from '../selectMapIcons/selectMapIcons_index'
import ShapeEditor from '../shapeEditor/index'


class DocumentEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editor: null
        }
    }
    /*
        componentWillReceiveProps(nextProps) {
            if (nextProps.input.value != this.props.input.value) {
                this.state.editor.setMarkdown(nextProps.input.value)
            }
    
        }*/

    UNSAFE_componentWillMount() {
        // console.log('before', this.props.match.params)
        if (this.props.match.params.id) {
            // console.log('before')
            this.props.fetchDocument(this.props.match.params.id)
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        // console.log('nextProps', nextProps)
        if (nextProps.document != this.props.document) {
            this.state.editor.setMarkdown(nextProps.document.content)
            // console.log('featureCollection', nextProps.document.featureCollection)
        }
    }

    componentDidMount() {
        /* let editor = new Editor({
             el: document.querySelector('#editSection'),
             initialEditType: 'wysiwyg',
             previewStyle: 'tab',
             height: '40vh',//window.innerHeight - 20,
             events: {
                 change: this.onChange
             },
             initialValue: this.props.document ? this.props.document.content : ""
         });
 
         this.setState({ editor });*/
    }

    onChange = (event) => {
        //this.props.change(this.props.meta.form, "markdown", this.state.editor.getValue())
    }

    render() {

        const { document } = this.props

        return (
            <div>
                <Grid style={{ maxWidth: '100%', padding: '12px', marginTop: '12px', marginLeft: '0px' }} container >
                    <Grid item lg={4} >

                        <Card>
                            {document &&
                                <TextField fullWidth defaultValue={document.title}></TextField>
                            }
                            <div id='editSection' />

                        </Card>
                        <Card>
                            <EditTagList style={{ width: '100%', marginTop: '10px', marginRight: '10px' }} />
                        </Card>
                        <SelectMapIcons />
                    </Grid>
                    <Grid item lg={8} >
                        {document &&
                            <ShapeEditor shapes={document.featureCollection} />
                        }
                    </Grid>
                </Grid>

            </div>
        )

    }
}

function mapStateToProps(state) {

    // console.log("state", state)
    return {
        document: state.documentEdit.document
    }
}
const styles = {}
export default withStyles(styles, { name: 'DocumentEditor' })(connect(mapStateToProps, {
    insertArticle, fetchDocument
})(DocumentEditor));