import { AUTH_ERROR, MANAGER_USER, AUTHENTICATED_USER,STARTER_USER, UNAUTHENTICATED_USER, ADMIN_USER,  EDITOR_USER, SUBSCRIBER_USER, COUNTRY_SUBSCRIBER_USER, DACH_SUBSCRIBER_USER } from "../../actions/types";

export default function (state = {}, action) {
    switch (action.type) {
        case AUTHENTICATED_USER:
            return { ...state, authenticated: true, admin: false, editor:false, manager:false }
        case STARTER_USER:
            return { ...state, authenticated: true, admin: false, editor:false, starter:true, manager:false }
        case UNAUTHENTICATED_USER:
            return { ...state, authenticated: false, admin: false, editor:false, manager:false }
        case ADMIN_USER:
            return { ...state, authenticated: true, admin: true, editor:true, manager:false }
        case MANAGER_USER:
            return { ...state, authenticated: true, admin: false, editor:true, manager:true,customer:action.customer }
        case EDITOR_USER:
            return { ...state, authenticated: true, admin: false, editor:true, manager:false }
        case SUBSCRIBER_USER:
            return { ...state, authenticated: true, admin: false, editor:false, manager:false }
        case COUNTRY_SUBSCRIBER_USER:
            return { ...state, authenticated: true, admin: false, editor:false, manager:false }
        case DACH_SUBSCRIBER_USER:
            return { ...state, authenticated: true, admin: false, editor:false, manager:false }
        case AUTH_ERROR:
            return { ...state, error: action.payload }
    }

    return state;
}