import React, { Component } from 'react'
import ReactDOM from "react-dom";
import { connect } from 'react-redux'
import { render, findDOMNode } from 'react-dom';
import ReactMapboxGl, { GeoJSONLayer, Layer, Feature, ZoomControl, RotationControl, Cluster, Marker } from "react-mapbox-gl";
import DrawControl from 'react-mapbox-gl-draw';
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { fetchLayer, fetchLayers, fetchLayerList, fetchMap } from './actions';
import LayerIcon from '@material-ui/icons/Layers';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dropzone from 'react-dropzone'
import axios from 'axios';
import { ROOT_URL } from "../../config";

import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';


import {
    Paper, Grid, Popover, Snackbar, Button, MenuItem, Select, Input, InputLabel, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography,
    AppBar,
    Toolbar
} from '@material-ui/core';

import Nominatim from '../nominatim/nominatim_index'

import GeoshapeTable from '../geoshapeTable'
import query_overpass from 'query-overpass'


import * as _ from "lodash";
import { centerOfMass, featureCollection, bbox, lineString } from '@turf/turf';

//import { TreeList } from 'react-treeview-mui/src';
//import TreeList from '../treeList/treeList_index'


const MAPBOX_TOKEN = ""//process.env.MapboxAccessToken || 'pk.eyJ1IjoibW9yaXR6a25lY2h0IiwiYSI6ImNqYXZyNzdrMTExazcyd24waDI2a2hydWUifQ.5yuV5AFnTNMAZBXwI3MocA'; // eslint-disable-line

const Mapbox = ReactMapboxGl({
    //accessToken: MAPBOX_TOKEN
});




const styles = {
    clusterMarker: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: 'orange',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        border: '2px solid #56C498',
        cursor: 'pointer'
    },
    marker: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: 'green',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid #C9C9C9'
    }
};

const symbolLayout = {
    'text-field': '{name}',
    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
    'text-offset': [0, 0.6],
    'text-anchor': 'top'
};
const symbolPaint = {
    'text-color': 'black'
};

const circleLayout = { visibility: 'visible' };
const circlePaint = {
    'circle-color': 'red'
};

const polygonPaint = {
    'fill-color': 'yellow',
    'fill-opacity': 0.7
};

const polygonPaint2 = {
    'fill-color': '#26784A',
    'fill-opacity': 0.7
};

const multiPolygonPaint = {
    'fill-color': '#3bb2d0',
    'fill-opacity': 0.5
};

const layerPaint = {
    'heatmap-weight': {
        property: 'scalerank',
        type: 'exponential',
        stops: [
            [0, 0],
            [5, 2]
        ]
    },
    // Increase the heatmap color weight weight by zoom level
    // heatmap-ntensity is a multiplier on top of heatmap-weight
    'heatmap-intensity': {
        stops: [
            [0, 0],
            [5, 1.2]
        ]
    },
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparancy color
    // to create a blur-like effect.
    'heatmap-color': [
        'interpolate',
        ['linear'],
        ['heatmap-density'],
        0, 'rgba(33,102,172,0)',
        0.25, 'rgb(103,169,207)',
        0.5, 'rgb(209,229,240)',
        0.8, 'rgb(253,219,199)',
        1, 'rgb(239,138,98)',
        2, 'rgb(178,24,43)'
    ],
    // Adjust the heatmap radius by zoom level
    'heatmap-radius': {
        stops: [
            [0, 1],
            [5, 50]
        ]
    }
}

var PointPaint = {
    "id": "earthquakes-point",
    "type": "circle",
    "source": "earthquakes",
    "minzoom": 7,
    "paint": {
        // Size circle radius by earthquake magnitude and zoom level
        "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            7, [
                "interpolate",
                ["linear"],
                ["get", "mag"],
                1, 1,
                6, 4
            ],
            16, [
                "interpolate",
                ["linear"],
                ["get", "mag"],
                1, 5,
                6, 50
            ]
        ],
        // Color circle by earthquake magnitude
        "circle-color": [
            "interpolate",
            ["linear"],
            ["get", "mag"],
            1, "rgba(33,102,172,0)",
            2, "rgb(103,169,207)",
            3, "rgb(209,229,240)",
            4, "rgb(253,219,199)",
            5, "rgb(239,138,98)",
            6, "rgb(178,24,43)"
        ],
        "circle-stroke-color": "white",
        "circle-stroke-width": 1,
        // Transition from heatmap to circle layer by zoom level
        "circle-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            7, 0,
            8, 1
        ]
    }
}


var simple =  "https://api.maptiler.com/maps/fef74ea1-76e5-440f-b0d9-86450b327741/style.json?key=MnSoswF3TIIirAiE6mqv"
        

//http://tiles.vespertine.it/styles/positron/style.json'
/*{
    "version": 8,
    "sources": {
        "osm": {
            "type": "vector",
            "tiles": ["http://tiles.vespertine.it/data/v3/{z}/{x}/{y}.pbf"]
        }
    },
    "layers": [
        {
            "id": "background",
            "type": "background",
            "paint": {
                "background-color": "#41afa5"
            }
        }, {
            "id": "water",
            "type": "fill",
            "source": "osm",
            "source-layer": "water",
            "filter": ["==", "$type", "Polygon"],
            "paint": {
                "fill-color": "#3887be"
            }
        }
    ]
};*/

class ShapeEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            layerMenuOpen: false,
            checked: [0],
            featureCollection: null,
            open: false,
            layers: [],
            zoomState:[0],
            layerTitle: "",
            riskLevel: 0,
            overpassMode: 'bbox',
            adminLevel: 0,
            openUploadModal: false,
            files: [],
            selectedFeatureTitle: null,
            snackOpen: false
        }
        this.button = null
        this.geoshapeTable = null

    }
/*
    handleToggle = value => () => {
       // console.log('handleToggle', value.id)
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value.id);
       // console.log('currentIndex', currentIndex)
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({
            checked: newChecked,
        }, this.getLayers)
    };*/

    fetchLayer(ids) {
        const url = `${ROOT_URL}/Layers/featureCollection?ids=${encodeURIComponent(JSON.stringify(ids))}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        })
    }




    componentDidMount() {

       // console.log('this.props', this.props)
        if (this.props.layerIds && this.props.layerIds.length) {
           // console.log('hellow')
            //this.props.fetchMap("10")

            this.setState({ layerIds: this.props.layerIds })

            this.fetchLayer(this.props.layerIds).then((res) => {

               // console.log('res', res.data)
                this.setState({ layers: res.data })

            })



        }
        if (this.props.shapes) {
           // console.log('has shapes', this.props.shapes)
            this.setState({ 'featureCollection': this.props.shapes })


        }
        // this.props.fetchLayerList()

        //        this.props.fetchLayer("16")




    }

    saveLayer() {

        const url = `${ROOT_URL}/Layers/saveFeatureCollection`
        const payload = {
            title: this.state.layerTitle,
            featureCollection: this.drawControl.draw.getAll()
        }
        return axios.post(url, payload, {
            headers: { authorization: localStorage.getItem('token') }
        })
    }

    handleDialogClickOpen = () => {
        this.setState({ open: true });
    };

    fitBounds(featureCollection) {
       // console.log('should fit bounds')
        var line = featureCollection.features.map(f => {
            return f.geometry.coordinates;
        })
        if (line && line.length > 1) {
            try {
                var box = bbox(lineString(line))
                const bounds = [ [box[0], box[1]], [box[2], box[3]] ]
           
                this.map.fitBounds(bounds,{
                    padding: 20
                  })
            } catch(e) {
                console.log('e', e)
            }
            
           
        }
        if (featureCollection.features.length == 1 ) {
            const f = featureCollection.features[0]
           // console.log('should fly to')
            this.map.flyTo({ center: f.geometry.coordinates, zoom:12})
        }
    }

    handleDialogClose = () => {
       // console.log('layerTitle', this.state.layerTitle)
        this.setState({ open: false });
       // console.log('getAll', this.drawControl.draw.getAll())
        this.saveLayer().then(res => {
           // console.log('res', res)
        }).catch(err => {
           // console.log('err', err)
        })

    };

    handleDialogCancel = () => {
        this.setState({ open: false, layerTitle: "" });
    };


    renderDrawControl() {
        if (this.state.showDrawControl) {
            //console.log('this.mapInstance', this.mapInstance)
            //return  <DrawControl map={this.mapInstance}/>
        }

    }


    renderMarker() {

    }

    onDrop(acceptedFiles, rejectedFiles) {
       
        const reader = new FileReader();
        reader.onload = () => {
            const str = reader.result;
            console.log('str', str)

          
        };
        reader.onabort = () =>// console.log('file reading was aborted');
        reader.onerror = () =>// console.log('file reading has failed');

        reader.readAsText(acceptedFiles[0]);
    }

    clusterMarker = (coordinates, pointCount, getLeaves) => {

        return (
            <Marker key={coordinates + pointCount} coordinates={coordinates} style={styles.clusterMarker}>
                {pointCount}
            </Marker>
        )
    }




    /*

    


              

                {layer && layer.features &&
                    <Layer
                        type="heatmap"
                        paint={layerPaint}
                    >
                        {
                            layer.features.map((el, index) => {
                               // console.log('el', el)
                                return <Feature
                                    key={index}
                                    coordinates={el.geometry.coordinates}
                                    properties={el.properties}
                                />
                            })
                        }
                    </Layer>
                }


                


    */

    /*
                  {

                layer &&
                <GeoJSONLayer
                    data={layer}
                    circleLayout={circleLayout}
                    circlePaint={circlePaint}
                   // circleOnClick={this.onClickCircle}
                    symbolLayout={symbolLayout}
                    symbolPaint={symbolPaint}
                />
                
                }
                <DrawControl />


                <Layer
                    type="heatmap"
                    paint={layerPaint}
                >
                    {
                        falls.features.map((el, index) => {
                           // console.log('el', el)
                            return <Feature
                                key={index}
                                coordinates={el.geometry.coordinates}
                                properties={el.properties}
                            />
                        })
                    }
                </Layer>

                 <Feature coordinates={[falls.features[0].polygonpoints]} />

    */

    markerChanged() {
        if (this.props.markerChanged) {
            const draw = this.drawControl.draw
            var ids = draw.getSelectedIds()

            var all = draw.getAll()
            var newRows = all.features.filter(f => {
                return ids.indexOf(f.id) != -1
            })
            this.props.markerChanged(newRows[0])
        }
    }


    onMapLoaded(map) {
       // console.log('map', map)
        this.map = map
        var that = this
        const draw = this.drawControl.draw;

        ['gl-draw-polygon-fill-active.hot',
            'gl-draw-polygon-fill-active.cold',
            'gl-draw-polygon-fill-inactive.hot',
            'gl-draw-polygon-fill-inactive.cold'].forEach(layer => {
               // console.log('layer', layer)
                /*
                this.map.setPaintProperty(layer, 'fill-color', [
                    'match',
                    ['get', 'user_riskLevel'],
                    0, '',
                    1, '#00ff00',
                    2, '#ffff00',
                    3, '#ffa500',
                    4, '#ff6600',
                    5, '#ff0000',
                                         '#7d9cce'
                ]);
                this.map.setPaintProperty(layer, 'fill-opacity', 0.6)*/
            })



        map.on('click', (e) => {
            var features = map.queryRenderedFeatures(e.point)
            if (features.length > 0) {
               // console.log('features', features.length > 0 ? features[0] : "no features found")
            }
        })

        map.on('draw.create', function (e) {
           // console.log("draw.create", e.features);
            if (e.features.length > 0) {
               // console.log('should be red')
                var prop = draw.setFeatureProperty(e.features[0].id, "riskLevel", 5)
                var added = draw.get(e.features[0].id)
                //console.log('that.geoshapeTable', that.geoshapeTable)
                that.geoshapeTable.addRows([added])
            }
        });
        map.on('draw.update', function (e) {
           // console.log('draw.update', e.features);

        });
        map.on('draw.delete', function (e) {
           // console.log('draw.delete', e.features);
            var ids = e.features.map(f => { return f.id })

            var test = that.geoshapeTable.deleteRows(ids)
           // console.log('after call', test)
        });

        map.on('draw.selectionchange', function (e) {
           // console.log('draw.selectionchange', e.features[0]);


            that.geoshapeTable.setSelection(draw.getSelectedIds())

            var features = map.queryRenderedFeatures(e.point, {
                layers: ['gl-draw-polygon-fill-inactive.cold', 'gl-draw-line-inactive.cold']
            });
           // console.log(features);


            //console.log('setPaintProperty', map.setPaintProperty)
            //map.setPaintProperty(e.features[0].id, 'fill-color', '#000000')
            // map.setPaintProperty('gl-draw-active-polygon.hot', 'fill-color', '#000000');
        });



        var that = this
        map.on('contextmenu', function (e) {
            var features = map.queryRenderedFeatures(e.point)
           // console.log('contextmenu', JSON.stringify(features[0].layer, !1, 2));


            //const layers = map.getStyle().layers
            //console.log('layers', layers)
            //console.log('getAll', draw.getAll());
            /*
            map.setPaintProperty('gl-draw-polygon-fill-active.hot', 'fill-color', '#000000');
            map.setPaintProperty('gl-draw-polygon-fill-active.hot', 'fill-outline-color', '#000000');
            map.setPaintProperty('gl-draw-polygon-fill-active.cold', 'fill-color', '#000000');
            map.setPaintProperty('gl-draw-polygon-fill-active.cold', 'fill-outline-color', '#000000');
            map.setPaintProperty('gl-draw-polygon-fill-inactive.hot', 'fill-color', '#000000');
            map.setPaintProperty('gl-draw-polygon-fill-inactive.hot', 'fill-outline-color', '#000000');
            map.setPaintProperty('gl-draw-polygon-fill-inactive.cold', 'fill-color', '#000000');
            map.setPaintProperty('gl-draw-polygon-fill-inactive.cold', 'fill-outline-color', '#000000');
            that.setState({
                imageBase64: map.getCanvas().toDataURL()
            })
           // console.log('e', e)
            e.originalEvent.stopPropagation()

            //console.log('map.getCanvas().toDataURL()', )*/
            /*let data = map.getCanvas().toDataURL('image/png', 1.0);
            //let blob = this.imageGenerationService._dataURItoBlob(data);
            map.getCanvas().toBlob(function(blob){
                FileSaver.saveAs(blob, 'aa.png');
            }, 'image/png', 1);
            */
        });

        if (this.state.featureCollection) {
           // console.log('should draw')
            var features = this.state.featureCollection.features.map(f => {
                f.geometry.coordinates[0] = parseFloat(f.geometry.coordinates[0])
                f.geometry.coordinates[1] = parseFloat(f.geometry.coordinates[1])
                f.properties.title = f.properties.name || f.properties.title
                f.properties.riskLevel = 0
                f.properties.user_riskLevel = 0

                delete f.properties.name
                return f
            })
            var featureCollection = { type: "FeatureCollection", features: features }
            var ids = draw.add(featureCollection)
            var all = draw.getAll()
           // console.log('all', all, 'ids', ids)
            var newRows = all.features.filter(f => {
                return ids.indexOf(f.id) != -1
            })
            this.geoshapeTable.addRows(newRows)

           
                this.fitBounds(featureCollection)
            

        }


    }

    handleClickButton = () => {
        this.setState({
            layerMenuOpen: true,
            anchorEl: findDOMNode(this.button),
        });
    };

    componentWillUnmount() {
        //this.setState({layers:null})
        const draw = this.drawControl.draw
        this.map.removeControl(draw)
       // this.map = null
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
       
        /*  if (this.props.layerList.length == 0 && nextProps.layerList.length > 0) // Check if it's a new user, you can also use some unique, like the ID
          {
  
              if (this.props.layerIds && this.props.layerIds.length) {
                 // console.log('bla')
                  this.props.layerIds.forEach((value) => {
                      //this.handleToggle({id:value})
                  })
              } else {
                 // console.log('layerIds not in props', this.props)
  
              }
  
          }*/
         
        if (nextProps.shapes) {
            if (nextProps.shapes.features && nextProps.shapes.features.length > 0) {
                if (this.state.featureCollection && this.state.featureCollection.features && this.state.featureCollection.features.length > 0 ) {
                    if ( JSON.stringify(this.state.featureCollection.features[0]) == JSON.stringify(nextProps.shapes.features[0])) {
                       
                        return
                    }
                }
            }
           
            this.setState({ 'featureCollection': nextProps.shapes })
            if (this.map) {
                
                this.fitBounds(nextProps.shapes)
            }
           
            
        }
    }



    handleClose = () => {
        this.setState({
            layerMenuOpen: false,
        });
    };

    renderLayers() {

        const { mapStyle, layerMenuOpen, anchorEl } = this.state
        const { layer, loading, layerList } = this.props

        return (
            <div>

            </div>
        )
    }

    /*

      <List style={{ width: 300 }}>
                        {layerList.length > 0 && layerList.map(value => (
                            <ListItem
                                key={value.id}
                                dense
                                button
                                onClick={this.handleToggle(value)}

                            >
                                <Checkbox
                                    checked={this.state.checked.indexOf(value.id) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                />
                                <ListItemText primary={value.title} />

                            </ListItem>
                        ))}
                    </List>




    */


    renderLayerMenu() {

        const { mapStyle, layerMenuOpen, anchorEl, checked } = this.state
        const { layer, loading, layerList } = this.props

        return (
            <div>
                <Button variant="fab" color="primary" mini aria-label="Layers" style={{ zIndex: 1800, top: 150, left: 5 }} onClick={this.handleClickButton}
                    ref={node => {
                        this.button = node;
                    }}>
                    <LayerIcon />
                </Button>
                <Popover
                    open={layerMenuOpen}
                    anchorEl={anchorEl}
                    anchorReference='anchorEl'
                    anchorPosition={{ top: 60, left: 20 }}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >




                </Popover>
            </div>
        )
    }

    renderLayerMenu2() {

        const { mapStyle, layerMenuOpen, anchorEl, checked } = this.state
        const { layer, loading, layerList } = this.props

        return (
            <div>



            </div>
        )

    }


    handleColorChange = event => {
        this.setState({ "riskLevel": event.target.value });
        const riskLevel = event.target.value
        var color = '#00ffff'
        switch (riskLevel) {
            case 0:
                color = '#00ffff'
                break;
            case 1:
                color = '#00ff00'
                break;
            case 2:
                color = '#ffff00'
                break;
            case 3:
                color = '#ffa500'
                break;
            case 4:
                color = '#ff6600'
                break;
            case 5:
                color = '#ff0000'
                break;
        }

        [
            'gl-draw-polygon-fill-active.hot',
            'gl-draw-polygon-fill-active.cold',
            'gl-draw-polygon-fill-inactive.hot',
            'gl-draw-polygon-fill-inactive.cold',
            /*'gl-draw-polygon-and-line-vertex-stroke-inactive.hot', 
            'gl-draw-polygon-and-line-vertex-inactive.hot'*/
        ].forEach(layer => {
            this.map.setPaintProperty(layer, 'fill-color', [
                'match',
                ['get', 'user_riskLevel'],
                0, '#00ffff',
                1, '#00ff00',
                2, '#ffff00',
                3, '#ffa500',
                4, '#ff6600',
                5, '#ff0000',
                /* other */ '#000000'
            ]);
            this.map.setPaintProperty(layer, 'fill-opacity', [
                'match',
                ['get', 'user_riskLevel'],
                0, 0.0,
                1, 0.5,
                2, 0.5,
                3, 0.5,
                4, 0.5,
                5, 0.5,
                0.5
            ]);
            /*
            this.map.setPaintProperty(layer, 'fill-outline-color', [
                'match',
                ['get', 'riskLevel'],
                0,'#00ffff',
                1,'#00ff00',
                2,'#ffff00',
                3,'#ffa500',
                4,'#ff6600',
                5,'#ff0000',
                '#00ffff'
            ]);*/

            // this.map.setPaintProperty(layer, 'circle-color', color);

            //this.map.setPaintProperty(layer, 'fill-outline-color', color);
            //this.map.setPaintProperty(layer, 'fill-opacity', 0.6)
        })

    }




    onSelectGeoshape(shape) {
       // console.log(shape)

        var shapes = []
        var geom = shape.geojson;

        if (geom.type === 'MultiPolygon') {
            for (var i = 0; i < geom.coordinates.length; i++) {
                var polygon = {
                    'type': 'Polygon',
                    'coordinates': geom.coordinates[i],

                };
                shapes.push(polygon)
               // console.log(JSON.stringify(polygon));
            }
        } else {
            shapes.push(shape.geojson)
        }
        shapes.forEach((sh, index) => {
           // console.log('sh', sh)

            var feature = {
                id: shape.place_id + '_' + index,
                riskLevel: 0,
                type: 'Feature',
                properties: {
                    title: shape.namedetails.name || shape.display_name,
                    name: shape.namedetails.name,
                    riskLevel: 0
                },
                geometry: sh
            };

           // console.log('feature', feature)

            var added = this.drawControl.draw.add(feature)
           // console.log('added', added)
            this.drawControl.draw.changeMode('simple_select', {
                featureIds: added
            })
           // console.log('feature.geometry.coordinates', feature.geometry.coordinates)
          /*  this.map.flyTo({
                center: feature.geometry.coordinates,
                zoom: 12,
                speed: 7,
               
            })*/


           // console.log('get', this.drawControl.draw.get(added[0]));
            this.geoshapeTable.addRows([this.drawControl.draw.get(added[0])])
            //this.drawControl.draw.set()
            //console.log('prop', prop)
        })

    }

    //{this.renderLayerMenu()}

    //preserveDrawingBuffer={true}
    onSelectionChange(features) {
       // console.log('features', features)
        const draw = this.drawControl.draw
        if (features.length > 0) {
            var feature = draw.get(features[0])
            this.setState({ selectedFeatureTitle: feature.properties.name })
           // console.log('feature', feature)
        } else {
            this.setState({ selectedFeatureTitle: null })
        }
        draw.changeMode('simple_select', {
            featureIds: features
        })
    }

    rowChanges = rowChanges => {
       // console.log('rowChanges', JSON.stringify(rowChanges, !1, 2))
        const draw = this.drawControl.draw
        var keys = Object.keys(rowChanges)
       // console.log("keys", keys)
        if (keys.length > 0 && rowChanges[keys[0]].hasOwnProperty("riskLevel")) {
           // console.log('keys[0].riskLevel', rowChanges[keys[0]].riskLevel)
            draw.setFeatureProperty(keys[0], "riskLevel", parseInt(rowChanges[keys[0]].riskLevel))
           // console.log(Object.keys(draw.getAll()))
            draw.changeMode('simple_select', {
                featureIds: [keys[0]]
            })
            draw.changeMode('simple_select')
           // console.log('res', draw.get(keys[0]))
        }


    }
    /*
      <Layer type="fill" paint={polygonPaint}>
                                        <Feature coordinates={[falls.features[0].polygonpoints]} />
                                    </Layer>
    
    
                                    <Layer type="fill" paint={polygonPaint2}>
                                        <Feature coordinates={[falls.features[1].polygonpoints]} />
    
                                    </Layer>
    
    */



    overpass() {
        const level = this.state.adminLevel
        const bounds = this.map.getBounds() 
        const draw = this.drawControl.draw
        var payload = null
        if (this.state.overpassMode == 'bbox') {
            payload = `[out:json][timeout:25];
            (
              relation["admin_level"="${level}"](${bounds._sw.lat}, ${bounds._sw.lng},${bounds._ne.lat}, ${bounds._ne.lng});
            );
            out body;
            >;
            out skel qt;`
        } else {
            payload = `[out:json][timeout:120];
            area[name="${this.state.selectedFeatureTitle}"]->.searchArea;
            (
            relation["admin_level"="${level}"](area.searchArea);
            );
            out body;
             >;
            out skel qt;`
        }
       // console.log('overpass', payload)
        query_overpass(payload, (err, res) => {
           // console.log('err', err)
           // console.log('res', JSON.stringify(res))
            var shapes = []
            if (res && res.features && res.features.length > 0) {
                res.features.forEach(f => {
                    f.properties.title = f.properties.tags.name || f.properties.tags.name || f.properties.relations && f.properties.relations.lengt > 0 && f.properties.relations[0].hasOwnProperty('reltags') && f.properties.relations[0].reltags.name
                    f.properties.riskLevel = 0
                    if (f.geometry.type === 'MultiPolygon') {

                        for (var i = 0; i < f.geometry.coordinates.length; i++) {
                            var shape = _.clone(f)

                            var coords = f.geometry.coordinates[i]
                            var first = f.geometry.coordinates[i][0]
                            var last = f.geometry.coordinates[i][f.geometry.coordinates[i].length - 1]
                           // console.log('first', first)
                           // console.log('last', last)
                            if (first != last) {
                                coords.push(first)
                            }

                            shape.geometry = {
                                'type': 'Polygon',
                                'coordinates': coords,

                            };
                            shapes.push(shape)

                        }
                    } else {
                        shapes.push(f)
                    }

                })
            }

            if (shapes.length == 0) {
                this.setState({ snackOpen: true, snackMessage: 'Keine Ergebnisse gefunden' })
            } else if (err) {
                this.setState({ snackOpen: true, snackMessage: err.message + " " + err.statusCode })
            } else {
                var ids = draw.add({ type: "FeatureCollection", features: shapes })
                var all = draw.getAll()
                var newRows = all.features.filter(f => {
                    return ids.indexOf(f.id) != -1
                })
                this.geoshapeTable.addRows(newRows)
            }

        })
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };


    render() {

        const { mapStyle, layerMenuOpen, anchorEl, layers,zoomState, imageBase64, adminLevel, layerTitle, overpassMode, snackOpen, snackMessage } = this.state
        const { layer, loading, layerList, hideList } = this.props

        return (
            <div>

                <Grid container >
                    <Grid style={{ marginLeft: '10px' }} item lg={hideList ? 12 : 6}>
                        <div style={{
                            display: "flex",
                            fallbacks: [
                                { display: '-webkit-box' },
                                { display: '-moz-box' },
                                { display: '-ms-flexbox' },
                                { display: '-webkit-flex' }
                              ],
                            flexDirection: "row",
                            msFlexDirection:'row',
                            flexWrap: "nowrap",
                            justifyContent: "space-between"
                        }}>
                            <Nominatim style={{ float: 'left', width: '80%' }} onSelectGeoshape={this.onSelectGeoshape.bind(this)} disablePolygon={this.props.disablePolygon} />
                            {this.props.markerChanged &&
                                <IconButton style={{ float: 'right' }} onClick={this.markerChanged.bind(this)}> <SaveIcon /></IconButton>
                            }
                        </div>
                        <Paper style={{ marginTop: '10px' }}>

                            <Mapbox
                                zoom={zoomState || [0]}
                                style={simple}
                                onStyleLoad={this.onMapLoaded.bind(this)}
                                containerStyle={{
                                    height: "calc( 100vh - 164px )",
                                    width: "100%"
                                }}>

                                {layers && layers.length > 0 &&
                                    layers.map((layer, index) => (
                                        <Cluster key={index} ClusterMarkerFactory={this.clusterMarker}>

                                            {layer.features && layer.features.map((feature, index) => (
                                                <Marker
                                                    key={index}
                                                    style={styles.marker}
                                                    coordinates={feature.geometry.coordinates}
                                                    data-feature={feature}
                                                >
                                                    <div title={feature.properties.name}>
                                                        {feature.properties.name}
                                                    </div>
                                                </Marker>
                                            ))}
                                        </Cluster>
                                    ))
                                }




                                < ZoomControl
                                    position="top-left" />
                                <RotationControl
                                    position="top-left" />

                                <DrawControl
                                    userProperties={true}
                                    position="top-right"
                                    ref={(drawControl) => {
                                        this.drawControl = drawControl;


                                    }}
                                />




                            </Mapbox></Paper>
                    </Grid>

                    <Grid item lg={hideList ? 0 : 5}>

                        <Select
                            value={overpassMode}
                            onChange={this.handleChange.bind(this)}
                            input={<Input name="overpassMode" id="age-simple" />}
                            autoWidth
                        >

                            <MenuItem value={"inNominatim"}>Suche in Nominatim</MenuItem>
                            <MenuItem value={'bbox'}>Kartenausschnitt</MenuItem>


                        </Select>
                        <Select
                            value={adminLevel}
                            onChange={this.handleChange.bind(this)}
                            input={<Input name="adminLevel" id="age-simple" />}
                            autoWidth
                        >

                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>

                        </Select>
                        <Button onClick={this.overpass.bind(this)}>Admin Level</Button>


                        <AppBar position="static">
                            <Toolbar>

                                <Dropzone onDrop={this.onDrop.bind(this)} style={{ maxHeight: '164px' }}>
                                    <CloudUploadIcon />
                                </Dropzone>
                                <Typography variant="title" color="inherit">
                                    Layer
                                </Typography>
                                <IconButton onClick={this.handleDialogClickOpen.bind(this)}>
                                    <SaveIcon style={{ color: 'white' }} />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Dialog
                            open={this.state.open}
                            onClose={this.handleClose}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Geben Sie den Titel des Layers ein.
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    onChange={(e) => {
                                       // console.log('e', e)
                                        this.setState({ layerTitle: e.target.value })
                                    }}
                                    value={layerTitle}
                                    label="Name"
                                    type="text"
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleDialogCancel} color="primary">
                                    Abbrechen
                                 </Button>
                                <Button onClick={this.handleDialogClose} color="primary">
                                    Speichern
                              </Button>
                            </DialogActions>
                        </Dialog>
                        <GeoshapeTable
                            onSelectionChange={this.onSelectionChange.bind(this)}
                            rowChanges={this.rowChanges.bind(this)}
                            onRef={(geoshapeTable) => {
                               // console.log('ref');
                                this.geoshapeTable = geoshapeTable;
                            }} />

                    </Grid>

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={snackOpen}
                        onClose={this.handleSnackClose}

                        message={<span id="message-id">{snackMessage}</span>}
                    />
                </Grid>


            </div>
        );
    }
}

function mapStateToProps(state) {
    /* let filtered = _.isEmpty(state.posts.postsGridData.gridFilters) ? [] : state.posts.postsGridData.gridFilters;
     let sorted = _.isEmpty(state.posts.postsGridData.gridSortCriteria) ? [] : state.posts.postsGridData.gridSortCriteria;
 */

   // console.log('state.map', state.map)



    return {
        //layerList: state.map.layerList,
        //layers: state.map.layers,
        //map: state.map.map
    }
}

export default connect(mapStateToProps, {
    fetchLayer: fetchLayer,
    fetchLayers: fetchLayers,
    fetchLayerList: fetchLayerList,
    fetchMap: fetchMap,
})(ShapeEditor);