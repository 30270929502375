import {
    SEND_WHITEPAPER_CONTACT
} from "../../actions/types";

const INITIAL_STATE = {
    sent:false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case SEND_WHITEPAPER_CONTACT:
            return {
                ...state,
                sent: action.payload.data
            };

        default:
            return state;
    }
}