import React, { Component } from 'react';
import '../../containers/dashboard/style/dashboard.css';

export default class Dashboard extends Component {
    render() {
        return (
            <div>
                <p>The dashbaord</p>
            </div>
        )
    }
}

