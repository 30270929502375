import React, { Component, PropTypes } from 'react';
import {
    Card, Badge, CardContent, Tooltip, Grid, IconButton, Icon,
    Toolbar, Typography, List, ListItem, ListItemAvatar, Avatar,
    ListItemText, ListItemSecondaryAction, LinearProgress,
    Dialog, DialogTitle, DialogContent, DialogActions, Button, Paper,
    TextField, InputAdornment
} from '@material-ui/core'

import {
    fetchUsers, goToRoute, fetchUser,
} from './actions'
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RouterIcon from '@material-ui/icons/Router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyIcon from '@material-ui/icons/VpnKey';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/CheckCircle';
import AccountIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next'
//import Gateways from '../gateways'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dropzone from 'react-dropzone'
import * as csv from "csvtojson"
import async from 'async'
import Map from '../map/map_index'
import { Markdown } from 'react-showdown';

const titleMdDE = `**Legende Risikogebiete:**

Die Risikoklassifizierung beruht auf den Angaben des [Robert Koch-Instituts](https://www.rki.de/DE/Content/InfAZ/N/Neuartiges_Coronavirus/Risikogebiete_neu.html):

* Grün: Keine Risikogebiete ausgewiesen,
* Orange: Einige Städte und/oder Gegenden als Risikogebiete eingestuft,
* Rot: Das ganze Land gilt als Risikogebiet.

**Legende Besuch und Einreise:**

Die Risikoklassifizierung beruht auf den Angaben der [Europäischen Union](https://reopen.europa.eu/en/map/DEU/2002) (etc.):

* Grün: Einreise ohne Beschränkungen möglich,
* Orange: Einreise unter Beachtung lokaler Regeln möglich,
* Rot: Keine Einreise möglich.
`

const titleMdEN = `**Map legend Risk Areas:**

The risk classification is based on the data of [Robert Koch-Institut](https://www.rki.de/DE/Content/InfAZ/N/Neuartiges_Coronavirus/Risikogebiete_neu.html):

* Green: No risk areas identified,
* Orange: Some cities and/or areas classified as risk areas,
* Red: The whole country is considered a risk area.

**Map legend Travel and entry:**

The risk classification is based on the information provided by the [European Union](https://reopen.europa.eu/en/map/DEU/2002) (etc.):

* Green: Entry possible without restrictions
* Orange: Entry possible subject to local rules
* Red: No entry possible.

`


class CustomerPositions extends Component {

    constructor(args) {
        super(args)
        this.state = {
            addModal: false,
            sent: false,
            users: this.props.users,
            value: 0,
        }

    }

    componentDidMount() {
        console.log("CustomerPositions componentDidMount", this.props.match.params.id)
        if (this.props.match.params.id) {
            this.props.fetchUsers(this.props.match.params.id)
        }
    }

    normalise(value) {
        return (value - 1) * 100 / (this.state.updateCount - 1)
    }

    UNSAFE_componentWillMount() {
        //this.props.fetchConfigurations()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((!this.state.users && nextProps.users) || (JSON.stringify(this.state.users) != JSON.stringify(nextProps.users))) {


            let featureCollection = {
                type: "FeatureCollection",
                features: []
            }

            nextProps.users.forEach(user => {
                if (user.userPositions) {
                    user.userPositions.forEach((userPosition) => {
                        featureCollection.features.push({
                            "type": "Feature",
                            "id": `${user.id}-${userPosition.id}`,
                            "geometry": {
                                "type": "Point",
                                "coordinates": [userPosition.location.lng, userPosition.location.lat]
                            },
                            "properties": {
                                "title":user.name,
                                "icon": "blank_black.png",
                                "msg": userPosition.msg,
                                "timestamp": userPosition.timestamp,
                                "user": {
                                    "id": user.id,
                                    "customerId": user.customer,
                                    "name": user.name,
                                }
                            }
                        })
                    })
                }
            })

            console.log('featureCollection', featureCollection)
            this.setState({ users: nextProps.users, featureCollection: featureCollection })
        }
    }

    goBack() {
        this.props.history.goBack()
    }

    onSearch(e) {
        console.log('search', e.target.value)
        var users = this.props.users

        users = users.filter(function (item) {
            if (item.name) {
                return item.name.toLowerCase().search(
                    e.target.value.toLowerCase()) !== -1;
            }
        });
        this.setState({ users })
    }

    editClick(user) {
        console.log('user', user)
        this.setState({ addModal: true, selectedUser: user })
    }

    onSelected(item) {
        console.log('onSelected item', item)

    }

    render() {
        const { admin, editor, t, lang } = this.props
        const { updateIndex, addModal, deleting, updating, selectedId, sent, sendMail, search, users, selectedUser, value, featureCollection } = this.state
        const customer = this.props.match.params.id
        console.log('users', users)
        return (
            <div style={{ padding: 20 }}>

                <Grid container
                    justify="center"
                    alignItems="center"
                   >
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div style={{ flexGrow: 1 }}>
                            <Toolbar>
                                <IconButton onClick={this.goBack.bind(this)} color="inherit" aria-label="Add Tenant">
                                    <BackIcon />
                                </IconButton>

                                <Typography variant="h5" color="inherit" style={{ flexGrow: 1, textAlign: 'center' }}>
                                    {t("customers.BenutzerPositionen")}
                                </Typography>
                            </Toolbar>
                            {sent &&
                                <LinearProgress color={"secondary"} />
                            }
                            {!sent &&
                                <div style={{ minHeight: 5 }}></div>
                            }
                        </div>
                    </Grid>
                    <Grid item lg={8}>

                        <Map containerStyle={{
                            height: '86vh',
                            width: '98%'
                        }}
                            onSelected={this.onSelected.bind(this)}
                            featureCollection={featureCollection}
                        />
                    </Grid>
                    <Grid item lg={4}>
                        <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                            <TextField

                                label="Suche"
                                onChange={this.onSearch.bind(this)}
                                value={search}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        {users && users.length > 0 && users.map(command => {
                            return (
                                <Card key={command.id} style={{ marginTop: 20 }}>
                                    <CardContent>
                                        <List dense={true}>
                                            <ListItem>
                                                <ListItemAvatar>

                                                    <div>
                                                        <Tooltip title={t("users.Benutzerbearbeiten")}>
                                                            <IconButton onClick={this.editClick.bind(this, command)}>
                                                                <AccountIcon style={{ color: command.lastLogin ? 'green' : 'red' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </ListItemAvatar>

                                                <Tooltip title={t("users.Berechtigungen")}>
                                                    <ListItemText
                                                        style={{ marginLeft: 20, width: '50vw' }}
                                                        primary={command.name || command.role}
                                                        secondary={command.email}
                                                    />
                                                </Tooltip>
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </Card>
                            )
                        })}

                    </Grid>
                </Grid>

                {updating &&
                    <Dialog open={updating} onClose={this.handleUpdateDialogClose.bind(this)} aria-labelledby="" style={{ minWidth: '50%' }}>
                        <DialogTitle>
                            {t("customer.UserImport")}
                        </DialogTitle>
                        <DialogContent>
                            <LinearProgress variant="determinate" value={this.normalise(updateIndex)} />

                        </DialogContent>

                    </Dialog>
                }
                {deleting &&
                    <Dialog open={deleting} onClose={this.handleDialogClose.bind(this)} aria-labelledby="Neuer Mieter" style={{ minWidth: '50%' }}>
                        <DialogTitle>
                            {t("users.Benutzerlöschen")}
                        </DialogTitle>
                        <DialogContent>
                            {t("users.WollenSie")}

                        </DialogContent>
                        <DialogActions>
                            {!updating &&
                                <div>
                                    <Button onClick={this.handleDialogClose.bind(this)} style={{ marginTop: 35 }} color="secondary">{t("users.Nein")}</Button>{' '}
                                    <Button onClick={this.onDelete.bind(this)} style={{ marginTop: 35 }} color="secondary">{t("users.Ja")}</Button>{' '}
                                </div>
                            }
                            {updating &&
                                <LinearProgress />
                            }
                        </DialogActions>
                    </Dialog>
                }
                {sendMail &&
                    <Dialog open={sendMail} onClose={this.handleEmailDialogClose.bind(this)} aria-labelledby="Neuer Mieter" style={{ minWidth: '50%' }}>
                        <DialogTitle>
                            {t("users.PasswortZurücksetzen")}
                        </DialogTitle>
                        <DialogContent>
                            {t("users.WollenSiePasswort")}

                        </DialogContent>
                        <DialogActions>
                            {!updating &&
                                <div>
                                    <Button onClick={this.handleEmailDialogClose.bind(this)} style={{ marginTop: 35 }} color="secondary">{t("users.Nein")}</Button>{' '}
                                    <Button onClick={this.resetPassword.bind(this)} style={{ marginTop: 35 }} color="secondary">{t("users.Ja")}</Button>{' '}
                                </div>
                            }
                            {updating &&
                                <LinearProgress />
                            }
                        </DialogActions>
                    </Dialog>
                }
            </div>
        )
    }
}

const styles = {

}

function mapStateToProps(state) {


    return {
        users: state.users.users,
        admin: state.auth.admin,
        editor: state.auth.editor,
        lang: state.lang.lang
    }
}
export default withNamespaces()(withStyles(styles, { name: 'Users' })(connect(mapStateToProps, {
    fetchUsers,
    fetchUser,
    goToRoute: goToRoute,

})(CustomerPositions)));

