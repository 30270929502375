import React from 'react';
import ReactDOM from 'react-dom';
import { Card,  CardActions, CardContent } from '@material-ui/core';
import { Markdown } from 'react-showdown';
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { ROOT_URL } from "../../config";
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core'
import Taggy from 'react-taggy'
import { IconButton } from "@material-ui/core"
import EditIcon from '@material-ui/icons/Edit'

const ents = [
    { type: 'per', color: { r: 166, g: 226, b: 45 } },
    { type: 'loc', color: { r: 67, g: 198, b: 252 } },
    { type: 'date', color: { r: 47, g: 187, b: 171 } },
    { type: 'misc', color: { r: 47, g: 187, b: 171 } }
]

class ArticleList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tags: [] };

    }
    componentDidMount() {

        if (this.props.onRef) {
            this.props.onRef(this)
        }

        this.onSearchEnter({ search: "" })


    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined)
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.tags.length > 0 && nextProps.tags != this.props.tags) {
           // console.log('componentWillReceiveProps', nextProps)
            const tags = nextProps.tags.map(tag => {
                return tag.id
            })
           // console.log('before fetch tags', tags)
            this.fetchArticlesByTags(tags).then(res => {
               // console.log('fetchArticles', res.data)
                this.setState({ articles: res.data })
            }).catch(err =>  {
               // console.log('err', err)
                this.setState({ articles: [] })
            })
        }
        else if (nextProps.tagTitle && nextProps.tagTitle != this.props.tagTitle) {
           // console.log('nextProps.tagTitle', nextProps.tagTitle)
            this.fetchArticlesByTagTitle(nextProps.tagTitle).then(res => {
               // console.log('fetchArticles', res.data)
                this.setState({ articles: res.data })
            })
        }
    }

    componentDidUpdate() {
        ReactDOM.findDOMNode(this).scrollTop = 0
    }

    fetchArticlesByTags(tags) {

        var _tags = tags.map(tag => {
            return tag.id
        })
        const url = `${ROOT_URL}/Articles/byTags?tags=${encodeURIComponent(JSON.stringify(_tags))}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        })
    }

    fetchArticlesByTagTitle(tagTitle) {


        const url = `${ROOT_URL}/Articles/byTagTitle?title=${encodeURIComponent(tagTitle)}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        })
    }

    fetchDocumentSearch(search) {


        const url = `${ROOT_URL}/Documents/search?q=${encodeURIComponent(search.search)}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        })
    }

    onClick(article) {
       // console.log('list onClick')
        if (this.props.onClick) {
            this.props.onClick(article)
        }
    }

    onTagClick(item) {

    }
   

    onSearchEnter(search) {
        this.fetchDocumentSearch(search).then(res => {
            this.setState({ 'articles': res.data })
        })
    }

    /*

     <CardActions>
                                <Button onClick={this.onClick.bind(this, article)} size="small">Mehr</Button>
                            </CardActions>

                             <Link key={tag.id} to={"/articles/" + tag.title}>
                              </Link>

    */

    render() {
        const { articles } = this.state        
        //const articles = null
        return <div style={{height : '100%'}}>
            {articles && articles.length &&
                articles.map((article, index) => {
                    return (

                        <Card key={article.id + index} style={{ marginBottom: '15px', maxWidth: '100%' }}>
                         
                            <CardContent>
                                <h2 onClick={this.onClick.bind(this, article)}><strong>
                                    <Taggy text={article.title} spans={article.spacyTitle} ents={ents} />
                                </strong><Link to={"documents/edit/" + article._id }>
                                                    <IconButton>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Link></h2>
                                <Taggy text={article.content} spans={article.spacyContent} ents={ents} />


                                <div>
                                    {article.featureCollection && article.featureCollection.features && article.featureCollection.features.map( (tag, index) => {
                                        return (


                                            <Chip
                                                style={{ margin: '3px' }}
                                                key={index}
                                                label={ tag.properties && (tag.properties.name || tag.properties.title || "empty") }
                                                onClick={this.onTagClick.bind(this, tag)}
                                            />


                                        )


                                    })}
                                </div>
                                <a href={article.url}>{article.author}</a>

                            </CardContent>

                        </Card>
                    )
                })
            }
            {!articles &&             
            <div className={'loading_wrapper'} style={{textAlign : 'center' , position: 'relative',  width : "100%", height: '100%'}}>
                <CircularProgress style={{position: 'absolute',top: '50%', margin: '0 auto'}} color="secondary"/>
            </div>
            }
            
        </div>

    }

}



function mapStateToProps(state) {


    return {
        countries: state.countries.all,
        country: state.countries.country,
    }
}
const styles = {

}
export default withStyles(styles, { name: 'ArticleList' })(connect(mapStateToProps, {

})(ArticleList));


