import React, { Component } from 'react';
import MapMetadataEditForm from './forms/mapMetadata_edit_form';
import { fetchMap } from "./mapMetadata_actions";
import { connect } from "react-redux";
// import './style/dossier.css';

class MapMetadataEdit extends Component {
    componentDidMount() {
        console.log('MapMetadataEdit componentDidMount')
        this.props.fetchMap(this.props.match.params.id)
    }

    render() {
        return (
            <MapMetadataEditForm />
        )
    }
}

export default connect(null, { fetchMap })(MapMetadataEdit)
