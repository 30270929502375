import React from 'react';
import "@fontsource/barlow"; 
import ReactDOM from 'react-dom';
import css from './index.css';
import App from './components/App';
import registerServiceWorker from './registerServiceWorker';
//import 'typeface-raleway';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from "redux";
import promise from 'redux-promise';
import reducers from './reducers';
import reduxThunk from 'redux-thunk';
import {createBrowserHistory} from 'history';
import { routerMiddleware } from "react-router-redux";
import { AUTHENTICATED_USER } from "./actions/types";
import { persistStore, autoRehydrate } from 'redux-persist';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import * as routes from './routes'
//import "@babel/polyfill"
//import Sitemap from 'react-router-sitemap'
import './i18n'
/*
//import ReactGA from 'react-ga';
const gaProperty = 'UA-130702159-1'
//ReactGA.initialize(gaProperty)
//ReactGA.set('anonymizeIp', true)
var disableStr = 'ga-disable-' + gaProperty;

if (document.cookie.indexOf(disableStr + '=true') > -1) {
    window[disableStr] = true;
}
*/
const history = createBrowserHistory();
console.log('history', history)
console.log('App', App)
const routerMdl = routerMiddleware(history);

const store = createStore(
    reducers,
    undefined,
    compose(
        applyMiddleware(promise, reduxThunk, routerMdl),
        autoRehydrate()
    )
);

const token = localStorage.getItem('token');

if (token) {
    store.dispatch({ type: AUTHENTICATED_USER });
}

store.subscribe(() => {
    //console.log('store updated', store.getState());
});
/*
new Sitemap(routes)
		.build('https://riskcompass.eu')
        .save('./sitemap.xml')
*/
persistStore(store, { blacklist: ['form.SignInForm', "form", "contact", "posts", "dossier", "layer", "countries", "dachCountries", "map", "dossiers", "maps", "tags", "articles", "articleEdit", "page", "agenda"] });

const theme = createTheme({
    palette: {
        "primary": {
            "50": "#E0F2F1",
            "100": "#B2DFDB",
            "200": "#80CBC4",
            "300": "#4DB6AC",
            "400": "#26A69A",
            "500": "#116bff",
            //"500": "#ffffff",
            "600": "#00897B",
            //"700": "#0a5586",
            "700": "#0a5586",
            "800": "#00695C",
            "900": "#004D40",
            "A100": "#82b1ff",
            "A200": "#448aff",
            "A400": "#2979ff",
            "A700": "#2962ff",
            "contrastDefaultColor": "light",

        },
        "secondary": {
            main: "#f48942"
        }
    },
    typography: {
        "fontFamily": '"Barlow", sans-serif;',
    },
});

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <Provider store={store}>
            <App history={history} />
        </Provider>
    </MuiThemeProvider>
    , document.querySelector('#root')
);




registerServiceWorker();
