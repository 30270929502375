

import React from "react";

import {
    Card,
    CardContent,
    Grid
} from '@material-ui/core';

import RegisterUser from '../registerUser'

export default class Register extends React.Component {

    render() {
        return (
            <Grid style={{ maxWidth: '100%', padding: '12px', paddingTop: 0, marginTop: '0px', marginLeft: '0px', }} container >
                <Grid item lg={1} sm={false} xs={false} md={false} >
                </Grid>
                <Grid item lg={10} sm={12} xs={12} md={12} >
                    <Card>
                        <CardContent>
                            <RegisterUser />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={1} sm={false} xs={false} md={false} >
                </Grid>
            </Grid>

        )

    }

}