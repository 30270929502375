import { push } from 'react-router-redux';
import {LAYOUT_SIDEBAR} from "../../actions/types";

export function goToRoute(route) {
    return function (dispatch) {
        dispatch(push(route));
    }
}

export function toggleSideBar(open)
{
    return {
        type: LAYOUT_SIDEBAR,
        payload: open
    }
}