const someVariable = 240;

export default (theme) => ({
    root: {
        //flexGrow: 1,

        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: 1 * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    headline: {
        textAlign: 'center',
        marginTop: '50px',
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    riskLevel: {

    },
    strong: {
        color: 'red',
    },
    textField: {
        marginLeft: 1,
        marginRight: 1,
        width: '50%',
    },
    gridList: {
        width: 500,
        height: 450,
    },
    subheader: {
        width: '100%',
    },
    button: {
        margin: 1,
        textAlign: 'center',
    },
    input: {
        display: 'none',
    },
});
