import {
    FETCH_HOTSPOT,
    UPDATE_HOTSPOT,
    INSERT_HOTSPOT,
    DELETE_HOTSPOT
   
} from "../../actions/types";

import * as _ from "lodash";


const INITIAL_STATE = {
    all: [],
    document:null,
    hotspot:null
};


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case FETCH_HOTSPOT:
            return {
                ...state,
                hotspot: action.payload.data[0]
            };
            case FETCH_HOTSPOT:
            return {
                ...state,
                hotspot: action.payload.data[0]
            };
            case UPDATE_HOTSPOT:
            return {
                ...state,
                hotspot: action.payload.data[0]
            };
            case INSERT_HOTSPOT:
            return {
                ...state,
                hotspot: action.payload.data[0]
            };
            case DELETE_HOTSPOT:
            return {
                ...state,
                hotspot: null
            };

        default:
            return state;
    }
}