


import React from "react";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { register } from './actions'
import { Link } from 'react-router-dom';
import { MCaptchaWidget, WidgetConfig, SiteKey } from '@mcaptcha/react-glue'

import {
    Button,
    TextField,
    FormControlLabel,
    ListItemText,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    CardContent,
    LinearProgress,
    Divider,

} from '@material-ui/core';

import VerifiedUser from '@material-ui/icons/VerifiedUser'


import axios from 'axios';

import { ROOT_URL } from "../../config";
import Newsletter from "../newsletter";

const config = {
    siteKey: { key: "5HHacBHRMLpoyVcHf2fvWPjDjikvjtwS" }
}

class RegisterUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            agb: false,
            business: false,
            intelligence: false,
            interests: [],
            sending: false,
            success: false,
            verified: true
        }
    }

    componentDidMount() {
        window.addEventListener("message", this.onVerify);

    }


    register(options) {
        const body = {
            email: options.email,
            password: options.password,
            info: options.info
        }


        const url = `${ROOT_URL}/users`
        return axios.post(url, body, {
            headers: { authorization: localStorage.getItem('token') }
        })
    }

    handleChange = name => event => {
        console.log('event.target', event.target)
        if (name == "agb") {
            this.setState({ [name]: event.target.checked });
        } else {
            this.setState({ [name]: event.target.value });
        }

    };


    submit = () => {
        console.log('submit', this.state)
        const { onValidated } = this.props
        const { email, fname, lname, firma, agb, funktion, tel, anrede, password, interests, agreement, mitarbeiter, strasse, plz, ort } = this.state
        if (email &&
            fname &&
            lname &&
            agb &&
            // mitarbeiter &&
            password &&
            email.indexOf("@") > -1) {
            if (!agb || !fname || !lname || !email) {
                this.setState({ error: "Bitte füllen Sie zuerst alle Pflichtfelder aus!" })
                return
            } else if (!this.state.verified) {
                this.setState({ error: "Recaptcha bestätigen" })
                return
            } else if (this.state.password != this.state.password2) {
                this.setState({ error: "Passwörter sind nicht identisch" })
                return
            } else {
                this.setState({ error: null })

            }


            this.setState({ sending: true })
            this.register({
                email, password,
                info: {
                    vorname: fname,
                    nachname: lname,
                    mitarbeiter: mitarbeiter,
                    firma: firma,
                    agreement: agreement,
                    email, anrede, firma, funktion, tel, strasse, plz, ort,
                    interessen: interests
                }

            }).then(res => {
                console.log('res', res)
                res = res.data
                this.setState({ sending: false })
                if (res && res.id) {
                    console.log('success')
                    this.setState({ success: true })
                } else if (res && res.error) {
                    console.log('error')
                    this.setState({ error: res.error || res.error.message })
                }
            }).catch(err => {
                //console.log('err', err)
                if (err && Object.keys(err).length > 0) {
                    console.log('err', err.response.data.error)
                    var msg = err.response.data.error.message
                    if (err.response.data.error.statusCode == 422) {
                        msg = "Ein Zugang mit dieser E-Mail-Adresse besteht bereits."
                    }
                    this.setState({ error: msg, sending: false, success: false })
                }

            })

        } else {
            this.setState({ error: "Bitte füllen Sie alle Pflichtfelder aus!" })
        }

    }

    onVerify = (e) => {
        console.log('onVerify', e)

        this.setState({
            verified: true
        });
        window.removeEventListener("message", this.onVerify);
    };


    onExpire = () => {
        this.setState({
            verified: false
        });
    };


    /*

                    <div style={{ marginTop: 10 }}>
                    <TextField fullWidth type="text" name="funktion" label="Funktion" onChange={this.handleChange('funktion')} InputLabelProps={{ shrink: true }} />
                </div>
                <div style={{ marginTop: 10 }}>
                    <TextField fullWidth type="text" name="firma" label="Firma (inkl. Rechtsform) *" onChange={this.handleChange('firma')} InputLabelProps={{ shrink: true }} />
                </div>
                <div style={{ marginTop: 10 }}>
                    <TextField fullWidth type="number" name="mitarbeiter" label="Anzahl Mitarbeiter *" onChange={this.handleChange('mitarbeiter')} InputLabelProps={{ shrink: true }} />
                </div>
                <div style={{ marginTop: 10 }}>
                    <TextField fullWidth type="text" name="strasse" label="Straße" onChange={this.handleChange('strasse')} InputLabelProps={{ shrink: true }} />
                </div>
                <div style={{ marginTop: 10 }}>
                    <TextField fullWidth type="text" name="plz" label="PLZ" onChange={this.handleChange('plz')} InputLabelProps={{ shrink: true }} />
                </div>
                <div style={{ marginTop: 10 }}>
                    <TextField fullWidth type="text" name="ort" label="Ort" onChange={this.handleChange('ort')} InputLabelProps={{ shrink: true }} />
                </div>
                <div style={{ marginTop: 10 }}>
                    <TextField fullWidth type="tel" name="tel" label="Telefon (Durchwahl) *" onChange={this.handleChange('tel')} InputLabelProps={{ shrink: true }} />
                </div>

                 <div style={{ marginTop: 10 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.agreement}
                                onChange={this.handleChange('agreement')}
                                value={this.state.agreement}
                                color="primary"
                            />
                        }
                        label="Ja, ich bin damit einverstanden, dass RiskCompass mir künftig per E-Mail Angebote zusendet."
                    />
                </div>

    */

    render() {
        const { status, onValidated, message, registered } = this.props
        const { agb, name, email, error, sending, success } = this.state
        return (
            <div
                style={{


                    padding: 20,

                }}
            >
                {!success &&
                    <div>
                        <div style={{ marginTop: 0 }}>

                            <CardContent style={{ paddingLeft: 0 }}>
                                {false &&
                                    <h2><b>Benutzerregistrierung für das kostenlose RiskCompass STARTER Angebot</b></h2>
                                }

                                <h2 style={{ marginTop: 0 }}> <b>Starter-Account: Hier gratis registrieren!</b></h2>
                                <div style={{ fontSize: 16 }}>
                                    Mit Ihrer Anmeldung erhalten Sie Zugang zum RiskCompass Starter-Angebot. Es umfasst alle Nachrichten, Reisewarnungen und Eilmeldungen. Sie erhalten außerdem den Gratis-Zugang zu den Kurzzusammenfassungen (»Auf einen Blick«) aller 201 Länder-Sicherheitsinformationen, einschließlich der Risikoeinstufung des jeweiligen Landes.
                                    <br /><br />
                                    Aufbau, Struktur und Inhalte einer kompletten Länderinformation und eines Städte-Reports können Sie hier am Beispiel von Albanien und Tirana näher kennenlernen.
                                    <br /><br />
                                    Mit Ihrem Starter-Account erhalten Sie sowohl Zugang zur RiskCompass-App als auch zur Website.
                                    <br /><br />
                                    Einfach das Formular ausfüllen und durchstarten.
                                    <br /><br />
                                </div>
                            </CardContent>


                            <FormControl fullWidth={true} >
                                <InputLabel shrink={true} htmlFor="anrede">Anrede</InputLabel>
                                <Select
                                    value={this.state.anrede}
                                    onChange={this.handleChange("anrede")}
                                    inputProps={{
                                        name: 'anrede',
                                        id: 'anrede',
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>-</em>
                                    </MenuItem>
                                    <MenuItem value={"Herr"}>Herr</MenuItem>
                                    <MenuItem value={"Frau"}>Frau</MenuItem>
                                    <MenuItem value={"Firma"}>Firma</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <TextField fullWidth type="text" name="fname" label="Vorname *" onChange={this.handleChange('fname')} InputLabelProps={{ shrink: true }} />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <TextField fullWidth type="text" name="lname" label="Nachname *" onChange={this.handleChange('lname')} InputLabelProps={{ shrink: true }} />
                        </div>




                        <div style={{ marginTop: 10 }}>
                            <TextField fullWidth type="email" name="email" label="E-Mail *" onChange={this.handleChange('email')} InputLabelProps={{ shrink: true }} />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <TextField fullWidth type="password" name="password" label="Passwort wählen *" onChange={this.handleChange('password')} InputLabelProps={{ shrink: true }} />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <TextField fullWidth type="password" name="password2" label="Passwort wiederholen *" onChange={this.handleChange('password2')} InputLabelProps={{ shrink: true }} />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <FormControl fullWidth={true}>
                                <InputLabel shrink={true} htmlFor="nutzung">Interesse an..</InputLabel>
                                <Select
                                    multiple={true}
                                    value={this.state.interests}
                                    onChange={this.handleChange("interests")}
                                    renderValue={selected => selected.join(', ')}
                                    inputProps={{
                                        name: 'interests',
                                        id: 'interests',
                                    }}
                                >
                                    {["Auslandssicherheit Länderdossiers", "Auslandssicherheit Städtedossiers", "Nachrichten", "Polizeinachrichten", "Newsletter"].map(name => {
                                        return (
                                            <MenuItem key={name} value={name}>
                                                <Checkbox checked={this.state.interests.indexOf(name) > -1} />
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        )
                                    })


                                    }




                                </Select>
                            </FormControl>
                        </div>
                        <p>Mit * gekennzeichnete Felder sind Pflichtfelder.</p>

                        <div style={{ marginTop: 10 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.agb}
                                        onChange={this.handleChange('agb')}
                                        value={this.state.agb}
                                        color="primary"
                                    />
                                }
                                label="Ich akzeptiere die Datenschutzbestimmung "
                            />
                        </div>



                        <div style={{ textAlign: 'center' }}>

                            {!sending && !success &&
                                <Button style={{ marginTop: 20 }} variant="contained" onClick={this.submit.bind(this)}>
                                    Registrieren
                                </Button>
                            }

                            {error &&
                                <p style={{ color: 'red' }}>{error}</p>
                            }
                            {sending &&
                                <LinearProgress />
                            }
                        </div>

                    </div>
                }
                {success &&
                    <CardContent>
                        <div style={{}}>
                            <div style={{ display: 'flex', flexDirection: 'row', border: '1px solid black' }}

                            >

                                <VerifiedUser style={{ marginTop: 20, marginRight: 20, marginLeft: 20, color: 'green', height: 70, width: 70 }} />


                                <div><b>
                                    <h1>Registrierung erfolgreich!</h1>
                                    <p>Bitte bestätigen Sie den Aktivierungslink, der Ihnen soeben an Ihre E-Mail-Adresse zugeschickt wurde.
                                        Falls Sie keine E-Mail erhalten haben, prüfen Sie bitte Ihren Spam-Order.</p>
                                    <br />
                                </b>
                                </div>
                            </div>
                            <Divider />
                            <br />
                            <h2>An exklusive Meldungen zum Thema Geschäftsreise und Sicherheit interessiert?</h2>
                            <p>
                                Dann abonnieren Sie hier den kostenlosen RiskCompass-Newsletter »Travel Risk Intelligence« und bleiben Sie auf dem Laufenden.
                            </p>

                            <br />
                            <Newsletter />
                        </div>
                    </CardContent>
                }
            </div>
        );
    }
};

function mapStateToProps(state) {


    return {
        page: state.page.page,
        admin: state.auth.admin,
        editor: state.auth.editor,
        lang: state.lang.lang,
        registered: state.register.registered
    }
}
const styles = {

}
export default withStyles(styles, { name: 'RegisterUser' })(connect(mapStateToProps, {
    register: register

})(RegisterUser));


