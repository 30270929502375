import React, { Component } from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    renderField, renderLocalizedField, renderLocalizedEditor, renderHiddenField, renderMapSelector
} from "../../components/field_formatters";
import PropTypes from 'prop-types';
import { success } from "react-notification-system-redux";
import {
    Button, IconButton, Menu, MenuItem, Grid,
    Paper, Card, CardContent, List, ListItem,
    ListItemText, CircularProgress, Dialog, DialogActions, DialogTitle
} from "@material-ui/core";
//import validate from './validation';
import { notificationDuration, newSectionDossierOptions as options } from '../../config';
import AddIcon from '@material-ui/icons/Add';
import {
    insertPage, updatePage, deletePage, fetchPage
} from "../page/actions";
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next'



const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 1 * 3,
        backgroundColor: theme.palette.background.paper,
    },
});






class AppEdit extends Component {

    state = {
        submittedEditDossier: false,
        open: false,
        anchorEl: null,
        lang: this.props.lang,
        selectedIndex: 0
    }

    dispatchNotification(fn, timeout) {
        setTimeout(() => {
            this.context.store.dispatch(fn(notificationOpts));
        }, timeout);
    }

    handleFormSubmit(formProps) {
        const { dossier } = this.props
        // console.log('formProps', formProps)
        const body = {
            /*title: formProps.title,
            polity: formProps.polity,
            generalInfo: formProps.generalInfo,
            infoLink: formProps.infoLink,
            riskPotentials: formProps.riskPotentials,
            riskLevel: formProps.riskLevel,
            id: formProps.country*/
        }
        this.props.updatePage(formProps).then(function () {
            this.setState({ submittedEditDossier: true })
            this.dispatchNotification(success, 200);
        }.bind(this));


        /*
        if (dossier.type == "country") {
            this.props.updateCountry(body).then(function () {
                this.setState({ submittedEditDossier: true })
                this.dispatchNotification(success, 200);
            }.bind(this));
        } else if (dossier.type == "city") {
            this.props.updateCity(body).then(function () {
                this.setState({ submittedEditDossier: true })
                this.dispatchNotification(success, 200);
            }.bind(this));
        }
*/

    }

    handleListItemClick(item, index) {

        this.setState({ selectedIndex: index })

    }

    UNSAFE_componentWillMount() {
       
        this.props.fetchPage("app")
    }

    componentDidMount() {
        console.log('did mount')
    }

    handleClick = event => {
        this.setState({
            open: true,
            anchorEl: event.currentTarget
        });
    };

    handleMenuItemClick = (event, index) => {
        this.setState({ open: false });
        switch (index) {
            case 0:
                this.props.addNewDossierSection();
        }
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    handleDeletePage(id) {
        this.setState({ del: id })
    }
    savePage() {

    }

    cancelDelete() {

    }

    deletePage() {

    }

    render() {

        //const {  } = this.props
        const { newPage, del, updating, selectedIndex } = this.state
        const { classes, error, handleSubmit, t, lang, page } = this.props
        console.log('page', page)
        if (this.state.submittedEditDossier) {
            return (
                <Redirect to={"/app"} />
            )
        }

        return (
            <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                <Field name="id" type="hidden" component={renderHiddenField} />

                <div>
                    <Grid container style={{ padding: '12px' }}>
                        <Grid item lg={12}>
                            {page &&
                                <Card>

                                    <CardContent>
                                        <Field name={'title'} type="text" component={renderLocalizedField} label="Title" />
                                        <Field name={'markdown'} type="text" component={renderLocalizedEditor} label="Content" />
                                      <div >
                                        <Field style={{marginTop:30}} name={'tutorial'} type="text" component={renderLocalizedEditor} label="Tutorial" />
                                        </div>
                                        {page &&
                                    <div style={{ paddingLeft: 10, paddingRight: 10, marginTop: 10, float: 'right' }}>
                                        {!updating &&
                                            <Button
                                                variant="fab"
                                                color="secondary"
                                                type="submit"
                                                onClick={this.savePage.bind(this)}
                                            >
                                                <SaveIcon />
                                            </Button>
                                        }
                                        {updating && <CircularProgress size={44} style={{


                                        }} />}

                                    </div>
                                }


                                    </CardContent>
                                </Card>
                            }
                        </Grid>
                    </Grid>

                    
                </div>
            </form>
        )

    }

  
}

AppEdit.propTypes = {
    //classes: PropTypes.object.isRequired,
};

const notificationOpts = {
    title: 'Dossier',
    message: 'Successfully Updated.',
    position: 'tr',
    autoDismiss: notificationDuration
}

const form = reduxForm({
    form: 'AppEdit',
    enableReinitialize: true,
    //validate
});

AppEdit.contextTypes = {
    store: PropTypes.object
};

function mapStateToProps(state) {
    return {
        initialValues: state.page.page,
        page: state.page.page,
        dossier: state.dossiers.dossier,
        lang: state.lang.lang,
        //dossierChapter: state.dossiers.dossierChapter,
        //dossiersections: state.dossiers.dossiersections
    }
}

export default withNamespaces()(connect(mapStateToProps, { updatePage, fetchPage })(form(AppEdit)));