import axios from 'axios';
import {
    INSERT_ARTICLE,
    UPDATE_ARTICLE,
    FETCH_DOCUMENT
} from "../../actions/types";

import {ROOT_URL} from "../../config";



export function insertArticle(id) {
    return function (dispatch) {

    }
}

export function fetchDocument(id) {
   // console.log('fetchDocument', id)
    return function (dispatch) {
        var search = "_id:"+id
        const url = `${ROOT_URL}/Documents/search?q=${encodeURIComponent(search)}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
           // console.log('response.data', response.data)
            dispatch({
                type: FETCH_DOCUMENT,
                payload: response
            });
        }).catch((err) => {
           // console.log('error fetching the articles', err);
        });
       
    }
}

export function updateAricle(id) {
    return function (dispatch) {

       
    }
}

