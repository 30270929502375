import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Tabs, Tab, TextField, FormControlLabel, Checkbox, InputAdornment, Grid, Card, CardContent, FormControl, InputLabel, Select, Divider, MenuItem } from "@material-ui/core";
import Editor from '@toast-ui/editor'

import '@toast-ui/editor/dist/toastui-editor.css'
import '@toast-ui/editor/dist/toastui-editor-viewer.css'
//import 'tui-editor/dist/tui-editor-contents.css
import 'codemirror/lib/codemirror.css'
import 'highlight.js/styles/github.css'
import { change } from 'redux-form'
import MapSelector from '../containers/mapSelector'
import FlagSelect from '../containers/flagSelect'
import { Markdown } from 'react-showdown'
/*
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
*/

export class renderSelect extends React.Component {
    constructor(args) {
        super(args)
        this.state = {
            selected: this.props.value || null
        }
    }

    handleChange = event => {
        console.log('handleChange', event)
        this.setState({ [event.target.name]: event.target.value });
        if (this.props.onChange) {
            this.props.onChange(event.target)
        }
    }

    render() {
        const { input, label, gateways, multiple, value, style, type, meta: { touched, error } } = this.props;
        const { selected } = this.state

        return (
            <FormControl fullWidth={true}>
                <InputLabel color="secondary" shrink={true}>{label}</InputLabel>
                <Select
                    fullWidth
                    inputlabelprops={{ shrink: true }}
                    multiple={multiple}
                    onChange={this.handleChange.bind(this)}
                    style={style}
                    value={selected}
                    {...input}
                >
                    {gateways.map((gateway, index) => {
                        return (
                            gateway.divider
                                ?
                                <Divider key={`fake-key-${Date.now()}-${index}`} />
                                :
                                <MenuItem key={gateway.id || gateway._id} value={gateway.id || gateway._id}>{gateway.title || gateway.name}</MenuItem>
                        )
                    })}


                </Select>
                {touched && error && <div style={{ textAlign: 'left', color: '#F04600', fontSize: '0.75rem' }}>{error}</div>}
            </FormControl>
        )

    }


}



export class renderField extends React.Component {
    render() {

        const { input, label, type, meta: { touched, error } } = this.props;
        //console.log('input', input)
        return (
            <div>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={label}
                    margin="normal"
                    type={type}
                    className="textFormField"
                    {...input}
                />
                <br />
                {touched && error && <span> {error} </span>}
            </div>
        );
    }
}

class _renderMapSelector extends React.Component {
    constructor(props) {
        super(props);
    }
    onSelectionChange(selection) {

        if (selection) {
            this.props.change(this.props.meta.form, "map", selection)
        }
    }

    render() {
        const { input, label, type, meta: { touched, error } } = this.props;

        return (
            <div>
                <MapSelector onSelectionChange={this.onSelectionChange.bind(this)} />
            </div>
        );
    }
}
function mapDispatchToProps2(dispatch) {
    return bindActionCreators({ change }, dispatch)
}
export const renderMapSelector = connect(null, mapDispatchToProps2)(_renderMapSelector)

/*
<FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-simple">Age</InputLabel>
          <Select
            value={this.state.age}
            onChange={this.handleChange}
            inputProps={{
              name: 'age',
              id: 'age-simple',
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>*/

class _renderLocalizedField extends React.Component {
    constructor(props) {
        super(props);
        //// console.log('props', props)
        this.state = {
            lang: this.props.lang || "de",
            value: this.props.input.value
        }
    }


    onLangSelect(lang) {
        //console.log('onLangSelect', lang)
        var input = this.props.input
        this.setState({ lang })

        //this.props.change(this.props.meta.form, input.name, input)
        //console.log('input.value', input.value)
        //this.props.change(this.props.meta.form, "map", selection)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {


        var { value } = this.state
        if (value[this.state.lang] != nextProps.input.value) {
            this.setState({ value: nextProps.input.value })
        }


    }

    onChange(event) {
        //this.props.change(this.props.meta.form, "", selection)
        //console.log('this.props.meta', this.props)
        //console.log('event', event.target.value)
        var input = this.props.input
        /*console.log('input.value', input.value)
        input.value[this.state.lang] = event.target.value
       // console.log('input', input.value)*/
        var { value } = this.state
        value[this.state.lang] = event.target.value
        this.setState({ value })
        this.props.change(this.props.meta.form, input.name, value)
        //console.log('target', target)
    }

    render() {

        const { input, label, type, meta: { touched, error } } = this.props;
        const { lang, value } = this.state
        //// console.log('input', input)

        return (
            <div style={{ display: 'flex', flexDirection: 'row', msFlexDirection: 'row', alignContent: 'center', minWidth: '100%', paddingRight: 20 }}>
                <div style={{ marginTop: 40, width: 30, height: 30, paddingRight: 10 }}>
                    <FlagSelect onSelect={this.onLangSelect.bind(this)} />
                </div>
                <div style={{ width: '100%', }}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={label}
                        margin="normal"
                        type={type}
                        onChange={this.onChange.bind(this)}
                        className="textFormField"
                        value={value[lang]}

                    //{...input}
                    />
                </div>
                <br />
                {touched && error && <span> {error} </span>}
            </div>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ change }, dispatch)
}
function mapStateToProps(state) {
    //console.log('srat', state)
    return {
        lang: state.lang.lang,
    }
}
export const renderLocalizedField = connect(mapStateToProps, mapDispatchToProps)(_renderLocalizedField)


export class renderCheckbox extends React.Component {

    render() {
        const { input, label, type, meta: { touched, error } } = this.props;

        return (
            <FormControlLabel
                control={
                    <Checkbox
                        // checked={this.state.checkedB}
                        //onChange={this.handleChange('checkedB')}
                        style={{ marginTop: 0, marginBottom: 0 }}
                        color="primary"
                        {...input}
                    />
                }
                label={label}
            />
        )

    }


}

export class renderFieldTextarea extends React.Component {
    constructor(props) {

        super(props);
    }

    render() {

        const { input, label, type, meta: { touched, error } } = this.props;

        return (
            <div ><TextField

                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                label={label}
                margin="normal"
                className="textFormField"
                {...input}
            />
                <br />
                {touched && error && <span> {error} </span>}
            </div>
        )
    }
}

class _renderFieldEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editor: null,
            changed: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.input.value != this.props.input.value && !this.state.changed) {
            this.state.editor.setMarkdown(nextProps.input.value)
            this.setState({ changed: true })
        }

    }

    handleModeChange() {

        var { value, name } = this.props.input

        if (this.state.editor) {
            value[this.state.lang] = this.state.editor.getMarkdown()
            this.props.change(this.props.meta.form, name, value)
            this.state.editor.setMarkdown(this.clean(value[this.state.lang]), false)
        }
    }

    clean(text) {
        return text && text.length > 0 ? text.replaceAll(/\\/g, '') : ''
    }

    componentDidMount() {
        let editor = new Editor({
            el: document.querySelector('#editSection'),
            initialEditType: 'wysiwyg',
            previewStyle: 'tab',
            height: window.innerHeight - 250,
            events: {
                change: this.onChange
            },
            hooks: {
                modeChange: this.handleModeChange.bind(this)
            },
            initialValue: this.props.input.value
        });

        this.setState({ editor });
    }

    onChange = (event) => {
        this.props.change(this.props.meta.form, "markdown", this.state.editor.getValue())
    }

    render() {
        return (
            <div id='editSection' />
        );
    }


}



function mapDispatchToProps6(dispatch) {

    return bindActionCreators({ change }, dispatch)
}
export const renderFieldEditor = connect(null, mapDispatchToProps6)(_renderFieldEditor)


export const renderHiddenField = ({ input, label, type, meta: { touched, error } }) => (
    <input {...input} type={type} />
);

String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
};

class _renderLocalizedEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editor: null,
            value: null,
            mode: 1,
            changed: false,
            lang: this.props.lang
        }
    }

    clean(text) {
        return text && text.length > 0 ? text.replaceAll(/\\/g, '') : ''
    }

    onLangSelect(lang) {
        //// console.log('onLangSelect', lang)
        var input = this.props.input
        this.setState({ lang })
        this.state.editor.setMarkdown(this.clean(input.value[lang]), false)
        //this.props.change(this.props.meta.form, input.name, input)
        //console.log('input.value', input.value)
        //this.props.change(this.props.meta.form, "map", selection)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log('nextProps', nextProps)
        console.log('state', this.state)
        if (nextProps.lang && !this.state.lang) {
            const lang = nextProps.lang
            //console.log('lang #5', nextProps)
            this.setState({ lang })
            if (nextProps.input.value && nextProps.input.value[lang]) {
                //console.log('should render text', nextProps)
                console.log('set value#1')
                this.state.editor.setMarkdown(this.clean(nextProps.input.value[lang], false))
                // this.setState({ changed: true })
            }
        }
        if (nextProps.input.value && (nextProps.input.value[this.state.lang] != this.props.input.value[this.state.lang] && !this.state.changed)) {
            console.log('set value#2', nextProps.input.value[this.state.lang])
            this.state.editor.setMarkdown(this.clean(nextProps.input.value[this.state.lang]), false)
            // this.setState({ changed: true })
        }

    }

    componentDidMount() {
        //console.log("mount", this.props)
        const { lang, input } = this.props
        const number = Math.floor((Math.random() * 100) + 1)
        this.setState({ number }, () => {
            let editor = new Editor({
                el: document.querySelector('#editSection' + number),
                initialEditType: 'wysiwyg',
                useDefaultHTMLSanitizer: false,
                previewStyle: 'tab',
                //hideModeSwitch: true,
                // height: window.innerHeight - 250,
                height: '86vh',
                width: '100%',
                events: {
                    change: this.onChange,
                },
                hooks: {
                    changeMode: this.handleModeChange.bind(this)
                },
                initialValue: this.clean(input.value[this.props.lang] || "")
            });

            this.setState({ editor, lang, });
        })



    }

    onChange = (event) => {
        if (this.state.editor) {
            var { value, name } = this.props.input
            //console.log('value bla', JSON.stringify(value))
            //value = {}
            if (typeof value == "string") {
                value = {}
            }

            value[this.state.lang] = this.clean(this.state.editor.getMarkdown())
            this.props.change(this.props.meta.form, name, value)
        }
    }
    handleModeChange() {

        var { value, name } = this.props.input

        if (this.state.editor) {
            value[this.state.lang] = this.state.editor.getMarkdown()
            this.props.change(this.props.meta.form, name, value)
            this.state.editor.setMarkdown(this.clean(value[this.state.lang]), false)
        }
    }

    render() {
        var { value, name } = this.props.input
        var { mode, lang, number } = this.state
        var md = value[lang]
        return (
            <Grid container >
                <Grid item lg={12}>
                    <div style={{ paddingTop: 0, marginLeft: 10, width: 30, height: 50, paddingRight: 10 }}>
                        <FlagSelect onSelect={this.onLangSelect.bind(this)} />
                    </div>


                    <div style={{ padding: 10 }} id={'editSection' + number} />

                </Grid>

            </Grid>
        );
    }


}



function mapDispatchToProps3(dispatch) {

    return bindActionCreators({ change }, dispatch)
}
function mapStateToProps3(state) {
    //console.log('srat', state)
    return {
        lang: state.lang.lang,
    }
}
export const renderLocalizedEditor = connect(mapStateToProps3, mapDispatchToProps3)(_renderLocalizedEditor)


