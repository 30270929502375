import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button, LinearProgress
} from '@material-ui/core';
import {
    renderField, renderFieldTextarea, renderHiddenField, renderCheckbox
} from "../../components/field_formatters";
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { sendReport } from "./actions";
import Reaptcha from 'reaptcha';
import { withStyles } from '@material-ui/styles'
const classes = {
   
    marginTop:'0 !important', marginBottom:'0 !important'
}


const styles = {
    dialogPaper: {
        
        maxHeight: '80vh',
    },
}

class ReportError extends Component {

    state = {
        submittedEditDossier: false,
        open: true,
        anchorEl: null,
        verified: false,
        submitted: false,
        submitting: false

    }

    handleFormSubmit(formProps) {
        // console.log('formProps', formProps)
        const { history } = this.props
        if (this.state.verified) {
            this.setState({ submitting: true })
            formProps.dossierTitle = this.props.dossierTitle
            this.props.sendReport(formProps).then(res => {
                //history.goBack()

                this.setState({ submitted: true, submitting: false })
                if (this.props.onFinish) {
                    this.props.onFinish()
                }


            }).catch(err => {

                this.setState({ submitted: false, error: err, submitting: false })

            })
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // console.log('nextProps', nextProps)
    }

    onVerify = () => {
        this.setState({
            verified: true
        });
    };

    onExpire = () => {
        this.setState({
            verified: false
        });
    };

    handleClose() {
        this.setState({open:false})
        if (this.props.onFinish) {
            this.props.onFinish()
        }
    }

    handleChange = name => event => {
       // console.log(name,event.target.checked )
        this.setState({ [name]: event.target.checked });
    };


    render() {

        const { handleSubmit, sent } = this.props;
        const { error, submitted, submitting, a, wrongLink, wrongInfo, missingInfo, other, searchProblem } = this.state

        return (


            <div style={{}}>
               
               <Dialog
               classes={{paper: styles.dialogPaper}}
               
               
          open={this.state.open}
          onClose={this.handleClose.bind(this)}
          aria-labelledby="form-dialog-title"
        >
        <form style={{padding:0}} onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
          <DialogTitle id="form-dialog-title">Fehler entdeckt?</DialogTitle>
          <DialogContent>
            <h2>
            Haben Sie einen Fehler in diesem Dossier entdeckt oder etwas vermisst, das wichtig ist?
            </h2>
            <p>
                   Bitte das Zutreffende anklicken und im Textfeld beschreiben:
                   </p>


          
            
                    <Field name="id" type="hidden" component={renderHiddenField} />
                    
                    <Field  name={'wrongLink'} type="checkbox" checked={"false"} onChange={this.handleChange("wrongLink")} component={renderCheckbox} label="Ein Link funktioniert nicht mehr" />
                    {wrongLink &&
                        <Field name={'wrongLinkText'} type="text" component={renderFieldTextarea} label="Nachricht" />

                    }

                    <Field  name={'wrongInfo'} type="checkbox" checked={"false"} onChange={this.handleChange("wrongInfo")} component={renderCheckbox} label="Eine Information ist veraltet oder falsch" />
                    {wrongInfo &&
                        <Field name={'wrongInfoText'} type="text" component={renderFieldTextarea} label="Nachricht" />
                    }
                    <Field  name={'missingInfo'} type="checkbox" checked={"false"} onChange={this.handleChange("missingInfo")} component={renderCheckbox} label="Eine wichtige Information fehlt" />
                    {missingInfo &&
                        <Field name={'missingInfoText'} type="text" component={renderFieldTextarea} label="Nachricht" />
                    }
                    <Field   name={'searchProblem'} type="checkbox" checked={"false"} onChange={this.handleChange("searchProblem")} component={renderCheckbox} label="Ich kann nicht finden, wonach ich suche" />
                    {searchProblem &&
                        <Field name={'searchProblemText'} type="text" component={renderFieldTextarea} label="Nachricht" />
                    }
                    <Field  name={'other'} type="checkbox" checked={"false"} onChange={this.handleChange("other")} component={renderCheckbox} label="Anderer Sachverhalt, der nicht in dieser Liste enthalten ist" />
                    {other &&
                        <Field name={'otherText'} type="text" component={renderFieldTextarea} label="Nachricht" />
                    }
                    <div style={{}}>
                        <Reaptcha sitekey="6Lf46HAUAAAAACGT61Y3fOIaEN3wtpZos926lMx0" onVerify={this.onVerify} onExpire={this.onExpire} onError={this.onExpire} />
                    </div>
                    {error && <strong> {error} </strong>}

                    
                    {submitting &&
                        <div style={{ marginTop: 20 }}>
                            <LinearProgress />
                        </div>
                    }
                    {submitted &&
                        <div style={{ marginTop: 20, textAlign: 'center', fontSize: '1.5em' }}>
                            <p >Ihre Nachricht wurde verschickt. Für Ihre Hilfe, besten Dank im Namen aller Nutzer.</p>
                        </div>
                    }
                
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose.bind(this)} color="primary">
              Abbrechen
            </Button>
            {!submitting && !submitted &&
                        <Button type="submit" color="primary">
                        Absenden
                      </Button>
                    }
           
          </DialogActions>
          </form>
        </Dialog>


                   



                    
                 
                

            </div>


        )
    }
}




const form = reduxForm({
    form: 'ReportError',
    enableReinitialize: true,
    //validate:validate
});

ReportError.contextTypes = {
    store: PropTypes.object
};

function mapStateToProps(state) {
    return {
        sent: state.report.sent
    }
}



export default  connect(mapStateToProps, {
    sendReport: sendReport,
})(form(ReportError));