import axios from 'axios';
import { push } from 'react-router-redux'
import {
    ADD_NEW_DOSSIER_SECTION,
    CREATE_DOSSIER,
    DELETE_DOSSIER,
    FETCH_DOSSIER,
    FETCHED_DOSSIERS_CHUNKS,
    FETCHING_DOSSIERS_CHUNKS,
    REMOVE_DOSSIER_SECTION,
    RESET_DOSSIER_SECTION_TO_0,
    SET_DOSSIERS_GRID_FILTERS,
    SET_DOSSIERS_GRID_PAGE_INDEX,
    SET_DOSSIERS_GRID_PAGE_SIZE,
    SET_DOSSIERS_GRID_SORT_CRITERIA,
    UPDATE_DOSSIER,
    FETCH_DOSSIER_CHAPTER,
    FETCH_DOSSIER_CHAPTERS,
    UPDATE_DOSSIER_CHAPTER,
    FETCH_COUNTRIES,
    FETCH_COUNTRY,
    FETCHING_COUNTRY,
    FETCHING_COUNTRIES,
    FETCHED_COUNTRY,
    FETCHED_COUNTRIES,
    UPDATE_COUNTRY,
    TRANSLATE_DOSSIER
} from "../../actions/types";

import { ROOT_URL } from "../../config";


export function goToRoute(route) {
    return function (dispatch) {
        dispatch(push(route));
    }
}

export function fetchDossiersChunks(page, pageSize, sorted, filtered) {
    return function (dispatch) {

        dispatch(fetchingDossiers());

        dispatch(setDossiersGridSortCriteria(sorted));
        dispatch(setDossiersGridFilters(filtered));

        //set temporary timeout for testing
        const payload = {
            include: "chapters"
        }

        //const url = `${ROOT_URL}/marker?_page=${page + 1}&_limit=${pageSize}`
        const url = `${ROOT_URL}/dossiers?filter=${encodeURIComponent(JSON.stringify(payload))}`

        setTimeout(() =>
            axios.get(url, {
                headers: { authorization: localStorage.getItem('token') }
            })
                .then(response => {
                    let data = response.data;
                    let totalRecords = 500//response.headers['x-total-count'];
                    let numberPages = Math.ceil(totalRecords / pageSize);
                    dispatch(fetchedDossiers(data, numberPages));
                })
                .catch(() => {
                    // console.log('error fetching the dossiers');
                }), 200);
    }
}

export function translateDossier(id, options) {
    return function (dispatch) {

        return axios.get(`${ROOT_URL}/dossiers/translate?id=${id}&options=${JSON.stringify(options)}`, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            dispatch({
                type: TRANSLATE_DOSSIER,
                payload: response
            });
        }).catch(() => {
            // console.log('error fetching the dossier');
        });
    }
}

export function fetchDossier(id, type) {
    return function (dispatch) {
        var url = `${ROOT_URL}/dossiers/byId?id=${id}`
        if (type) {
            url += '&type=dach'
        }
        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            dispatch({
                type: FETCH_DOSSIER,
                payload: response
            });
        }).catch(() => {
            // console.log('error fetching the dossier');
        });
    }
}

export function updateCity(country) {
    return function (dispatch) {

        const body = {
            id: country.id,
        }
        delete country.id
        body.data = country

        const url = `${ROOT_URL}/Cities/updateById`
        return axios.post(url, body, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: UPDATE_COUNTRY,
                payload: response
            });
        }).catch((err) => {
            // console.log('error updating the page', err);
        });
    }
}



export function updateCountry(country) {
    return function (dispatch) {

        const body = {
            id: country.id,
        }
        delete country.id
        body.data = country

        const url = `${ROOT_URL}/Countries/updateById`
        return axios.post(url, body, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: UPDATE_COUNTRY,
                payload: response
            });
        }).catch((err) => {
            // console.log('error updating the page', err);
        });
    }
}

export function fetchDossierChapter(id) {
    return function (dispatch) {

        const filter = {
            include: "map"
        }

        return axios.get(`${ROOT_URL}/dossierchapters/${id}?`, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            dispatch({
                type: FETCH_DOSSIER_CHAPTER,
                payload: response
            });
        }).catch(() => {
            // console.log('error fetching the dossier');
        });
    }
}

export function fetchDossierChapters(dossierId) {
    return function (dispatch) {
        dispatch({
            type: FETCH_DOSSIER_CHAPTERS,
            payload: null
        });
        const filter = {
            "where": {
                "type": "situationReport",
                "dossier": dossierId,
            }
        }

        return axios.get(`${ROOT_URL}/dossierchapters?filter=${encodeURIComponent(JSON.stringify(filter))}`, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            console.log('response', response)
            dispatch({
                type: FETCH_DOSSIER_CHAPTERS,
                payload: response
            });
        }).catch((err) => {
            console.log('err', err)
            // console.log('error fetching the dossier');
        });
    }
}


export function createdDossier(response) {
    return {
        type: CREATE_DOSSIER,
        payload: response
    }
}

export function createDossier(props) {
    return function (dispatch) {
        return axios.dossier(`${ROOT_URL}/dossiers`, props, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            dispatch(createdDossier(response));
        }).catch(() => {
            // console.log('error fetching the dossier');
        });
    }
}

export function updateDossier(props) {

    const request = axios.put(`${ROOT_URL}/dossiers/${props.id}`, props, {
        headers: { authorization: localStorage.getItem('token') }
    });

    return {
        type: UPDATE_DOSSIER,
        payload: request
    }
}

export function updateDossierChapter(props) {


    var payload = props
    const filter = {
        id: props.id.toString()
    }

    payload.iframe = payload.iframe || ""
    delete payload.id
    Object.keys(payload).forEach(key => {
        if (payload[key] == null) {
            delete payload[key]
        }
    })

    const url = `${ROOT_URL}/dossierchapters/update?where=${encodeURIComponent(JSON.stringify(filter))}`
    return function (dispatch) {
        return axios.post(url, payload, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {

            dispatch({
                type: UPDATE_DOSSIER_CHAPTER,
                payload: response
            });
        }).catch(() => {

        });
    }
}

export function deletedDossier(dossier) {
    return {
        type: DELETE_DOSSIER,
        dossier: dossier
    }
}

export function deleteDossier(dossier) {
    return function (dispatch) {
        return axios.delete(`${ROOT_URL}/dossiers/${dossier.id}`, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            dispatch(deletedDossier(dossier));
        });
    }
}

export function addNewDossierSection() {
    return {
        type: ADD_NEW_DOSSIER_SECTION,
        payload: null
    }
}

export function resetDossierSections() {
    return {
        type: RESET_DOSSIER_SECTION_TO_0,
        payload: null
    }
}

export function removeSection(id) {
    return {
        type: REMOVE_DOSSIER_SECTION,
        payload: id
    }
}

export function setDossiersGridPageSize(pageSize) {
    return {
        type: SET_DOSSIERS_GRID_PAGE_SIZE,
        payload: pageSize
    }
}

export function setDossiersGridPageIndex(pageIndex) {
    return {
        type: SET_DOSSIERS_GRID_PAGE_INDEX,
        payload: pageIndex
    }
}

export function setDossiersGridSortCriteria(newSorted) {
    return {
        type: SET_DOSSIERS_GRID_SORT_CRITERIA,
        payload: newSorted,
    }
}

export function setDossiersGridFilters(filtered) {
    return {
        type: SET_DOSSIERS_GRID_FILTERS,
        payload: filtered,
    }
}

export function fetchingDossiers() {
    return {
        type: FETCHING_DOSSIERS_CHUNKS,
        payload: true
    }
}

export function fetchedDossiers(data, numberPages) {
    return {
        type: FETCHED_DOSSIERS_CHUNKS,
        payload: {
            data: data,
            numberPages: numberPages,
            loading: false
        }
    }
}

export function fetchCountry(id) {
    return function (dispatch) {

        dispatch(fetchingCountry());

        const payload = {
            include: "children",
            include: "chapters"
        }
        const url = `${ROOT_URL}/Countries/${id}?filter=${encodeURIComponent(JSON.stringify(payload))}`

        setTimeout(() =>
            axios.get(url, {
                headers: { authorization: localStorage.getItem('token') }
            })
                .then(response => {
                    let data = response.data;

                    dispatch(fetchedCountry(data));
                })
                .catch(() => {

                }), 200);
    }
}

export function fetchingCountry() {
    return {
        type: FETCHING_COUNTRY,
        payload: true
    }
}

export function fetchedCountry(data) {

    return {
        type: FETCHED_COUNTRY,
        payload: {
            data: data,
            loading: false
        }
    }
}

export function fetchCountries(type) {
    return function (dispatch) {

        dispatch(fetchingCountries());
        const fields = { id: true, title: true, polity: true, riskLevel: true, dossier: true, children: true }
        const payload = {
            /*"where":{
                "lang":lang || "de" 
            },*/
            include: 'children',
            relation: "children",
            scope: { // further filter the owner object
                fields: fields
            },
            order: "title",
            fields: fields
        }
        var model = "Countries"
        if (type) {
            model = "DachCountries"
        }
        const url = `${ROOT_URL}/${model}/getList?filter=${encodeURIComponent(JSON.stringify(payload))}`

        setTimeout(() =>
            axios.get(url, {
                headers: { authorization: localStorage.getItem('token') }
            })
                .then(response => {
                    let data = response.data;

                    dispatch(fetchedCountries(data));
                })
                .catch((err) => {

                }), 200);
    }
}

export function fetchingCountries() {
    return {
        type: FETCHING_COUNTRIES,
        payload: true
    }
}

export function fetchedCountries(data) {

    return {
        type: FETCHED_COUNTRIES,
        payload: {
            data: data,
            loading: false
        }
    }
}