const drawerWidth = 0;

export default (theme) => ({
    root: {
        width: '100%',
        zIndex: 1,
        overflow: 'hidden',
    },
    appFrame: {
        position: 'relative',
        display: 'flex',
        fallbacks: [
            { display: '-webkit-box' },
            { display: '-moz-box' },
            { display: '-ms-flexbox' },
            { display: '-webkit-flex' }
          ],
        width: '100%',
        minHeight: '100vh',
    },
    content: {
        width: '100%',
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        //marginTop: 71,
    },
    /*content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: 1 * 3,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },*/
    'content-left': {
        marginLeft: -drawerWidth,
    },
    'content-right': {
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    'contentShift-left': {
        marginLeft: 0,
    },
    'contentShift-right': {
        marginRight: 0,
    }
});