import React from 'react';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, Menu, ListItemText, IconButton } from '@material-ui/core';
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next';
import { getLang, setLang } from './actions'
import Flag from "react-flags";

const langs = [
    {
        lang: "de",
        title: "Deutsch",
        iso3: "DEU"
    },/*,
    {
        lang: "fr",
        title: "Francais",
        iso3: "FRA"
    }*/
    {
        lang: "en",
        title: "English",
        iso3: "GBR"
    }
]

class SelectFlag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            lang:"",
            stateLang:this.props.lang
        };

    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    UNSAFE_componentWillMount() {
        this.props.getLang()
    }

    setLang(lang) {
        const { i18n } = this.props
        i18n.changeLanguage(lang)
        this.props.setLang(lang)
        this.setState({ anchorEl: null });
    }

    onSelect(lang) {
        this.setState({ anchorEl: null, stateLang:lang });
        if (this.props.onSelect) {
            this.props.onSelect(lang)
        }
    }

    render() {

        var { lang, setLang } = this.props
        const { anchorEl, stateLang } = this.state
        if (this.props.onSelect) {
            lang = stateLang
        }
        return (<div>
            <div>
                {lang &&
                    <div>
                        {langs.map(_lang => {
                            return (
                                <div key={_lang.lang}>
                                    {_lang.lang == lang &&
                                        <IconButton
                                            aria-owns={anchorEl ? 'simple-menu' : null}
                                            aria-haspopup="true"
                                            onClick={this.handleClick.bind(this)}>
                                            <Flag
                                                style={{paddingTop:5}}
                                                name={_lang.iso3}
                                                format="png"
                                                pngSize={24}
                                                shiny={true}
                                                basePath={"/images/flags"}
                                                alt=""
                                            />
                                        </IconButton>
                                    }
                                </div>)
                        })}

                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                        >
                            {langs.map(_lang => {
                                return (
                                    <MenuItem key={_lang.lang} onClick={this.props.onSelect ? this.onSelect.bind(this, _lang.lang) : this.setLang.bind(this, _lang.lang)}>

                                        <div
                                            aria-owns={anchorEl ? 'simple-menu' : null}
                                            aria-haspopup="true"
                                            onClick={this.handleClick}>
                                            <Flag
                                                name={_lang.iso3.toUpperCase()}
                                                format="png"
                                                pngSize={24}
                                                shiny={true}
                                                basePath={"/images/flags"}
                                                alt={_lang.title}
                                            />
                                        </div>
                                        <ListItemText inset primary={_lang.title} />
                                    </MenuItem>
                                )
                            })}



                        </Menu>
                    </div>
                }
            </div>

        </div>)


    }

}

function mapStateToProps(state) {

    return {
        lang: state.lang.lang,
    }
}
const styles = {

}
export default withNamespaces()(withStyles(styles, { name: 'SelectFlag' })(connect(mapStateToProps, {
    setLang: setLang,
    getLang: getLang

})(SelectFlag)));


