import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderField, renderHiddenField,renderFieldTextarea } from "../../components/field_formatters";
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogActions, LinearProgress } from "@material-ui/core";

import { updateCustomer } from "./actions";

class CustomerForm extends Component {

    constructor(args) {
        super(args)
        this.state = {
            open: true,
            updating: false,
        }

    }

    componentDidMount() {
        this.setState({open:true})
    }

    handleFormSubmit(formProps) {
        console.log('formProps', formProps)
        if (formProps.title) {
            this.setState({ updating: true })
            this.props.updateCustomer(formProps).then(res => {
                this.setState({ updating: false })
                this.handleClose()
            }).catch(error => {
                this.setState({ updating: false, error })
            })
        }
    }

    renderErrorMessage() {
        if (this.props.errorMessage) {
            return (
                <div>
                    <strong></strong> {this.props.errorMessage}
                </div>
            )
        }
    }

    handleClose = value => {
        this.setState({ open: false });
        if (this.props.onDismiss) {
            this.props.onDismiss()
        }
    };
// <Field name="password" type="password" component={renderField} label="Passwort" />
                         
    render() {
        const { error, handleSubmit, initialValues,admin } = this.props;
        const { open, updating } = this.state
        console.log('admin', admin)
        return (
            
                <Dialog open={open} onClose={this.handleClose.bind(this)} aria-labelledby="Neuer Kunde" style={{ minWidth: '50%' }}>
                     <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                    <DialogTitle id="simple-dialog-title">{ initialValues && initialValues.id ? 'Kunde bearbeiten' :'Neuer Kunde' }</DialogTitle>
                   
                    <div style={{ padding: 20, }}>
                        <Field name="id" type="hidden" component={renderHiddenField} />

                        <Field name="title" type="text" component={renderField} label="Name" />
                        <Field name="email" type="email" component={renderField} label="Email" />
                       {admin &&
                        <Field name="note" type="text" component={renderFieldTextarea} label="Notiz" />
                       }

                       
                    </div>
                    <DialogActions>
                        {!updating &&
                            <div>
                                <Button onClick={this.handleClose.bind(this)} style={{ marginTop: 35 }} variant="contained">Abbrechen</Button>{' '}
                                <Button type="submit" style={{ marginTop: 35 }} variant="contained">Speichern</Button>{' '}
                            </div>
                        }
                        {updating &&
                            <LinearProgress />
                        }
                    </DialogActions>
                    </form>
                </Dialog>
           
        )
    }
}

const form = reduxForm({
    form: 'CustomerForm',

});

CustomerForm.contextTypes = {
    store: PropTypes.object
};

function mapStateToProps(state) {
    return { 
        admin: state.auth.admin,
        errorMessage: state.auth.error,
        updated:state.customers.updated,
        initialValues:state.customers.customer
    }
}

export default connect(mapStateToProps, { updateCustomer })(form(CustomerForm));