import {
    FETCH_LAYER,
    FETCH_LAYERS,
    FETCH_LAYERLIST,
    FETCH_MAP,
    DELETE_POST,
    SET_POSTS_GRID_PAGE_SIZE,
    SET_POSTS_GRID_PAGE_INDEX,
    SET_POSTS_GRID_SORT_CRITERIA,
    SET_POSTS_GRID_FILTERS,
    FETCHED_LAYER,
    FETCHING_LAYER,
    FETCHED_LAYERS,
    FETCHING_LAYERS,
    FETCHED_LAYERLIST,
    FETCHING_LAYERLIST,
    FETCHED_MAP,
    FETCHING_MAP,
    ADD_NEW_POST_SECTION,
    RESET_POST_SECTION_TO_0,
    REMOVE_POST_SECTION
} from "../../actions/types";

import * as _ from "lodash";

const INITIAL_STATE = {
    layers: {},
    layerList: [],
    map: {},
    loading: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case FETCH_LAYER:
            return {
                ...state,
                layers: action.payload.data,
                layer: (action.payload.data && action.payload.data.length > 0) ? action.payload.data[0] : [],


            };

        case FETCH_LAYERS:
            return {
                ...state,
                layers: action.payload.data,

            };
            case FETCH_LAYERLIST:
            return {
                ...state,
                layerList: action.payload.data,

            };

        case FETCH_MAP:
            return {
                ...state,
                map: action.payload.data,

            };

        case RESET_POST_SECTION_TO_0:
            return { ...state, postsections: [] };

        case ADD_NEW_POST_SECTION:

            let newPostSections = [];
            let elm = 0;

            if (state.postsections.length) {
                newPostSections = state.postsections.slice();
                elm = newPostSections[newPostSections.length - 1];
            }

            newPostSections.push(parseInt(elm) + 1);

            return {
                ...state,
                postsections: newPostSections
            };

        case REMOVE_POST_SECTION:

            let new_sections1 = state.postsections.filter(function (el) {
                return el != action.payload;
            });

            return {
                ...state,
                postsections: new_sections1
            };

            return state;

        case FETCHING_LAYER:
            return {
                ...state,
                loading: action.payload
            }

        case FETCHED_LAYER:
            return {
                ...state,
                layers: action.payload.data,
                layer: (action.payload.data && action.payload.data.length > 0) ? action.payload.data[0] : [],

            };

        case FETCHING_LAYERS:
            return {
                ...state,
                loading: action.payload
            }

        case FETCHED_LAYERLIST:
            return {
                ...state,
                layerList: action.payload.data,
            };

        case FETCHING_LAYERLIST:
            return {
                ...state,
                loading: action.payload
            }

        case FETCHED_LAYERS:
            return {
                ...state,
                layers: action.payload.data,
            };
        case FETCHING_MAP:
            return {
                ...state,
                loading: action.payload
            }

        case FETCHED_MAP:
            return {
                ...state,
                map: action.payload.data,
            };

        case DELETE_POST:
            var allPosts = state.all.filter(function (obj) {
                return obj.id !== action.post.id;
            });
            return { ...state, all: allPosts };

        case SET_POSTS_GRID_PAGE_SIZE:
            var pGridData = {
                ...state.postsGridData,
                pageSize: action.payload
            };
            return { ...state, postsGridData: pGridData };

        case SET_POSTS_GRID_PAGE_INDEX:
            var pGridData = {
                ...state.postsGridData,
                pageIndex: action.payload
            };
            return { ...state, postsGridData: pGridData };

        case SET_POSTS_GRID_SORT_CRITERIA:
            var pGridData = {
                ...state.postsGridData,
                gridSortCriteria: action.payload
            };
            return { ...state, postsGridData: pGridData };

        case SET_POSTS_GRID_FILTERS:
            var pGridData = {
                ...state.postsGridData,
                gridFilters: action.payload
            };
            return { ...state, postsGridData: pGridData };

        default:
            return state;
    }
}