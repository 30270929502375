import { push } from 'react-router-redux';
import axios from 'axios';
import { ROOT_URL } from "../../config";


export function goToRoute(route) {
    return function (dispatch) {
        dispatch(push(route));
    }
}

export function fetchUsers(customer, userId) {
    return function (dispatch) {
        var filter = {
            order: 'title ASC',
            //where: { role:"user" } ,
            include: ['userPositions'],

        }
        if (customer) {
            filter.where = { customer:customer, /*role:"user"*/  }
        }
        console.log('filter', filter)
        const url = `${ROOT_URL}/users?filter=${encodeURIComponent(JSON.stringify(filter))}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: 'FETCH_USERS',
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the page', err);
        });
    }
}

export function fetchUser(id) {
    return function (dispatch) {

        var filter = {
           // include: "multipolar"
        }
        const url = `${ROOT_URL}/users/${id}`
        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: 'FETCH_USER',
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the page', err);
        });
    }
}

export function fetchUserInfo(id) {
    return function (dispatch) {

      
        const url = `${ROOT_URL}/users/getInfo`
        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: 'FETCH_USER_INFO',
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the page', err);
        });
    }
}