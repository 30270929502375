import React from 'react';
import Snackbar from '@material-ui/core//Snackbar';
import Button from '@material-ui/core//Button';
import { goToRoute } from './actions'
import { connect } from 'react-redux';

class CookieBanner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            snackBarOpen: !localStorage.getItem('cookie-law', false)
        };

    }

    handleSnackBarClick = () => {
        this.props.goToRoute("/dataProtection")
    };

    handleSnackBarClose = (event, reason) => {

        localStorage.setItem('cookie-law', true)

        this.setState({ snackBarOpen: false });
    };

    render() {
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={this.state.snackBarOpen}
                //autoHideDuration={6000}
                onClose={this.handleSnackBarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">RiskCompass setzt auf dieser Webseite Cookies und andere Technologien ein, um Ihnen den vollen Funktionsumfang unseres Angebotes zu ermöglichen. Durch die weitere Nutzung dieser Webseite erklären Sie sich damit einverstanden. Mehr in der Datenschutzerklärung.</span>}
                action={[ <Button key="ok" color="primary" size="small" onClick={this.handleSnackBarClick}>
                Mehr Informationen
                    </Button>,
                    <Button key="ok" color="primary" size="small" onClick={this.handleSnackBarClose}>
                        OK
                            </Button>

                ]}
            />
        )
    }
}

function mapStateToProps(state) {
    
    return {
        
    }
}

export default connect(mapStateToProps, {
    goToRoute: goToRoute,
})(CookieBanner);

