import React, { Component } from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {renderField} from "../../../components/field_formatters";
import PropTypes from 'prop-types';
import {Button} from "@material-ui/core";
import validateLogin from "./validation";
import {signInUser} from "../actions";
import {fetchUserInfo } from '../../customer/actions'
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next'

class SignInForm extends Component {

    handleFormSubmit(formProps) {
        this.props.signInUser(formProps).then(res=>{
            console.log('should fetch')
            this.props.fetchUserInfo()
        });
    }

    renderErrorMessage() {
        if (this.props.errorMessage) {
            return (
                <div>
                    <strong></strong> { this.props.errorMessage }
                </div>
            )
        }
    }

    render() {
        const {error, t, handleSubmit} = this.props;

        return (
            <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                <Field name="username" type="text" component={renderField} label="Email"/>
                <Field name="password" type="password" component={renderField} label="Passwort"/>
                {error && <strong> {error} </strong>}
                {this.renderErrorMessage()}
                <div style={{textAlign:'center'}}>
                <Button type="submit" style={{marginTop:35}} variant="raised">{t("sideMenu.Anmelden")}</Button>{' '}
                </div>
            </form>
        )
    }
}

const form = reduxForm({
    form: 'SignInForm',
    validateLogin
});

SignInForm.contextTypes = {
    store: PropTypes.object
};

function mapStateToProps(state) {
    return { errorMessage: state.auth.error }
}

export default withNamespaces()(connect(mapStateToProps, {signInUser,fetchUserInfo})(form(SignInForm)));