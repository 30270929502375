import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import Button from '@material-ui/core/Button';
import {
    Tooltip,
    Hidden,
    Tabs, Tab
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import {
    deleteDossier,
    fetchDossier,
    fetchDossiersChunks,
    setDossiersGridFilters, setDossiersGridPageIndex, setDossiersGridPageSize,
    setDossiersGridSortCriteria,
    goToRoute,
    fetchCountries
} from "./actions";
import { Link } from 'react-router-dom'
import { IconButton } from '@material-ui/core';
import Notifications, { success } from 'react-notification-system-redux';
import PropTypes from 'prop-types';
import './style/dossier.css';
import * as _ from "lodash";
import { notificationDuration } from "../../config";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ArticleList from '../articleList'
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import PrintIcon from '@material-ui/icons/Print'
import ReportIcon from '@material-ui/icons/ReportProblem'
import OfferForm from '../offerForm'
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FlagIcon from '@material-ui/icons/Flag';
import CityIcon from '@material-ui/icons/LocationCity';
import Avatar from '@material-ui/core/Avatar'
import { List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from '@material-ui/core';

import { TreeView } from "@bosket/react"
import { Input } from '@material-ui/core';
import { Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import Map from '../map/map_index'
import ReactMarkdown from 'react-markdown'
import RiskPotentials from '../riskPotentials'
import { Markdown } from 'react-showdown';
import TextField from '@material-ui/core/TextField';
import { InputLabel, InputAdornment } from '@material-ui/core'
import { fetchArticlesByTagTitles } from '../articles/actions'
import ContactForm from '../contactForm'
import RegisterUser from '../registerUser'
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next'
//import ReactGA from 'react-ga';
import ReportError from '../reportError';
window.moment = moment
//Import the pagination component
//import Pagination from 'materialui-pagination';
//Material UI Components
//import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
//import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

var _search = null

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 450,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
})


class DossierList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            toDelete: {
                popupIsOpen: false,
                dossier: null
            },
            tabValue: "news",
            rowsPerPage: [5, 10, 15],
            rows: [],
            currentId: null,
            numberOfRows: 5,
            page: 1,
            showReport: false,
            sort: 'alphabet',
            treeOpen: true,
            total: undefined,
            selection: [],
            width: window.innerWidth,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {


        console.log('did mount')
        //ReactGA.initialize('UA-130702159-1');
        //ReactGA.pageview(window.document.location.pathname + window.location.search)
        if (this.props.match.params.id) {
            this.setState({ currentId: this.props.match.params.id })
            var dach = document.location.pathname.startsWith("/dach") ? "dach" : null
            this.props.fetchDossier(this.props.match.params.id, dach)
        } else {
            this.setState({ currentId: null })
        }
        //// console.log('fetch ', this.props.lang)
        var dach = document.location.pathname.startsWith("/dach") ? "dach" : null

        this.props.fetchCountries(dach)
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    UNSAFE_componentWillMount() {

        var _dach = document.location.pathname.startsWith("/dach") ? "dach" : null
        this.dach = _dach
        this.unlisten = this.props.history.listen((location, action) => {
            var dach = document.location.pathname.startsWith("/dach") ? "dach" : null
            console.log('this.dach', this.dach)
            console.log('dach', dach)
            //console.log('this.props.match.params.id', this.props.match.params.id)
            if (this.dach != dach) {

                this.props.fetchCountries(dach)

            }
            this.dach = dach


        });
    }


    componentWillUnmount() {
        this.unlisten();
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth });
    }

    makeLinksExternal() {
        var links = document.links;
        for (var i = 0, linksLength = links.length; i < linksLength; i++) {
            if (links[i].hostname != window.location.hostname) {
                links[i].target = "_blank";
                links[i].setAttribute("rel", "noopener noreferrer");
                links[i].className += " externalLink";
            } else {
                links[i].className += " localLink no-print";
            }
        }
    }

    UNSAFE_componentWillUpdate(nextProps) {

    }

    dispatchNotification(fn, timeout) {
        setTimeout(() => {
            this.context.store.dispatch(fn(notificationOpts));
        }, timeout);
    }

    onDeleteClick(dossier) {
        this.setState({
            toDelete: {
                popupIsOpen: true,
                dossier: dossier
            }
        });
    }

    componentDidUpdate() {

        this.makeLinksExternal()
    }

    confirmedDeleteDossier() {
        this.props.deleteDossier(this.state.toDelete.dossier).payload.then(() => {
            this.setState({
                toDelete: {
                    popupIsOpen: false,
                    dossier: null
                }
            });
            this.dispatchNotification(success, 200);
        });
    }

    handleRequestClose() {
        this.setState({
            toDelete: {
                popupIsOpen: false,
                dossier: null
            }
        });
    }

    fetchDossiersChunks(rows) {
        //// console.log("rows", rows);
    }

    handleTreeOpen() {
        this.setState({ treeOpen: !this.state.treeOpen })
    }

    infoTitle(key) {
        const { t } = this.props
        return t("dossier." + key)
    }



    renderGeneralInfo(item, infoLink) {
        //console.log('renderGeneralInfo', info) // ["capital", "area", "population"]
        const { lang, t } = this.props
        return (
            <div className={'generalInfo visible-print'} style={{ marginTop: 20 }}>
                {["capital", "area", "population", "currency", "languages"].map(key => {

                    return (
                        <div key={key}>
                            {item[key] && item[key][lang] &&
                                <div><strong>{this.infoTitle(key)}</strong> {item[key][lang]}
                                    {infoLink && infoLink[lang] && infoLink[lang] != "" && key == "entryRequirements" &&



                                        <span ><a target="_blank" href={infoLink[lang]}> Info AA</a>).</span>



                                    }
                                </div>
                            }
                        </div>
                    )
                })}

                {infoLink && infoLink[lang] && infoLink[lang] != "" &&

                    <div>


                        <span><h6>{t("dossier.Einreisebestimmungen")}</h6></span>
                    </div>
                }

            </div>
        )
    }

    /*


    <GridList cellHeight={180}>
                    <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                      <Subheader component="div">December</Subheader>
                    </GridListTile>
                    {dossiers.map(item => (
                      <GridListTile key={item.id}>
                        <img src={item.id} alt={item.title} />
                        <GridListTileBar
                          title={item.title}
                          subtitle={<span>by: {item.creator}</span>}
                          actionIcon={
                            <span>
                                <Link to={"dossiers/edit/" + item.id}>
                                    <IconButton className="smallIconButton">
                                        <EditIcon />
                                    </IconButton>
                                </Link>{' '}
                                <IconButton className="smallIconButton" onClick={this.onDeleteClick.bind(item)}>
                                    <DeleteIcon />
                                </IconButton>
                            </span>                            
                          }
                        />
                        <div dangerouslySetInnerHTML={{__html: item.html}} />
                      </GridListTile>
                    ))}                    
                  </GridList>
                <Notifications notifications={notifications} />



    */


    renderChapterTitle(type) {
        const { t } = this.props

        return t("dossier." + type)
    }

    editDossier(dossier) {

    }




    scrollTo(chapterId) {
        console.log('chapterId', chapterId)
        const dossier = ReactDOM.findDOMNode(this.dossierRef)
        if (chapterId) {
            //// console.log('chapterId', chapterId)

            const testNode = ReactDOM.findDOMNode(this[chapterId])
            //// console.log('testNode', testNode)
            //// console.log('testNode.offsetTop', testNode.offsetTop)

            dossier.scrollTop = testNode.offsetTop - 80//(0, testNode.offsetTop - 80);
        } else {
            dossier.scrollTop = 0//(0, 0);
        }


    }

    handlePrint() {
        window.print()
    }

    closeReport() {
        this.setState({ showReport: false })
    }
    showReportDialog() {
        this.setState({ showReport: true })
    }


    renderDossier() {

        const { dossier, admin, articles, lang, t, authenticated } = this.props
        const { treeOpen, width, showReport } = this.state
        console.log('authenticated', authenticated)
        const singleDossierViewStyle = width < 600 ?
            { display: 'block', margin: '12px', width: 'calc( 100% - 24px)', height: 'auto', overflowY: 'scroll', WebkitOverflowScrolling: 'touch' }
            : { display: 'block', width: '100%', overflowY: 'scroll', WebkitOverflowScrolling: 'touch', marginRight: '0px', marginLeft: '0px', height: '100%' };

        return (
            <Paper /*style={singleDossierViewStyle}*/ id={"content"} ref={ref => { this.dossierRef = ref }} className={'singleDossierView visible-print block '}>
                <Card>
                    <CardContent>

                        <img src={`${window.location.origin}/images/flag/${dossier.iso2.toLowerCase()}.jpg`} style={{
                            width: 150, borderColor: 'grey', borderWidth: 1, boxShadow: "4px 3px 8px 1px #969696",
                            WebkitBoxShadow: "4px 3px 8px 1px #969696"
                        }}></img>

                        <h1>

                            <strong>
                                {dossier.title[lang]}
                                <Tooltip title="Drucken">
                                    <IconButton className={"no-print"} style={{ float: 'right' }} onClick={this.handlePrint.bind(this)}>
                                        <PrintIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Fehler entdeckt?">
                                    <IconButton className={"no-print"} style={{ float: 'right' }} onClick={this.showReportDialog.bind(this)}>
                                        <ReportIcon style={{ color: "red" }} />
                                    </IconButton>
                                </Tooltip>
                                {admin === true &&
                                    <Link className={"no-print"} to={"/dossier/edit/" + dossier.id}>
                                        <IconButton>
                                            <EditIcon />
                                        </IconButton>
                                    </Link>
                                }
                            </strong>
                        </h1>
                        {showReport &&
                            <ReportError dossierTitle={dossier.title[lang]} onFinish={this.closeReport.bind(this)} />
                        }

                        <h2>
                            <strong>
                                {t("dossier.Gefährdungstufe")} {dossier.riskLevel && dossier.riskLevel.toString()}
                            </strong>
                        </h2>
                        {dossier && dossier.riskPotentials &&
                            <Grid container>
                                <Grid item lg={6} sm={6}>
                                    <div style={{ marginTop: 5 }}>
                                        <RiskPotentials riskPotentials={dossier.riskPotentials} />
                                    </div>
                                </Grid>
                                <Grid item lg={6} sm={6}>
                                    {dossier.chapters && dossier.chapters.length > 0 &&
                                        <div style={{ marginLeft: 10, marginTop: 0 }}>
                                            <h2 style={{ marginTop: 0 }}>
                                                <strong>{t("dossier.Inhaltsverzeichnis")}</strong>
                                            </h2>
                                            <ul style={{ margin: 0, padding: '0px 0 0 20px', listStyleType: "disc", listStylePosition: 'outside' }}>
                                                {dossier && dossier.summary && dossier.type == "country" &&
                                                    <li key={"summary2"} style={{ fontSize: '1rem', color: '#0a5586', marginLeft: 0 }} onClick={this.scrollTo.bind(this, "summary")}>
                                                        {this.renderChapterTitle("AufEinenBlick")}
                                                        {admin === true &&
                                                            <Link to={"/dossier/edit/" + dossier.id}>
                                                                <EditIcon style={{ paddingLeft: 15 }} />
                                                            </Link>
                                                        }
                                                    </li>
                                                }
                                                {dossier.chapters && dossier.chapters.length > 0 && dossier.chapters.filter(chapter => {
                                                    return chapter.markdown && chapter.markdown[lang] != "undefined"
                                                }).map(chapter => {
                                                    return <li key={chapter.id} style={{ fontSize: '1rem', color: '#0a5586' }} onClick={this.scrollTo.bind(this, chapter.id)}>
                                                        {this.renderChapterTitle(chapter.type)}
                                                        {admin === true &&
                                                            <Link to={"/dossierchapter/edit/" + chapter.id}>

                                                                <EditIcon style={{ paddingLeft: 15 }} />

                                                            </Link>
                                                        }
                                                    </li>

                                                })}
                                            </ul>
                                        </div>
                                    }
                                </Grid>

                            </Grid>



                        }
                        {dossier && !dossier.riskPotentials &&
                            <Grid container>

                                <Grid item lg={12} sm={12}>
                                    {dossier.chapters && dossier.chapters.length > 0 &&
                                        <div style={{ marginLeft: 10, marginTop: 0 }}>
                                            <h2 style={{ marginTop: 0 }}>
                                                <strong>{t("dossier.Inhaltsverzeichnis")}</strong>
                                            </h2>
                                            <ul style={{ margin: 0, padding: '0px 0 0 20px', listStyleType: "disc", listStylePosition: 'outside' }}>
                                                {dossier && dossier.summary && dossier.type == "country" &&
                                                    <li key={"summary2"} style={{ fontSize: '1em', color: '#0a5586', marginLeft: 0 }} onClick={this.scrollTo.bind(this, "summary")}>
                                                        {this.renderChapterTitle("AufEinenBlick")}
                                                        {admin === true &&
                                                            <Link to={"/dossier/edit/" + dossier.id}>
                                                                <EditIcon style={{ paddingLeft: 15 }} />
                                                            </Link>
                                                        }
                                                    </li>
                                                }
                                                {dossier.chapters && dossier.chapters.length > 0 && dossier.chapters.filter(chapter => {
                                                    return chapter.markdown && chapter.markdown[lang] != "undefined"
                                                }).map(chapter => {
                                                    return <li key={chapter.id} style={{ fontSize: '1em', color: '#0a5586' }} onClick={this.scrollTo.bind(this, chapter.id)}>
                                                        {this.renderChapterTitle(chapter.type)}
                                                        {admin === true &&
                                                            <Link to={"/dossierchapter/edit/" + chapter.id}>
                                                                <EditIcon style={{ paddingLeft: 15 }} />
                                                            </Link>
                                                        }

                                                    </li>
                                                })}
                                            </ul>
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        }

                        {dossier.generalInfo && dossier.type == "country" &&
                            this.renderGeneralInfo(dossier.generalInfo, dossier.infoLink)
                        }

                    </CardContent>
                </Card>


                {dossier.summary && dossier.summary[lang] && dossier.summary[lang].length > 0 &&
                    <Card ref={ref => this["summary"] = ref} >
                        <CardContent>
                            <h2>
                                <strong>{t("dossier.AufEinenBlick")}</strong>
                            </h2>

                            <Markdown markup={dossier.summary[lang]} tables={true} />
                            {dossier.summaryNotes && dossier.summaryNotes[lang] && dossier.summaryNotes[lang].length > 0 &&
                                <Markdown markup={dossier.summaryNotes[lang]} tables={true} />
                            }


                            <a style={{ cursor: 'pointer' }} className={'no-print'} onClick={this.scrollTo.bind(this, null)}>{t("dossier.Zum Seitenanfang")}</a>
                        </CardContent>
                    </Card>
                }

                {!dossier.chapters &&
                    <Card>
                        {!authenticated && (dossier.type == "country" || dossier.type == "city") &&
                            <CardContent>
                                <RegisterUser />
                            </CardContent>
                        }
                        {authenticated && (dossier.type == "country" || dossier.type == "city") &&
                            <CardContent>
                                <h2>Wenn Sie das <Link to="/offer">vollständige Angebot</Link> der RiskCompass Länder- und/oder Städtedatenbanken nutzen möchten, können Sie hier ihre Lizenz bestellen. </h2>
                                <br />
                                <OfferForm />
                            </CardContent>
                        }
                    </Card>
                }

                {dossier.chapters && dossier.chapters.length > 0 && dossier.chapters.filter(chapter => {
                    return chapter.markdown && chapter.markdown[lang] != "undefined"
                }).map(chapter => {
                    return (
                        <div key={chapter.id} className="chapter" ref={ref => { this[chapter.id] = ref }}>
                            <Card >

                                <CardContent>
                                    {chapter.type &&
                                        <h2><strong>{this.renderChapterTitle(chapter.type)}</strong>
                                            {admin === true &&
                                                <Link to={"/dossierchapter/edit/" + chapter.id}>
                                                    <IconButton>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Link>
                                            }</h2>
                                    }

                                    {chapter.iframe &&
                                        <div>
                                            <iframe style={{ border: '1px solid #666' }} src={chapter.iframe} width={'80%'} height={'600'} scrolling="no"></iframe>

                                            <span><a target="_blank" href={chapter.iframe}>Größere Karte anzeigen</a></span>
                                        </div>
                                    }


                                    {chapter.mapId &&

                                        <Map containerStyle={{
                                            height: '500px',
                                            width: "100%"
                                        }}
                                            saveBounds={admin}
                                            mapId={chapter.mapId}
                                            featureCollection={chapter.type != "riskGeography" ? articles.featureCollection : null}
                                        />
                                    }
                                    {chapter.type == "situationReport" && chapter.news && chapter.news[lang] &&
                                        <div style={{ border: '1px solid black', padding: 15, paddingTop: 0, paddingBottom: 0 }}>
                                            <div>
                                                <p>
                                                    Das »Bundesministerium des Auswärtigen« informiert unter der Rubrik <a href={chapter.link}>»Aktuelles«</a> auf der Website <a href="https://www.auswaertiges-amt.de/de/ReiseUndSicherheit">»Sicher Reisen«</a> über die aktuellen Entwicklungen in {dossier.title[lang]}.
                                                </p><p>
                                                    Nachfolgend ein Auszug der aktuellen Lageeinschätzung, Datum: {moment(chapter.updated).format("DD.MM.YYYY HH:mm")}
                                                </p>

                                            </div>   </div>
                                    }
                                    {chapter.news && chapter.news[lang] &&
                                        <Markdown markup={chapter.news[lang]} tables={true} />
                                    }
                                    {chapter.type == "entryRequirements" &&
                                        <div style={{ border: '1px solid black', padding: 15, paddingTop: 0, paddingBottom: 0 }}>
                                            <div>
                                                <div><p>Es gelten die vom »Bundesministerium des Auswärtigen« unter der Rubrik <a href={chapter.link}>»Einreise und Zoll«</a>auf der Website <a href="https://www.auswaertiges-amt.de/de/ReiseUndSicherheit">»Sicher Reisen«</a> veröffentlichten »Einreise- und Einfuhrbestimmungen für deutsche Staatsangehörige«.
                                                </p><p>
                                                        Nachfolgend ein Auszug der o.g. Bestimmungen, Datum: {moment(chapter.updated).format("DD.MM.YYYY HH:mm")}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    }


                                    <Markdown markup={chapter.markdown[lang]} tables={true} />

                                    {chapter.type == "generalSafetyInstructions" && chapter.criminal && chapter.criminal[lang] &&
                                        <div>
                                            <br />
                                            <Markdown markup={chapter.criminal[lang]} tables={true} ></Markdown>

                                        </div>
                                    }
                                    {chapter.type == "entryRequirements" &&
                                        <h6>
                                            {t("dossier.LetzteAktualisierung")}: {moment(chapter.updated).format("DD.MM.YYYY")}
                                        </h6>
                                    }
                                    <a style={{ cursor: 'pointer' }} className={'no-print'} onClick={this.scrollTo.bind(this, null)}>{t("dossier.Zum Seitenanfang")}</a>
                                </CardContent>
                            </Card>


                        </div>
                    )
                })

                }

                <Card >
                    <CardContent>
                        <span><b>{t("dossier.LetzteAktualisierung")}: {moment(dossier.updated).format("DD.MM.YYYY")}</b></span>
                        <span><h6><strong>{t("dossier.Hinweis")}</strong>{t("dossier.Dieser Text")}</h6></span>
                        <span><h6>{t("dossier.Copyright")}</h6></span>

                    </CardContent>
                </Card>

            </Paper>
        )
    }



    onClick(item) {
        //console.log('item', item)
        //this.props.fetchDossier(item.dossier)
        var route = document.location.pathname.startsWith("/dach") ? "/dach/" : '/dossiers/'
        this.props.goToRoute(route + item.dossier)
        //this.props.history.push('/dossiers/' + item.dossier)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.match.params.id && nextProps.match.params.id != this.props.match.params.id) {
            this.setState({ currentId: nextProps.match.params.id })
            var dach = document.location.pathname.startsWith("/dach") ? "dach" : null
            this.props.fetchDossier(nextProps.match.params.id, dach)

        } else if (!nextProps.match.params.id) {
            this.setState({ currentId: null })
        }
        //// console.log('nextProps.dossier', nextProps.dossier)
        //// console.log('this.props.dossier', this.props.dossier)

        if (nextProps.lang != this.props.lang) {
            //  this.props.fetchCountries(nextProps.lang)
        }

        if (nextProps.dossier) {
            if (!this.props.dossier || (this.props.dossier && nextProps.dossier.id != this.props.dossier.id)) {
                console.log('nextProps.dossier', nextProps.dossier)

                var tags = [
                    //nextProps.dossier.title.trim().toLowerCase(),

                    nextProps.dossier.tag
                ]
                if (!nextProps.dossier.police) {
                    tags.push("nachricht")
                    this.props.fetchArticlesByTagTitles(tags, { operator: 'and', featureCollection: true, limit: 50 })

                } else if (nextProps.dossier.police) {
                    tags.push("polizeinachricht")
                    /*  tags.push("brand")
                      tags.push("sexualdelikt")
                      tags.push("eigentum")*/


                    this.props.fetchArticlesByTagTitles(tags, { operator: 'and', operator2: "or", tags2: ["brand", "eigentum", "gewalt", "sexualdelikt", "drogen"], featureCollection: true, limit: 50 })

                }

                //// console.log('fetchArticles ')
                //this.props.fetchDossier
            }
        } else {
            //// console.log('no dossier')
            this.setState({ currentId: null })
        }



    }

    renderCountry(item) {
        const { lang } = this.props
        var color = ""
        var fontColor = ""
        switch (parseInt(item.riskLevel)) {
            case 0:
                color = '#00ffff'
                fontColor = 'black'
                break;
            case 1:
                color = '#00ff00'
                fontColor = 'black'
                break;
            case 2:
                color = '#ffff00'
                fontColor = 'black'
                break;
            case 3:
                color = '#ffa500'
                fontColor = 'white'
                break;
            case 4:
                color = '#ff6600'
                fontColor = 'white'
                break;
            case 5:
                color = '#ff0000'
                fontColor = 'white'
                break;
        }

        //console.log('color', color)
        var style = {
            margin: 10,
            fontSize: '1.0rem',

            width: 20,
            height: 20,
            //backgroundColor: color,
        }

        /*
         <ListItemSecondaryAction>
                      <Avatar style={style}>{node.riskLevel}</Avatar>
                  </ListItemSecondaryAction>
    
        */
        //  console.log('item', item)

        return (
            <div>
                <ul style={{ WebkitPaddingStart: "0px", paddingLeft: '0px' }}>
                    <ListItem button={false} style={{ background: 'white' }} key={item.id} >
                        <Avatar style={{
                            background: color, width: 30,
                            height: 30,
                            marginRight: 10
                        }}>
                            {item.children ? <FlagIcon style={{ color: fontColor, fontSize: 17, }} /> : <CityIcon style={{ color: fontColor, fontSize: 17, }} />}
                        </Avatar>
                        <ListItemText primary={item && typeof item.title == 'object' ? item.title[lang] : item.title} secondary={item.polity ? item.polity[lang] : ""} />
                        {(item.allowed || item.dossier == "7e2e9731-645a-4a8a-9237-a69b9c984969" || item.dossier == "0743cf81-ef86-4eaa-93e8-695570943982") &&
                            <Avatar style={{ fontSize: 12, color: 'red' }}>FREE</Avatar>
                        }
                    </ListItem>
                </ul>
            </div>
        );
    }
    /*
        shouldComponentUpdate(nextProps, nextState) {
           //// console.log('shouldComponentUpdate')
            // You can access `this.props` and `this.state` here
            // This function should return a boolean, whether the component should re-render.
            const  id  = window.document.location.pathname.replace('/dossiers/', '');
           //// console.log('id', window.document.location.pathname.replace('/dossiers/', '') )
           //// console.log('id2',this.state.currentId)
           
            if (id !== this.state.currentId) {
                //this.props.fetchDossier(this.props.match.params.id)
               //// console.log('dont update')
                return true
            } else {
                return true;
            }
           
        }*/

    onSelect(newSelection, item, ancestors, neighbours) {
        //// console.log('newSelect', newSelection)
        console.log('item', item)
        this.setState({ selection: newSelection })
        var dach = document.location.pathname.startsWith("/dach") ? "dach" : null
        this.props.fetchDossier(item.dossier, dach)
        var route = document.location.pathname.startsWith("/dach") ? "/dach/" : '/dossiers/'

        this.props.goToRoute(route + item.dossier)
        // window.history.pushState(null, null, '/dossiers/' + item.dossier)
        //this.props.history.push('/dossiers/' + item.dossier)
    }

    onFilterMouseUp(e) {
        const filter = e.target.value.trim();
        //// console.log('filter', filter)
        const domElt = document.querySelector(".TreeView input[type='search']")
        //domElt.value = filter

        var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
        nativeInputValueSetter.call(domElt, filter);

        var ev2 = new Event('input', { bubbles: true });
        domElt.dispatchEvent(ev2);

    }

    handleTabChange(event, value2) {
        const { tabValue } = this.state
        console.log('tabchange', event, value2)
        this.setState({ tabValue: value2 })
        if (value2 == "news") {
            const tags = [
                //nextProps.dossier.title.trim().toLowerCase(),
                "nachricht",
                this.props.dossier.tag
            ]
            //// console.log('fetchArticles ')
            this.props.fetchArticlesByTagTitles(tags, { operator: 'and', featureCollection: true, limit: 50 })

        }
        if (value2 == "police") {
            const tags = [
                //nextProps.dossier.title.trim().toLowerCase(),
                "polizeinachricht",
                this.props.dossier.tag
            ]
            //// console.log('fetchArticles ')
            //this.props.fetchArticlesByTagTitles(tags, { operator: 'and', featureCollection: true, limit: 50 })
            this.props.fetchArticlesByTagTitles(tags, { operator: 'and', operator2: "or", tags2: ["brand", "eigentum", "gewalt", "sexualdelikt", "drogen"], featureCollection: true, limit: 50 })



        }
        /*
                if (value == 0 && value2 == "") {
                    this.setState({ currentTag: "" })
                    const tags = [
                        //nextProps.dossier.title.trim().toLowerCase(),
                        "nachricht",
                        "infektionskrankheiten"
                    ]
                    //// console.log('fetchArticles ')
                    this.props.fetchArticlesByTagTitles(tags, { operator: 'and', featureCollection: true, limit: 150 })
        
        
                }
                else if (value == 1 && value2 == "") {
                    this.setState({ currentTag: "" })
                    const tags = [
                        //nextProps.dossier.title.trim().toLowerCase(),
                        "deutschland",
                        "oesterreich",
                        "schweiz",
        
                    ]
                    //// console.log('fetchArticles ')
                    this.props.fetchArticlesByTagTitles(tags, {
                        operator: 'or',
                        operator2: "and",
                        tags2: ["infektionskrankheiten", "nachricht"],
                        featureCollection: true,
                        limit: 150
                    })
        
                }
                */
    }


    // //<TreeView rowClick={this.onClick.bind(this)} />

    render() {
        const { selection, treeOpen, width, currentId } = this.state
        const { notifications, dossiers, dossier, countries, articles, lang, t } = this.props;
        const leftSize = treeOpen ? 3 : 0
        const rightSize = treeOpen ? 6 : 7
        //// console.log('lang', lang)

        const treeViewStyle = width < 600 ?
            { height: 'calc( 25vh)', overflowY: 'scroll', WebkitOverflowScrolling: 'touch' }
            : { height: 'calc( 100vh - 170px)', overflowY: 'scroll', WebkitOverflowScrolling: 'touch' };

        const paperStyle = width < 600 ?
            { margin: '0 12px', width: 'calc( 100% - 24px)' }
            : { marginLeft: '10px', width: 'calc( 100% - 15px)' };

        const articleStyle = width < 600 ?
            { margin: '10px', width: 'calc( 100% - 24px)' }
            : { width: 'calc( 100% - 15px)', overflowY: 'scroll', WebkitOverflowScrolling: 'touch', height: 'calc( 100vh - 172px)' };



        return (


            <Grid className={'printMargins'} style={{ marginTop: '10px' }} container >
                {!treeOpen &&
                    <Grid item  >
                        <IconButton onClick={this.handleTreeOpen.bind(this)}>
                            <ArrowRightIcon />
                        </IconButton>
                    </Grid>
                }
                {treeOpen &&
                    <Grid className={"no-print"} item lg={leftSize} sm={leftSize} xs={12}>
                        <Paper style={paperStyle}>
                            <div style={{

                                width: '90%',
                                marginLeft: '20px',
                                marginRight: '0px'
                            }}>
                                <Grid container alignItems="flex-end">
                                    <Grid item lg={2} sm={2} md={2} >
                                        <SearchIcon />
                                    </Grid>
                                    <Grid item lg={8} sm={8} md={8}>

                                        <TextField
                                            fullWidth
                                            id="search"
                                            placeholder={t("treeview.Suche")}
                                            onChange={this.onFilterMouseUp.bind(this)}
                                            //onKeyUp={this.onFilterMouseUp.bind(this)}
                                            margin="normal"
                                            InputProps={{

                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={2} sm={2} md={2} >
                                        {treeOpen &&
                                            <IconButton onClick={this.handleTreeOpen.bind(this)}>
                                                <ArrowLeftIcon />
                                            </IconButton>
                                        }
                                        {!treeOpen &&
                                            <IconButton onClick={this.handleTreeOpen.bind(this)}>
                                                <ArrowRightIcon />
                                            </IconButton>
                                        }
                                    </Grid>
                                </Grid>


                            </div>
                            <div style={treeViewStyle}>
                                <TreeView
                                    selection={selection}
                                    model={countries || []}
                                    search={input => item => {
                                        //console.log('input', input)

                                        var result = item.title[lang].toLowerCase().includes(input.toLowerCase())

                                        if (result) {

                                            //  console.log('item', item)
                                        } else {
                                            if (item && item.countryTitle && item.countryTitle[lang]) {
                                                result = item.countryTitle[lang].toLowerCase().includes(input.toLowerCase())

                                            }
                                            //_search = null
                                        }
                                        return result
                                    }
                                    }
                                    sort={(a, b) => {
                                        if (a.title && a.title[lang] && b.title && b.title[lang]) {
                                            return a.title[lang].localeCompare(b.title[lang])
                                        }
                                        return 0
                                    }}
                                    onSelect={this.onSelect.bind(this)}
                                    category={"children"}
                                    display={this.renderCountry.bind(this)}>
                                </TreeView>
                            </div>
                        </Paper>
                    </Grid>
                }
                {(this.props.match.params.id || currentId) && dossier &&
                    <Grid className={'dossierWrapper'} item lg={rightSize} sm={rightSize} md={rightSize} xs={12}>
                        {this.renderDossier()}
                    </Grid>
                }
                {!this.props.match.params.id &&
                    <Hidden smDown>
                        <Grid item lg={9} sm={9} md={9} xs={9}>
                            <Map containerStyle={{
                                height: '87vh',
                                width: '100%'
                            }}
                                mapId={document.location.pathname.startsWith("/dach") ? "a89a271b-a91e-40b3-99b8-d9fc2d0dd8c4" : "f18927f8-1f05-47f5-8857-b36fb7caa715"}
                                riskPotentials={[
                                    "riskLevel",
                                    "travelSafety",
                                    "geopolitics",
                                    "G35",
                                    "kidnapping",
                                    "terrorism",

                                    "financialRisk",
                                    "anthropogenicThreats",
                                    "naturalHazards",
                                    "economicSecurity",
                                    "corruption",
                                    "medicalRisks",
                                    "trafficRisks",
                                ]}
                            />
                        </Grid>
                    </Hidden>
                }
                {articles && dossier &&
                    <Grid className={"no-print"} item lg={3} sm={3} md={3} xs={12} style={{ paddingLeft: 5 }} >
                        {!document.location.pathname.startsWith("/dach") &&
                            <h2>{t("dossier.Aktuelle Nachrichten")}</h2>
                        }
                        {document.location.pathname.startsWith("/dach") &&
                            <div>
                                <Tabs
                                    variant="fullWidth"
                                    centered
                                    value={this.state.tabValue} onChange={this.handleTabChange.bind(this)} aria-label="simple tabs example">

                                    <Tab label={t("dossier.Nachrichten")} value={"news"} />
                                    <Tab label={t("dossier.Polizeimeldungen")} value={"police"} />

                                </Tabs>

                                <Paper style={articleStyle}>
                                    <ArticleList
                                    // checklistsVisible={false}
                                    //onRef={ref => {//// console.log('ref'); this.articleList = ref }}
                                    //onClick={this.onClick.bind(this)}
                                    //onTagClick={this.onTagClick.bind(this)}

                                    />
                                </Paper>

                            </div>
                        }
                        {!document.location.pathname.startsWith("/dach") &&
                            <Paper style={articleStyle}>
                                <ArticleList
                                // checklistsVisible={false}
                                //onRef={ref => {//// console.log('ref'); this.articleList = ref }}
                                //onClick={this.onClick.bind(this)}
                                //onTagClick={this.onTagClick.bind(this)}

                                />
                            </Paper>
                        }
                    </Grid>
                }

            </Grid>

        );
    }
}


DossierList.contextTypes = {
    store: PropTypes.object
};

const notificationOpts = {
    title: 'Dossier',
    message: 'Successfully Deleted.',
    position: 'tr',
    autoDismiss: notificationDuration
}

function mapStateToProps(state) {
    let filtered = _.isEmpty(state.dossiers.dossiersGridData.gridFilters) ? [] : state.dossiers.dossiersGridData.gridFilters;
    let sorted = _.isEmpty(state.dossiers.dossiersGridData.gridSortCriteria) ? [] : state.dossiers.dossiersGridData.gridSortCriteria;
    //console.log('state.dossiers.dossier', state.dossiers.dossier)
    //console.log('countries', state.dossiers.countries)
    return {
        loading: state.dossiers.loading,
        dossiers: state.dossiers.all,
        dossier: state.dossiers.dossier,
        countries: state.dossiers.countries,
        admin: state.auth.admin,
        authenticated: state.auth.authenticated,
        lang: state.lang.lang,
        articles: state.articles.all,
    }
}

export default withNamespaces()(connect(mapStateToProps, {
    deleteDossier: deleteDossier,
    setSortCriteria: setDossiersGridSortCriteria,
    setFilter: setDossiersGridFilters,
    fetchDossiersChunks: fetchDossiersChunks,
    setPageSize: setDossiersGridPageSize,
    setPageIndex: setDossiersGridPageIndex,
    fetchDossier: fetchDossier,
    goToRoute: goToRoute,
    fetchCountries: fetchCountries,
    fetchArticlesByTagTitles: fetchArticlesByTagTitles
})(DossierList));

