import React from 'react';
import ReactDOM from 'react-dom';
import { Card,  CardActions, CardContent } from '@material-ui/core';
import DocumentList from '../documentList'
import DocumentSearch from '../documentSearch'
import Map from '../map/map_index'


import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { ROOT_URL } from "../../config";
import { push } from 'react-router-redux';
import { goToRoute } from './actions'
import Hidden from '@material-ui/core/Hidden';



const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 450,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
});

class Documents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            tagTitle: "nachricht"
        };

    }
    componentDidMount() {
        //console.log('Article')
        if (this.props.match.params.tagTitle) {
            this.setState({
                tagTitle: this.props.match.params.tagTitle,
                tags: []
            })
        } else {
            this.setState({
                tagTitle: "nachricht",
                tags: []
            })
        }


    }

    componentDidUpdate() {
        //console.log('Article')
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.tags) {

        }
    }

    onClick(article) {
       // console.log('doc click', article)

        this.setState({featureCollection:article.featureCollection})
    }

    onDeleteTag(tags) {

    }

    onTagClick(tag) {
       // console.log('onTreeClick click', tag)
        var tags = this.state.tags
        tags.push(tag)
        this.setState({ tags: tags, tagTitle: null })

        //if (this.props.onTagClick) {
        //this.props.onTagClick(tag)

        // goToRoute('articles/'+tag.title)
        // this.setState({ tags: [tag.id] })
        //}
    }

    /*  

      <Grid item lg={3} sm={3} >
                    <Paper style={{ marginLeft: '10px' }}>
                        <TagTreeView rowClick={this.onTagClick.bind(this)} />
                    </Paper>
                </Grid>
                <Grid className={'dossierWrapper'} item lg={9} sm={9} md={8} style={{ width: 'calc( 100% - 24px)', height: 'calc( 100vh - 64px  )', overflowY: 'auto' }}>
                   
                </Grid>


    */

    onTagClick(item) {}

    onSearchEnter(search) {
        this.documentList.onSearchEnter(search)
    }

    render() {
        const { articles, tags, tagTitle, featureCollection } = this.state
        return (
            <Grid style={{ maxWidth: '100%', padding: '12px', marginTop: '12px', marginLeft: '0px' }} container >
                <Hidden smDown>
                    <Grid style={{ paddingLeft: '0px', paddingRight: '10px', marginRight: '0px' }} item lg={4} xs={12} sm={12} md={3} >
                        <Map  drawControl={false} containerStyle={{
                            height: '90vh',
                            width: "100%"
                        }}featureCollection={featureCollection} />
                    </Grid>
                </Hidden>
                
                <Grid item lg={8} sm={12} xs={12} md={9} >

                    <DocumentSearch style={{ width: '100%', marginTop: '10px', marginRight: '10px' }} onEnter={this.onSearchEnter.bind(this)} />

                    <div style={{
                        marginTop: '10px',
                        paddingLeft: '2px',
                        paddingRight: '2px',
                        height: 'calc( 100vh - 204px  )',
                        overflowY: 'scroll',
                        WebkitOverflowScrolling: 'touch'

                    }}>

                        <DocumentList
                            onClick={this.onClick.bind(this)}
                            onTagClick={this.onTagClick.bind(this)}
                            tags={tags}
                            tagTitle={tagTitle} />
                    </div>
                </Grid>

                
            </Grid>
        )

    }

}



function mapStateToProps(state) {


    return {

    }
}

export default withStyles(styles, { name: 'Articles' })(connect(mapStateToProps, {

})(Documents));


