import React, { Component } from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    renderField, renderLocalizedField, renderLocalizedEditor, renderHiddenField, renderMapSelector
} from "../../components/field_formatters";
import PropTypes from 'prop-types';
import { success } from "react-notification-system-redux";
import {
    Button, IconButton, Menu, MenuItem, Grid,
    Paper, Card, CardContent, List, ListItem,
    ListItemText, CircularProgress, Dialog, DialogActions, DialogTitle
} from "@material-ui/core";
//import validate from './validation';
import { notificationDuration, newSectionDossierOptions as options } from '../../config';
import AddIcon from '@material-ui/icons/Add';
import {
    insertPage, updatePage, deletePage, fetchPage
} from "../page/actions";
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next'



const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 1 * 3,
        backgroundColor: theme.palette.background.paper,
    },
});






class OfferEdit extends Component {

    state = {
        submittedEditDossier: false,
        open: false,
        anchorEl: null,
        lang: this.props.lang,
        selectedIndex: 0
    }

    dispatchNotification(fn, timeout) {
        setTimeout(() => {
            this.context.store.dispatch(fn(notificationOpts));
        }, timeout);
    }

    handleFormSubmit(formProps) {
        const { dossier } = this.props
        // console.log('formProps', formProps)
        const body = {
            /*title: formProps.title,
            polity: formProps.polity,
            generalInfo: formProps.generalInfo,
            infoLink: formProps.infoLink,
            riskPotentials: formProps.riskPotentials,
            riskLevel: formProps.riskLevel,
            id: formProps.country*/
        }
        this.props.updatePage(formProps).then(function () {
            this.setState({ submittedEditDossier: true })
            this.dispatchNotification(success, 200);
        }.bind(this));


        /*
        if (dossier.type == "country") {
            this.props.updateCountry(body).then(function () {
                this.setState({ submittedEditDossier: true })
                this.dispatchNotification(success, 200);
            }.bind(this));
        } else if (dossier.type == "city") {
            this.props.updateCity(body).then(function () {
                this.setState({ submittedEditDossier: true })
                this.dispatchNotification(success, 200);
            }.bind(this));
        }
*/

    }

    handleListItemClick(item, index) {

        this.setState({ selectedIndex: index })

    }

    UNSAFE_componentWillMount() {
       
        this.props.fetchPage("offer")
    }

    componentDidMount() {
        console.log('did mount')
    }

    handleClick = event => {
        this.setState({
            open: true,
            anchorEl: event.currentTarget
        });
    };

    handleMenuItemClick = (event, index) => {
        this.setState({ open: false });
        switch (index) {
            case 0:
                this.props.addNewDossierSection();
        }
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    handleDeletePage(id) {
        this.setState({ del: id })
    }
    savePage() {

    }

    cancelDelete() {

    }

    deletePage() {

    }

    render() {

        //const {  } = this.props
        const { newPage, del, updating, selectedIndex } = this.state
        const { classes, error, handleSubmit, t, lang, page } = this.props
        console.log('page', page)
        if (this.state.submittedEditDossier) {
            return (
                <Redirect to={"/offer"} />
            )
        }

        return (
            <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                <Field name="id" type="hidden" component={renderHiddenField} />

                <div>
                    <Grid container style={{ padding: '12px' }}>
                        <Grid item lg={12}>
                            {page &&
                                <Card>

                                    <CardContent>
                                        <Field name={'title'} type="text" component={renderLocalizedField} label="Title" />
                                       


                                    </CardContent>
                                </Card>
                            }
                        </Grid>
                    </Grid>

                    <Grid style={{ maxWidth: '100%', padding: '12px', marginTop: '0px', marginLeft: '0px' }} container >
                        <Grid item lg={3} sm={3} xs={3} md={3} >
                            <Card>


                                <List component="nav">
                                    {page && page.fields && page.fields.map((field, index) => {
                                        //console.log('l', field.title[lang])
                                        return (<ListItem
                                            key={index}
                                            button
                                            // selected={checklist.postName === pageName}
                                            onClick={this.handleListItemClick.bind(this, field, index)}
                                        >
                                            <ListItemText primary={field.title[lang]} />
                                        </ListItem>)
                                    })}

                                </List>
                            </Card>
                        </Grid>
                        <Grid item lg={9} sm={9} xs={9} md={9} >





                            <Card>
                                {page &&
                                    <div style={{ paddingLeft: 10, paddingRight: 10, marginTop: 10, float: 'right' }}>
                                        {!updating &&
                                            <Button
                                                variant="fab"
                                                color="secondary"
                                                type="submit"
                                                onClick={this.savePage.bind(this)}
                                            >
                                                <SaveIcon />
                                            </Button>
                                        }
                                        {updating && <CircularProgress size={44} style={{


                                        }} />}

                                    </div>
                                }








                                <div style={{
                                    marginTop: 10,
                                    display: 'flex',
                                    flexWrap: 'wrap'
                                }}>


                                    {page && page.fields && page.fields.filter((field, index) => {
                                        return index == selectedIndex
                                    }).map((field, index) => {
                                        return (
                                            <div>
                                                <Field name={'fields[' + selectedIndex + '].title'} type="text" component={renderLocalizedField} label="Title" />
                                               
                                                <br />
                                                <Field name={'fields[' + selectedIndex + '].markdown'} type="text" component={renderLocalizedEditor} label="Content" />

                                            </div>
                                        )
                                    })}




                                </div>



                            </Card>

                            {page && !newPage &&
                                <IconButton onClick={this.handleDeletePage.bind(this, page.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            }
                        </Grid>

                        {page &&
                            <Dialog
                                open={del}
                                onClose={this.cancelDelete.bind(this)}

                            >
                                <DialogTitle>Wirklich löschen?</DialogTitle>

                                <DialogActions>
                                    <Button onClick={this.deletePage.bind(this, page.id)} color="secondary">Ja</Button>
                                    <Button onClick={this.cancelDelete.bind(this)} color="primary">Nein</Button>

                                </DialogActions>
                            </Dialog>
                        }
                    </Grid>

                </div>
            </form>
        )

    }

    render2() {

        const { error, handleSubmit, dossier, t } = this.props;
        const { classes } = this.props;
        const { value, lang } = this.state;
        // console.log('this.props', this.props)

        if (this.state.submittedEditDossier) {
            return (
                <Redirect to={"/dossiers/" + dossier.id} />
            )
        }


        return (

            <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                <Field name="id" type="hidden" component={renderHiddenField} />

                <div>

                    <Grid style={{ marginTop: '0px', padding: 15, paddingTop: 0 }} container >

                        <Grid item lg={6} md={6} sm={6} >
                            <Card><CardContent>
                                {dossier &&
                                    <div>
                                        <Field name={'title'} type="text" component={renderLocalizedField} label="Titel" />

                                        <Field name={'polity'} type="text" component={renderLocalizedField} label="Staatsform" />
                                        <Field name={'riskLevel'} type="number" component={renderField} label="Risklevel" />
                                    </div>
                                }

                            </CardContent></Card>

                            {dossier && dossier.generalInfo &&
                                <Card style={{ marginTop: 10 }}><CardContent>
                                    <Field name={'generalInfo.capital'} type="text" component={renderLocalizedField} label={t("dossier.capital")} />
                                    <Field name={'generalInfo.area'} type="text" component={renderLocalizedField} label={t("dossier.area")} />
                                    <Field name={'generalInfo.population'} type="text" component={renderLocalizedField} label={t("dossier.population")} />
                                    <Field name={'generalInfo.currency'} type="text" component={renderLocalizedField} label={t("dossier.currency")} />
                                    <Field name={'generalInfo.languages'} type="text" component={renderLocalizedField} label={t("dossier.languages")} />
                                    <Field name={'generalInfo.entryRequirements'} type="text" component={renderLocalizedField} label={t("dossier.entryRequirements")} />
                                    <Field name={"infoLink"} type="text" component={renderLocalizedField} label={"Link Einreisebestimmungen"} />

                                </CardContent></Card>
                            }

                        </Grid>
                        <Grid style={{ paddingLeft: 0 }} item lg={6} md={6} sm={6} >



                            {dossier && dossier.riskPotentials &&
                                <Card ><CardContent>

                                    {Object.keys(dossier.riskPotentials).map((fieldName, index) => {
                                        return (
                                            <Field
                                                key={fieldName}
                                                name={'riskPotentials.' + fieldName}
                                                type="text"
                                                component={renderField}
                                                label={t('riskPotentials.' + fieldName)}
                                            />)
                                    }
                                    )}


                                </CardContent></Card>
                            }
                            <div>
                                <Button type="submit" variant="contained" color="primary">Speichern</Button>{' '}
                                <Button variant="contained" component={Link} to="/dossiers">Abbrechen</Button>
                            </div>
                        </Grid>



                    </Grid>






                </div>



                {error && <strong> {error} </strong>}


            </form>
        )
    }
}

OfferEdit.propTypes = {
    //classes: PropTypes.object.isRequired,
};

const notificationOpts = {
    title: 'Dossier',
    message: 'Successfully Updated.',
    position: 'tr',
    autoDismiss: notificationDuration
}

const form = reduxForm({
    form: 'OfferEdit',
    enableReinitialize: true,
    //validate
});

OfferEdit.contextTypes = {
    store: PropTypes.object
};

function mapStateToProps(state) {
    return {
        initialValues: state.page.page,
        page: state.page.page,
        dossier: state.dossiers.dossier,
        lang: state.lang.lang,
        //dossierChapter: state.dossiers.dossierChapter,
        //dossiersections: state.dossiers.dossiersections
    }
}

export default withNamespaces()(connect(mapStateToProps, { updatePage, fetchPage })(form(OfferEdit)));