import {
    FETCH_CHECKLISTS,
    FETCH_PAGE,
    UPDATE_PAGE,
    INSERT_PAGE,
    FETCH_TERMS
} from "../../actions/types";

import * as _ from "lodash";

const INITIAL_STATE = {
    checklists: [],
    terms:[]
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case FETCH_CHECKLISTS:
            
            return {
                ...state,
                checklists: action.payload.data
            };
        case FETCH_TERMS:
        return {
            ...state,
            terms: action.payload.data
        };
        case FETCH_PAGE:
            var page = action.payload.data
            if (page.length > 0) {
                page = page[0]
            }
            return {
                ...state,
                page: page
            };


        default:
            return state;
    }
}