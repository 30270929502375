import React from 'react';
import ReactDOM from 'react-dom';
import { Card, CardActions, CardContent, MenuItem, Select, Input, FormControl, Tooltip, IconButton, Button, CircularProgress } from '@material-ui/core';
import { Markdown } from 'react-showdown';
import ArticleList from '../articleList'
import ArticleSearch from '../articleSearch'
import AppInfo from '../appInfo'
import { Link } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import PrintIcon from '@material-ui/icons/Print'
import TagList from '../tagList'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { ROOT_URL } from "../../config";
import { push } from 'react-router-redux';
import { goToRoute } from './actions'
import Map from '../map/map_index'
import Hidden from '@material-ui/core/Hidden';
import EditTagList from '../editTagList/editTagList_index'
import NewsLetter from '../newsletter'
import {
    fetchArticlesByTagTitles, fetchChecklists, fetchArticlesSearch
} from "./actions";
//import ReactGA from 'react-ga';
const limit = 100
const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 450,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
});

class Articles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            operator: "and",
            tagTitles: [],
            expanded: false
        };
        this.list = null

    }
    componentDidMount() {
        //console.log('componentDidMount')
        if (this.props.match.params.tagTitles) {
            // console.log('params', this.props.match.params.tagTitles)
            this.setState({ tagTitles: this.props.match.params.tagTitles.split(',') })
        }
        else {
            this.setState({ tagTitles: ["nachricht"] })
        }



    }

    UNSAFE_componentWillMount() {
        //ReactGA.initialize('UA-130702159-1');
        //ReactGA.pageview(window.location.pathname + window.location.search)
        //console.log('componentDidUnmount')
    }

    componentDidUpdate() {
        // console.log('componentDidUpdate')
        if (this.props.match.params.tagTitles) {
            if (this.props.match.params.tagTitles.split(',')[0] != this.state.tagTitles[0]) {
                //console.log('params', this.props.match.params.tagTitles)
                this.setState({ tagTitles: this.props.match.params.tagTitles.split(',') })
            }

        } else {
            if (this.state.tagTitles[0] != "nachricht") {
                this.setState({ tagTitles: ["nachricht"] })
            }

        }


    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.tags) {

        }
    }



    onClick(article) {
        if (this.props.onClick) {
            //this.props.onClick(article)
        }
    }

    onTagsChange(tags) {
        // console.log('onTagsChange', tags)
        this.setState({ tagTitles: tags })
        //console.log('onTagDelete', tag)
        /* var tagTitles = this.state.tagTitles
         var index = tagTitles.indexOf(tag)
         if (index > -1) {
             tagTitles.splice(index, 1);
             */
        // console.log('tags', tags)
        this.props.fetchArticlesByTagTitles(tags, { featureCollection: true, limit: limit })
        // }


    }

    reload() {
        this.props.fetchArticlesByTagTitles(this.state.tagTitles, { featureCollection: true, limit: limit })
    }




    onTagClick(tag) {
        //console.log('onTreeClick click', tag)
        var tagTitles = this.state.tagTitles
        var index = tagTitles.indexOf(tag)
        if (index == -1) {
            tagTitles.push(tag)
            this.setState({ tagTitles: tagTitles })
            this.props.fetchArticlesByTagTitles(tagTitles, { featureCollection: true, limit: limit })
        }


        //if (this.props.onTagClick) {
        //this.props.onTagClick(tag)

        // goToRoute('articles/'+tag.title)
        // this.setState({ tags: [tag.id] })
        //}
    }

    /*  

      <Grid item lg={3} sm={3} >
                    <Paper style={{ marginLeft: '10px' }}>
                        <TagTreeView rowClick={this.onTagClick.bind(this)} />
                    </Paper>
                </Grid>
                <Grid className={'dossierWrapper'} item lg={9} sm={9} md={8} style={{ width: 'calc( 100% - 24px)', height: 'calc( 100vh - 64px  )', overflowY: 'auto' }}>
                   
                </Grid>


    */

    onSearchEnter(search) {
        this.props.fetchArticlesSearch(search)
    }

    showOnMap(id) {
        if (this.map) {
            // console.log('this.map', this.map)
            this.map.selectMarker(id)
        }

    }

    onExpand() {
        // console.log('should expand')
        this.setState({ expanded: !this.state.expanded })
        if (this.map) {
            // console.log('should trigger resize')
            setTimeout(() => { this.map.resize() }, 1500)
        }
    }

    render() {
        const { tagTitles, expanded } = this.state
        const { articles, admin, isFetching, editor } = this.props
        var isDailyBrief = false
        if (this.props.match.params.tagTitles) {
            isDailyBrief = this.props.match.params.tagTitles.indexOf("daily-brief") != -1
        }

        var leftSize = 6
        var rightSize = 6
        if (isDailyBrief) {
            leftSize = 0
            rightSize = 12
        }
        return (
            <div>
                {!isDailyBrief &&

                    <Grid style={{ maxWidth: '100%', padding: '12px', paddingTop: 0, marginTop: '0px', marginLeft: '7px' }} container >

                        <Hidden smDown>
                            <Grid style={{ paddingLeft: '0px', paddingRight: '10px', marginRight: '0px' }} item lg={expanded ? 12 : leftSize} xs={12} sm={12} md={expanded ? 12 : leftSize} >
                                <Paper>
                                    {!isDailyBrief &&
                                        <Map
                                            // expanded={expanded}
                                            // onExpand={this.onExpand.bind(this)}
                                            onSelected={(article) => {
                                                if (this.articleList) {
                                                    var offset = this.articleList.scrollTo(article.id)
                                                    // console.log('offset', offset)

                                                    const list = ReactDOM.findDOMNode(this.list)
                                                    // console.log('list', list)
                                                    list.scrollTop = offset - 280//(0, offset - 280)
                                                    //console.log('list', list)

                                                }
                                            }}
                                            onRef={(ref) => this.map = ref}
                                            drawControl={false} containerStyle={{
                                                height: '80vh',
                                                width: "100%"
                                            }} featureCollection={articles.featureCollection}>
                                        </Map>
                                    }
                                    <Tooltip title="Neu laden">

                                        <IconButton onClick={this.reload.bind(this)} style={{ marginTop: 10, bottom: -20, zIndex: 100, float: 'right', right: 20 }} variant="fab" color="primary" aria-label="Neu laden" >

                                            <RefreshIcon />

                                        </IconButton>



                                    </Tooltip>
                                    {(admin || editor) &&
                                        <Tooltip className="no-print" title="neuen Artikel schreiben">
                                            <Link className="no-print" style={{ top: 15, zIndex: 100, float: 'right', right: 40, }} to="/article/edit/new">
                                                <IconButton className="no-print" style={{ marginTop: 10, bottom: -20, zIndex: 100, float: 'right', right: 40, marginRight: 10 }} variant="fab" color="primary" aria-label="Neuer Artikel" >

                                                    <AddIcon />

                                                </IconButton>
                                            </Link>
                                        </Tooltip>
                                    }



                                </Paper>
                                <Paper style={{ marginTop: 10 }}>
                                    {tagTitles.length > 0 &&

                                        <div>
                                            {!isDailyBrief &&
                                                <EditTagList style={{ marginTop: '10px', }} tags={tagTitles} onChange={this.onTagsChange.bind(this)} />
                                            }
                                        </div>

                                        // <TagList style={{ width: '100%', marginTop: '10px', marginRight: '10px' }} tagTitles={tagTitles} onTagDelete={this.onTagDelete.bind(this)} />
                                    }
                                </Paper>

                            </Grid>
                        </Hidden>

                        <Grid item lg={rightSize} sm={12} xs={12} md={rightSize} >
                            {articles && !articles.articles &&
                                <div style={{ padding: 22, textAlign: 'center' }}>
                                    <CircularProgress />
                                </div>
                            }

                            {articles && !articles.articles &&
                                <div style={{ padding: 22, textAlign: 'center' }}>
                                    <CircularProgress />
                                </div>
                            }

                            {false && !isDailyBrief &&
                                <ArticleSearch style={{ width: '100%', marginTop: '10px', marginRight: '10px' }} onEnter={this.onSearchEnter.bind(this)} />
                            }

                            <div ref={(ref) => { this.list = ref }} style={{
                                marginTop: '0px',
                                paddingLeft: '2px',
                                paddingRight: '2px',
                                height: '90vh',
                                overflowY: 'scroll',
                                WebkitOverflowScrolling: 'touch'
                            }}>

                                <ArticleList
                                    showOnMap={isDailyBrief ? null : this.showOnMap.bind(this)}
                                    tags={isDailyBrief ? false : true}
                                    onRef={ref => { this.articleList = ref }}
                                    onClick={this.onClick.bind(this)}
                                    checklistsVisible={isDailyBrief ? false : true}
                                    showMore={isDailyBrief ? true : false}
                                    onTagClick={this.onTagClick.bind(this)}
                                    tagTitles={tagTitles} />
                            </div>
                        </Grid>



                    </Grid>
                }
                {isDailyBrief &&
                    <div style={{ marginTop: 10 }}>
                        <Grid container  className={'no-print'}>
                            <Grid item lg={2}>

                            </Grid>

                            <Grid item lg={8} style={{ marginBottom: '35px' }}>
                                <Card>
                                    <CardContent>

                                        <div >
                                            <div>
                                                <h1><strong>Newsletter (Fachmedien: Intelligence Monitor, Travel Risk Intelligence)</strong></h1>
                                                <p><strong>Mit welchen Inhalten können Sie rechnen?</strong></p>
                                                <p>Mit den Online-Publikationen von RiskCompass bleiben Sie als Entscheidungsträger und/oder interessierte Privatperson auf dem Laufenden. Sie erhalten kurz und prägnant einen kritisch-analytischen Blick auf die Welt, auf die innere und äußere Sicherheitslage sowie auf Ereignisse und Entwicklungen in allen relevanten Zielgebieten. Die Informationen, Hintergrundrecherchen, Interviews, Analysen und Prognosen sollen dazu beitragen, Zusammenhänge zu erkennen und zu verstehen, damit fundierte Entscheidungen getroffen werden können.</p>
                                                <ul>
                                                    <li><p>Der Newsletter »Travel Risk Intelligence« ist ein Nachrichten- und Informationsdienst für Unternehmer, Geschäfts- und Privatreisende, Sicherheitspersonal und -verantwortliche, Travel- und Fuhrparkmanager sowie alle Menschen, die mobil und/oder weltweit unterwegs sind! Inhaltlich ergänzt er die täglichen <Link to="/news">RiskCompass-Nachrichten</Link> und liefert Hintergrund- bzw. Zusatzinformationen zu allen Aspekten der Reisesicherheit (z.B. Recht und Steuern; Einreisebestimmungen; Studien, Trends und Prognosen; kommende Ereignisse; Länderanalysen; Experten-Interviews).</p></li>
                                                    <li><p>Der Newsletter »Intelligence Monitor« ist ein Nachrichtendienst mit Informationen zur aktuellen inneren bzw. äußeren Sicherheitslage und zu geopolitischen Entwicklungen.</p></li>
                                                </ul>
                                                <p><strong>Wie häufig erfolgt der Newsletter-Versand?</strong></p>
                                                <p>Beide Newsletter werden ca. 1 x wöchentlich versandt, je nach Ereignis- und Nachrichtenlage kann dies aber auch häufiger sein.</p>
                                                <p><strong>Einsatz von MailChimp</strong></p>
                                                <p>Der Versand der Newsletter erfolgt mittels <a href="https://mailchimp.com/">»MailChimp«</a>, bei dem auch die E-Mail-Adressen und weitere Informationen zum Versand und zur Analyse der Newsletter gespeichert werden (z.B. IP-Adresse).</p>
                                                <p><strong>Double-Opt-In und Opt-Out</strong></p>
                                                <p>Sie erhalten eine sog. Double-Opt-In-E-Mail, in der Sie um Bestätigung der Anmeldung gebeten werden. Falls Sie innerhalb von 30 Sekunden nach Absenden Ihrer Registrierung keine Verifikations-E-Mail erhalten haben, sollten Sie Ihren Spam-Filter überprüfen. Sie können dem Empfang der Newsletter jederzeit widersprechen (sog. Opt-Out). Einen Abmeldelink finden Sie in jedem Newsletter oder der Double-Opt-In-E-Mail.</p>
                                                <p><strong>Zusatzangaben</strong></p>
                                                <p>Die von Ihnen außer Ihrer E-Mail-Adresse angegebenen Daten verwenden wir ausschließlich zur Personalisierung des Newsletters, für Zwecke der Marktforschung (anonymisierte, interne Auswertung der Kundenstruktur) bzw. zur bedarfsgerechten Optimierung unserer redaktionellen Inhalte sowie für Kundenbetreuungszwecke.</p>
                                                <p><strong>Statistiken und Erfolgsmessung</strong></p>
                                                <p>Um die Newsletter für Sie weiter optimieren zu können, willigen Sie in unsere Auswertung ein, mit der wir messen, wie häufig ein Newsletter geöffnet wird und auf welche Links die Leser klicken.</p>
                                                <p><strong>Datenschutzhinweise und Widerrufsmöglichkeit</strong></p>
                                                <p>Ausführliche Informationen zum Versandverfahren und den Statistiken sowie zu den Möglichkeiten, Ihre Einwilligung widerrufen zu können, erhalten Sie in unserer <Link to="/dataProtection">Datenschutzerklärung</Link>.</p></div>
                                            <br /><br />

                                        </div>
                                        <NewsLetter />
                                    </CardContent>
                                </Card>

                            </Grid>
                            <Grid item lg={2}>

                            </Grid>



                        </Grid>

                        <Grid container >


                            <Grid item lg={2}>
                            </Grid>
                            <Grid item lg={8}>
                                <Card>

                                    <h1 style={{ marginLeft: 25 }}><b>Newsletter-Archiv</b></h1>

                                </Card>
                                <div style={{ marginTop: 20 }}>
                                    <ArticleList
                                        showOnMap={isDailyBrief ? null : this.showOnMap.bind(this)}
                                        tags={isDailyBrief ? false : true}
                                        onRef={ref => { this.articleList = ref }}
                                        onClick={this.onClick.bind(this)}
                                        checklistsVisible={false}
                                        showMore={true}
                                        onTagClick={this.onTagClick.bind(this)}
                                        tagTitles={["daily-brief"]} />
                                </div>
                            </Grid>

                            <Grid item lg={2}>

                                <IconButton className={"no-print"} style={{}} onClick={window.print}>
                                    <PrintIcon />
                                </IconButton>
                                {(admin || editor) &&
                                    <Tooltip className="no-print" title="neuen Artikel schreiben">
                                        <Link className="no-print" style={{}} to="/article/edit/new-daily-brief">
                                            <IconButton className="no-print" style={{ marginLeft: 0, paddingLeft: 0, top: 60, zIndex: 100, }} variant="fab" color="primary" aria-label="Neuer Artikel" >

                                                <AddIcon />

                                            </IconButton>
                                        </Link>
                                    </Tooltip>
                                }
                            </Grid>
                        </Grid>


                        <Grid container  className={'no-print'}>
                            <Grid item lg={2}>

                            </Grid>

                            <Grid item lg={8} style={{ marginBottom: '35px' }}>
                                <Card>
                                    <CardContent>

                                        <div >
                                            <div>
                                                <h1><strong>Newsletter </strong></h1>
                                                <p><strong>Mit welchen Inhalten können Sie rechnen?</strong></p>
                                                <p>Mit dem Newsletter von RiskCompass bleiben Sie als Entscheidungsträger und/oder interessierte Privatperson auf dem Laufenden. Sie erhalten kurz und prägnant einen kritisch-analytischen Blick auf die Welt, auf die innere und äußere Sicherheitslage sowie auf Ereignisse und Entwicklungen an allen relevanten Orten und Destinationen. Die Informationen, Hintergrundrecherchen, Interviews, Analysen und Prognosen sollen dazu beitragen, Zusammenhänge zu erkennen und zu verstehen, damit fundierte Entscheidungen getroffen werden können.</p>
                                                <p>Der Newsletter »Risk Intelligence Monitor« ist ein Nachrichten- und Informationsdienst für das Risk-, Travel Risk- und Business Continuity Management, für Unternehmer, Geschäftsführer und Entscheidungsträger! Inhaltlich ergänzt er die täglichen <Link to="/news">RiskCompass-Nachrichten</Link> und liefert Hintergrund- bzw. Zusatzinformationen.</p>

                                                <p><strong>Wie häufig erfolgt der Newsletter-Versand?</strong></p>
                                                <p>Beide Newsletter werden ca. 1 x wöchentlich versandt, je nach Ereignis- und Nachrichtenlage kann dies aber auch häufiger sein.</p>
                                                <p><strong>Einsatz von MailChimp</strong></p>
                                                <p>Der Versand der Newsletter erfolgt mittels <a href="https://mailchimp.com/">»MailChimp«</a>, bei dem auch die E-Mail-Adressen und weitere Informationen zum Versand und zur Analyse der Newsletter gespeichert werden (z.B. IP-Adresse).</p>
                                                <p><strong>Double-Opt-In und Opt-Out</strong></p>
                                                <p>Sie erhalten eine sog. Double-Opt-In-E-Mail, in der Sie um Bestätigung der Anmeldung gebeten werden. Falls Sie innerhalb von 30 Sekunden nach Absenden Ihrer Registrierung keine Verifikations-E-Mail erhalten haben, sollten Sie Ihren Spam-Filter überprüfen. Sie können dem Empfang der Newsletter jederzeit widersprechen (sog. Opt-Out). Einen Abmeldelink finden Sie in jedem Newsletter oder der Double-Opt-In-E-Mail.</p>
                                                <p><strong>Zusatzangaben</strong></p>
                                                <p>Die von Ihnen außer Ihrer E-Mail-Adresse angegebenen Daten verwenden wir ausschließlich zur Personalisierung des Newsletters, für Zwecke der Marktforschung (anonymisierte, interne Auswertung der Kundenstruktur) bzw. zur bedarfsgerechten Optimierung unserer redaktionellen Inhalte sowie für Kundenbetreuungszwecke.</p>
                                                <p><strong>Statistiken und Erfolgsmessung</strong></p>
                                                <p>Um die Newsletter für Sie weiter optimieren zu können, willigen Sie in unsere Auswertung ein, mit der wir messen, wie häufig ein Newsletter geöffnet wird und auf welche Links die Leser klicken.</p>
                                                <p><strong>Datenschutzhinweise und Widerrufsmöglichkeit</strong></p>
                                                <p>Ausführliche Informationen zum Versandverfahren und den Statistiken sowie zu den Möglichkeiten, Ihre Einwilligung widerrufen zu können, erhalten Sie in unserer <Link to="/dataProtection">Datenschutzerklärung</Link>.</p></div>
                                            <br /><br />



                                        </div>
                                        <NewsLetter />
                                    </CardContent>
                                </Card>

                            </Grid>
                            <Grid item lg={2}>

                            </Grid>



                        </Grid>



                    </div>

                }
            </div>


        )

    }

}



function mapStateToProps(state) {


    return {
        // isFetching:state.article.isFetching,
        articles: state.articles.all,
        checklists: state.articles.checklists,
        admin: state.auth.admin,
        editor: state.auth.editor
    }
}

export default withStyles(styles, { name: 'Articles' })(connect(mapStateToProps, {
    fetchArticlesSearch: fetchArticlesSearch,
    fetchArticlesByTagTitles: fetchArticlesByTagTitles,
    fetchChecklists: fetchChecklists

})(Articles));


