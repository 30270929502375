import React from 'react';
import ReactDOM from 'react-dom';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardContent, List, ListItem,ListItemIcon, ListItemText, Tooltip, Toolbar, Button, LinearProgress, Typography } from '@material-ui/core';

import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid'
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/CheckCircle';
import { fetchAgendas, goToRoute } from './actions'

class Agenda extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pageName: null };

    }

    componentWillUnmount() {
        this.timer = null; 
    }

    UNSAFE_componentWillMount() {
        this.props.fetchAgendas()
        this.timer = setInterval(() => {

            this.props.fetchAgendas()
        }, 10000);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate() {

    }
    handleListItemClick(pageName) {
       // console.log('handleListItemClick', pageName)
        //this.props.goToRoute("/page/" + pageName)
        this.setState({ pageName })

    }



    render() {
        const { pageName } = this.state
        const { agendas, admin, editor, isFetching } = this.props
       // console.log('agendas', agendas)
        return <div>
            {agendas && agendas.length > 0 &&
                <Grid style={{ maxWidth: '100%', padding: '12px', marginTop: '0px', marginLeft: '0px' }} container >
                    <Grid item lg={3} sm={3} xs={3} md={3} >
                        <Card>
                            <Toolbar>
                                <h1>Agenda</h1>
                                <div style={{ flexGrow: 1 }} />
                                <div style={{ paddingLeft: 0, paddingRight: 0, marginTop: 0, float: 'right' }}>
                                   
                                        <Button
                                            
                                            color="primary"
                                           
                                            onClick={this.props.fetchAgendas}
                                        >
                                            <RefreshIcon />
                                        </Button>
                                    


                                </div>

                            </Toolbar>
                            {isFetching &&
                                <LinearProgress
                                    variant={'indeterminate'}
                                    
                                />
                            }
                            {!isFetching &&
                                <LinearProgress
                                    variant={"determinate"}
                                    value={100}
                                />
                            }

                            <List component="nav">
                                {agendas.map(agenda => {
                                    return (<ListItem
                                        key={agenda.id}
                                        button
                                        selected={agenda.postName === pageName}
                                        onClick={this.handleListItemClick.bind(this, agenda.name)}
                                    >
                                    {
                                        agenda.failedAt && 
                                        new Date(agenda.lastFinishedAt).getTime() < new Date(agenda.failedAt).getTime() 
                                        &&
                                    <ListItemIcon>
                                    <ErrorIcon style={{color:'red'}}/>
                                  </ListItemIcon>
                                    }

                                  
                                 { !agenda.failedAt  &&
                                    <ListItemIcon>
                                    <CheckIcon style={{color:'green'}}/>
                                  </ListItemIcon>
                                    }

                                { agenda.failedAt  && new Date(agenda.lastFinishedAt).getTime() > new Date(agenda.failedAt).getTime()  &&
                                    <ListItemIcon>
                                    <CheckIcon style={{color:'green'}}/>
                                  </ListItemIcon>
                                    }

                                        <ListItemText primary={agenda.name.split(".")[1]} />
                                    </ListItem>)
                                })}

                            </List>
                        </Card>
                    </Grid>
                    <Grid item lg={9} sm={9} xs={9} md={9} >


                        <div style={{
                            marginTop: '0px',
                            paddingLeft: '2px',
                            paddingRight: '2px',
                            height: '100%',
                            overflowY: 'scroll',
                            WebkitOverflowScrolling: 'touch'
                        }}>
                            {pageName && agendas && agendas.filter(agenda => {

                                return agenda.name === pageName


                            }).map(page => {
                                return (
                                    <Card key={page.id} style={{ marginBottom: '15px', maxWidth: '100%' }}>
                                        {false && (admin || editor) &&
                                            <Tooltip title="neuen Checkliste schreiben">
                                                <Link style={{ position: 'absolute', top: 115, right: 50 }} to="/page/edit/new">
                                                    <Button style={{ color: 'white' }} variant="fab" color="secondary" aria-label="Neue Checkliste" >

                                                        <AddIcon />

                                                    </Button>
                                                </Link>
                                            </Tooltip>
                                        }
                                        <CardContent style={{ paddingBottom: 0 }}><h1>{page.name}</h1></CardContent>
                                        <CardContent style={{ paddingTop: 0 }}>
                                            <p>Zuletzt beendet: {new Date(page.lastFinishedAt).toLocaleString()}</p>
                                            <p>Nächster Durchlauf: {new Date(page.nextRunAt).toLocaleString()}</p>
                                            <p>Fehleranzahl: {page.failCount}</p>
                                            <p>Fehler um: {new Date(page.failedAt).toLocaleString()}</p>
                                            <p>Fehlermeldung: {page.failReason}</p>
                                            {false && (admin || editor) &&
                                                <Tooltip title="Checkliste bearbeiten">
                                                    <a style={{ marginLeft: 0 }} ><Link to={"/page/edit/" + page.id}><EditIcon /></Link></a>
                                                </Tooltip>
                                            }

                                        </CardContent>

                                    </Card>)
                            })}



                        </div>
                    </Grid>


                </Grid>

            }



        </div>

    }

}

function mapStateToProps(state) {


    return {
        agendas: state.agenda.agendas,
        isFetching: state.agenda.isFetching,
        admin: state.auth.admin,
        editor: state.auth.editor
    }
}
const styles = {

}
export default withStyles(styles, { name: 'Agenda' })(connect(mapStateToProps, {
    fetchAgendas: fetchAgendas,
    goToRoute: goToRoute,

})(Agenda));


