import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signOut } from "./actions";

class SignOut extends Component {

    UNSAFE_componentWillMount() {
        this.props.signOut();
    }

    render() {
        return null;
    }

}

export default connect(null, { signOut })(SignOut);