import React, { Component } from 'react';
import DossierNewForm from './forms/dossier_new_form';
import './style/dossier.css';
import {resetDossierSections} from "./actions";
import {connect} from "react-redux";

class DossierNew extends Component {

    UNSAFE_componentWillMount() {
        this.props.resetDossierSections();
    }

    render() {
        return (
            <div className="row">
                <div className="col col-12">
                    <p className="lead">New Dossier</p>
                    <DossierNewForm />
                </div>
            </div>
        )
    }
}

export default connect(null, {resetDossierSections})(DossierNew);

