import React, { Component, PropTypes } from 'react';
import './style/impressum.css';
import {Card, CardContent, Tooltip} from '@material-ui/core'
import { Markdown } from 'react-showdown';
import { fetchPage, goToRoute } from '../page/actions'
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class Impressum extends Component {

    componentDidMount() {

    }

    UNSAFE_componentWillMount() {
        this.props.fetchPage("impressum")
    }

    render() {
        const { page, admin, editor, lang } = this.props
        console.log('page', page)
        return (
            <div style={{padding:20}}> 
                {page && page.type == "impressum" &&
                    <Card >
                        <CardContent style={{ paddingBottom: 0 }}><h1>{page.title}</h1></CardContent>
                        <CardContent style={{ paddingTop: 0 }}>
                            <Markdown markup={page.markdown} tables={true} />

                            {(admin || editor) &&
                                <Tooltip title="Impressum bearbeiten">
                                    <a style={{ marginLeft: 0 }} ><Link to={"/page/edit/" + page.id}><EditIcon /></Link></a>
                                </Tooltip>
                            }

                        </CardContent>
                    </Card>
            }
            </div>
        )
    }
}

const styles = {

}

function mapStateToProps(state) {


    return {
        page: state.page.page,
        admin: state.auth.admin,
        editor: state.auth.editor, 
        lang:state.lang.lang
    }
}
export default withStyles(styles, { name: 'Page' })(connect(mapStateToProps, {
    fetchPage: fetchPage,
    goToRoute: goToRoute,

})(Impressum));

