import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import {
  fetchArticlesByTagTitles, fetchArticle
} from "../articles/actions";

import AddIcon from '@material-ui/icons/AddCircle';
import TagSelector from '../tagSelector/tagselector_index'

import {
  Chip,
  Paper,
  Dialog,
  DialogActions,
  IconButton,
  Button,
  DialogContent,
  DialogTitle,
  Tooltip
} from '@material-ui/core';
import axios from 'axios';
import { ROOT_URL } from "../../config";

const styles = {};

class editTagList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [], //this.props.tags || [],
      showTagSelector: false,
      selected: null
    };

  }
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined)
    }
  }


  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this)
    }
    if (this.props.tags) {

      this.setState({ tags: this.props.tags, selection: this.props.tags })
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {

    if (nextProps.tags && nextProps.tags != this.state.selection) {

      this.setState({ tags: nextProps.tags, selection: nextProps.tags })
    }
    if (nextProps.mainTag && nextProps.mainTag != this.state.selected) {
      this.setState({selected:nextProps.mainTag})
    }
  }

  componentDidUpdate() {

  }

  openTagSelector() {
    this.setState({ showTagSelector: true })
  }

  handleClose() {
    this.setState({ showTagSelector: false })
  }

  handleSaveClose() {
    this.setState({ showTagSelector: false })
   // console.log('this.props.onTagsChange', this.props.onTagsChange)
    if (this.props.onChange) {
      var tags = this.state.selection
      this.props.onChange(tags)
    }
    this.setState({ tags: this.state.selection })

  }



  fetchTagsByIds(ids) {

    var query = { "where": { "id": { "inq": ids } }, "pluck": ["title", "id"] }

    const url = `${ROOT_URL}/Tags?filter=${encodeURIComponent(JSON.stringify(query))}`

    return axios.get(url, {
      headers: { authorization: localStorage.getItem('token') }
    })
  }

  onSelectionChange(selection) {
   // console.log('selecton:', selection)
    this.setState({ selection })
  }

  handleDelete(tag) {
   // console.log("handleDelete", tag)
    var tags = this.state.tags
    var index = tags.indexOf(tag)
    if (index > -1) {
      tags.splice(index, 1);
      this.setState({ tags })
      if (this.props.onChange) {
        this.props.onChange(tags)
      }
    }
  };

  onClick(tag) {
    if (this.props.onTagSelect) {
      this.props.onTagSelect(tag)
      this.setState({ selected: tag })
    }
  }

  render() {
    const { tags, selection, showTagSelector, selected } = this.state;
    //var selectedIds = tags.map( tag => {return tag.id})

    return (
      <div>
        {showTagSelector && <Dialog

          disableBackdropClick={true}
          disableEscapeKeyDown={true}

          fullScreen={false}
          open={this.state.showTagSelector}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Tags auswählen</DialogTitle>


          <DialogContent>
            <TagSelector selection={selection} onSelectionChange={this.onSelectionChange.bind(this)} />
          </DialogContent>

          <DialogActions>

            <Button variant="raised" onClick={this.handleClose.bind(this)} color="primary" autoFocus>
              Abbrechen
            </Button>
            <Button variant="raised" onClick={this.handleSaveClose.bind(this)} color="primary">
              Speichern
            </Button>
          </DialogActions>
        </Dialog>
        }
        <div style={{ padding: 10 }}>
          <div style={{ paddingTop: 10, marginLeft: '0px', alignItems: 'center' }}>
            {tags && tags.map(tag => {

              return (
                <Chip
                  key={tag}
                  color={selected == tag ? "primary" : "default"}
                  style={{ margin: '3px' }}
                  onClick={this.onClick.bind(this, tag)}
                  label={tag}
                  onDelete={this.handleDelete.bind(this, tag)}

                />
              );
            })}
            <a>
              <Tooltip title="Tag hinzufügen">
                <IconButton
                  aria-haspopup="true"
                  style={{ margin: 3 }}

                  onClick={this.openTagSelector.bind(this)}
                  color="inherit"
                >
                  <AddIcon style={{ fontSize: 30 }} />
                </IconButton>
              </Tooltip>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {

  //console.log('state.articles', state.articles)
  return {

  }
}

export default withStyles(styles, { name: 'editTagList' })(connect(mapStateToProps, {

})(editTagList));