export default function validate(formProps) {
    const errors = {};

    if (!formProps.title) {
        errors.title = 'Please enter a title';
    }

    if (!formProps.author) {
        errors.author = 'Please enter an author';
    }

    return errors;
}