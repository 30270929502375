import {
    FETCH_ARTICLE,
    UPDATE_ARTICLE,
    INSERT_ARTICLE,
    DELETE_ARTICLE
   
} from "../../actions/types";

import * as _ from "lodash";


const INITIAL_STATE = {
    all: [],
    document:null,
    article:null
};


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case FETCH_ARTICLE:
            return {
                ...state,
                article: action.payload.data[0]
            };
            case FETCH_ARTICLE:
            return {
                ...state,
                article: action.payload.data[0]
            };
            case UPDATE_ARTICLE:
            return {
                ...state,
                article: action.payload.data[0]
            };
            case INSERT_ARTICLE:
            return {
                ...state,
                article: action.payload.data[0]
            };
            case DELETE_ARTICLE:
            return {
                ...state,
                article: null
            };

        default:
            return state;
    }
}