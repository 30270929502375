import {
    FETCH_DOCUMENT
   
} from "../../actions/types";

import * as _ from "lodash";

const INITIAL_STATE = {
    all: [],
    document:null
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case FETCH_DOCUMENT:
            return {
                ...state,
                document: action.payload.data[0]
            };

        default:
            return state;
    }
}