import React from 'react';
import ReactDOM from 'react-dom';
import {
    Card, CardActions, CardContent, IconButton, Paper, Grid, Checkbox, FormControlLabel,
    Dialog, DialogActions, DialogTitle, Button, CircularProgress
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { ROOT_URL } from "../../config";

import Hidden from '@material-ui/core/Hidden';
import {
    insertWhitepaper, updateWhitepaper, deleteWhitepaper, fetchWhitepaper
} from "./actions";

import TextField from '@material-ui/core/TextField/TextField';

import Editor from '@toast-ui/editor'
import '@toast-ui/editor/dist/toastui-editor.css'
////import 'tui-editor/dist/tui-editor-contents.css
import 'codemirror/lib/codemirror.css'
import 'highlight.js/styles/github.css'



class WhitepaperEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editor: null,
            del: false,
            updating: false,
            page: {
                id: null,
                title: "",
                markdown: "",
            },
            neWhitepaper: false
        }
    }
    /*
        componentWillReceiveProps(nextProps) {
            if (nextProps.input.value != this.props.input.value) {
                this.state.editor.setMarkdown(nextProps.input.value)
            }
    
        }*/

    UNSAFE_componentWillMount() {
        // console.log('before', this.props.match.params)
        if (this.props.match.params.id) {
            // console.log('before')
            if (this.props.match.params.id != "new") {
                this.props.fetchWhitepaper(this.props.match.params.id)
            } else {
                this.setState({ newWhitepaper: true })
            }

        }
    }

    handleChange = name => event => {
        //console.log('handleChange', name)
        var page = this.state.page

        page[name] = event.target.value

        //console.log('handleChange', article)
        this.setState({ page });
    };

    saveWhitepaper() {
        var page = this.state.page

        const fields = ["title"]
        fields.forEach(key => {
            if (page[key]) {
                page[key] = page[key].trim()
            }

        })

        page.markdown = this.state.editor.getMarkdown()

        if (this.state.newWhitepaper) {
            this.props.insertWhitepaper(page).then(res => {
                this.props.history.goBack()

            }).catch(err => {
                // console.log('err', err)
            })
        } else {
            this.setState({ updating: true })
            this.props.updateWhitepaper(page).then(res => {
                this.props.history.goBack()


            }).catch(err => {
                // console.log('err', err)
            })
        }

    }
    handleDeleteWhitepaper(id) {
        this.setState({ del: id })
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        //// console.log('nextProps', nextProps)
        if (!this.state.page.id && nextProps.page) {
            this.state.editor.setMarkdown(nextProps.page.markdown)
            this.setState({ page: nextProps.page })
        }

    }

    handleModeChange() {

        //var { value, name } = this.props.input

        if (this.state.editor) {
            var { page } = this.state
            page.markdown = this.clean(this.state.editor.getMarkdown())
            this.state.editor.setMarkdown(this.clean(page.markdown), false)
            this.setState({ page })
        }
    }

    clean(text) {
        return text.replaceAll(/\\/g, '')
    }

    componentDidMount() {
        let editor = new Editor({
            el: document.querySelector('#editSection'),
            initialEditType: 'wysiwyg',
            //height:'500',
            previewStyle: 'tab',
            events: {
                change: this.onChange
            },
            hooks: {
                changeMode: this.handleModeChange.bind(this)
            },
            initialValue: this.state.page ? this.state.page.markdown : ""
        });

        this.setState({ editor });
    }

    onChange = (event) => {
        // this.props.change(this.props.meta.form, "markdown", this.clean(this.state.editor.getMarkdown()) ) 
    }

    cancelDelete() {
        this.setState({ del: null })
    }

    deleteWhitepaper(id) {
        this.props.deleteWhitepaper(id).then(res => {
            this.props.history.goBack()
        }).catch(err => {

        })
    }



    render() {

        //const {  } = this.props
        const { page, newWhitepaper, del, updating } = this.state
        const { classes } = this.props


        return (
            <div>

                <Grid style={{ maxWidth: '100%', padding: '12px', marginTop: '0px', marginLeft: '0px' }} container >
                    <Grid item lg={12} >

                        <Card>
                            {page &&
                                <div style={{ paddingLeft: 10, paddingRight: 10, marginTop: 10, float: 'right' }}>
                                    {!updating &&
                                        <Button
                                            variant="fab"
                                            color="secondary"

                                            onClick={this.saveWhitepaper.bind(this)}
                                        >
                                            <SaveIcon />
                                        </Button>
                                    }
                                    {updating && <CircularProgress size={44} style={{


                                    }} />}

                                </div>
                            }
                            {page &&
                                <div style={{
                                    marginTop: 10,
                                    display: 'flex',
                                    flexWrap: 'wrap'
                                }}>



                                    <TextField
                                        id={"title"}
                                        label={"Titel"}
                                        fullWidth
                                        className={classes.textField}
                                        value={page.title}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleChange("title")}
                                        margin='dense'>
                                    </TextField>

                                </div>

                            }
                        </Card>
                        <div style={{ marginTop: 20, minHeight: '70vh' }} id='editSection' />

                        {!newWhitepaper &&
                            <IconButton onClick={this.handleDeleteWhitepaper.bind(this, page.id)}>
                                <DeleteIcon />
                            </IconButton>
                        }
                    </Grid>

                    {page &&
                        <Dialog
                            open={del}
                            onClose={this.cancelDelete.bind(this)}

                        >
                            <DialogTitle>Wirklich löschen?</DialogTitle>

                            <DialogActions>
                                <Button onClick={this.deleteWhitepaper.bind(this, page.id)} color="secondary">Ja</Button>
                                <Button onClick={this.cancelDelete.bind(this)} color="primary">Nein</Button>

                            </DialogActions>
                        </Dialog>
                    }
                </Grid>

            </div>
        )

    }
}

function mapStateToProps(state) {

    // console.log("state", state)
    return {
        // document: state.documentEdit.document,
        page: state.page.page
    }
}
const styles = {
    textField: {
        marginLeft: 15,
        marginRight: 15,

    },
    textFieldWide: {

        marginLeft: 15,
        marginRight: 15,


    }
}
export default withStyles(styles, { name: 'WhitepaperEditor' })(connect(mapStateToProps, {
    insertWhitepaper, updateWhitepaper, fetchWhitepaper, deleteWhitepaper
})(WhitepaperEditor));