import React from 'react';
import {
  SortingState, EditingState, PagingState, FilteringState, IntegratedFiltering, RowDetailState,
  IntegratedPaging, IntegratedSorting, SelectionState, IntegratedSelection
} from '@devexpress/dx-react-grid';
import {
  Grid, VirtualTable,
  Table, TableHeaderRow, TableEditRow, TableEditColumn, TableSelection, TableRowDetail,
  PagingPanel, DragDropProvider, TableColumnReordering, TableFilterRow
} from '@devexpress/dx-react-grid-material-ui';
import Paper from '@material-ui/core/Paper';

import {
  Template, TemplateConnector
} from '@devexpress/dx-react-core';


import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';
import { TableCell } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import LayerIcon from '@material-ui/icons/Layers'
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { fetchMap, fetchMaps } from './actions';
import axios from 'axios'
import LayerTable from '../layerTable'
import Drawer from '@material-ui/core//Drawer'


import  {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withMobileDialog,
} from '@material-ui/core';

import {
  ProgressBarCell,
} from './cells/progress-bar-cell';
import {
  HighlightedCell,
} from './cells/highlighted-cell';

import {
  generateRows,
  globalSalesValues,
} from './demo-data/generator';

const styles = theme => ({
  lookupEditCell: {
    paddingTop: 1 * 0.875,
    paddingRight: 1,
    paddingLeft: 1,
  },
  dialog: {
    width: 'calc(100% - 16px)',
  },
  inputRoot: {
    width: '100%',
  },
});

const AddButton = ({ onExecute }) => (
  <div style={{ textAlign: 'center' }}>
    <Button
      color="primary"
      variant="raised"
      onClick={onExecute}
      title="Create new row"
    >
      <AddIcon />
    </Button>
  </div>
);

const EditButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Edit row">
    <EditIcon />
  </IconButton>
);

const EditButton2 = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Edit row">
    <EditIcon />
  </IconButton>
);


const LayerButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Edit row">
    <LayerIcon />
  </IconButton>
);


const DeleteButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Delete row">
    <DeleteIcon />
  </IconButton>
);

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Save changes">
    <SaveIcon />
  </IconButton>
);

const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancel changes">
    <CancelIcon />
  </IconButton>
);

const commandComponents = {
  add: AddButton,
  edit: EditButton,
  layers: LayerButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton,
};

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return (
    <CommandButton
      onExecute={onExecute}
    />
  );
};

const availableValues = {
  style: ["topo", "basic", "light", "dark"],
  thememap: ["Ja", "Nein"]
};




const ButtonCellBase = ({
  availableColumnValues, value, onValueChange, classes, column, row
}) => {
  //console.log('row', row)
  return (
    <TableCell
      className={classes.lookupEditCell}
    >
      <Button>Edit</Button>
    </TableCell>

  )
}

const CustomTableRowBase = ({ classes, onClick, ...restProps }) => {

  const handleClick = (e) => {
   // console.log('e', restProps)

    onClick(restProps.row)
  }

  return (
    <Table.Row
      onClick={handleClick}
      {...restProps}
    />
  )
}
export const CustomTableRow = withStyles(styles, { name: 'CustomTableRow' })(CustomTableRowBase);



export const ButtonCell = withStyles(styles, { name: 'MapTable' })(ButtonCellBase);


const Cell = (props) => {
 //// console.log('props', props)
 /* if (props.column.name == "layers") {
    return <Table.Cell> {props.value ? props.value.length : ...props}</Table.Cell>
  } else {*/
    return <Table.Cell {...props} />;
  //}





  // 
};
/*
const EditCell = (props) => {
  const availableColumnValues = availableValues[props.column.name];
  if (availableColumnValues) {
    return <LookupEditCell {...props} availableColumnValues={availableColumnValues} />;
  }
  if (props.column.name == "layers") {
    return <ButtonCell {...props} />;
  }
  return <TableEditRow.Cell {...props} />;
};
*/

class LayerCell extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    }
    this.layerTable = null

  }

  componentDidMount() {
   // console.log('componentDidMount', this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
   // console.log('nextProps', nextProps)
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSaveClose() {
   // console.log('this.layerTable.selection', this.layerTable.getSelection())
    this.props.onValueChange(this.layerTable.getSelection())
    this.setState({ open: false });
  }

  render() {
   // console.log('render', this.props)

    const { availableColumnValues, value, onValueChange, classes } = this.props

    return (
      <TableCell

      >
        <Button onClick={this.handleClickOpen}>Auswählen</Button>
        <Dialog

          disableBackdropClick={true}
          disableEscapeKeyDown={true}

          fullScreen={false}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Layer auswählen"}</DialogTitle>


          <DialogContent>
            <LayerTable
              editable={false}
              selection={this.props.row.layers}
              onRef={(layerTable) => {
               // console.log('layerTable', layerTable); this.layerTable = layerTable;
              }} />
          </DialogContent>

          <DialogActions>

            <Button variant="raised" onClick={this.handleClose} color="primary" autoFocus>
              Abbrechen
            </Button>
            <Button variant="raised" onClick={this.handleSaveClose.bind(this)} color="primary">
              Speichern
            </Button>
          </DialogActions>
        </Dialog>

      </TableCell>
    )
  }
}

class LayerCell2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    }
    this.drawer = null
    this.layerTable = null

  }

  componentDidMount() {
   // console.log('componentDidMount', this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
   // console.log('nextProps', nextProps)
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSaveClose() {
   // console.log('this.layerTable.selection', this.layerTable.getSelection())
    this.props.onValueChange(this.layerTable.getSelection())
    this.setState({ open: false });
  }

  render() {
   // console.log('render', this.props)

    const { availableColumnValues, value, onValueChange, classes } = this.props

    return (
      <TableCell

      >
        <Button onClick={this.handleClickOpen}>Auswählen</Button>

        <Drawer ref={ref => {
          this.drawer = ref
        }} anchor="top" open={this.state.open} onClose={this.handleClose.bind(this)}>
          <div style={{ minHeight: '100vh' }} >

            <LayerTable
              selection={this.props.row.layers}
              onRef={(layerTable) => {
               // console.log('layerTable', layerTable); this.layerTable = layerTable;
              }} />


            <DialogActions>

              <Button onClick={this.handleClose} color="primary" autoFocus>
                Abbrechen
</Button>
              <Button onClick={this.handleSaveClose.bind(this)} color="primary">
                Speichern
</Button>
            </DialogActions>



          </div>
        </Drawer>






      </TableCell>
    )
  }
}

const LookupEditCellBase = ({
  availableColumnValues, value, onValueChange, classes,
}) => (
    <TableCell
      className={classes.lookupEditCell}
    >
      <Select
        value={value || "light"}
        onChange={event => onValueChange(event.target.value)}
        input={
          <Input
            classes={{ root: classes.inputRoot }}
          />
        }
      >
        {availableColumnValues.map(item => (
          <MenuItem key={item} value={item}>{item}</MenuItem>
        ))}
      </Select>
    </TableCell>
  );
export const LookupEditCell = withStyles(styles, { name: 'ControlledModeDemo' })(LookupEditCellBase);



class EditCell extends React.PureComponent {


  componentDidMount() {
    //console.log('componentDidMount', this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log('nextProps', nextProps)
  }

  render() {
   // console.log('render', this.props) 
    const availableColumnValues = availableValues[this.props.column.name];

    if (this.props.column.name == "layers") {
      return <LayerCell {...this.props} />;
    }
   
    if (availableColumnValues) {
      return <LookupEditCell {...this.props} availableColumnValues={availableColumnValues} />;
    }
    return <TableEditRow.Cell {...this.props} />;
  }
}

const getRowId = row => row.id;

class MapTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {

      columns: [
        { name: 'title', title: 'Name' },
        { name: 'style', title: 'Kartenstil' },
        { name: 'thememap', title: 'Themenkarte' },
        { name: 'layers', title: 'Ebenen' },
      ],
      detailColumns: [
        { name: 'title', title: 'Title' }
      ],
      tableColumnExtensions: [
        { columnName: 'amount', align: 'right' },
      ],
      editable: true,
      rows: [],
      selection: [],
      sorting: [],
      editingRowIds: [],
      expandedRowIds: [],
      addedRows: [],
      rowChanges: {},
      currentPage: 0,
      deletingRows: [],
      pageSize: 0,
      pageSizes: [5, 10, 0],
      columnOrder: ['title', 'style', 'thememap', 'layers', 'zoom', 'pitch', 'bearing'],
    };
    //console.log("rows", this.state.rows)





    this.changeSorting = sorting => this.setState({ sorting });
    this.changeEditingRowIds = editingRowIds => {
     // console.log('editingRowIds', editingRowIds)
      if (this.props.onEditingRowIdsChange) {
        this.props.onEditingRowIdsChange(editingRowIds)
      }
      this.setState({ editingRowIds });
    }
    this.changeAddedRows = addedRows => this.setState({
      addedRows: addedRows.map(row => (Object.keys(row).length ? row : {
        title: ""
      })),
    });
    this.changeRowChanges = rowChanges => {

      this.props.rowChanges(rowChanges)
      this.setState({ rowChanges });



    }
    this.changeCurrentPage = currentPage => this.setState({ currentPage });
    this.changePageSize = pageSize => this.setState({ pageSize });
    this.commitChanges = ({ added, changed, deleted }) => {
      let { rows } = this.state;

      if (added) {

        this.props.rowAdded(added)
      }

      if (changed) {
       // console.log('changed', changed)
        this.props.rowCommitted(changed)
        //layerList = layerList.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      }
      this.setState({ deletingRows: deleted || this.state.deletingRows });
    };
    this.cancelDelete = () => this.setState({ deletingRows: [] });
    this._deleteRows = () => {
      if (this.props.rowDelete) {
        this.props.rowDelete(this.state.deletingRows[0])
      }
      const rows = this.state.rows.slice();
      this.state.deletingRows.forEach((rowId) => {
        const index = rows.findIndex(row => row.id === rowId);
        if (index > -1) {
          rows.splice(index, 1);
        }
      });
      this.setState({ rows, deletingRows: [] });
     
    };
    this.changeColumnOrder = (order) => {
      this.setState({ columnOrder: order });
    };
  }

  upsertMap(item) {

  }

  updateRow(item) {
   // console.log('item', item)
    const id = item.id

    this.editingState.changeRow({ rowId: id, change: item })

    //this.setState({rows:rows})
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
   // console.log('props', this.props)
    if (nextProps.maps) {
      /*nextProps.maps.forEach(map => {
        if (!map.hasOwnProperty("location") && !map.location) {
          map.location = [0,0]
        } else if (map.location) {
          
          map.location = map.location.coordinates
        } else {

        }
      })
      */
      this.setState({ rows: nextProps.maps })
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  setSelection(selection) {
    this.setState({ selection })
  }



  addRows(geojson) {
   // console.log('geojson', geojson)
    var rows = this.state.rows.slice()
    if (geojson.type == "FeatureCollection") {
      geojson.features.forEach(f => {
        var payload = { id: f.id, title: f.properties.title, location: f.coordinates, riskLevel: f.properties.riskLevel, type: f.geometry.type }
        rows.push(payload)
      })
    } else if (Array.isArray(geojson)) {

      geojson.forEach(f => {
        var payload = { id: f.id, title: f.properties.title, location: f.coordinates, riskLevel: f.properties.riskLevel, type: f.geometry.type }
        rows.push(payload)
      })
    }

    this.setState({ rows: rows })

  }

  deleteRows(ids) {
   // console.log('deleteRows')
    var rows = []
    var rows = this.state.rows.slice()
    var selection = this.state.selection.slice()
   // console.log('selection 1', selection)
   // console.log('rows.length 1', rows.length)
    var rows = rows.filter(r => {
      return ids.indexOf(r.id) == -1
    })
    var selection = selection.filter(r => {
      return ids.indexOf(r.id) != -1
    })
   // console.log('selection 2', selection)
   // console.log('rows.length 2', rows.length)

    this.setState({ rows: rows, selection, selection })
    return "success"

  }


  onSelectionChange(selection) {
    //console.log('selection', selection)
    //this.setState({ selection })

    const lastSelected = selection
      .find(selected => this.state.selection.indexOf(selected) === -1);

    if (lastSelected !== undefined) {
      this.setState({ selection: [lastSelected] });
    } else {
      // NOTE: Uncomment the next line in order to allow clear selection by double-click
      this.setState({ selection: [] });
    }

    this.props.onSelectionChange(lastSelected)
  }

  GridDetailContainerBase({ row, classes }) {
   // console.log('this', this)
   // console.log('row', row)

    const layers = row.layers || []

    const { detailColumns } = this.state
    return (
      <div style={{ margin: 20 }}>

        <Paper>
          <Grid
            rows={layers}
            columns={detailColumns}
          >
            <Table
              rowComponent={CustomTableRow}
            />
            <TableHeaderRow />
            <Template
              name="tableRow"
              predicate={({ tableRow }) => tableRow.type === 'data'}
            >
              {params => (
                <TemplateConnector>
                  {({ selection }, { toggleSelection }) => (
                    <CustomTableRow
                      {...params}

                      onClick={() => this.handleClick(params.tableRow.row)}
                    />
                  )}
                </TemplateConnector>
              )}
            </Template>
          </Grid>
        </Paper>
      </div>
    )
  }

  onClick(e) {
   // console.log('onClick')
  }

  onDialogExit(e) {
   // console.log('e', e)
  }

  onExpandedRowIdsChange = expandedRowIds => this.setState({ expandedRowIds });


  render() {
    const {
      classes,
    } = this.props;
    const {
      rows,
      columns,
      tableColumnExtensions,
      sorting,
      editingRowIds,
      addedRows,
      expandedRowIds,
      rowChanges,
      currentPage,
      deletingRows,
      pageSize,
      pageSizes,
      columnOrder,
      editable,
      selection
    } = this.state;

    const { maps } = this.props

    return (
      <div>
        <Grid
          rows={maps}
          columns={columns}
          getRowId={getRowId}
          height={window.innerHeight}
        >
          <SortingState
            sorting={sorting}
            onSortingChange={this.changeSorting}
          />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={this.changeCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={this.changePageSize}
          />
          <RowDetailState
            expandedRowIds={expandedRowIds}
            onExpandedRowIdsChange={this.onExpandedRowIdsChange.bind(this)}
          />
          <FilteringState defaultFilters={[]} />
          <SelectionState
            selection={selection}
            onSelectionChange={this.onSelectionChange.bind(this)} />
          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedSelection />
          <IntegratedPaging />
          {editable &&
            <EditingState
              ref={ref => this.editingState = ref}
              editingRowIds={editingRowIds}
              onEditingRowIdsChange={this.changeEditingRowIds}
              rowChanges={rowChanges}
              onRowChangesChange={this.changeRowChanges}
              addedRows={addedRows}
              onAddedRowsChange={this.changeAddedRows}
              onCommitChanges={this.commitChanges}
            />
          }
          <DragDropProvider />

          <Table 
            columnExtensions={tableColumnExtensions}
            cellComponent={Cell}

          />
          <TableColumnReordering
            order={columnOrder}
            onOrderChange={this.changeColumnOrder}
          />

          <TableHeaderRow showSortingControls />
          <TableRowDetail
            contentComponent={this.GridDetailContainerBase.bind(this)}
          />
          <TableFilterRow />
          <TableSelection />
          {editable &&
            <TableEditRow
              cellComponent={EditCell}
            />
          }
          {editable &&
            <TableEditColumn
              width={120}
              showAddCommand={!addedRows.length}
              showEditCommand
              showDeleteCommand
              commandComponent={Command}
            />
          }
          <PagingPanel
            pageSizes={pageSizes}
          />
        </Grid>

        <Dialog
          open={!!deletingRows.length}
          onClose={this.cancelDelete}
          disableBackdropClick={true}
          keepMounted={true}
          onClose={this.onDialogExit.bind(this)}
          classes={{ paper: classes.dialog }}
        >
          <DialogTitle>Delete Row</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure to delete the following row?
            </DialogContentText>
            <Paper>
              <Grid
                rows={rows.filter(row => deletingRows.indexOf(row.id) > -1)}
                columns={columns}
              >
                <Table
                  columnExtensions={tableColumnExtensions}
                  cellComponent={Cell}
                />
                <TableHeaderRow />
              </Grid>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.cancelDelete} color="primary">Cancel</Button>
            <Button onClick={this._deleteRows} color="secondary">Delete</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}



function mapStateToProps(state) {
  /* let filtered = _.isEmpty(state.posts.postsGridData.gridFilters) ? [] : state.posts.postsGridData.gridFilters;
   let sorted = _.isEmpty(state.posts.postsGridData.gridSortCriteria) ? [] : state.posts.postsGridData.gridSortCriteria;
*/

 // console.log('state.map', state.maps)

  return {
    maps: state.maps.maps,
  }
}

export default withStyles(styles, { name: 'MapTable' })(connect(mapStateToProps, null, null, { withRef: true }, {
  fetchMap: fetchMap,
  fetchMaps: fetchMaps,
})(MapTable));

//export default withStyles(styles, { name: 'DataTable' })(DataTable);