import axios from 'axios';
import { push } from 'react-router-redux'
import {
    
    SEND_OFFER,
} from "../../actions/types";

import { ROOT_URL } from "../../config";


export function goToRoute(route) {
    return function (dispatch) {
        dispatch(push(route));
    }
}

export function sendForm(props) {

    
    var payload = props

    const url = `${ROOT_URL}/users/offer`
    return function (dispatch) {
        return axios.post(url, payload, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            
            dispatch({
                type: SEND_OFFER,
                payload: response
            });
        }).catch(() => {
            
        });
    }
}
