
export default function (state = {}, action) {
    switch (action.type) {
        case 'FETCH_CUSTOMERS':
            return { ...state, customers: action.payload.data }
        case 'FETCH_CUSTOMER':
        console.log('FETCH_CUSTOMER',action.payload.data)
            const customer = (action.payload.data && action.payload.data.length > 0) ? action.payload.data[0] : null
            return { ...state, customer }
        case 'RESET_CUSTOMER':
            return { ...state, customer:null }
        case 'UPDATE_CUSTOMER':
            return { ...state, updated: action.payload.data }
        case  'FETCH_CUSTOMER_CONFIGURATIONS':
        console.log('action.payload.data', action.payload.data)
            return { ...state, configurations: action.payload.data }

    }

    return state;
}