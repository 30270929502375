import React, { Component, PropTypes } from 'react';
import {Card, CardContent, Tooltip} from '@material-ui/core'
import { Markdown } from 'react-showdown';
import { fetchPage, goToRoute } from '../page/actions'
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Map from '../map/map_index'

class FullMap extends Component {

    componentDidMount() {

    }

    UNSAFE_componentWillMount() {
        this.props.fetchPage("team")
    }

    render() {
        const { page, admin, editor } = this.props
        return (
            <div style={{padding:0}}> 
                <Map containerStyle={{
                                height: '100vh',
                                width: '100vw',
                            }} mapId="f18927f8-1f05-47f5-8857-b36fb7caa715" />
            }
            </div>
        )
    }
}

const styles = {

}

function mapStateToProps(state) {


    return {
        page: state.page.page,
        admin: state.auth.admin,
        editor: state.auth.editor
    }
}
export default withStyles(styles, { name: 'Team' })(connect(mapStateToProps, {
    fetchPage: fetchPage,
    goToRoute: goToRoute,

})(FullMap));

