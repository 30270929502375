import React from 'react';
import Editor from '@toast-ui/editor'
import { Button } from '@material-ui/core'
import '@toast-ui/editor/dist/toastui-editor.css'
//import 'tui-editor/dist/tui-editor-contents.css
import 'codemirror/lib/codemirror.css'
import 'highlight.js/styles/github.css'


class MarkdownEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editor: null,
            changed: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.markup != this.props.markup && !this.state.changed) {
            this.state.editor.setMarkdown(nextProps.markup)
            this.setState({ changed: true })
        }

    }

    handleModeChange() {
        console.log('handleModeChange')
        //var { value, name } = this.props.input

        if (this.state.editor) {
            var value = this.state.editor.getMarkdown()
            this.props.onChange("markdown", value)
            this.state.editor.setMarkdown(this.clean(value), false)
        }
    }

    clean(text) {
        return text.replaceAll(/\\/g, '')
    }

    onSave() {
        this.props.onSave(this.state.editor.getValue())
    }

    componentDidMount() {
        let editor = new Editor({
            el: document.querySelector('#editSection'),
            initialEditType: 'wysiwyg',
            previewStyle: 'tab',
            height: window.innerHeight - 250,
            events: {
                change: this.onChange
            },
            hooks: {
                // modeChange:this.handleModeChange.bind(this)
            },
            initialValue: this.props.markup
        });

        this.setState({ editor });
    }

    onChange = (event) => {
        this.props.onChange("markdown", this.state.editor.getValue())
    }

    render() {
        return (
            <div>
                <div id='editSection' />
                <Button onClick={this.onSave.bind(this)}>Speichern</Button>
            </div>

        );
    }


}

export default MarkdownEditor