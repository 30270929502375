import { SELECT_LANG } from "../../actions/types";

export default function (state = {}, action) {
    switch (action.type) {
        case SELECT_LANG:
        
            return { ...state, lang:action.data }
        
    }

    return state;
}