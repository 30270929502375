import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderField, renderHiddenField, renderSelect } from "../../components/field_formatters";
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogActions, LinearProgress } from "@material-ui/core";
import { fetchCustomers } from '../customers/actions'
import { updateUser } from "./actions";
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next'

class UserForm extends Component {

    constructor(args) {
        super(args)
        this.state = {
            open: true,
            updating: false,
        }

    }

    componentDidMount() {
        console.log('did mount', this.props)
        this.setState({ open: true })
        this.props.fetchCustomers()
    }

    handleFormSubmit(formProps) {
        console.log('formProps', formProps)
        if (this.props.initialValues) {
            formProps = { name: formProps.name, id: formProps.id }
        }
        this.setState({ updating: true })
        if (formProps.email) {
            formProps.email = formProps.email.toLowerCase()
    
        }
        formProps.customer = this.props.customer
        formProps.role = "user"
        this.props.updateUser(formProps).then(res => {
            console.log('after updateUser', res)
            this.setState({ updating: false })
            this.handleClose()
            /*if (res) {
                
            } else {
                this.setState({ error: true })
            }*/
        }).catch(error => {
            console.log('error', error)
            this.setState({ updating: false, error })
        })

    }

    renderErrorMessage() {
        if (this.props.errorMessage) {
            return (
                <div>
                    <strong></strong> {this.props.errorMessage}
                </div>
            )
        }
    }

    handleClose() {
        this.setState({ open: false });
        //console.log('handleClose', this.props.onDismiss)
        if (this.props.onDismiss) {

            this.props.onDismiss()
        }
    };

    render() {
        const { handleSubmit, initialValues, customers, t } = this.props;
        const { open, updating, error } = this.state
        console.log('initialValues', initialValues)
        return (

            <Dialog open={open} onClose={this.handleClose.bind(this)} aria-labelledby={t("users.NeuenBenutzeranlegen")} style={{ minWidth: '50%' }}>
                <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                    <DialogTitle id="simple-dialog-title">{initialValues && initialValues.id ? t("users.Benutzerbearbeiten") : t("users.NeuenBenutzeranlegen")}</DialogTitle>

                    <div style={{ padding: 20, }}>
                        <Field name="id" type="hidden" component={renderHiddenField} />
                        <Field name="name" type="text" component={renderField} label="Name" />
                        {!initialValues &&
                            <Field name="email" type="email" component={renderField} label="Email" />
                        }
                        {false &&
                            <Field name="role" gateways={["user", "manager"]} type="text" component={renderSelect} label="Rolle" />
                        }
                        {false && customers &&
                            <Field name="tenant" type="text" gateways={customers} component={renderSelect} label="Mieter" />
                        }

                        {error &&
                            <div style={{ color: 'red', fontWeight: 'bold' }}>{t("users.BenutzerExistiert")}</div>
                        }
                    </div>

                    <DialogActions>
                        {!updating &&
                            <div>
                                <Button onClick={this.handleClose.bind(this)} style={{ marginTop: 35 }} >{t("users.Abbrechen")}</Button>{' '}
                                <Button type="submit" style={{ marginTop: 35 }} color="secondary">{t("users.Speichern")}</Button>{' '}
                            </div>
                        }
                        {updating &&
                            <LinearProgress />
                        }
                    </DialogActions>
                </form>
            </Dialog>

        )
    }
}

const form = reduxForm({
    form: 'UserForm',

});

UserForm.contextTypes = {
    store: PropTypes.object
};

function mapStateToProps(state) {
    return {
        customers: state.customers.customers,
        user: state.users.user,
        errorMessage: state.auth.error,
        updated: state.users.updated,
        //initialValues: state.users.user
    }
}

export default withNamespaces()(connect(mapStateToProps, { updateUser, fetchCustomers })(form(UserForm)));