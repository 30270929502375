export default function validateLogin(formProps) {
    const errors = {};

    if (!formProps.username) {
        errors.username = 'Please enter a username';
    }

    if (!formProps.password) {
        errors.password = 'Please enter a password';
    }

    return errors;
}