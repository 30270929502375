import React from 'react';
import ReactDOM from 'react-dom';

import { Markdown } from 'react-showdown';
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardActions,  CardContent, List, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { ROOT_URL } from "../../config";
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import { Link } from 'react-router-dom';
import Map from '../map/map_index'
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import AddIcon from '@material-ui/icons/Add';
import { fetchChecklists, fetchWhitepaper, goToRoute } from './actions'
//import ReactGA from 'react-ga';
import WhitepaperContactForm from '../whitepaperForm'

const gaProperty = 'UA-130702159-1'
const id = "94784b35-4542-4fc4-b96b-62c6d1298a01"

class Whitepaper extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pageName: null };

    }
    UNSAFE_componentWillMount() {
        //ReactGA.initialize(gaProperty);
        //ReactGA.pageview(window.location.pathname + window.location.search)
        this.props.fetchWhitepaper(id)
        console.log('should fetch whitepaper')
        if (this.props.match.params.postName) {
            //  this.setState({ pageName: this.props.match.params.postName })

            /*this.fetchWhitepaper(this.props.match.params.postName).then(res => {
                if (res.data.length > 0) {
                    this.setState({
                        whitepaper: res.data[0]
                    })
                }

            }).catch(err => {
               // console.log('err', err)
            })*/


        }


    }



    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate() {

    }
    handleListItemClick(pageName) {
        // console.log('handleListItemClick', pageName)
        this.props.goToRoute("/whitepaper/" + pageName)
        this.setState({ pageName })

    }

    print() {

        window.print()
    }

    showForm() {
        this.setState({showForm:true})
    }


    render() {
        const { pageName, showForm } = this.state
        const { checklists, admin, editor, whitepaper, sent, lang } = this.props
        // console.log('checklists', checklists)
        // console.log('pageName', pageName)
        const contentSize = this.props.match.params.postName ? 12 : 9
        return <div>
            {whitepaper &&
                <Grid container >
                    <Grid item lg={1} sm={0} xs={0} md={1} >

                    </Grid>
                    <Grid item lg={10} sm={12} xs={12} md={10} >
                       <Card>
                            <CardContent>
                                <h2>{whitepaper.title[lang]}</h2>
                                <Markdown markup={whitepaper.markdown[lang]}></Markdown>
                                {!showForm &&
                                <div style={{textAlign:'center'}}>
                                <Button variant="contained" color="secondary"  onClick={this.showForm.bind(this)}>DOWNLOAD</Button>
                                </div>
    }
                                {showForm && 
                                   <WhitepaperContactForm whitepaperId={whitepaper.id} />
                                }
                             
                            </CardContent>
                        </Card>

                    </Grid>
                    <Grid item lg={1} sm={0} xs={0} md={1} >

                    </Grid>


                </Grid>
            }
        </div>

    }

}

function mapStateToProps(state) {


    return {
        whitepaper: state.whitepaper.whitepaper,
        admin: state.auth.admin,
        whitepaperContact: state.whitepaperContact.sent,
        editor: state.auth.editor,
        lang: state.lang.lang
    }
}
const styles = {

}
export default withStyles(styles, { name: 'Whitepaper' })(connect(mapStateToProps, {
    fetchWhitepaper: fetchWhitepaper,
    goToRoute: goToRoute,

})(Whitepaper));


