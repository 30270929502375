import axios from 'axios';
import {
    ADD_NEW_POST_SECTION,
    CREATE_POST,
    DELETE_POST,
    FETCH_POST,
    FETCHED_LAYER,
    FETCHING_LAYER,
    FETCHING_LAYERLIST,
    FETCHED_LAYERS,
    FETCHING_LAYERS,
    FETCHED_MAP,
    FETCHED_LAYERLIST,
    FETCHING_MAP,
    REMOVE_POST_SECTION,
    RESET_POST_SECTION_TO_0,
    SET_POSTS_GRID_FILTERS,
    SET_POSTS_GRID_PAGE_INDEX,
    SET_POSTS_GRID_PAGE_SIZE,
    SET_POSTS_GRID_SORT_CRITERIA,
    UPDATE_POST
} from "../../actions/types";

import {ROOT_URL} from "../../config";

export function fetchLayer(ids) {
    return function (dispatch) {

        dispatch(fetchingLayer());

        //const url = `${ROOT_URL}/marker?_page=${page + 1}&_limit=${pageSize}`
        const url = `${ROOT_URL}/Layers/featureCollection?ids=${encodeURIComponent( JSON.stringify(ids) )}`


        setTimeout(() =>
            axios.get(url, {
                headers: {authorization: localStorage.getItem('token')}
            })
                .then(response => {
                    let data = response.data;
                   
                    dispatch(fetchedLayer(data));
                })
                .catch(() => {
                   // console.log('error fetching the posts');
                }), 200);
    }
}

export function fetchLayerList() {
    return function (dispatch) {

        dispatch(fetchingLayerList());

        const url = `${ROOT_URL}/Layers`


        setTimeout(() =>
            axios.get(url, {
                headers: {authorization: localStorage.getItem('token')}
            })
                .then(response => {
                    let data = response.data;
                   // console.log('fetched layer list')
                    dispatch(fetchedLayerList(data));
                })
                .catch((err) => {
                   // console.log('error fetching layerList', err);
                }), 200);
    }
}

export function fetchLayers(ids) {
    return function (dispatch) {

        dispatch(fetchingLayers());

        const url = `${ROOT_URL}/Layers`


        setTimeout(() =>
            axios.get(url, {
                headers: {authorization: localStorage.getItem('token')}
            })
                .then(response => {
                    let data = response.data;
                   // console.log('fetched layer list')
                    dispatch(fetchedLayers(data));
                })
                .catch((err) => {
                   // console.log('error fetching layerList', err);
                }), 200);
    }
}

export function fetchMap(id) {
    return function (dispatch) {

        dispatch(fetchingMap());
        
        const url = `${ROOT_URL}/Maps/${id}`

        setTimeout(() =>
            axios.get(url, {
                headers: {authorization: localStorage.getItem('token')}
            })
                .then(response => {
                    let data = response.data;
                   
                    dispatch(fetchedMap(data));
                })
                .catch(() => {
                   // console.log('error fetching the map');
                }), 200);
    }
}

export function fetchNewsSearch(id) {
    
    

    return function (dispatch) {
        return axios.get(`${ROOT_URL}/events/${id}`, {
            headers: {authorization: localStorage.getItem('token')}
        }).then((response) => {
            dispatch({
                type: FETCH_POST,
                payload: response
            });
        }).catch(() => {
           // console.log('error fetching the post');
        });
    }
}


export function fetchPost(id) {
    return function (dispatch) {
        return axios.get(`${ROOT_URL}/events/${id}`, {
            headers: {authorization: localStorage.getItem('token')}
        }).then((response) => {
            dispatch({
                type: FETCH_POST,
                payload: response
            });
        }).catch(() => {
           // console.log('error fetching the post');
        });
    }
}

export function createdPost(response) {
    return {
        type: CREATE_POST,
        payload: response
    }
}

export function createPost(props) {
    return function (dispatch) {
        return axios.post(`${ROOT_URL}/events`, props, {
            headers: {authorization: localStorage.getItem('token')}
        }).then((response) => {
            dispatch(createdPost(response));
        }).catch(() => {
           // console.log('error fetching the post');
        });
    }
}

export function updatePost(props) {

    const request = axios.put(`${ROOT_URL}/events/${props.id}`, props, {
        headers: { authorization: localStorage.getItem('token') }
    });

    return {
        type: UPDATE_POST,
        payload: request
    }
}

export function deletedPost(post) {
    return {
        type: DELETE_POST,
        post:post
    }
}

export function deletePost(post) {
    return function (dispatch) {
        return axios.delete(`${ROOT_URL}/events/${post.id}`, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            dispatch(deletedPost(post));
        });
    }
}

export function addNewPostSection() {
    return {
        type: ADD_NEW_POST_SECTION,
        payload: null
    }
}

export function resetPostSections() {
    return {
        type: RESET_POST_SECTION_TO_0,
        payload: null
    }
}

export function removeSection(id) {
    return {
        type: REMOVE_POST_SECTION,
        payload: id
    }
}

export function setPostsGridPageSize(pageSize) {
    return {
        type: SET_POSTS_GRID_PAGE_SIZE,
        payload: pageSize
    }
}

export function setPostsGridPageIndex(pageIndex) {
    return {
        type: SET_POSTS_GRID_PAGE_INDEX,
        payload : pageIndex
    }
}

export function setPostsGridSortCriteria(newSorted) {
    return {
        type: SET_POSTS_GRID_SORT_CRITERIA,
        payload : newSorted,
    }
}

export function setPostsGridFilters(filtered) {
    return {
        type: SET_POSTS_GRID_FILTERS,
        payload : filtered,
    }
}

export function fetchingLayer()
{
    return {
        type: FETCHING_LAYER,
        payload: true
    }
}

export function fetchingLayers()
{
    return {
        type: FETCHING_LAYER,
        payload: true
    }
}

export function fetchingLayerList()
{
    return {
        type: FETCHING_LAYERLIST,
        payload: true
    }
}

export function fetchingMap()
{
    return {
        type: FETCHING_MAP,
        payload: true
    }
}

export function fetchedLayer(data) {
   // console.log('data', data)
    return {
        type: FETCHED_LAYER,
        payload: {
            data: data,
            loading: false
        }
    }
}

export function fetchedLayerList(data) {
   // console.log('data', data)
    return {
        type: FETCHED_LAYERLIST,
        payload: {
            data: data,
            loading: false
        }
    }
}

export function fetchedLayers(data) {
   // console.log('data', data)
    return {
        type: FETCHED_LAYERS,
        payload: {
            data: data,
            loading: false
        }
    }
}

export function fetchedMap(data) {
   // console.log('data', data)
    return {
        type: FETCHED_MAP,
        payload: {
            data: data,
            loading: false
        }
    }
}