import React, { Component, PropTypes } from 'react';
import {Card, CardContent, Tooltip} from '@material-ui/core'
import { Markdown } from 'react-showdown';
import { fetchPage, goToRoute } from '../page/actions'
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class Team extends Component {

    componentDidMount() {

    }

    UNSAFE_componentWillMount() {
        this.props.fetchPage("team")
    }

    render() {
        const { page, admin, editor } = this.props
        return (
            <div style={{padding:20}}> 
                {page && page.id == "team" &&
                    <Card >
                        <CardContent style={{ paddingBottom: 0 }}><h1>{page.title}</h1></CardContent>
                        <CardContent style={{ paddingTop: 0 }}>
                            <Markdown markup={page.markdown} tables={true} />

                            {(admin || editor) &&
                                <Tooltip title="Team bearbeiten">
                                   <Link to={"/page/edit/" + page.id}><EditIcon /></Link>
                                </Tooltip>
                            }

                        </CardContent>
                    </Card>
            }
            </div>
        )
    }
}

const styles = {

}

function mapStateToProps(state) {


    return {
        page: state.page.page,
        admin: state.auth.admin,
        editor: state.auth.editor
    }
}
export default withStyles(styles, { name: 'Team' })(connect(mapStateToProps, {
    fetchPage: fetchPage,
    goToRoute: goToRoute,

})(Team));

