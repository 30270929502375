import { combineReducers } from 'redux';
import DossiersReducer from '../containers/dossier/reducer';
import MapReducer from '../containers/map/reducer';
import MapEditorReducer from '../containers/mapEditor/reducer';
import { reducer as reduxFormReducer, actionTypes } from 'redux-form';
import {reducer as notifications} from 'react-notification-system-redux';
import {routerReducer} from "react-router-redux";
import authReducer from '../containers/auth/reducer';
import sideBarReducer from '../containers/layout/reducer';
import TreeViewReducer from '../containers/treeList/reducer';
import ArticlesReducer from '../containers/articles/reducer';
import DocumentEditorReducer from '../containers/documentEditor/reducer';
import PageReducer from '../containers/page/reducer'
import ArticleEditorReducer from '../containers/articleEditor/reducer';
import HotspotEditorReducer from '../containers/hotspotEditor/reducer';
import ContactReducer from '../containers/contactForm/reducer'
import OfferReducer from '../containers/offerForm/reducer'
import TagsReducer from '../containers/tagSelector/reducer';
import AgendaReducer from '../containers/agenda/reducer';

import WhitepaperReducer from '../containers/whitepaper/reducer';
import WhitepaperContactReducer from '../containers/whitepaperForm/reducer';

import ReportReducer from '../containers/reportError/reducer';
import RegisterReducer from '../containers/registerUser/reducer';

import LangReducer from '../containers/flagSelect/reducer';
import CustomerReducer from '../containers/customers/reducer';

import UserReducer from '../containers/customer/reducer';

import RLReducer from '../containers/riskLevels/reducer';


const removeUnregisteredFormFieldValue = (state, action) => {
    if (action.type !== actionTypes.UNREGISTER_FIELD) return state;

    if (state && state.values) {
        const {values: {[action.payload.name]: unregistered, ...values}} = state;
        return {...state, values}
    } else {
        return state;
    }
}

const rootReducer = combineReducers({
    layer:MapReducer,
    contact:ContactReducer,
    tags:TagsReducer,
    offer:OfferReducer,
    lang:LangReducer,
    maps:MapEditorReducer,
    documentEdit:DocumentEditorReducer,
    articleEdit:ArticleEditorReducer,
    hotspotEdit:HotspotEditorReducer,
    dossiers: DossiersReducer,
    agenda:AgendaReducer,
    articles:ArticlesReducer,
    countries:TreeViewReducer,
    page:PageReducer,
    report:ReportReducer,
    form: reduxFormReducer.plugin({
        PostEditForm: removeUnregisteredFormFieldValue,
        PostNewForm: removeUnregisteredFormFieldValue,
    }),
    notifications: notifications,
    router: routerReducer,
    auth: authReducer,
    layout: sideBarReducer,
    register:RegisterReducer,
    customers:CustomerReducer,
    users:UserReducer,
    whitepaper:WhitepaperReducer,
    whitepaperContact:WhitepaperContactReducer,
    riskLevels:RLReducer
});

export default rootReducer;
