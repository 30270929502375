import React from 'react';
import ReactDOM from 'react-dom';
import { Card, CardActions, CardContent } from '@material-ui/core';
import { Markdown } from 'react-showdown';
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { ROOT_URL } from "../../config";
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import LinkIcon from '@material-ui/icons/Link'
import { CircularProgress, Tooltip, Menu, IconButton, MenuItem } from '@material-ui/core'
import ExploreIcon from '@material-ui/icons/Explore'

import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import { Link } from 'react-router-dom';

import {
    fetchArticlesByTagTitles, fetchArticle, fetchArticlesSearch, fetchChecklists
} from "../articles/actions";
const limit = 20

class NewsTicker extends React.Component {
    constructor(props) {
        super(props);
        this.ref = {}
        this.list = null
        this.state = { tags: [], anchorEl: null, }

    }
    componentDidMount() {
       
        if (this.props.onRef) {
            this.props.onRef(this)
        }
        if (this.props.tagTitles) {
              this.props.fetchArticlesByTagTitles(this.props.tagTitles, { featureCollection: false, limit: limit })
        
             
          }

    }

  

    componentWillUnmount() {
        this.timer = null; 
        //console.log('componentWillUnmount')
        if (this.props.onRef) {
            this.props.onRef(undefined)
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        //console.log('componentWillReceiveProps', nextProps)
        //console.log('this.props', this.props)

        if (JSON.stringify(nextProps.tagTitles) != JSON.stringify(this.props.tagTitles)) {
            //console.log('nextProps.tagTitles', nextProps.tagTitles)

            this.props.fetchArticlesByTagTitles(nextProps.tagTitles, { featureCollection: true, limit: limit })
        }


        /*
         if (nextProps.tags.length > 0 && nextProps.tags != this.props.tags) {
             //console.log('componentWillReceiveProps', nextProps)
             const tags = nextProps.tags.map(tag => {
                 return tag.id
             })
             //console.log('before fetch tags', tags)
             this.fetchArticlesByTags(tags).then(res => {
                 //console.log('fetchArticles', res.data)
                 this.setState({ articles: res.data })
             }).catch(err =>  {
                 //console.log('err', err)
                 this.setState({ articles: [] })
             })
         }
         else if (nextProps.tagTitles && nextProps.tagTitles != this.props.tagTitles) {
             //console.log('nextProps.tagTitle', nextProps.tagTitle)
             this.fetchArticlesByTagTitle(nextProps.tagTitle).then(res => {
                 //console.log('fetchArticles', res.data)
                 this.setState({ articles: res.data })
             })
         }*/
    }

    componentDidUpdate() {
        ReactDOM.findDOMNode(this).scrollTop = 0
        this.makeLinksExternal()
    }




    onClick(article) {
        if (this.props.onClick) {
            this.props.onClick(article)
        }
    }

    onTagDelete(tag) {
        if (this.props.onTagDelete) {
            this.props.onTagDelete(tag)
        }
    }

    onTagClick(tag) {
        //console.log('tag click', tag)
        if (this.props.onTagClick) {
            this.props.onTagClick(tag)
        }
        /*
                const tags = this.state.tags
                tags.push(tag)
                this.setState({ tags: tags })*/
        /* this.fetchArticlesByTags(tags).then(res => {
             this.setState({ articles: res.data })
         }).catch(err => {
             //console.log('err', err)
         })*/


    }

    onSearchEnter(search) {
        //console.log('search', search)
        this.props.fetchArticlesSearch(search)
    }

    makeLinksExternal() {
        //console.log('makeLinksExternal')
        var links = document.links;
        for (var i = 0, linksLength = links.length; i < linksLength; i++) {
            if (links[i].hostname != window.location.hostname) {
                links[i].target = "_blank";
                links[i].setAttribute("rel", "noopener noreferrer");
                links[i].className += " externalLink";
            } else {
                links[i].className += " localLink no-print";
            }
        }
    }

    /*

     <CardActions>
                                <Button onClick={this.onClick.bind(this, article)} size="small">Mehr</Button>
                            </CardActions>

                             <Link key={tag.id} to={"/articles/" + tag.title}>
                              </Link>

    */

    scrollTo(articleId) {
       // console.log('scrollTo', articleId)

        if (articleId) {
            const article = ReactDOM.findDOMNode(this.ref[articleId])
           // console.log('article', article.offsetTop)
            return article.offsetTop
            // list.scrollTo(0, article.offsetTop - 80);
        } else {
            // list.scrollTo(0, 0);
        }

    }

    showOnMap(id) {
       // console.log('showOnMap', id)
        if (this.props.showOnMap) {
           // console.log('props showOnMap', id)
            this.props.showOnMap(id)
        }
    }

    handleShareClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleShareClose = () => {
        this.setState({ anchorEl: null });
    };

    /*
 <div style={{ flexDirection: 'row',verticalAlign: "top", flex:1, marginRight: '30px',
                                        textAlign: 'center'}}>
                                         <Tooltip title="Auf Facebook teilen">

                                        <FacebookShareButton url={"https://riskcompass.eu/article/" + article.postName}>
                                       
                                            <FacebookIcon size={24}round/>
                                           
                                        </FacebookShareButton>
                                        </Tooltip>

                                        <TwitterShareButton title={`${article.country} (${article.city ? article.city + ', ' : ''}${article.date}): ${article.title}`} url={"https://riskcompass.eu/article/" + article.postName}>
                                            <TwitterIcon size={24}round/>
                                        </TwitterShareButton>
                                        <TelegramShareButton url={"https://riskcompass.eu/article/" + article.postName}>
                                            <TelegramIcon size={24}round/>
                                        </TelegramShareButton>
                                        <WhatsappShareButton url={"https://riskcompass.eu/article/" + article.postName}>
                                            <WhatsappIcon size={24}round/>
                                        </WhatsappShareButton>
                                         <EmailShareButton url={"https://riskcompass.eu/article/" + article.postName}>
                                            <EmailIcon size={24}round/>
                                        </EmailShareButton>
                                        </div>

    */

    render() {
        const { anchorEl } = this.state;
        const { articles, tags, checklists, admin, editor, share } = this.props
        //console.log('admin', admin)
        return <div style={{ height: '100%' }} ref={ref => { this.list = ref }}>
            {articles && articles.articles &&
                articles.articles.map((article, index) => {
                    var showChecklists = []
                    if (article.tags) {


                        showChecklists = article.tags.map(tag => {
                            var res = checklists.filter(checklist => {
                                const match = checklist.tag == tag

                                return match
                            })
                            if (res.length > 0) {
                                return res[0]
                            }
                        }).filter(i => {
                            return i != undefined
                        })
                    } 


                    return (

                        <div ref={ref => { this.ref[article.id] = ref }} key={article.id + index} style={{ marginTop:'0px',marginBottom: '0px', maxWidth: '100%' }}>
                           
                                <div style={{paddingTop:3,paddingBottom:3, fontSize:'1.0em'}}>

                                    <Link style={{ textDecoration: 'none', color:'white' }} to={"/article/" + article.postName}>{article.country} ({article.city ? article.city + ', ' : ''}{article.date}): {article.title}</Link>
                                     </div>

                            

                        </div>
                    )
                })
            }
            {!articles &&
                <div className={'loading_wrapper'} style={{ textAlign: 'center', position: 'relative', width: "100%", height: '100%' }}>
                    <CircularProgress style={{ position: 'absolute', top: '50%', margin: '0 auto' }} color="secondary" />
                </div>

            }
        </div>

    }

}



function mapStateToProps(state) {

    //console.log('state.articles', state.articles)
    return {
        articles: state.articles.all,
        article: state.articles.article,
        admin: state.auth.admin,
        editor: state.auth.editor,
        checklists: state.articles.checklists
    }
}
const styles = {

}
export default withStyles(styles, { name: 'NewsTicker' })(connect(mapStateToProps, {
    fetchArticlesSearch: fetchArticlesSearch,
    fetchArticlesByTagTitles: fetchArticlesByTagTitles,
    fetchArticle: fetchArticle,
    fetchChecklists: fetchChecklists
})(NewsTicker));


