import {
    FETCH_LAYER,
    FETCH_LAYERS,
    FETCH_LAYERLIST,
    FETCH_MAP,
    FETCH_MAPS,
    DELETE_POST,
    SET_POSTS_GRID_PAGE_SIZE,
    SET_POSTS_GRID_PAGE_INDEX,
    SET_POSTS_GRID_SORT_CRITERIA,
    SET_POSTS_GRID_FILTERS,
    FETCHED_LAYER,
    FETCHING_LAYER,
    FETCHED_LAYERS,
    FETCHING_LAYERS,
    FETCHED_LAYERLIST,
    FETCHING_LAYERLIST,
    FETCHED_MAP,
    FETCHING_MAP,
    FETCHED_MAPS,
    FETCHING_MAPS,
    ADD_NEW_POST_SECTION,
    RESET_POST_SECTION_TO_0,
    REMOVE_POST_SECTION
} from "../../actions/types";

import * as _ from "lodash";

const INITIAL_STATE = {
    map: {},
    maps: [],
    loading: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case FETCH_MAP:

           // console.log('FETCH_MAP')

            return {
                ...state,
                map: action.payload.data,

            };

        case FETCH_MAPS:

           // console.log('FETCH_MAPS')

            return {
                ...state,
                maps: action.payload.data,

            };

        case FETCHING_MAP:
            return {
                ...state,
                loading: action.payload
            }

        case FETCHED_MAP:
           // console.log('FETCHED_MAP')
            return {
                ...state,
                map: action.payload.data,
            };

        case FETCHING_MAPS:
            return {
                ...state,
                loading: action.payload
            }

        case FETCHED_MAPS:
           // console.log('FETCHED_MAPS')
            return {
                ...state,
                maps: action.payload.data,
            };

        default:
            return state;
    }
}