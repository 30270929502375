import { push } from 'react-router-redux';
import axios from 'axios';
import {
    FETCH_AGENDAS,
    FETCH_AGENDA,
    FETCHED_AGENDAS,
    FETCHED_AGENDA,
    INSERT_AGENDA,
    UPDATE_AGENDA,
    DELETE_AGENDA,
    FETCH_TERMS
} from "../../actions/types";

import {ROOT_URL} from "../../config";

export function goToRoute(route) {
    return function (dispatch) {
        dispatch(push(route));
    }
}

export function deleteAgenda(id) {
   // console.log('deleteArticle', id)
    return function (dispatch) {
        
        const url = `${ROOT_URL}/Agenda/delete?id=${encodeURIComponent(id)}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
           // console.log('response.data', response.data)
            dispatch({
                type: DELETE_AGENDA,
                payload: response
            });
        }).catch((err) => {
           // console.log('error fetching the articles', err);
        });
       
    }
}

export function fetchAgenda(id) {
    return function (dispatch) {
    var query = { "where": { "id": id } }
    const url = `${ROOT_URL}/Agenda?filter=${encodeURIComponent(JSON.stringify(query))}`

    return axios.get(url, {
        headers: { authorization: localStorage.getItem('token') }
    }).then((response) => {
       // console.log('response.data', response.data)
        dispatch({
            type: FETCH_AGENDA,
            payload: response
        });
    }).catch((err) => {
       // console.log('error fetching the page', err);
    });
}
}


export function updateAgenda(page) {
    return function (dispatch) {

        const body = {
            id: page.id,
        }
        delete page.id
        body.page = page

        const url = `${ROOT_URL}/Agenda/updateById?`
        return axios.post(url, body, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
           // console.log('response.data', response.data)
            dispatch({
                type: UPDATE_AGENDA,
                payload: response
            });
        }).catch((err) => {
           // console.log('error updating the page', err);
        });
    }
}

export function insertAgenda(article) {
    return function (dispatch) {

        const body = {
            id: article.id,
        }
        delete article.id
        body.article = article

        const url = `${ROOT_URL}/Agenda/insert?`
        return axios.post(url, body, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
           // console.log('response.data', response.data)
            dispatch({
                type: INSERT_AGENDA,
                payload: response
            });
        }).catch((err) => {
           // console.log('error fetching the articles', err);
        });
    }
}





export function fetchAgendas() {
   // console.log('fetchAgendas')
   
    return function (dispatch) {
        const payload = {
            where:{
                type:"single"
            }
        }
        dispatch(fetchingAgendas());
        return axios.get(`${ROOT_URL}/Agenda?filter=${encodeURIComponent(JSON.stringify(payload))}`, {
            headers: {authorization: localStorage.getItem('token')}
        }).then((response) => {
           // console.log('response.data', response.data)
            dispatch({
                type: FETCHED_AGENDAS,
                payload: response
            });
        }).catch((err) => {
           // console.log('error fetching the articles', err);
        });
    }
}

export function fetchingAgendas() {
    return {
      type: FETCH_AGENDAS,
      payload: true
    }
  }


