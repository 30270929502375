import { push } from 'react-router-redux';
import axios from 'axios';

import { ROOT_URL } from "../../config";

export function goToRoute(route) {
    return function (dispatch) {
        dispatch(push(route));
    }
}

export function fetchCustomers(id) {
    return function (dispatch) {
        const filter = {
            order: 'title ASC',
            include: [ "users"],
            
        }
        const url = `${ROOT_URL}/Customers?filter=${encodeURIComponent(JSON.stringify(filter))}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: 'FETCH_CUSTOMERS',
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the page', err);
        });
    }
}

export function resetCustomer(id) {
    return function (dispatch) {

        return Promise.resolve().then(() => {
            dispatch({
                type: 'RESET_CUSTOMER',
                payload: null
            });
        })



    }
}

export function updateCustomer(args) {
    return function (dispatch) {

        const url = `${ROOT_URL}/Customers`
        const payload = {
            id:args.id,
            title:args.title,
            note:args.note,
            password:args.password,
            email:args.email
        }
        return axios.patch(url, payload, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: 'UPDATE_CUSTOMER',
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the page', err);
        });
    }
}

export function fetchConfigurations(customerId) {
    return function (dispatch) {

        
       
        console.log('customerId', customerId)
        const url = `${ROOT_URL}/Customers/${encodeURIComponent(customerId)}/configurations`
        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
             console.log('conf response.data', response.data)
            dispatch({
                type: 'FETCH_CUSTOMER_CONFIGURATIONS',
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the page', err);
        });
    }
}

export function fetchCustomer(id) {
    return function (dispatch) {

        var filter = {
            where: {
                id: id,
            },
            include: ["users"],

        }
        
        console.log('id', id)
        const url = `${ROOT_URL}/Customers?filter=${encodeURIComponent(JSON.stringify(filter))}`
        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: 'FETCH_CUSTOMER',
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the page', err);
        });
    }
}



export function fetchGateways() {
    return function (dispatch) {

        const url = `${ROOT_URL}/Gateway`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            // console.log('response.data', response.data)
            dispatch({
                type: 'FETCH_CUSTOMERS',
                payload: response
            });
        }).catch((err) => {
            // console.log('error fetching the page', err);
        });
    }
}

export function deleteCustomer(id) {
    return function (dispatch) {
       
        const url = `${ROOT_URL}/Customers/${id}`

        return axios.delete(url, {
            headers: { authorization: localStorage.getItem('token') }
        })
            .then(response => {
                dispatch({
                    type: 'DELETE_CUSTOMER',
                    payload: response
                });
            })
            .catch(() => {
                //dispatch();
            });
    }
}

