import {
    FETCH_TAGS,
    
} from "../../actions/types";

import * as _ from "lodash";

const INITIAL_STATE = {
    all:[]
    
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case FETCH_TAGS:
            return {
                ...state,
                all: action.payload.data,

            };

       

        default:
            return state;
    }
}