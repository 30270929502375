
import React from 'react';
import ReactDOM from 'react-dom';

import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { ROOT_URL } from "../../config";

import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import  { Radio, RadioGroup } from '@material-ui/core'
import { FormLabel, FormControl, FormControlLabel, FormHelperText, FormGroup } from '@material-ui/core';
import  {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
} from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'



class DocumentSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            sort: "timestamp:desc",
            //tags: false
        };

    }
    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined)
        }
    }


    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate() {

    }


    onChange(e) {
        this.setState({ search: e.target.value })
    }

    onSearch() {
        if (this.props.onEnter) {
            this.props.onEnter({
                search: this.state.search,
                sort: this.state.sort
            })
        }
    }

    onKeyDown = event => {
        switch (event.key) {
            case 'Enter':
                this.onSearch()

                break
            case 'Escape':
                // etc...
                break
            default: break
        }
    }

    handleChange = (event, value) => {
        this.setState({ sort: value });
    };

    handleTagChange = (event, value) => {
       // console.log('event', event)
       // console.log('value', value)
        this.setState({ tags: value });
    };

    render() {
        const { article } = this.state
        return <div style={{ padding: '0px', paddingLeft: '0px', paddingRight: '0px' }}>


            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <TextField
                        fullWidth
                        id="search"
                        placeholder="Suche"
                        onChange={this.onChange.bind(this)}
                        onKeyDown={this.onKeyDown.bind(this)}
                        margin="normal"
                    />

                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div >
                        <FormControl style={{ marginTop: '0px' }} component="sort"  >
                            <FormLabel component="legend">Sortierung</FormLabel>

                            <RadioGroup
                                aria-label="sort"
                                name="Sortierung"
                                style={{ flexDirection: 'row',msFlexDirection:'row' }}
                                value={this.state.sort}
                                onChange={this.handleChange.bind(this)}
                            >
                                <FormControlLabel value="" control={<Radio />} label="Relevanz" />
                                <FormControlLabel value="timestamp:desc" control={<Radio />} label="Datum" />
                            </RadioGroup>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Optionen</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.tags}
                                                onChange={this.handleTagChange.bind(this)}
                                                value="tags"
                                                color="secondary"
                                            />
                                        }
                                        label="Nur in ausgewählten Tags suchen"
                                    />
                                </FormGroup>

                               
                            </FormControl>



                        </FormControl>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>



        </div>

    }

}



function mapStateToProps(state) {


    return {

    }
}
const styles = {

}
export default withStyles(styles, { name: 'DocumentSearch' })(connect(mapStateToProps, {

})(DocumentSearch));




