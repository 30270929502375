import React from 'react';
import ReactDOM from 'react-dom';

import { Markdown } from 'react-showdown';
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
    Card,
    CardActions,
    CardContent,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    CardHeader,
    CardMedia
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Map from '../map/map_index'
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import AddIcon from '@material-ui/icons/Add';
//import { fetchChecklists, goToRoute } from './actions'
import image from '../layout/images/palme.jpg'
import image2 from '../layout/images/shutter1.png'
import imgBG from '../layout/images/Weltkarte_OSM.png'
import small_logo from '../layout/images/small_logo.png'
import compass from '../layout/images/compass.png'
import handshake from '../layout/images/handshake.png'
import iconMap from '../layout/images/icon_map.png'
import iconApp from '../layout/images/icon_app.png'
import iconNews from '../layout/images/icon_newsletter.png'
import iconEarth from '../layout/images/icon_earth.png'
import iconWarning from '../layout/images/icon_warning.png'
import iconCity from '../layout/images/icon_city.png'
//import heatmap from '../layout/images/heatmap_de.jpg'
//import riskmap from '../layout/images/risikomap.png'
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import { fetchPage, goToRoute } from '../page/actions'
import ContactForm from '../contactForm'
import NewsLetter from '../newsletter'
import Footer from '../footer/footer'
import NewsTicker from '../newsticker'
//import ReactGA from 'react-ga';



const icoMap = {
    city: iconCity,
    warning: iconWarning,
    app: iconApp,
    news: iconNews,
    earth: iconEarth,
    map: iconMap
}

const imageMap = {
    "compass.png": compass,
    "handshake.png": handshake
}

class Image extends React.Component {
    render() {
        // console.log('this.props', this.props)
        return <img style={{}} width={'100%'} {...this.props}></img>
    }
}

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pageName: null };

    }
    UNSAFE_componentWillMount() {
        //ReactGA.initialize('UA-130702159-1');
        //ReactGA.pageview(window.location.pathname + window.location.search)
        this.props.fetchPage("homeLocale")
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate() {

    }
    handleListItemClick(pageName) {
        // console.log('handleListItemClick', pageName)
        this.props.goToRoute("/page/" + pageName)
        this.setState({ pageName })

    }



    render() {
        const { pageName } = this.state
        const { page, admin, editor, lang } = this.props
        if (page && page.fields) {
            console.log('page', page.fields)
        }


        return <div style={{
            backgroundImage: 'url(' + imgBG + ')',
            backgroundPosition: 'center center', backgroundSize: '100% 100%', backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed"
        }}>
            {page && page.subtitle &&
                <div  >
                    <Grid

                        style={{
                            marginBottom: 0,
                        }} container >
                        <Grid item lg={12} sm={12} xs={12} md={12} style={{ marginRight: '30px', marginLeft: '30px' }}>
                            <div style={{ height: '400px', position: 'relative', marginLeft: '-30px', marginTop: '-31px', width: 'calc(100% + 64px)', backgroundImage: 'url(' + image2 + ')', backgroundPosition: 'center center', backgroundSize: '100% auto' }}>

                                <Card style={{ textAlign: 'left', padding: '15px', position: 'absolute', bottom: '30px', left: '30px', marginRight: '30px', }}>

                                    <h1 style={{ paddingTop: '0', marginTop: '5px', maxWidth: '400px' }}>{page.title[lang]}</h1>
                                    <p>{page.subtitle[lang]}</p>

                                </Card>
                                <Hidden xsDown>
                                    <div style={{
                                        marginTop: '30px',
                                        paddingLeft: '2px',
                                        float: 'right',
                                        width: '25%',
                                        height: '380px',
                                        color: 'white',
                                        paddingRight: '20px'
                                    }}>

                                        <h2 >Aktuelle Nachrichten</h2>

                                        <div ref={(ref) => { this.list = ref }} style={{
                                            marginTop: 5,
                                            paddingTop: 0,
                                            height: '295px',
                                            overflowY: 'scroll',
                                            WebkitOverflowScrolling: 'touch'
                                        }}>


                                            <NewsTicker tagTitles={["nachricht"]} />

                                        </div>

                                    </div>
                                </Hidden>
                            </div>




                        </Grid>

                        <div style={{ position: 'relative', maxWidth: '100%' }}>
                            <Grid container style={{ height: '100%', marginTop: '0px', position: 'relative', width: '100%', }} >




                                <Grid item lg={2} sm={1} xs={1} md={1} style={{ marginTop: '20px' }}>
                                </Grid>

                                <Grid item lg={8} sm={10} xs={10} md={10} style={{ marginTop: '20px' }}>
                                    <Grid style={{ marginBottom: 0 }} container >

                                        {page && page.fields && page.fields.filter(field => field.type == "card").map((field, index) => {

                                            return (
                                                <Grid key={index} item lg={12} sm={12} xs={12} md={12} style={{ padding: 10 }}>
                                                    <Card key={index} style={{ marginTop: 20, marginBottom: 20 }}>
                                                        <Grid item container >
                                                            <Grid style={{ textAlign: 'left', lineHeight: '0' }} item lg={4} sm={4} xs={12} >
                                                                <img src={imageMap[field.image]} style={{ maxWidth: '90%', margin: 20 }} ></img>
                                                            </Grid>
                                                            <Grid item lg={8} sm={8} xs={12}>

                                                                <CardHeader title={field.title[lang]} style={{ paddingBottom: 0 }} />
                                                                <CardContent style={{ paddingTop: 5, maxWidth: "90%", }} >
                                                                    <div style={{ display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: 'center' }}>
                                                                        <Markdown className="homeWrapper" markup={field.markdown[lang]} />
                                                                    </div>
                                                                </CardContent>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                    {false && page && page.fields && page.fields.filter((field, index) => field.type == "accordion" && index == 2).map((field, index) => {

                                        return (
                                            <div key={index}>

                                                <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                                                    <h2 style={{ fontSize: '2.2em' }}>
                                                        Die RiskCompass-Produkte und -Dienstleistungen umfassen:
                                                    </h2>
                                                </div>


                                                <Accordion defaultExpanded>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <div style={{
                                                            display: 'flex', fallbacks: [
                                                                { display: '-webkit-box' },
                                                                { display: '-moz-box' },
                                                                { display: '-ms-flexbox' },
                                                                { display: '-webkit-flex' }
                                                            ], flexDirection: 'row', msFlexDirection: 'row', marginTop: '0px', alignItems: 'center', verticalAlign: 'middle', justifyContent: 'center'
                                                        }}>
                                                            <img style={{ display: 'inline-flex', }} src={icoMap[field.icon]} width={25} height={25}></img>
                                                            <h3 style={{ marginLeft: 20, marginTop: 20, fontWeight: 'bold' }}>{field.title[lang]}</h3>
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails >
                                                        <Markdown className="homeWrapper" markup={field.markdown[lang]} components={{ img: Image }} />
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        )
                                    })}


                                    {false && page && page.fields && page.fields.filter((field, index) => field.type == "accordion" && index != 2).map((field, index) => {

                                        return (
                                            <div key={index}>

                                                <Accordion>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <div style={{
                                                            display: 'flex', fallbacks: [
                                                                { display: '-webkit-box' },
                                                                { display: '-moz-box' },
                                                                { display: '-ms-flexbox' },
                                                                { display: '-webkit-flex' }
                                                            ], flexDirection: 'row', msFlexDirection: 'row', paddingTop: '0px', alignItems: 'center', verticalAlign: 'middle', justifyContent: 'center'
                                                        }}>
                                                            <img style={{ display: 'inline-flex', }} src={icoMap[field.icon]} width={25} height={25}></img>

                                                            <h3 style={{ marginLeft: 20, marginTop: 20, fontWeight: 'bold' }}>{field.title[lang]}</h3>
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div >
                                                            <Markdown className="homeWrapper" markup={field.markdown[lang]} components={{ img: Image }} />
                                                            {field.icon == "news" &&
                                                                <div>
                                                                    <NewsLetter />
                                                                </div>
                                                            }
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        )

                                    })}
                                    <div style={{ minHeight: 200 }}></div>

                                    {(admin || editor) &&
                                        <div style={{ marginTop: 20, marginBottom: 50, marginRight: 20, float: 'right' }}>
                                            <Tooltip title="Seite bearbeiten">
                                                <Link to={"/home/edit"}><EditIcon /></Link>
                                            </Tooltip>
                                        </div>
                                    }
                                </Grid>

                                <Grid item lg={2} sm={1} xs={1} md={1}>
                                </Grid>

                            </Grid>
                        </div>






                    </Grid>
                    <Footer />
                </div>

            }



        </div>

    }

}

function mapStateToProps(state) {


    return {
        page: state.page.page,
        admin: state.auth.admin,
        editor: state.auth.editor,
        lang: state.lang.lang,
    }
}
const styles = {

}
export default withStyles(styles, { name: 'Home' })(connect(mapStateToProps, {
    fetchPage: fetchPage

})(Home));


