import React, { Component } from 'react';
import './App.css';
import CMSRoutes from '../routes';
import SideMenu from "../containers/layout/side_menu";
import Footer from "../containers/footer/footer";
import { ConnectedRouter } from "react-router-redux";
import { withStyles } from '@material-ui/core/styles';
import styles from './App.style';
import classNames from 'classnames';

/*
import $ from 'jquery';
import jQuery from 'jquery'; 

window.jQuery = $;
window.$ = $;
*/
class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false
        };
    }
    

    onOpen(open) {
        this.setState({ open: open })
    }

    render() {

        const classes = this.props.classes;
        const { open } = this.state

        return (
            <ConnectedRouter history={this.props.history}>
                <div>
                    <div className={classes.root}>
                        <div className={classNames('printMeFix' , classes.appFrame)}>
                            <SideMenu onOpen={this.onOpen.bind(this)} />
                            <main
                                className={classNames('mainContentWrapper' , classes.content)}
                            >
                                <CMSRoutes />
                                
                            </main>
                           
                        </div>
                    </div>
                </div>
            </ConnectedRouter>
        );
    }
}

export default withStyles(styles)(App);


