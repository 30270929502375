import React from 'react';
import { Route, Switch, Router } from 'react-router-dom';

import DossierList from "../containers/dossier/dossier_index";
import DossierNew from "../containers/dossier/dossier_new";

import DossierChapterEdit from '../containers/dossier/dossierchapter_edit';
import DossierEdit from '../containers/dossier/dossier_edit';
import SignIn from "../containers/auth/sign_in";
import Verified from "../containers/auth/verified";
import SignOut from "../containers/auth/sign_out";
import Dashboard from "../containers/dashboard/dashboard";
import Impressum from "../containers/impressum/impressum";
import Agb from "../containers/agb/agb";
import Faq from "../containers/faq/faq";
import DataProtection from "../containers/dataProtection/dataProtection";
import Map from "../containers/mapEditor/index";
import ShapeEditor from "../containers/shapeEditor/index";
import MapEditor from "../containers/mapEditor/index";
import MapMetadataEdit from "../containers/map/mapMetadata_edit";
import Articles from "../containers/articles/articles_index";
import Documents from "../containers/documents/documents_index";
import Article from "../containers/article";
import DocumentEditor from "../containers/documentEditor/documentEditor_index"
import ArticleEditor from "../containers/articleEditor"
import Start from "../containers/startpage/start_index";
import Page from "../containers/page";
import PageEdit from "../containers/page/pageEdit";
import Whitepaper from "../containers/whitepaper";
import WhitepaperEdit from "../containers/whitepaper/whitepaperEdit";
import Home from "../containers/home";
import RiskLevels from "../containers/riskLevels";
import RiskLevelEdit from "../containers/riskLevels/riskLevelEdit";

import HomeEditor from "../containers/home/homeEdit";
import HotspotEditor from "../containers/hotspotEditor";
import OfferEditor from "../containers/offer/offerEdit";
import PortfolioEditor from "../containers/offer/portfolioEdit";
import ThemeMaps from "../containers/thememaps";
import Contact from "../containers/contact";
import NewsLetter from '../containers/newsletter'
import Team from '../containers/team'
import Profile from '../containers/profile'
import Terms from '../containers/terms'
import Agenda from '../containers/agenda'
import Police from '../containers/police'
import Islam from '../containers/islam'
import OptOut from '../containers/optout'
import Register from '../containers/register'
import FullMap from '../containers/fullMap'
import App from '../containers/app'
import DeleteAccount from '../containers/deleteAccount'
import AppEdit from '../containers/app/appEdit'

import Offer from '../containers/offer'
import LayerEditor from '../containers/layerEditor'
import Portfolio from '../containers/offer/portfolio'
import Customers from '../containers/customers'
import Tracking from '../containers/tracking'
import Track from '../containers/track'

import Customer from '../containers/customer'
import CustomerPositions from '../containers/customerPositions'
import Covid from '../containers/covid'
import CovidDach from '../containers/covid'




import { requireAuthentication } from "../containers/auth/require_auth";

export default () => (
    <Switch>
        <Route exact path="/impressum" component={Impressum} />
        <Route exact path="/agb" component={Agb} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/layerEditor/:id/map/:map" component={LayerEditor} />
        <Route exact path="/terms/:id" component={Terms} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/dataProtection" component={DataProtection} />
        <Route exact path="/shapeEditor" component={requireAuthentication(ShapeEditor)} />
        <Route exact path="/map" component={requireAuthentication(MapEditor)} />
        <Route exact path="/mapMetadata/edit/:id" component={requireAuthentication(MapMetadataEdit)} />
        <Route exact path="/thememaps" component={requireAuthentication(ThemeMaps)} />
        <Route exact path="/dossiers/:id?" component={DossierList} />
        <Route exact path="/dach/:id?" component={DossierList} />
        <Route exact path="/profile/:token" component={Profile} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/covid" component={Covid} />
        <Route exact path="/delete-account" component={DeleteAccount} />
        <Route exact path="/covid-dach" component={CovidDach} />

        <Route exact path="/dachDossiers/:id?" component={requireAuthentication(DossierList)} />
        <Route exact path="/news" component={Articles} />
        <Route exact path="/documents" component={requireAuthentication(Documents)} />
        <Route exact path="/documents/edit/:id" component={requireAuthentication(DocumentEditor)} />
        <Route exact path="/news/:tagTitles" component={Articles} />
        <Route exact path="/article/:postName" component={Article} />
        <Route exact path="/article/edit/:id" component={requireAuthentication(ArticleEditor)} />
        <Route exact path="/article/edit/new" component={requireAuthentication(ArticleEditor)} />
        <Route exact path="/page/:postName" component={Page} />
        <Route exact path="/page/edit/:id" component={PageEdit} />
        <Route exact path="/whitepaper" component={Whitepaper} />
        <Route exact path="/whitepaper/edit/:id" component={WhitepaperEdit} />
        <Route exact path="/checklists" component={Page} />
        <Route exact path="/islam" component={Islam} />
        <Route exact path="/police" component={Police} />

        <Route exact path="/riskLevels/:id" component={RiskLevels} />
        <Route exact path="/riskLevels/edit/:id" component={RiskLevelEdit} />
        <Route exact path="/dossiers/new" component={requireAuthentication(DossierNew)} />
        <Route exact path="/dossierchapter/edit/:id" component={requireAuthentication(DossierChapterEdit)} />
        <Route exact path="/dossier/edit/:id" component={requireAuthentication(DossierEdit)} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/" component={Home} />
        <Route exact path="/home/edit" component={HomeEditor} />
        <Route exact path="/hotspot/edit/:id" component={HotspotEditor} />
        <Route exact path="/offer/edit" component={OfferEditor} />
        <Route exact path="/portfolio/edit" component={PortfolioEditor} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/subscribe" component={NewsLetter} />
        <Route exact path="/team" component={Team} />
        <Route exact path="/riskLevels" component={RiskLevels} />
        <Route exact path="/customers" component={Customers} />
        <Route exact path="/tracking" component={Tracking} />
        <Route exact path="/customer/:id" component={Customer} />
        <Route exact path="/customer/:id/positions" component={CustomerPositions} />
        <Route exact path="/track/:id" component={Track} />
        <Route exact path="/app" component={App} />
        <Route exact path="/app/edit" component={AppEdit} />
        <Route exact path="/login" component={SignIn} />
        <Route exact path="/verified" component={Verified} />
        <Route exact path="/optout" component={OptOut} />
        <Route exact path="/logout" component={SignOut} />
        <Route exact path="/offer" component={Offer} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route exact path="/fullMap" component={FullMap} />
        <Route exact path="/agenda" component={requireAuthentication(Agenda)} />
    </Switch>
);