import React from 'react';
import ReactDOM from 'react-dom';
import { Card, CardActions, CardContent } from '@material-ui/core';
import { Markdown } from 'react-showdown';
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { ROOT_URL } from "../../config";
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import LinkIcon from '@material-ui/icons/Link'
import { CircularProgress, Tooltip, Menu, IconButton, MenuItem, ListItem, Avatar, ListItemText } from '@material-ui/core'
import ExploreIcon from '@material-ui/icons/Explore'
import DeleteIcon from '@material-ui/icons/Delete';

import {
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailShareButton,

    FacebookIcon,
    TwitterIcon,
    TelegramIcon,
    WhatsappIcon,
    EmailIcon,
} from 'react-share';
import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import { Link } from 'react-router-dom';

import {
    fetchArticlesByTagTitles, fetchArticle, fetchArticlesSearch, fetchChecklists,
} from "../articles/actions";
import {
    deleteArticle
} from "../articleEditor/actions";

const limit = 150

class ArticleList extends React.Component {
    constructor(props) {
        super(props);
        this.ref = {}
        this.list = null
        this.state = { tags: [], anchorEl: null, }

    }
    componentDidMount() {
        //console.log('articleList componentDidMount')
        /*
        if (this.props.tags.length > 0) {
            this.fetchArticlesByTags(this.props.tags).then(res => {
                //console.log('fetchArticlesByTags', res.data)
                this.setState({ articles: res.data })
            }).catch(err => {
                //console.log('err', err)
            })
        } else if (this.props.tagTitle) {
            this.fetchArticlesByTagTitle(this.props.tagTitle).then(res => {
                //console.log('fetchArticles', res.data)
                this.setState({ articles: res.data })
            })
        }*/
        this.props.fetchChecklists()
        if (this.props.onRef) {
            this.props.onRef(this)
        }
        if (this.props.tagTitles && this.props.tagTitles.length > 0) {
            this.props.fetchArticlesByTagTitles(this.props.tagTitles, { featureCollection: true, limit: limit })
        }


    }

    componentWillUnmount() {
        //console.log('componentWillUnmount')
        if (this.props.onRef) {
            this.props.onRef(undefined)
        }

    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        //console.log('componentWillReceiveProps', nextProps)
        //console.log('this.props', this.props)

        if (JSON.stringify(nextProps.tagTitles) != JSON.stringify(this.props.tagTitles)) {
            //console.log('nextProps.tagTitles', nextProps.tagTitles)

            this.props.fetchArticlesByTagTitles(nextProps.tagTitles, { featureCollection: true, limit: 300 })
        }


        /*
         if (nextProps.tags.length > 0 && nextProps.tags != this.props.tags) {
             //console.log('componentWillReceiveProps', nextProps)
             const tags = nextProps.tags.map(tag => {
                 return tag.id
             })
             //console.log('before fetch tags', tags)
             this.fetchArticlesByTags(tags).then(res => {
                 //console.log('fetchArticles', res.data)
                 this.setState({ articles: res.data })
             }).catch(err =>  {
                 //console.log('err', err)
                 this.setState({ articles: [] })
             })
         }
         else if (nextProps.tagTitles && nextProps.tagTitles != this.props.tagTitles) {
             //console.log('nextProps.tagTitle', nextProps.tagTitle)
             this.fetchArticlesByTagTitle(nextProps.tagTitle).then(res => {
                 //console.log('fetchArticles', res.data)
                 this.setState({ articles: res.data })
             })
         }*/
    }

    componentDidUpdate() {
        ReactDOM.findDOMNode(this).scrollTop = 0
        this.makeLinksExternal()
    }




    onClick(article) {
        if (this.props.onClick) {
            this.props.onClick(article)
        }
    }

    onTagDelete(tag) {
        if (this.props.onTagDelete) {
            this.props.onTagDelete(tag)
        }
    }

    onTagClick(tag) {
        //console.log('tag click', tag)
        if (this.props.onTagClick) {
            this.props.onTagClick(tag)
        }
        /*
                const tags = this.state.tags
                tags.push(tag)
                this.setState({ tags: tags })*/
        /* this.fetchArticlesByTags(tags).then(res => {
             this.setState({ articles: res.data })
         }).catch(err => {
             //console.log('err', err)
         })*/


    }

    onSearchEnter(search) {
        //console.log('search', search)
        this.props.fetchArticlesSearch(search)
    }

    makeLinksExternal() {
        //console.log('makeLinksExternal')
        var links = document.links;
        for (var i = 0, linksLength = links.length; i < linksLength; i++) {
            if (links[i].hostname != window.location.hostname) {
                links[i].target = "_blank";
                links[i].setAttribute("rel", "noopener noreferrer");
                links[i].className += " externalLink";
            } else {
                links[i].className += " localLink ";
            }
        }
    }

    renderRisklevel(item) {
        const { lang } = this.props
        var color = ""
        var fontColor = ""
        // item.riskLevel = 3
        switch (parseInt(item.riskLevel)) {
            case 0:
                color = '#00ffff'
                fontColor = 'black'
                break;
            case 1:
                color = '#00ff00'
                fontColor = 'black'
                break;
            case 2:
                color = '#ffff00'
                fontColor = 'black'
                break;
            case 3:
                color = '#ffa500'
                fontColor = 'white'
                break;
            case 4:
                color = '#ff6600'
                fontColor = 'white'
                break;
            case 5:
                color = '#ff0000'
                fontColor = 'white'
                break;
        }

        //console.log('color', color)
        var style = {
            margin: 10,
            fontSize: 11,

            width: 20,
            height: 20,
            //backgroundColor: color,
        }

        /*
         <ListItemSecondaryAction>
                      <Avatar style={style}>{node.riskLevel}</Avatar>
                  </ListItemSecondaryAction>
    
        */
        return (

            <Avatar style={{
                backgroundColor: color, width: 30,
                margin: '0 0 10 10',
                fontSize: '1rem',
                color: fontColor,
                height: 30,

            }}>
                <div style={{ fontSize: '1rem', marginTop: -2 }}>{item.riskLevel}</div>
            </Avatar>

        );
    }

    /*

     <CardActions>
                                <Button onClick={this.onClick.bind(this, article)} size="small">Mehr</Button>
                            </CardActions>

                             <Link key={tag.id} to={"/articles/" + tag.title}>
                              </Link>

    */

    scrollTo(articleId) {
        // console.log('scrollTo', articleId)

        if (articleId) {
            const article = ReactDOM.findDOMNode(this.ref[articleId])
            // console.log('article', article.offsetTop)
            return article.offsetTop
            // list.scrollTo(0, article.offsetTop - 80);
        } else {
            // list.scrollTo(0, 0);
        }

    }

    showOnMap(id) {
        // console.log('showOnMap', id)
        if (this.props.showOnMap) {
            // console.log('props showOnMap', id)
            this.props.showOnMap(id)
        }
    }

    handleShareClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleShareClose = () => {
        this.setState({ anchorEl: null });
    };

    /*
 <div style={{ flexDirection: 'row',verticalAlign: "top", flex:1, marginRight: '30px',
                                        textAlign: 'center'}}>
                                         <Tooltip title="Auf Facebook teilen">

                                        <FacebookShareButton url={"https://riskcompass.eu/article/" + article.postName}>
                                       
                                            <FacebookIcon size={24}round/>
                                           
                                        </FacebookShareButton>
                                        </Tooltip>

                                        <TwitterShareButton title={`${article.country} (${article.city ? article.city + ', ' : ''}${article.date}): ${article.title}`} url={"https://riskcompass.eu/article/" + article.postName}>
                                            <TwitterIcon size={24}round/>
                                        </TwitterShareButton>
                                        <TelegramShareButton url={"https://riskcompass.eu/article/" + article.postName}>
                                            <TelegramIcon size={24}round/>
                                        </TelegramShareButton>
                                        <WhatsappShareButton url={"https://riskcompass.eu/article/" + article.postName}>
                                            <WhatsappIcon size={24}round/>
                                        </WhatsappShareButton>
                                         <EmailShareButton url={"https://riskcompass.eu/article/" + article.postName}>
                                            <EmailIcon size={24}round/>
                                        </EmailShareButton>
                                        </div>

    */

    deleteArticle(id) {
        this.props.deleteArticle(id).then(res => {
            this.props.fetchArticlesByTagTitles(this.props.tagTitles, { featureCollection: true, limit: limit })

        }).catch(err => {
            //this.props.fetchArticlesByTagTitles(this.props.tagTitles,  { featureCollection: true, limit: limit })
        })
    }

    render() {
        const { anchorEl } = this.state;
        const { articles, tags, checklists, admin, editor, share, tagTitles, checklistsVisible, showMore } = this.props
        //console.log('admin', admin)
        return <div style={{ height: '100%' }} className={"visible-print"} ref={ref => { this.list = ref }}>
            {articles && articles.articles &&
                articles.articles.map((article, index) => {
                    //   console.log('article.link', article.link)
                    var showChecklists = []
                    if (article.tags) {


                        showChecklists = article.tags.map(tag => {
                            var res = checklists.filter(checklist => {
                                const match = checklist.tag == tag

                                return match
                            })
                            if (res.length > 0) {
                                return res[0]
                            }
                        }).filter(i => {
                            return i != undefined
                        })
                    }
                    //console.log("tags", tags) 
                    //  console.log('checklistsVisible', checklistsVisible)

                    return (

                        <Card ref={ref => { this.ref[article.id] = ref }} key={article.id + index} style={{ marginBottom: '15px', maxWidth: '100%' }}>
                            <CardContent style={{ marginBottom: 0, paddingBottom: 0 }}>
                                <div>
                                    <div style={{ display: 'flex', flexDirection: "row", marginBottom: 0 }}>
                                        <h3 style={{ margin: 0 }}><strong><Tooltip title="Zum Artikel"><Link style={{ textDecoration: 'none' }} to={"/article/" + article.postName}>{article.country} ({article.city ? article.city + ', ' : ''}{article.date}): {article.title}</Link></Tooltip></strong></h3>
                                        {article.riskLevel && this.renderRisklevel(article)}
                                    </div>

                                    <Link style={{ textDecoration: 'none', color: '#333333' }} to={"/article/" + article.postName}>
                                        <Markdown markup={article.teaser} tables={true} />
                                    </Link>




                                    {checklistsVisible && showChecklists.length > 0 &&
                                        <p>
                                            Im Ereignisfall sollten Reisende die Sicherheits- und/oder Verhaltenshinweise gemäß der RiskCompass-Checkliste
                                    {checklistsVisible && showChecklists.map((checklist, index) => {
                                            return <Link key={index} style={{ paddingLeft: 5, marginBottom: 30, textDecoration: 'none' }} to={"/page/" + checklist.postName}>{checklist.title}{index == showChecklists.length - 1 ? "" : ","}</Link>
                                        })} beachten.
                                    </p>

                                    }
                                    {showMore &&
                                        <Link style={{ textDecoration: 'none' }} to={"/article/" + article.postName}><b>Weiter zum gesamten Inhalt</b></Link>
                                    }
                                    {false && 
                                    <h6 style={{ fontSize: '0.575rem', margin: 0 }}>RiskCompass GmbH. Alle Rechte vorbehalten, all rights reserved.</h6>
                                    }

                                    {article.copyright &&
                                        <h6 style={{ fontSize: '0.575rem', margin: 0 }}>{article.copyright}</h6>
                                    }

                                    <div>
                                        {tags && article.tags && article.tags.map(tag => {
                                            return (


                                                <Chip
                                                    className="chip"
                                                    variant="outlined"
                                                    style={{ margin: '3px' }}
                                                    key={tag}
                                                    label={tag}
                                                    onClick={this.onTagClick.bind(this, tag)}
                                                />


                                            )


                                        })}
                                    </div>

                                    <div style={{
                                        marginTop: 20, paddingBottom: 20, marginLeft: 5, display: 'flex', fallbacks: [
                                            { display: '-webkit-box' },
                                            { display: '-moz-box' },
                                            { display: '-ms-flexbox' },
                                            { display: '-webkit-flex' }
                                        ], flexDirection: 'row', msFlexDirection: 'row'
                                    }}>
                                        {this.props.showOnMap && article.location &&
                                            <Tooltip title="Auf Karte anzeigen">
                                                <a><ExploreIcon style={{ marginLeft: 0, marginRight: 10 }} onClick={this.showOnMap.bind(this, article.id)} /></a>
                                            </Tooltip>
                                        }
                                        {article.link &&
                                            <Tooltip title="Zur Original-Quelle">
                                                <a style={{ marginLeft: this.props.showOnMap ? 10 : 0, marginRight: 10 }} href={article.link}><LinkIcon /></a>
                                            </Tooltip>
                                        }

                                        {false && tagTitles && tagTitles.length > 0 && tagTitles[0] != "entwurf" &&


                                            <div style={{
                                                display: 'flex', fallbacks: [
                                                    { display: '-webkit-box' },
                                                    { display: '-moz-box' },
                                                    { display: '-ms-flexbox' },
                                                    { display: '-webkit-flex' }
                                                ], flexDirection: 'row', msFlexDirection: 'row'
                                            }}>
                                                <Tooltip title="Auf Facebook teilen">
                                                    <div>
                                                        <FacebookShareButton style={{ marginLeft: 10, marginRight: 10 }} url={"https://riskcompass.eu/article/" + article.postName}>

                                                            <FacebookIcon size={24} round />

                                                        </FacebookShareButton>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title="Auf Twitter teilen">
                                                    <div>
                                                        <TwitterShareButton style={{ marginLeft: 10, marginRight: 10 }} title={`${article.country} (${article.city ? article.city + ', ' : ''}${article.date}): ${article.title}`} url={"https://riskcompass.eu/article/" + article.postName}>
                                                            <TwitterIcon size={24} round />
                                                        </TwitterShareButton>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title="Per Telegram teilen">
                                                    <div>
                                                        <TelegramShareButton style={{ marginLeft: 10, marginRight: 10 }} url={"https://riskcompass.eu/article/" + article.postName}>
                                                            <TelegramIcon size={24} round />
                                                        </TelegramShareButton>
                                                    </div>
                                                </Tooltip>

                                                <Tooltip title="Per Whatsapp teilen">
                                                    <div>
                                                        <WhatsappShareButton style={{ marginLeft: 10, marginRight: 10 }} url={"https://riskcompass.eu/article/" + article.postName}>
                                                            <WhatsappIcon size={24} round />
                                                        </WhatsappShareButton>
                                                    </div>
                                                </Tooltip>

                                                <Tooltip title="Per Email teilen">
                                                    <div>
                                                        <EmailShareButton style={{ marginLeft: 10, marginRight: 10 }} url={"https://riskcompass.eu/article/" + article.postName}>
                                                            <EmailIcon size={24} round />
                                                        </EmailShareButton>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        }

                                        {(admin || editor) &&
                                            <Tooltip title="Artikel bearbeiten" className="no-print">
                                                <div className="no-print" style={{ marginLeft: 10, }} href={article.link}><Link to={"/article/edit/" + article.id}><EditIcon /></Link></div>
                                            </Tooltip>
                                        }
                                        {(admin || editor) && tagTitles && tagTitles.length > 0 && tagTitles[0] == "entwurf" &&
                                            <Tooltip className="no-print" title="Artikel löschen">
                                                <div className="no-print" style={{ marginLeft: 10, }} onClick={this.deleteArticle.bind(this, article.id)}><DeleteIcon /></div>
                                            </Tooltip>
                                        }






                                    </div>

                                </div>



                            </CardContent>

                        </Card>
                    )
                })
            }
            {!articles &&
                <div className={'loading_wrapper'} style={{ textAlign: 'center', position: 'relative', width: "100%", height: '100%' }}>
                    <CircularProgress style={{ position: 'absolute', top: '50%', margin: '0 auto' }} color="secondary" />
                </div>

            }
        </div>

    }

}



function mapStateToProps(state) {

    //console.log('state.articles', state.articles)
    return {
        articles: state.articles.all,
        article: state.articles.article,
        admin: state.auth.admin,
        editor: state.auth.editor,
        checklists: state.articles.checklists
    }
}
const styles = {

}
export default withStyles(styles, { name: 'ArticleList' })(connect(mapStateToProps, {
    fetchArticlesSearch: fetchArticlesSearch,
    fetchArticlesByTagTitles: fetchArticlesByTagTitles,
    fetchArticle: fetchArticle,
    fetchChecklists: fetchChecklists,
    deleteArticle: deleteArticle
})(ArticleList));


