import { push } from 'react-router-redux';
import axios from 'axios';
import {
    REGISTER_USER
} from "../../actions/types";

import {ROOT_URL} from "../../config";

export function goToRoute(route) {
    return function (dispatch) {
        dispatch(push(route));
    }
}

export function register(options) {
    return function (dispatch) {

        const body = {
            email:options.email,
            password:options.password,
            info:options.info
        }
        

        const url = `${ROOT_URL}/users`
        return axios.post(url, body, {
            headers: { authorization: localStorage.getItem('token') }
        }).then((response) => {
            console.log('response.data', response.data)
            dispatch({
                type: REGISTER_USER,
                payload: response
            });
        }).catch((err) => {
            //console.log('err', JSON.stringify(err))
            dispatch({
                type: REGISTER_USER,
                payload: err
            });
        });
    }
}




