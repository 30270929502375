import React, { Component } from 'react'
import ReactDOM from "react-dom";
import { connect } from 'react-redux'
import { render, findDOMNode } from 'react-dom';
import Map from '../map/map_index'
import MapTable from '../mapTable'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { fetchMap, fetchMaps } from './actions';
import axios from 'axios'
import { ROOT_URL } from "../../config";
import * as _ from "lodash";

class MapEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            layerIds: [],
            mapId: null,
            zoom: [0],
            style:"light",
            editMap: null
        }
        this.mapTable = null
        this.map = null

    }
    componentDidMount() {
        this.props.fetchMaps()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
       // console.log('props', this.props)

    }

    onSelectionChange(selection) {
       // console.log('selection', selection)
        var selectedMap = this.props.maps.filter(m => {
            return m.id == selection
        })
        if (selectedMap.length > 0) {
           // console.log('selectedMap.location', selectedMap[0].location)
            var center = selectedMap[0].location && selectedMap[0].location.coordinates ? selectedMap[0].location.coordinates : [-80.191788, 25.761681]
           // console.log('center', center)

            this.setState({
                mapId: selectedMap[0].id,
                zoom: selectedMap[0].zoom ? [selectedMap[0].zoom] : [5],
                pitch: selectedMap[0].pitch ? [selectedMap[0].pitch] :  [0],
                bearing: selectedMap[0].bearing ? [selectedMap[0].bearing] : [0],
                center: center,
                style:selectedMap[0].style || "dark"
            })


           // console.log('selectedMap', selectedMap[0])
        }
    }

    updateMap(changes) {

        var id = Object.keys(changes)[0]

        var payload = changes[id]
        delete payload.id
        // delete payload.location

        const filter = {
            id: id
        }

        const url = `${ROOT_URL}/Maps/update?where=${encodeURIComponent(JSON.stringify(filter))}`
       // console.log('id', id)
       // console.log('payload', payload)
        return axios.post(url, payload, {
            headers: { authorization: localStorage.getItem('token') }
        })
    }

    insertMap(payload) {

        const url = `${ROOT_URL}/Maps`
       
       // console.log('payload', payload)
        return axios.post(url, payload, {
            headers: { authorization: localStorage.getItem('token') }
        })
    }

    deleteMap(id) {
       // console.log('deleteMap')
        const url = `${ROOT_URL}/Maps/${id}`
       
        return axios.delete(url,{
            headers: { authorization: localStorage.getItem('token') }
        })
    }

    rowCommitted(changes) {
       // console.log('changes', changes)
        var c = _.clone(changes)
        var center = this.map.getCenter()
        var id = Object.keys(changes)[0]
       // console.log('id', id)
        if (c[id]) {
            c[id].location = { type: "Point", coordinates: [center.lng, center.lat] }
           // console.log('fucking changes', c)
            this.updateMap(c).then(res => {
               // console.log('rowCommitted res', res)
                this.props.fetchMaps()
            }).catch(err => {
               // console.log('rowCommitted err', err)
            })
        } else {
            this.insertMap(c).then(res => {
               // console.log('insertMap', res)
                this.props.fetchMaps()
            }).catch(err => {
               // console.log('rowCommitted err', err)
            })
        }

    }

    rowChanges(changes) {
       // console.log('changes', changes)
    }

    rowAdded(added) {
       // console.log('added', added)



        var row = added[0]
        if (!row.style) row.style = "light"
        this.insertMap(row).then(res => {
           // console.log('insertMap', res)
            this.props.fetchMaps()
        }).catch(err => {
           // console.log('rowCommitted err', err)
        })


        /*  const startingAddedId = (rows.length - 1) > 0 ? rows[rows.length - 1].id + 1 : 0;
          rows = [
            ...layerList,
            ...added.map((row, index) => ({
              id: startingAddedId + index,
              ...row,
            })),
          ];*/
    }


    onMapViewportChanged(map, evt) {

        if (this.state.editMap) {
            var center = map.getCenter()
           // console.log('center', center)
            var bounds = map.getBounds()
           // console.log('map.getBounds()', bounds)
            this.mapTable.updateRow({
                id: this.state.editMap,
                bounds:[ [bounds._sw.lng, bounds._sw.lat], [bounds._ne.lng,bounds._ne.lat]]
            })
        }
    }

    onEditingRowIdsChange(value) {
        if (value.length > 0) {
            this.setState({ editMap: value[0] })
        }

    }

    rowDelete(row) {
       // console.log('row', this.deleteMap)
        this.deleteMap(row).then(res => {
           // console.log('deleteMap', res)
            this.props.fetchMaps()
        }).catch(err => {
           // console.log('deleteMap err', err)
        })
    }


    render() {

        const { mapStyle, layerMenuOpen, anchorEl, imageBase64, adminLevel, layerIds, zoom, pitch, center, mapId, bearing, style, bounds } = this.state
        const { loading, maps, layers } = this.props


        return (
            <div>

                <Grid style={{ marginTop: '0px' , padding:'10px'}} container >
                    <Grid  item lg={7}>
                        <Paper>
                            <Map containerStyle={{
                                height: "calc( 100vh - 100px )",
                                width: "100%"
                            }}
                                zoom={zoom}
                                pitch={pitch}
                                bearing={bearing}
                                bounds={bounds}
                                center={center}
                                style={style}
                                onMoveEnd={this.onMapViewportChanged.bind(this)}
                                onZoomEnd={this.onMapViewportChanged.bind(this)}
                                onPitchEnd={this.onMapViewportChanged.bind(this)}
                                onRotateEnd={this.onMapViewportChanged.bind(this)}
                                mapId={mapId}
                                onRef={(map) => {
                                   // console.log('ref'); this.map = map;
                                }}
                            />

                        </Paper>
                    </Grid>
                    <Grid item lg={5}>
                        <Paper >
                            <MapTable 
                                onSelectionChange={this.onSelectionChange.bind(this)}
                                rowChanges={this.rowChanges.bind(this)}
                                rowAdded={this.rowAdded.bind(this)}
                                rowCommitted={this.rowCommitted.bind(this)}
                                rowDelete={this.rowDelete.bind(this)}
                                onEditingRowIdsChange={this.onEditingRowIdsChange.bind(this)}
                                onRef={(mapTable) => {
                                   // console.log('ref'); this.mapTable = mapTable;
                                }} />
                        </Paper>
                    </Grid>
                </Grid>


            </div>
        );
    }
}

function mapStateToProps(state) {
    /* let filtered = _.isEmpty(state.posts.postsGridData.gridFilters) ? [] : state.posts.postsGridData.gridFilters;
     let sorted = _.isEmpty(state.posts.postsGridData.gridSortCriteria) ? [] : state.posts.postsGridData.gridSortCriteria;
 */

   // console.log('state.map', state.maps)



    return {

        maps: state.maps.maps
    }
}

export default connect(mapStateToProps, {
    fetchMap: fetchMap,
    fetchMaps: fetchMaps,
})(MapEditor);