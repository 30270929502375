import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardContent, List, ListItem, ListItemText, Tooltip, Divider, IconButton } from '@material-ui/core';
import { ROOT_URL } from "../../config";
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import { Link } from 'react-router-dom';
import { Markdown } from 'react-showdown';
import Map from '../map/map_index'
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import OfferForm from '../offerForm'
//import ReactGA from 'react-ga';
import PrintIcon from '@material-ui/icons/Print'

import pdf from '../../files/Tutorial-RiskCompass-Sicherheits-App.pdf'
import { fetchPage } from '../page/actions'

import AppTeaser from '../appteaser'
const gaProperty = 'UA-130702159-1'

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pageName: null };

    }
    UNSAFE_componentWillMount() {
        //ReactGA.initialize(gaProperty);
        //ReactGA.pageview(window.location.pathname + window.location.search)

    }

    componentDidMount() {
        this.props.fetchPage("app")
    }



    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate() {

    }
    handleListItemClick(pageName) {
        // console.log('handleListItemClick', pageName)
        //this.props.goToRoute("/page/" + pageName)
        this.setState({ pageName })

    }



    render() {
        const { pageName } = this.state
        const { checklists, admin, editor, page, lang } = this.props

        // const contentSize = this.props.match.params.postName ? 12 : 9
        return <div>
            {page && page.type == "app" &&
                <div>

                    <Grid style={{ maxWidth: '100%', padding: '12px', paddingTop: 0, marginTop: '0px', marginLeft: '0px' }} container >
                        <Grid item lg={2} sm={2} xs={2} md={2} >
                        </Grid>
                        <Grid item lg={8} sm={8} xs={8} md={8} >
                            <Card>
                                <CardContent style={{ marginTop: 0, marginBottom: 0, paddingBottom: 10 }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h1><b style={{ marginBottom: 0, fontSize: 32, }}>{page.title[lang]}</b>
                                            <IconButton className={"no-print"} style={{ paddingLeft: 20 }} onClick={window.print}>
                                                <PrintIcon />
                                            </IconButton>
                                        </h1>
                                    </div>
                                    <div style={{ paddingLeft: 30, paddingRight: 30 }}>
                                        <Markdown markup={page.markdown[lang]} />
                                    </div>
                                    {(admin || editor) &&
                                        <Tooltip title="Bearbeiten">
                                            <a style={{ marginLeft: 0 }} ><Link to={"/app/edit"}><EditIcon /></Link></a>
                                        </Tooltip>
                                    }

                                </CardContent>
                            </Card>
                            <Card style={{ marginTop: 20 }}>
                                <CardContent>
                                    <div style={{ textAlign: 'center' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                                            <img src={'/images/RiskCompass-App-Icon-2021-04-27.png'} width={80} height={80} style={{ padding: 20 }} />
                                            <div style={{ marginTop: 15, textAlign: 'left', marginRight: 20, }}>
                                                <Markdown markup={page.tutorial && page.tutorial[lang]} />
                                            </div>
                                        </div>
                                        <a href={pdf} download><Button type="raised" color={"primary"}>Zum Download</Button></a>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card style={{ marginTop: 20 }}>
                                <CardContent>
                                    <div style={{ textAlign: 'center' }}>
                                        <AppTeaser />
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={2} sm={2} xs={2} md={2} >
                        </Grid>
                    </Grid>

                </div>
            }
        </div>

    }

}

function mapStateToProps(state) {


    return {
        lang: state.lang.lang,
        page: state.page.page,
        admin: state.auth.admin,
        editor: state.auth.editor
    }
}
const styles = {

}
export default withStyles(styles, { name: 'App' })(connect(mapStateToProps, {
    fetchPage
})(App));


