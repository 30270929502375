import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import {
    renderField, renderFieldTextarea, renderHiddenField, renderCheckbox
} from "../../components/field_formatters";
import PropTypes from 'prop-types';
import { Button, LinearProgress } from "@material-ui/core";
//import validate  from './validation'
import { Link } from 'react-router-dom';
import { sendForm } from "./actions";
import Reaptcha from 'reaptcha';

 


class ContactForm extends Component {

    state = {
        submittedEditDossier: false,
        open: false,
        anchorEl: null,
        verified: false,
        submitted:false,
        submitting:false

    }

    handleFormSubmit(formProps) {
       // console.log('formProps', formProps)
        const { history } = this.props
        if (formProps.Nachname && formProps.Email && formProps.Datenschutz && this.state.verified) {
            this.setState({submitting:true})
            this.props.sendForm(formProps).then(res => {
                //history.goBack()
               
                    this.setState({submitted:true, submitting:false})
                
                
            }).catch(err => {
                
                    this.setState({submitted:false, error:err, submitting:false})
               
            })
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
       // console.log('nextProps', nextProps)
    }

    onVerify = () => {
        this.setState({
          verified: true
        });
      };

      onExpire = () => {
        this.setState({
          verified: false
        });
      };
    

    render() {

        const { handleSubmit, sent } = this.props;
        const { error, submitted, submitting } = this.state
       // console.log('this.props', this.props)




        return (


            <div style={{ }}>
            <div >
                        <p>Hier können Sie mit uns in Kontakt treten, uns z.B. Ihre Fragen stellen oder 
                            (vertrauliche) Informationen zusenden. Füllen Sie einfach das nachfolgende 
                            Formular aus und senden Sie Ihre Mitteilung mit einem Klick an uns ab. 
                            Ihre persönlichen Angaben und Daten werden streng vertraulich behandelt 
                            (hier mehr zum <Link to="/dataProtection">Datenschutz</Link>).</p>
                            <p>Mit * gekennzeichnete Felder müssen ausgefüllt werden.</p>
                    </div>
                <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                    <Field name="id" type="hidden" component={renderHiddenField} />

                    <Field name={'Vorname'} type="text" component={renderField} label="Vorname" />
                    <Field name={'Nachname'} type="text" component={renderField} label="Nachname *" />
                    <Field name={'Funktion'} type="text" component={renderField} label="Funktion" />
                    <Field name={'Firma'} type="text" component={renderField} label="Firma (inkl. Rechtsform)*" />
                    <Field name={'Email'} type="email" component={renderField} label="E-Mail *" />
                    <Field name={'Betreff'} type="text" component={renderField} label="Betreff" />
                    <Field name={'Nachricht'} type="text" component={renderFieldTextarea} label="Nachricht" />
                   
                    <Field name={'Datenschutz'} type="checkbox" checked={"false"} component={renderCheckbox} label="Ich akzeptiere die Datenschutzbestimmungen *" />
                    <div style={{}}>
                    <Reaptcha sitekey="6Lf46HAUAAAAACGT61Y3fOIaEN3wtpZos926lMx0" onVerify={this.onVerify} onExpire={this.onExpire} onError={this.onExpire}/>
                    </div>
                    {error && <strong> {error} </strong>}

                    { !submitting && !submitted &&
                    <div style={{ marginTop: 20, textAlign: 'center', }}>
                        <Button type="submit" variant="contained" color="primary">Absenden</Button>

                    </div>
                    }
                    { submitting &&
                    <div style={{marginTop:20}}>
                      <LinearProgress />
                      </div>
                    }
                    { submitted &&
                    <div style={{marginTop:20,textAlign:'center', fontSize:'1.5em'}}>
                        <p >Ihre Nachricht wurde verschickt.</p>
                        </div>
                    }
                </form>

            </div>


        )
    }
}

ContactForm.propTypes = {
    classes: PropTypes.object.isRequired,
};


const form = reduxForm({
    form: 'ContactForm',
    enableReinitialize: true,
    //validate:validate
});

ContactForm.contextTypes = {
    store: PropTypes.object
};

function mapStateToProps(state) {
    return {
        sent: state.contact.sent
    }
}

export default connect(mapStateToProps, {
    sendForm: sendForm,
})(form(ContactForm));