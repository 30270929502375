import {
    SEND_REPORT
} from "../../actions/types";

const INITIAL_STATE = {
    sent:false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case SEND_REPORT:
            return {
                ...state,
                sent: action.payload.data
            };

        default:
            return state;
    }
}