import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import StickyFooter from 'react-sticky-footer';

class Footer extends React.Component {


    render() {
        return (
            <StickyFooter
                bottomThreshold={0}
                normalStyles={{
                    backgroundColor: '#116bff',
                    padding: "10",
                    paddingTop:"0"
                }}
                stickyStyles={{
                    backgroundColor: '#116bff',
                    width: '100%',
                    padding: "10"
                }}
            >

                <div className="fixed" style={{ display: 'flex', color: 'white', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', msFlexDirection: 'column' }}  >
                    <div style={{ marginTop: 20, paddingLeft: 10, paddingRight: 10, textAlign: 'center' }}>
                        RiskCompass GmbH. Alle Rechte vorbehalten. All rights reserved.
                 </div>
                    <div style={{ display: 'flex', flexDirection: "row", msFlexDirection: 'row', fontWeight: 600, paddingLeft: 10, paddingRight: 10, paddingBottom: 20, paddingTop: 10 }}>
                        <div style={{ paddingLeft: 3, paddingRight: 3 }}>
                            <Link to="/agb">AGB</Link>
                        </div>
                        <div style={{ paddingLeft: 5, paddingRight: 5, color: '#0a5586' }}>|</div>
                        <div style={{ paddingLeft: 3, paddingRight: 3 }}>
                            <Link to="/dataProtection">Datenschutz</Link>
                        </div>
                        <div style={{ paddingLeft: 5, paddingRight: 5, color: '#0a5586' }}>|</div>
                        <div style={{ paddingLeft: 3, paddingRight: 3 }}>
                            <Link to="/faq">FAQ</Link>
                        </div>
                        <div style={{ paddingLeft: 5, paddingRight: 5, color: '#0a5586' }}>|</div>
                        <div style={{ paddingLeft: 3, paddingRight: 3 }}>
                            <Link to="/impressum">Impressum</Link>
                        </div>
                        <div style={{ paddingLeft: 5, paddingRight: 5, color: '#0a5586' }}>|</div>
                        <div style={{ paddingLeft: 3, paddingRight: 3 }}>
                            <Link to="/contact">Kontakt</Link>
                        </div>
                    </div>
                </div>
            </StickyFooter>
        );
    }
}


export default (Footer)
