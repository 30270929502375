import {
    FETCH_ARTICLE_SEARCH,
    FETCH_ARTICLE,
    FETCH_ARTICLES,
    FETCH_CHECKLISTS

} from "../../actions/types";

Array.prototype.clean = function (deleteValue) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] == deleteValue) {
      this.splice(i, 1);
      i--;
    }
  }
  return this;
};


const INITIAL_STATE = {
    all: [],
    article: null,
    checklists: []
};

function mainTagToIcon(mainTag) {
    var icon = ""

    switch (mainTag) {

        case "infrastruktur":
            icon = 'caution.png'
            break
        case "verkehrskatastrophe":
        case "verkehr":
            icon = "caution.png"
            break;
        case "verkehrsrisiken":
            icon = "circle_brown_traffic_incident.png"
            break;
        
        case "kriminalitaet":
            icon = 'blank_red.png'
            break
        case "vergewaltigung":
            icon = "triangle_rape.png"
            break;
        case "lagezentrum":
            break
        case "nachricht":
            break
        case 'amok':
        case 'schiesserei':
            icon = 'triangle_red_warning_crime.png'
            break
        case "naturgefahren":
            icon = 'blank_green.png'
            break
        case "reisemedizin":
            icon = 'medicine.png'
            break
        case "umweltverschmutzung":
            icon = "triangle_grey_warning_pollution.png"
            break
        case "gesundheitswarnung":
            icon = "triangle_blue_warning_health_outbreaks.png"
            break


        case "gewalt":
            icon = 'blank_red.png'
            break;

        case "reisewarnung":
        case "wetterwarnung":

            icon = 'warning_general.png'
            break
        case "terrorwarnung":
            icon = 'warning_terror.png'
            break
        case "risikogeographie":
            break
        case "soziale-unruhen":
            icon = 'blank_pink.png'
            break
        case "terrorismus":

            icon = 'blank_black.png'
            break
        case "brand":
        case "brandanschlag":
        case "busch-und-waldbraende":
        case "eigentum":
            icon = "fire.png"
        case "wirtschaftssicherheit":
            icon = 'blank_pink.png'
            break
        default:
            icon = ""
            break
    }
    return icon
}

function articleToFeature(marker) {
        //console.log('marker', marker)
        if (!marker.location) {
            
            return null
        }
        var feature = {
            type: "Feature",
            geometry: {
                type: marker.location.type || "point",
                coordinates: marker.location.coordinates,
            },
            properties: {
                title: marker.country + " (" + (marker.city ? marker.city + ', ' + marker.date + '): ' : '' + marker.date + '): ') + marker.title,
                icon: mainTagToIcon(marker.mainTag),
                postName: marker.postName,
                incidentScene: marker.incidentScene,
                geojsonUrl: marker.geojsonUrl,
                id: marker.id,
            }
        }
        return feature
    }

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case FETCH_ARTICLE:
            return {
                ...state,
                article: action.payload.data
            };

        case FETCH_ARTICLE_SEARCH:
            return {
                ...state,
                all: action.payload.data
            };

        case FETCH_CHECKLISTS:
            return {
                ...state,
                checklists: action.payload.data
            };
        case FETCH_ARTICLES:
            var articles =  action.payload.data
            articles.featureCollection =  { type: "FeatureCollection", features: [] }

            articles.featureCollection.features = action.payload.data.articles.map( article => {
                return articleToFeature(article)
            }).clean(null)
           // console.log('articles', articles)

            return {
                ...state,
                isFetching:true,
                all:articles
            };

        default:
            return state;
    }
}