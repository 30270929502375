//export const ROOT_URL = 'http://localhost:9000/api';
export const ROOT_URL = 'https://api.riskcompass.eu/api';

export const LOGIN_URL = ROOT_URL+'/users/login';

export const notificationDuration = 2;

export const newSectionPostOptions = [
    'Add New Section',
    'Add New Image'
];

export const newSectionDossierOptions = [
    'Add New Section',
    'Add New Image'
];
  