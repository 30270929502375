import React, { Component, PropTypes } from 'react';
import {
    Card, Badge, CardContent, Tooltip, Grid, IconButton, Icon,
    Toolbar, Typography, List, ListItem, ListItemAvatar, Avatar,
    ListItemText, ListItemSecondaryAction, LinearProgress,
    Dialog, DialogTitle, DialogContent, DialogActions, Button,
    TextField, InputAdornment
} from '@material-ui/core'

import {
    fetchUsers, goToRoute, fetchUser,
    resetUser, deleteUser, resetPassword,
    updateUser
} from './actions'
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RouterIcon from '@material-ui/icons/Router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ConfigurationForm from './form'
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyIcon from '@material-ui/icons/VpnKey';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/CheckCircle';
import AccountIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next'
//import Gateways from '../gateways'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dropzone from 'react-dropzone'
import * as csv from "csvtojson"
import async from 'async'

class Users extends Component {

    constructor(args) {
        super(args)
        this.state = {
            addModal: false,
            sent: false,
            users:this.props.users
        }

    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.fetchUsers(this.props.match.params.id)
        }
    }

    normalise(value) {
        return (value - 1) * 100 / (this.state.updateCount - 1)
    }

    UNSAFE_componentWillMount() {
        //this.props.fetchConfigurations()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ( (!this.state.users && nextProps.users) || (JSON.stringify(this.state.users) != JSON.stringify(nextProps.users))) {
            this.setState({users:nextProps.users})
        }
    }

    goBack() {
        this.props.history.goBack()
    }

    onSearch(e) {
        console.log('search', e.target.value)
        var users = this.props.users

        users = users.filter(function (item) {
            if (item.name) {
                return item.name.toLowerCase().search(
                    e.target.value.toLowerCase()) !== -1;
            }
        });
        this.setState({ users })
    }

    addClick(id) {


        if (id && typeof id == 'string') {
            this.props.fetchUser(id).then(res => {
                //console.log('res', res)
                this.setState({
                    addModal: true,
                    currentId: id
                })
            }).catch(error => {

            })
        } else {
            this.props.resetUser().then(res => {
                this.setState({
                    addModal: true,
                    currentId: null
                })
            })
        }

    }

    onDismiss() {
        this.setState({ addModal: false })
        if (this.props.match.params.id) {
            this.props.fetchUsers(this.props.match.params.id)
        }

    }

    deleteItem(id) {
        console.log('deleteItem', id)
        this.setState({ deleting: true, selectedId: id })
    }

    handleDialogClose() {
        this.setState({ deleting: false, selectedId: null })
    }

    resetPassword(user) {
        this.setState({ sent: true })

        this.props.resetPassword(this.state.selectedUser).then(res => {
            console.log('res', res)
            //setTimeout(()=>{
            this.setState({ sent: false })
            this.handleEmailDialogClose()
            //},1000)

        }).catch(err => {
            console.log('err', err)
        })
    }

    onDelete(id) {
        this.setState({ updating: true })
        this.props.deleteUser(this.state.selectedId).then(res => {
            this.setState({ updating: false, deleting: false, selectedId: null })
            if (this.props.match.params.id) {
                this.props.fetchUsers(this.props.match.params.id)
            }
        })
    }

    showResetPassword(user) {
        console.log('user', user)
        this.setState({sendMail:true, selectedUser:user})

    }

    handleEmailDialogClose() {
        this.setState({sendMail:false,selectedUser:null})
    }
    onSendMail() {
        
    }

    editClick(user) {
        console.log('user', user)
        this.setState({addModal:true, selectedUser:user})
    }

    addUser(formProps, index, cb) {
        console.log('formProps', formProps)
       
        this.setState({ updating: true })
        if (formProps.email) {
            formProps.email = formProps.email.toLowerCase()
    
        }
        formProps.customer = this.props.customer
       // formProps.role = "user"
        

    }

    onDrop(acceptedFiles, rejectedFiles) {
        console.log('onDrop', acceptedFiles)
        const reader = new FileReader();
        reader.onload = () => {
            const str = reader.result;
            console.log('str', str)
            csv({
                //noheader:true,
                delimiter:";",
               
            })
            .fromString(str)
            .then((rows)=>{ 
                console.log("rows", rows) // => [["1","2","3"], ["4","5","6"], ["7","8","9"]]
                rows = rows.map( (row,_index) =>{
                    row.index = _index
                    return row
                })
                this.setState({updating:true,updateIndex:0, updateCount:rows.length})
                async.mapLimit(rows, 1, (row, cb)=>{
                   
                    var formProps = row
                    delete formProps.index
                    formProps.customer = this.props.match.params.id
                    this.props.updateUser(formProps).then(res => {
                        console.log('after updateUser', res)
                        this.setState({ updateIndex: row.index })
                        setTimeout( () =>{
                            cb(null, res)
                        }, 1000)
                       
                       // this.handleClose()
                       
                        /*if (res) {
                            
                        } else {
                            this.setState({ error: true })
                        }*/
                    }).catch(error => {
                        console.log('error', error)
                        
                        if (this.props.match.params.id) {
                            this.props.fetchUsers(this.props.match.params.id)
                        }
                        //this.setState({ updating: false, error })
                    })

                }, (err, results)=>{
                    console.log('done', results)
                    this.setState({updating:false,updateIndex:null, updateCount:null})
                    if (this.props.match.params.id) {
                        this.props.fetchUsers(this.props.match.params.id)
                    }

                }) 
            
            
            })

          
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');

        reader.readAsText(acceptedFiles[0]);
    }

    handleUpdateDialogClose() {
        this.setState({updating:false})
    }

    /*
     <Tooltip title={command.emailVerified ? t("users.Verifiziert") +'\n/ '+ t("users.Zurücksetzen") : t("users.NichtVerifiziert") +'\n/ '+ t("users.Zurücksetzen")}>
                                                               <IconButton aria-label={command.emailVerified ? t("users.Verifiziert") +'\n/ '+ t("users.Zurücksetzen") : t("users.NichtVerifiziert") +'\n/ '+ t("users.Zurücksetzen")}>
                                                                 {command.emailVerified &&
                                                                   <CheckIcon style={{color:'green'}}/>
                                                                   }
                                                                    {!command.emailVerified &&
                                                                    <ErrorIcon style={{color:'red'}}/>
                                                               }
                                                               </IconButton>
                                                           </Tooltip>
                                                                  
                                                                    */

    render() {
        const {  admin, editor, t } = this.props
        const { updateIndex, addModal, deleting, updating, selectedId, sent, sendMail, search,users, selectedUser } = this.state
        const customer = this.props.match.params.id
        console.log('users', users)
        return (
            <div style={{ padding: 20 }}>

                <Grid container
                    justify="center"
                    alignItems="center"
                    >
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div style={{ flexGrow: 1 }}>
                            <Toolbar>
                                <IconButton onClick={this.goBack.bind(this)} color="inherit" aria-label="Add Tenant">
                                    <BackIcon />
                                </IconButton>

                                <Typography variant="h5" color="inherit" style={{ flexGrow: 1,textAlign:'center' }}>
                                    {t("users.Benutzer")}
                                </Typography>

                                <Dropzone onDrop={this.onDrop.bind(this)} style={{ maxHeight: '164px' }}>
                                    <CloudUploadIcon />
                                </Dropzone>


                                <Tooltip title={t("users.NeuenBenutzeranlegen")}>
                                    <IconButton onClick={this.addClick.bind(this)} color="inherit" aria-label={t("users.NeuenBenutzeranlegen")}>
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            </Toolbar>
                            {sent &&
                                <LinearProgress color={"secondary"} />
                            }
                            {!sent &&
                                <div style={{ minHeight: 5 }}></div>
                            }
                        </div>
                    </Grid>
                    <Grid item lg={12}>

                    <div style={{ paddingLeft:40,paddingRight:40 }}>
                        <TextField

                            label="Suche"
                            onChange={this.onSearch.bind(this)}
                            value={search}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                        {users && users.length > 0 && users.map(command => {
                            return (
                                <Card key={command.id} style={{ marginTop: 20 }}>
                                    <CardContent>
                                        <List dense={true}>
                                            <ListItem>
                                                <ListItemAvatar>

                                                    <div>
                                                    <Tooltip title={t("users.Benutzerbearbeiten")}>
                                                            <IconButton onClick={this.editClick.bind(this, command)}>
                                                                <AccountIcon style={{color:command.lastLogin ? 'green' : 'red'}}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </ListItemAvatar>

                                                <Tooltip title={t("users.Berechtigungen")}>
                                                    <ListItemText
                                                        style={{ marginLeft: 20, width: '50vw' }}
                                                        primary={command.name || command.role}
                                                        secondary={command.email}
                                                    />
                                                </Tooltip>

                                                <ListItemSecondaryAction>
                                               
                                                <Tooltip title={t("users.Zurücksetzen")}>
                                                            <IconButton onClick={this.showResetPassword.bind(this, command)} aria-label={t("users.Zurücksetzen")}>
                                                                <EmailIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    <Tooltip title={t("users.Benutzerlöschen")}>
                                                        <IconButton onClick={this.deleteItem.bind(this, command.id)} aria-label={t("users.Benutzerlöschen")}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    
                                                </ListItemSecondaryAction>

                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </Card>
                            )
                        })}

                    </Grid>
                </Grid>



                {addModal && 

                    <ConfigurationForm customer={customer} initialValues={selectedUser} onDismiss={this.onDismiss.bind(this)} />


                }
                 {updating &&
                    <Dialog open={updating} onClose={this.handleUpdateDialogClose.bind(this)} aria-labelledby="" style={{ minWidth: '50%' }}>
                        <DialogTitle>
                            {t("customer.UserImport")}
                        </DialogTitle>
                        <DialogContent>
                            <LinearProgress variant="determinate"  value={this.normalise(updateIndex) }/>

                        </DialogContent>
                        
                    </Dialog>
                }
                {deleting &&
                    <Dialog open={deleting} onClose={this.handleDialogClose.bind(this)} aria-labelledby="Neuer Mieter" style={{ minWidth: '50%' }}>
                        <DialogTitle>
                            {t("users.Benutzerlöschen")}
                        </DialogTitle>
                        <DialogContent>
                            {t("users.WollenSie")}

                        </DialogContent>
                        <DialogActions>
                            {!updating &&
                                <div>
                                    <Button onClick={this.handleDialogClose.bind(this)} style={{ marginTop: 35 }} color="secondary">{t("users.Nein")}</Button>{' '}
                                    <Button onClick={this.onDelete.bind(this)} style={{ marginTop: 35 }} color="secondary">{t("users.Ja")}</Button>{' '}
                                </div>
                            }
                            {updating &&
                                <LinearProgress />
                            }
                        </DialogActions>
                    </Dialog>
                }
                {sendMail &&
                    <Dialog open={sendMail} onClose={this.handleEmailDialogClose.bind(this)} aria-labelledby="Neuer Mieter" style={{ minWidth: '50%' }}>
                        <DialogTitle>
                            {t("users.PasswortZurücksetzen")}
                        </DialogTitle>
                        <DialogContent>
                            {t("users.WollenSiePasswort")}

                        </DialogContent>
                        <DialogActions>
                            {!updating &&
                                <div>
                                    <Button onClick={this.handleEmailDialogClose.bind(this)} style={{ marginTop: 35 }} color="secondary">{t("users.Nein")}</Button>{' '}
                                    <Button onClick={this.resetPassword.bind(this)} style={{ marginTop: 35 }} color="secondary">{t("users.Ja")}</Button>{' '}
                                </div>
                            }
                            {updating &&
                                <LinearProgress />
                            }
                        </DialogActions>
                    </Dialog>
                }
            </div>
        )
    }
}

const styles = {

}

function mapStateToProps(state) {


    return {
        users: state.users.users,
        admin: state.auth.admin,
        editor: state.auth.editor,

    }
}
export default withNamespaces()(withStyles(styles, { name: 'Users' })(connect(mapStateToProps, {
    fetchUsers,
    fetchUser,
    resetUser,
    resetPassword,
    deleteUser,
    updateUser,
    goToRoute: goToRoute,

})(Users)));

