import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Risklegend from "./riskLegend";
import Maplegend from "./mapLegend";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Map from '../map/map_index'
import ArticleList from '../articleList'
import styles from './style/start';
import AppInfo from '../appInfo'
import CookieBanner from '../cookieBanner'
import Hidden from '@material-ui/core/Hidden';

import phoneImg from './images/RC-Android-App.jpg';
import playstoreImg from './images/google-play-badge.png';
import { fetchArticlesByTagTitles } from '../articles/actions'

import './style/start.css';

const limit = 100

class Start extends Component {

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleScrollToElement(event) {
        //$('window').scrollTop($('.elementToScrollTo').scrollTop());
        //ReactDOM.findDOMNode('elementToScrollTo').scrollTop = 0
        const scrollToElement = ReactDOM.findDOMNode(this.refs.test)
        scrollToElement.scrollIntoView({ block: 'start', behavior: 'smooth' });

    }

    componentDidMount() {
        this.props.fetchArticlesByTagTitles(["nachricht"], { featureCollection: true, limit: limit })
    }

    /*
         <Grid item xs={12}>
                        <h1 className={classes.headline}><b>Die weltweite »RiskCompass-Ereigniskarte«</b></h1>
                       
                    </Grid>
    */

    showOnMap(id) {
        if (this.map) {
           // console.log('this.map', this.map)
            this.map.selectMarker(id)
        }

    }

    render() {
        const classes = this.props.classes;
        const { articles } = this.props

        return (
            <Grid container
                style={{ marginTop: 20, maxHeight: 'calc(100vh - 64px)', overflowY: 'scroll', WebkitOverflowScrolling: 'touch' }}
                alignItems={'stretch'}
                
                justify={'center'}>

                <CookieBanner />

                <Hidden smUp>
                    <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
                        <Button onClick={this.handleScrollToElement.bind(this)} variant="raised" color="primary" component="span" className={classes.button}>
                            Scroll Down
                        </Button>
                    </Grid>
                </Hidden>

                <Grid item xl={3} xs={10} sm={5}>
                    <Paper>
                        <Map
                            onRef={ref=>this.map = ref}
                            onSelected={(article) => {
                                if (this.articleList) {
                                    var offset = this.articleList.scrollTo(article.id)
                                   // console.log('offset', offset)

                                    const list = ReactDOM.findDOMNode(this.list)
                                   // console.log('list', list)
                                    list.scrollTop = offset - 103
                                    //console.log('list', list)

                                }
                            }}
                            drawControl={false} containerStyle={{
                                height: '70vh',
                                width: "100%"
                            }} featureCollection={articles.featureCollection}>
                        </Map>
                    </Paper>
                </Grid>
                <Grid item xs={10} sm={5} >
                    <Paper ref={(ref) => { this.list = ref }} style={{ maxHeight: '70vh', WebkitOverflowScrolling: 'touch', overflowY: 'scroll', backgroundColor: 'rgba(0, 0, 0, 0)' }}>
                        <ArticleList
                            showOnMap={this.showOnMap.bind(this)}
                            onRef={ref => { this.articleList = ref }}
                            //onClick={this.onClick.bind(this)}
                            // onTagClick={this.onTagClick.bind(this)}
                            tagTitles={["nachricht"]} />
                    </Paper>
                </Grid>


                <Grid ref="test" item xs={10} xl={3}>
                    <Maplegend />

                </Grid>

                <Grid item xs={10} md={5} xl={3}>
                    <Paper style={{ padding: 20 }}>
                        <h2><b>Willkommen bei Riskcompass</b></h2>
                        <p>RiskCompass ist ein Nachrichten- und Informationsanbieter mit dem Fokus auf geopolitische und sicherheitsrelevante Themen. Die RiskCompass-Produkte und -Dienstleistungen umfassen: ein globales Sicherheitsportal (Inlands- und Auslandssicherheit), 24/7-Eil- und Warnmeldungen, umfassende, detaillierte Länder- und Städte-Datenbanken, lokalisierte Sicherheitsinformationen (dynamische Kartenschnittstelle zur Kartierung von Risiken, Bedrohungen und »Vulnerabilitäten« auf der ganzen Welt), Gefahrenanalyse, mobile Sicherheits-App, Newsletter/Intelligence Reports – alles für Privat- und Geschäftsleute, Unternehmen, Behörden usw.
</p>
                        <p>
                            <h2><b>Welcome to Riskcompass</b></h2>
                            RiskCompass is an intelligence and information provider focusing on geopolitical and security-related topics. RiskCompass products and services include: global security portal (domestic and foreign security), 24/7 alerts, comprehensive in-depth country and city profiles databases, localized security information (dynamic mapping interface mapping risks, threats and vulnerabilities around the world), threat analysis, mobile personal safety App, newsletter/intelligence Reports – all for private and business people, companies, authorities, law enforcement agencies etc..
                 </p>
                        <p><span><h6>Copyright © 2005-2018/2019. RiskCompass GmbH. Alle Rechte vorbehalten. All rights reserved.</h6></span></p>

                    </Paper>
                </Grid>

                <Grid item xs={10} md={5} xl={4}>
                    <AppInfo />
                </Grid>



            </Grid>
        )
    }
}

/*
// Newsletter 

 <Grid item xs={10} md={5} xl={3}>
                    <Paper className={'appAd'}>
                        <Typography variant="title" gutterBottom>
                            Bleiben Sie auf dem Laufenden
                    </Typography>
                        <Typography variant="subheading" gutterBottom>
                            Melden Sie sich jetzt für den kostenlosen »RiskCompass«-Newsletter an
                    </Typography>
                        <form className={classes.container} noValidate autoComplete="off">
                            <TextField
                                id="name"
                                label="Vorname"
                                className={classes.textField}
                                onChange={this.handleChange('name')}
                                margin="normal"
                            />
                            <TextField
                                required
                                id="surname"
                                label="Nachname"
                                className={classes.textField}
                                onChange={this.handleChange('surname')}
                                margin="normal"
                            />
                            <TextField
                                required
                                id="email"
                                label="E-Mail Adresse"
                                className={classes.textField}
                                margin="normal"
                            />
                            <TextField
                                id="company"
                                label="Firma"
                                className={classes.textField}
                                margin="normal"
                            />
                            <input
                                className={classes.input}
                                id="raised-button"
                                type="submit"
                            />
                            <label htmlFor="raised-button">
                                <Button variant="raised" color="primary" component="span" className={classes.button}>
                                    Jetzt abonnieren!
                                </Button>
                            </label>
                        </form>

                    </Paper>
                </Grid>

*/


function mapStateToProps(state) {

    //console.log('state.articles', state.articles)
    return {
        articles: state.articles.all,
    }
}
export default withStyles(styles, { name: 'Start' })(connect(mapStateToProps, {
    fetchArticlesByTagTitles: fetchArticlesByTagTitles
})(Start));