import React from 'react';
import ReactDOM from 'react-dom';
import { Card, CardActions, CardContent, Input, Tooltip, IconButton, Button, CircularProgress } from '@material-ui/core';
import { Markdown } from 'react-showdown';
import ArticleList from '../articleList'
import ArticleSearch from '../articleSearch'
import AppInfo from '../appInfo'
import { Link } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import PrintIcon from '@material-ui/icons/Print'
import axios from 'axios';
import TagList from '../tagList'
import { Grid, TextField, Paper, FormControl, InputLabel, Select, Checkbox, Chip, MenuItem, ListItemText } from '@material-ui/core'

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { ROOT_URL } from "../../config";
import { push } from 'react-router-redux';
import { fetchArticle } from '../article'
import Map from '../map/map_index'
import Hidden from '@material-ui/core/Hidden';
import EditTagList from '../editTagList/editTagList_index'
import NewsLetter from '../newsletter'
import LinkIcon from '@material-ui/icons/Link'

import EditIcon from '@material-ui/icons/Edit'
import {
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailShareButton,

    FacebookIcon,
    TwitterIcon,
    TelegramIcon,
    WhatsappIcon,
    EmailIcon,
} from 'react-share';
//import ReactGA from 'react-ga';
import moment from 'moment';
window.moment = moment
console.log('moment', window.moment)
const limit = 100
const _states = {
    "Deutschland": [

        "Baden-Württemberg",
        "Bayern",
        "Berlin",
        //   "Berlin/Brandenburg" ,
        "Brandenburg",
        "Bremen",

        "Hamburg",
        "Hessen",

        "Mecklenburg-Vorpommern",
        "Niedersachsen",

        "Nordrhein-Westfalen",
        "Rheinland-Pfalz",
        "Saarland",
        "Sachsen",
        "Sachsen-Anhalt",

        "Schleswig-Holstein",
        "Thüringen",



    ],
    "Österreich": [
        "Burgenland",
        "Kärnten",
        "Nieder­österreich",
        "Ober­österreich",
        "Salzburg",
        "Steiermark",
        "Tirol",
        "Vorarlberg",
        "Wien"
    ]
}


const _tags = [
    "brand",
    "eigentum",
    "gewalt",
    "sexualdelikt",
    "verkehr",
    "drogen"
]

const _subtags = [
    "messer",
    "nichtdeutsche",
    "uebergriffe"
]

const _countries = [
    "Deutschland",
    "Österreich",
]

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 450,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
});





class Police extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            featureCollection: {
                features: [],
                type: "FeatureCollection"
            },
            tags: [],
            subtags: [],
            country: "Deutschland",
            states: [],
            cities: [],
            start: moment().add('-3', "months").format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
            countries: []
        }
    }

    handleChange = event => {

        var states = event.target.value
        this.setState({ states });
        /*
         
         this.fetchCollection(this.state.tags, this.state.subtags, states).then(featureCollection => {
             this.setState({ featureCollection: featureCollection.data })
         })*/
    };

    handleChangeTag = event => {
        var tags = event.target.value

        this.setState({ tags });
        /*
         this.fetchCollection(tags, this.state.subtags, this.state.states).then(featureCollection => {
             this.setState({ featureCollection: featureCollection.data })
         })*/
    };

    handleChangeSubTag = event => {
        var subtags = event.target.value

        this.setState({ subtags });

        /* this.fetchCollection(this.state.tags, subtags, this.state.states).then(featureCollection => {
             this.setState({ featureCollection: featureCollection.data })
         })*/

    };

    onSubmit() {
        this.setState({ loading: true })
        var options = {
            start: moment(this.state.start, 'YYYY-MM-DD').toDate(),
            end: moment(this.state.end, 'YYYY-MM-DD').toDate(),
            limit: 18000
        }
        this.fetchCollection(this.state.tags, this.state.subtags, this.state.states, options).then(featureCollection => {
            this.setState({ featureCollection: featureCollection.data, loading: false })
        })
    }



    handleChangeCountry = event => {
        this.setState({ country: event.target.value, states: [] });
    };

    fetchArticleByPostname(postName) {

        const url = `${ROOT_URL}/Articles/byPostname?postName=${encodeURIComponent(postName)}`

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        })
    }

    fetchCollection(tags, subtags, states, opt) {
        console.log('tags', tags)
        console.log('subtags', subtags)
        console.log('states', states)
        console.log('opt', opt)
        if (!opt) {
            opt = { limit: 18000 }
        }

        var url = `${ROOT_URL}/Police/featureCollection?tags=${encodeURIComponent(JSON.stringify(tags))}&subtags=${encodeURIComponent(JSON.stringify(subtags))}&options=${encodeURIComponent(JSON.stringify(opt))}`
        if (states) {
            url = `${ROOT_URL}/Police/featureCollection?tags=${encodeURIComponent(JSON.stringify(tags))}&subtags=${encodeURIComponent(JSON.stringify(subtags))}&states=${encodeURIComponent(JSON.stringify(states))}&options=${encodeURIComponent(JSON.stringify(opt))}`

        }

        return axios.get(url, {
            headers: { authorization: localStorage.getItem('token') }
        })


    }

    UNSAFE_componentWillMount() {
        this.fetchCollection(null, null, null).then(featureCollection => {
            this.setState({ featureCollection: featureCollection.data })
        })
    }

    onTagClick() {

    }

    startChanged = event => {
        var start = event.target.value
        this.setState({ start: start })

    }

    endChanged = event => {
        var end = event.target.value
        this.setState({ end: end })
    }

    render() {
        const { featureCollection, article, country, loading, start, end } = this.state
        const { admin, editor } = this.props
        return (
            <div>
                <Grid style={{ maxWidth: '100%', padding: '12px', paddingTop: 0, marginTop: '0px', marginLeft: '7px' }} container >


                    <Grid style={{ paddingLeft: '0px', paddingRight: '10px', marginRight: '0px' }} item lg={8} xs={12} sm={12} md={8} >
                        <Paper>

                            <Map
                                // expanded={expanded}
                               // heatmap={true}
                                // onExpand={this.onExpand.bind(this)}
                                saveBounds={admin}
                                mapId={'9988798d-61d7-42f4-8b86-b267dc8b739b'}
                                onSelected={(article) => {
                                    console.log('onSelected', article)
                                    this.fetchArticleByPostname(article.postName).then(article => {
                                        this.setState({ article: article.data.article })
                                    })
                                }}
                                onRef={(ref) => this.map = ref}
                                drawControl={false} containerStyle={{
                                    height: '90vh',
                                    width: "100%"
                                }} featureCollection={featureCollection}>
                            </Map>

                        </Paper>
                    </Grid>
                    <Grid item lg={4} xs={12} sm={12} md={4}>
                        <Card>
                            <CardContent>
                                <div style={{ display: 'flex', flexDirection: "column" }}>
                                    <div>
                                        <FormControl fullWidth style={{}} >
                                            <InputLabel shrink htmlFor="country">Land</InputLabel>
                                            <Select

                                                value={this.state.country}
                                                onChange={this.handleChangeCountry}
                                                autoWidth
                                                input={<Input id="country" />}
                                                renderValue={selected => selected}
                                            //MenuProps={MenuProps}
                                            >
                                                {_countries.map(name => (
                                                    <MenuItem key={name} value={name}>
                                                        <Checkbox checked={this.state.country.indexOf(name) > -1} />
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                        <FormControl fullWidth >
                                            <InputLabel shrink htmlFor="states">Bundesland</InputLabel>
                                            <Select

                                                multiple
                                                value={this.state.states}
                                                onChange={this.handleChange.bind(this)}
                                                autoWidth
                                                input={<Input id="states" />}
                                                renderValue={selected => selected.join(', ')}
                                            //MenuProps={MenuProps}
                                            >
                                                {_states[country].map(name => (
                                                    <MenuItem key={name} value={name}>
                                                        <Checkbox checked={this.state.states.indexOf(name) > -1} />
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: "column", marginTop: 10 }}>

                                    <div>
                                        <FormControl fullWidth >
                                            <InputLabel shrink htmlFor="tags">Delikt</InputLabel>
                                            <Select
                                                multiple

                                                value={this.state.tags}
                                                onChange={this.handleChangeTag.bind(this)}
                                                autoWidth
                                                input={<Input id="tags" />}
                                                renderValue={selected => selected.join(', ')}
                                            //MenuProps={MenuProps}
                                            >
                                                {_tags.map(name => (
                                                    <MenuItem key={name} value={name}>
                                                        <Checkbox checked={this.state.tags.indexOf(name) > -1} />
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                        <FormControl fullWidth >
                                            <InputLabel shrink htmlFor="subtags">Sonstiges</InputLabel>
                                            <Select

                                                multiple

                                                value={this.state.subtags}
                                                onChange={this.handleChangeSubTag.bind(this)}
                                                autoWidth
                                                input={<Input id="subtags" />}
                                                renderValue={selected => selected.join(', ')}
                                            //MenuProps={MenuProps}
                                            >
                                                {_subtags.map(name => (
                                                    <MenuItem key={name} value={name}>
                                                        <Checkbox checked={this.state.subtags.indexOf(name) > -1} />
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: "column", marginTop: 10 }}>

                                    <div>
                                        <FormControl fullWidth >
                                            <TextField
                                                id="start"
                                                label="Start"
                                                type="date"
                                                locale="de"
                                                fullWidth
                                                value={start}
                                                onChange={this.startChanged.bind(this)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                        <FormControl fullWidth >
                                            <TextField
                                                id="end"
                                                label="Ende"
                                                type="date"
                                                locale="de"
                                                fullWidth
                                                value={end}
                                                onChange={this.endChanged.bind(this)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>

                                <div>

                                </div>
                            </CardContent>
                            <CardActions style={{ marginBottom: 20, }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <Button variant="contained" onClick={this.onSubmit.bind(this)}>
                                            {!loading &&

                                                "Suchen"
                                            }
                                            {loading &&
                                                <CircularProgress />
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </CardActions>
                        </Card>
                        {article &&
                            <Card key={article.id} style={{ marginBottom: '15px', maxWidth: '100%' }}>
                                <CardContent>
                                    <h2><strong><Link to={"/article/" + article.postName}>{article.country} ({article.city ? article.city + ', ' : ''}{article.date}): {article.title}</Link></strong></h2>
                                    <Markdown markup={article.markdown} tables={true} />



                                    {article.copyright &&
                                        <p><h6>{article.copyright}</h6></p>
                                    }

                                    {article.tags && article.tags.map(tag => {
                                        return (


                                            <Chip
                                                className="chip"
                                                variant="outlined"
                                                style={{ margin: '3px' }}
                                                key={tag}
                                                label={tag}
                                                onClick={this.onTagClick.bind(this, tag)}
                                            />


                                        )


                                    })}

                                    <div style={{ marginTop: 20, paddingBottom: 20, marginLeft: 5, display: 'flex', flexDirection: 'row', msFlexDirection: 'row' }}>

                                        {article.link &&
                                            <Tooltip title="Zur Original-Quelle">
                                                <a style={{ marginLeft: 0 }} href={article.link}><LinkIcon /></a>
                                            </Tooltip>
                                        }
                                        <Tooltip title="Auf Facebook teilen">
                                            <div>
                                                <FacebookShareButton style={{ marginLeft: 10, marginRight: 10 }} url={"https://riskcompass.eu/article/" + article.postName}>

                                                    <FacebookIcon size={24} round />

                                                </FacebookShareButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Auf Twitter teilen">
                                            <div>
                                                <TwitterShareButton style={{ marginLeft: 10, marginRight: 10 }} title={`${article.country} (${article.city ? article.city + ', ' : ''}${article.date}): ${article.title}`} url={"https://riskcompass.eu/article/" + article.postName}>
                                                    <TwitterIcon size={24} round />
                                                </TwitterShareButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Per Telegram teilen">
                                            <div>
                                                <TelegramShareButton style={{ marginLeft: 10, marginRight: 10 }} url={"https://riskcompass.eu/article/" + article.postName}>
                                                    <TelegramIcon size={24} round />
                                                </TelegramShareButton>
                                            </div>
                                        </Tooltip>

                                        <Tooltip title="Per Whatsapp teilen">
                                            <div>
                                                <WhatsappShareButton style={{ marginLeft: 10, marginRight: 10 }} url={"https://riskcompass.eu/article/" + article.postName}>
                                                    <WhatsappIcon size={24} round />
                                                </WhatsappShareButton>
                                            </div>
                                        </Tooltip>

                                        <Tooltip title="Per Email teilen">
                                            <div>
                                                <EmailShareButton style={{ marginLeft: 10, marginRight: 10 }} url={"https://riskcompass.eu/article/" + article.postName}>
                                                    <EmailIcon size={24} round />
                                                </EmailShareButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Drucken">

                                            <PrintIcon style={{ marginLeft: 10, }} onClick={window.print} />

                                        </Tooltip>

                                        {(admin || editor) &&
                                            <Tooltip title="Artikel bearbeiten">
                                                <a style={{ marginLeft: 10, }} href={article.link}><Link to={"/article/edit/" + article.id}><EditIcon /></Link></a>
                                            </Tooltip>
                                        }


                                    </div>



                                </CardContent>

                            </Card>
                        }
                    </Grid>
                </Grid>

            </div>
        )
    }
}

function mapStateToProps(state) {


    return {
        checklists: state.articles.checklists,
        admin: state.auth.admin,
        editor: state.auth.editor
    }
}

export default withStyles(styles, { name: 'Police' })(connect(mapStateToProps)(Police));