import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardContent, List, ListItem, ListItemText, Tooltip, Divider, IconButton } from '@material-ui/core';
import { ROOT_URL } from "../../config";
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import { Link } from 'react-router-dom';
import { Markdown } from 'react-showdown';
import Map from '../map/map_index'
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { goToRoute } from './actions'
import OfferForm from '../offerForm'
//import ReactGA from 'react-ga';
import PrintIcon from '@material-ui/icons/Print'
import pdf from '../../files/RiskCompass_Auslandssicherheit_Preisliste.pdf'
import { fetchPage } from '../page/actions'
const gaProperty = 'UA-130702159-1'


class Portfolio extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pageName: null };

    }
    UNSAFE_componentWillMount() {
        //ReactGA.initialize(gaProperty);
        //ReactGA.pageview(window.location.pathname + window.location.search)

    }

    componentDidMount() {
        this.props.fetchPage("portfolio")
    }



    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate() {

    }
    handleListItemClick(pageName) {
        // console.log('handleListItemClick', pageName)
        this.props.goToRoute("/page/" + pageName)
        this.setState({ pageName })

    }



    render() {
        const { pageName } = this.state
        const { checklists, admin, editor, page, lang } = this.props
        console.log('page', page)
        // const contentSize = this.props.match.params.postName ? 12 : 9
        return <div>
            {page && page.type == "portfolio" && 
                <div>

                    <Grid style={{ maxWidth: '100%', padding: '12px', paddingTop: 0, paddingBottom: 0, marginTop: '0px', marginLeft: '0px', }} container >
                        <Grid item lg={1} sm={0} xs={0} md={0} >
                        </Grid>
                        <Grid item lg={10} sm={12} xs={12} md={12} >
                            <CardContent style={{ marginTop: 0, marginBottom: 0, textAlign: 'center', paddingBottom: 10 }}>
                                <h1><b style={{ marginBottom: 0, fontSize: 32 }}>{page.title[lang]}</b>
                                    <IconButton className={"no-print"} style={{ paddingLeft: 20 }} onClick={window.print}>
                                        <PrintIcon />
                                    </IconButton>
                                </h1>

                            </CardContent>

                        </Grid>
                        <Grid item lg={1} sm={0} xs={0} md={0} >
                        </Grid>
                    </Grid>



                    <Grid style={{ maxWidth: '100%', padding: '12px', paddingTop: 0, marginTop: '0px', marginLeft: '0px' }} container >
                        <Grid item lg={1} sm={0} xs={0} md={0} >
                        </Grid>



                        <Grid item lg={10} sm={12} xs={12} md={12} >



                            <Grid style={{ maxWidth: '100%', padding: '12px', paddingTop: 0, marginTop: '0px', marginLeft: '0px' }} container >


                                {page.fields && page.fields.filter((field) => field.type == 'portfolio').map((field) => {
                                    return (
                                        <Grid item lg={4} sm={12} xs={12} md={4} >
                                            <Card style={{}}>
                                                <CardContent style={{ marginTop: 0, marginBottom: 0, textAlign: 'center', paddingBottom: 10, backgroundColor: 'rgba(56, 160, 229, 0.61)' }}>
                                                    <b style={{ fontSize: 18, color: 'black' }}>{field.title[lang]}</b>
                                                    <div style={{ width: '100%', marginTop: 20, height: 1, backgroundColor: 'white' }}></div>
                                                    <p>  <b style={{ fontSize: 16, color: 'black' }}>{field.subtitle[lang]}</b></p>

                                                </CardContent>
                                                <Divider />
                                                <CardContent style={{ marginTop: 0, paddingTop: 0, }}>
                                                    <Markdown markup={field.markdown[lang]}></Markdown>
                                                    {admin &&
                                                        <Tooltip title="Feld bearbeiten">
                                                            <a style={{ marginLeft: 0 }} ><Link to={"/portfolio/edit"}><EditIcon /></Link></a>
                                                        </Tooltip>
                                                    }
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                    )
                                })}

                            </Grid>
                            <Grid style={{ maxWidth: '100%', padding: '12px', paddingTop: 0, paddingBottom: 0, marginTop: '0px', marginLeft: '0px', }} container >
                                <Grid item lg={1} sm={0} xs={0} md={0} >
                                </Grid>

                                <Grid item lg={1} sm={0} xs={0} md={0} >
                                </Grid>

                            </Grid>





                        </Grid>








                    </Grid>
                </div>
            }
        </div>

    }

}

function mapStateToProps(state) {


    return {
        lang: state.lang.lang,
        page: state.page.page,
        admin: state.auth.admin,
        editor: state.auth.editor
    }
}
const styles = {

}
export default withStyles(styles, { name: 'Portfolio' })(connect(mapStateToProps, {

    fetchPage: fetchPage,
    goToRoute: goToRoute,

})(Portfolio));


