import axios from 'axios';
import {
    FETCH_MAP,
    FETCH_MAPS,
    FETCHED_MAP,
    FETCHED_MAPS,
    FETCHING_MAP,
    FETCHING_MAPS,
} from "../../actions/types";

import {ROOT_URL} from "../../config";


export function fetchMap(id) {
    return function (dispatch) {

        dispatch(fetchingMap());
        
        const url = `${ROOT_URL}/Maps/${id}`

        setTimeout(() =>
            axios.get(url, {
                headers: {authorization: localStorage.getItem('token')}
            })
                .then(response => {
                    let data = response.data;
                   
                    dispatch(fetchedMap(data));
                })
                .catch(() => {
                   // console.log('error fetching the map');
                }), 200);
    }
}

export function fetchMaps(ids) {
    return function (dispatch) {

        dispatch(fetchingMap());
        
        const url = `${ROOT_URL}/Maps/`

        setTimeout(() =>
            axios.get(url, {
                headers: {authorization: localStorage.getItem('token')}
            })
                .then(response => {
                    let data = response.data;
                   
                    dispatch(fetchedMaps(data));
                })
                .catch(() => {
                   // console.log('error fetching the map');
                }), 200);
    }
}



export function fetchingMap()
{
    return {
        type: FETCHING_MAP,
        payload: true
    }
}

export function fetchingMaps()
{
    return {
        type: FETCHING_MAPS,
        payload: true
    }
}

export function fetchedMap(data) {
   // console.log('data', data)
    return {
        type: FETCHED_MAP,
        payload: {
            data: data,
            loading: false
        }
    }
}

export function fetchedMaps(data) {
   // console.log('data', data)
    return {
        type: FETCHED_MAPS,
        payload: {
            data: data,
            loading: false
        }
    }
}