import React, { Component } from 'react';
import DossierChapterEditForm from './forms/dossierchapter_edit_form';
import {fetchDossierChapter} from "./actions";
import {connect} from "react-redux";
import './style/dossier.css';

class DossierChapterEdit extends Component {

    UNSAFE_componentWillMount() {
       // console.log('DossierChapterEdit')
        this.props.fetchDossierChapter(this.props.match.params.id);
    }

    render() {
        return (
            <div style={{maxHeight:'calc ( 100vh - 350px )'}}>
                
                    <DossierChapterEditForm />
                
            </div>
        )
    }
}

export default connect(null, {fetchDossierChapter})(DossierChapterEdit);
